<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Cuentas Propias</li>
      </ol>
    </nav>
    <div class="container-fluid">
      <router-link class="btn btn-small btn-primary" tag="a" to="/admin/accounts/register">Cuenta Nueva</router-link>
    </div>
    <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th class="text-center th-min">Banco</th>
          <th class="text-center th-min">Nombre de la Cuenta</th>
          <th class="text-center th-min">Titular</th>
          <th class="text-center th-min">Nro. Cuenta</th>
          <th class="text-center th-min">Estatus</th>
          <th class="text-center th-min">Balance</th>
          <th class="text-center">Caja</th>
          <th class="text-center">Abono</th>
          <th class="text-center">Activar</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="val in accounts" :key="val.id">
          <td class="">
            <router-link class="text-primary" tag="a" :to="{path:'/admin/accounts/detail/'+val.id}">{{val.bank.name}}</router-link>
            </td>
          <td class="">{{val.name.substr(0,10)}}</td>
          <td class="">{{val.holder.substr(0,10)}}</td>
          <td class="jj-center-align">{{val.account_dni}}</td>
          <td class="jj-center-align">{{(val.status == 1) ? 'ACTIVA' : 'INACTIVA'}}</td>
          <td class="jj-right-align">{{val.balance}}</td>
          <td class="text-center">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" @click="setAccountByCash(val.id,$event)" :checked="val.activate_cash">
            </div>
          </td>
          <td class="text-center">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" @click="setAccountByPayment(val.id,$event)" :checked="val.activate_payment">
            </div>
          </td>
           <td class="text-center">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" @click="setAccountStatus(val.id,$event)" :checked="val.status">
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  </div>
</template>
<script>
import AccountService from '@/service/AccountService'
import axios from 'axios';
export default {
  
  data(){
    return {
      accounts:[],
    }
  },

  created(){
    AccountService.get()
    .then(resp => this.accounts = resp.data.data)
  },

  methods:{

    setAccountByCash(id,event){
      axios.put('accounts/set-cash',{id:id,value:event.target.checked})
      .then(resp => {
        
      })
      .catch(err => {
        alert("Error")
      })
    },

    setAccountByPayment(id, event){
      axios.put('accounts/set-payment',{id:id,value:event.target.checked})
      .then(resp => {
        
      })
      .catch(err => {
        alert("Error")
      })
    },

    setAccountStatus(id, event){
      axios.put('accounts/set-status',{id:id,value:event.target.checked})
      .then(resp => {
        
      })
      .catch(err => {
        alert("Error")
      })
    }
  }
}
</script>
