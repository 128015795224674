<template>
  <div><br>
      <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary"><router-link tag="a" to="/">Tablero</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">Ordenes de Pago</li>
      </ol>
    </nav>
      <OperationNumbersForm class="display-hide"/>
      <div class="table-responsive-xl">
        <table v-if="display_table" class="table-hover table">
          <thead>
            <tr>
            <th class="center-align">
              <button class="btn btn-small btn-primary" @click="showSearchOption">
                  <i class="material-icons">search</i>
              </button>
          </th>
          <th class="center-align th-min">Registrada el</th>
          <th class="center-align th-min">Registrada por</th>
          <th class="center-align th-min">N# Orden</th>
          <th class="center-align th-min">
            <select class="form-control" v-model="account_id">
              <option value="">Cuentas</option>
              <option v-for="val in accountsFilter" :key="val.id" :value="val.id">{{val.name}}</option>
            </select>
            </th>
          <th class="center-align th-min">Nro. de Operación</th>
          <th class="center-align th-min">F. Operacion</th>
          <th class="center-align">Monto</th>
          <th class="center-align"></th>
        </tr>
      </thead>
      <tbody>
          <tr v-for="(val, index) in ordersFilter" :key="val.id">
              <td class="jj-center-align">{{index + 1}}</td>
              <td class="jj-center-align">{{val.created_at}}</td>
              <td>{{val.user.name}}</td>
              <td class="text-center">
                  <router-link tag="a" :to="{path:'/confirm/payment-orders/detail/'+val.id}">
                    {{val.order_code}}
                  </router-link>
                  </td>
              <td class="text-left">{{nombreCuenta(val)}}</td>
              <td class="text-center">
                  <router-link class="text-primary" tag="a" :to="{path:'/confirm/payment-orders/detail/'+val.id}">
                    {{(val.payment_in) ? val.payment_in.operation_number : 'N/D'}}
                  </router-link>
              </td>
              <td class="text-center">
                  <span v-if="val.confirmed">{{(val.payment_in) ? val.payment_in.date : 'N/D'}}</span>
                  <span v-else>**********</span>
                  </td>
              <td class="text-right">
                  <span v-if="val.confirmed">{{val.amount}}</span>
                  <span v-else>**********</span>
                  </td>
              <td class="jj-right-align">
                  <i v-if="val.confirmed" class="material-icons text-success" data-toggle="tooltip" data-placement="left" title="Confirmado">done_all</i>
                  <i v-else class="material-icons text-warning" data-toggle="tooltip" data-placement="left" title="No Confirmado">report_problem</i>
              </td>
          </tr>
          <PaymentAgentsList v-for="val in payments" :key="val.id" :payment="val"/>
      </tbody>
    </table>
    </div>
    <SearchOption v-if="display_search"/> 

    
  </div>
</template>
<script>
import EventBus from "@/event-bus.js";
import ConfirmationService from '@/service/ConfirmationService';
import SearchOption from '@/components/confirm/SearchOptionsView'
import OperationNumbersForm from '@/components/confirm/OperationNumbersFormView'
import PaymentAgentsList from '@/components/confirm/PaymentAgentsList'

export default {
    components:{
        SearchOption,
        OperationNumbersForm,
        PaymentAgentsList
    },

  data() {
    return {
        display_table:true,
        display_search:false,
        account_id:'',
        orders: [],
        payments:[],
    };
  },

  async created(){
      EventBus.$on("searchParam", this.searchParam);
      EventBus.$on("searchRange", this.searchRange);
      EventBus.$on("refreshOperationNumbersList", this.refreshList);
      EventBus.$on("hideSearch", this.hideSearch);
      await ConfirmationService.Order.get().then(resp => this.orders = resp.data);
      await ConfirmationService.PaymentAgent.get().then(resp => this.payments = resp.data);
  },

  computed:{
     accountsFilter() {
      const data = this.orders;
      const result = [];
      const map = new Map();

      for (const val of data) {
        
        if(val.payment_in){
          let account = val.payment_in.account_receive;
          if(typeof(account) == "string"){
              account = JSON.parse(account);
          }
          if (!map.has(account.account_id)) {
            map.set(account.account_id, true); // set any value to Map
            result.push({
              id: account.account_id,
              name: account.name
            });
          }
        }
      }

      return result;
    },

    ordersFilter(){
      let filter_data = this.orders

      if(this.account_id !=''){
          filter_data = filter_data.filter(order => {

            let cuenta = order.payment_in.account_receive;
            if(typeof(cuenta) == "string"){
                cuenta = JSON.parse(cuenta);
            }
            // console.log("Received "+JSON.parse(order.payment_in.account_receive).account_id+" Selected "+this.account_id)
              return cuenta.account_id == this.account_id;
          })
      }

      return filter_data
    }
  },

  methods:{
      showSearchOption(){
          this.display_search = true;
        this.display_table = false;
         /*  this.$modal.show('search') */
      },

      async refreshList(){
          this.orders = [];
          await ConfirmationService.Order.get().then(resp => this.orders = resp.data);
      },

    searchParam(data){
        this.display_search = false;
        this.display_table = true;
        let query = '?opt='+data.search_option+'&val='+data.param;

          ConfirmationService.Order.get(query)
          .then(resp => {
              this.orders= []; 
              if(resp.data.length <= 0 && resp.data.order_type_id == 3){
                  window.swal("Información", "No se encontraron resultados.", "warning")
              }
              this.orders = resp.data
              
              })
          .catch(error => {
                let obj = error.response.data.error.message;
                let message = "";

                for (const prop in obj) {

                    if(prop == 'no-confirmed'){
                        return window.swal("Información", obj[prop], "error")
                    }
                    message += obj[prop]+"\n";
                    //console.log(`obj.${prop} = ${obj[prop]}`);
                }
                window.swal("Información", message, "error")
              
          })
      },

      searchRange(data){
          this.display_search = false;
            this.display_table = true;
          let query = '?opt=range'+'&fd='+data.from_date+'&td='+data.to_date+'&s='+data.status_id+'&c='+data.currency_id;
          this.orders = []
          ConfirmationService.Order.get(query)
          .then(resp => this.orders = resp.data)
          .catch(error => {
                let obj = error.response.data.error.message;
                let message = "";

                for (const prop in obj) {

                    if(prop == 'no-confirmed'){
                        return window.swal("Información", obj[prop], "error")
                    }
                    message += obj[prop]+"\n";
                    //console.log(`obj.${prop} = ${obj[prop]}`);
                }
                window.swal("Información", message, "error")
              
          })
      },

      nombreCuenta(val){
        
        if(val.payment_in){
          let cuenta = val.payment_in.account_receive;
              if(typeof(cuenta) == "string"){
                  cuenta = JSON.parse(cuenta);
              }
            return cuenta.name;

        }else{
          return 'N/D'
        }
        
      },
       hideSearch(){
      this.display_search = false;
      this.display_table = true;
    },
  }
};
</script>