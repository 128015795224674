<template>
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <label for="country_name" class="active">Pais</label>
              <select class="form-control" v-model="country" :disabled="id">
                <option value>SELECCIONE</option>
                <option v-for="(val, index) in countries" :key="index" :value="val.id">{{val.name}}</option>
              </select>
            </div>
            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <label for="currency_name" class="active">Moneda</label>
              <select class="form-control" v-model="currency" :disabled="id">
                <option value>SELECCIONE</option>
                <option v-for="(val, index) in currencies" :key="index" :value="val.id">{{val.name}}</option>
              </select>
            </div>
            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <label for="rb_wholesale_rate">Tasa al Mayor</label>
              <input id="rb_wholesale_rate" type="text" class="form-control" v-model="wholesale_rate"/>
            </div>
             <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <label for="rb_moneychanger_rate">Tasa Cambista</label>
              <input id="rb_moneychanger_rate" type="text" class="form-control" v-model="moneychanger_rate"/>
            </div>
            
             <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <label for="rb_purchase_rate">Tasa Compra Detal ($)</label>
              <input id="rb_purchase_rate" type="text" class="form-control" v-model="purchase_rate"/>
            </div>
            <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <label for="rb_sale_rate" class="active">Tasa Venta Detal ($)</label>
              <input id="rb_sale_rate" type="text" class="form-control" v-model="sale_rate"/>
            </div>
          </div>
        </div>
      </div>
    
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="rb_retail_rate">Tasa Moneda al Detal</label>
                        <input id="rb_retail_rate" type="text" class="form-control" v-model="retail_rate" @keyup="calculateProfitability"/>
                    </div>
                    <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="rb_profitability">Rentabilidad (%)</label>
                        <input id="rb_profitability" type="text" class="form-control" :class="setClassProfit" v-model="profitability" disabled />
                    </div>
                    <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="rb_retail_rate_especial">Tasa Moneda al Detal (Especial)</label>
                        <input id="rb_retail_rate_especial" type="text" class="form-control" v-model="retail_rate_especial" @keyup="calculateProfitabilityEsp"/>
                    </div>
                    <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                        <label for="rb_profitability_especial">Rentabilidad (% Tasa Especial)</label>
                        <input id="rb_profitability_especial" type="text" class="form-control" :class="setClassProfitEsp" v-model="profitability_especial" disabled />
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
      <div class="card">
        <div class="card-body">
          <div class="row">
           
            <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <label for="rb_dolar_rate" class="active">Tasa Remesas ($)</label>
              <input id="rb_dolar_rate" type="text" class="form-control" v-model="dolar_rate"/>
            </div>
            <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <label for="rb_dolar_rate_especial" class="active">Tasa Remesas Especial ($)</label>
              <input id="rb_dolar_rate_especial" type="text" class="form-control" v-model="dolar_rate_especial"/>
            </div>
            <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <label for="rb_dolar_rate_especial" class="active">Retrasar Actualización (Minutos)</label>
              <input type="number" id="update_in" class="form-control" v-model="update_time">
            </div>
            <div class="form-group text-right col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div class="btn-group">
                    <button class="btn btn-success" @click="updateRate" :disabled="disabled_action">Actualizar Tasa</button>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RateService from '@/service/RateService';
import SelectListService from "@/service/SelectListService";
import Rate from "@/modules/Rate";
import Helpers from "@/Helpers";

export default {
    props:["id"],
    data(){
        return {
            disabled_action:false,
            percentage_rate:0.5,
            calculated:false,
            country:'',
            currency:'',
            rate_exchange_country:'',
            moneychanger_rate:'',
            wholesale_rate:'',
            retail_rate:'',
            retail_rate_especial:'',
            profitability:'',
            profitability_especial:'',
            purchase_rate:'',
            sale_rate:'',
            dolar_rate:'',
            dolar_rate_especial:'',
            update_time:0,
            countries:[],
            currencies:[],
        }
    },

    async mounted(){
        await SelectListService.Currency.get().then(resp => this.currencies = resp.data)
        await SelectListService.country.get().then(resp => this.countries = resp.data)
        await RateService.getById(this.id)
        .then(resp => this.setField(resp.data))
    },

    computed:{
        setClassProfit(){
            if(this.profitability > 0){
                return 'text-success';
            }else{
                return 'text-danger';
            }
        },
        setClassProfitEsp(){
            if(this.profitability_especial > 0){
                return 'text-success';
            }else{
                return 'text-danger';
            }
        },
    },

    watch:{
      wholesale_rate(){
        this.calculateProfitability();
        this.calculateProfitabilityEsp();
      },

      moneychanger_rate(){
        this.calcPurchaseSaleRate();
      }
    },

    methods:{

        calcPurchaseSaleRate(){
          // this.purchase_rate = (this.moneychanger_rate / (1 + (this.percentage_rate / 100 ))).toFixed(3);
          // this.sale_rate = (this.moneychanger_rate * (1 + (this.percentage_rate / 100 ))).toFixed(3);
          this.dolar_rate = this.retail_rate * this.purchase_rate;
          this.dolar_rate_especial = this.retail_rate_especial * this.purchase_rate;
        },

        calculateProfitability(){
            
            let profitability = this.retail_rate / this.wholesale_rate;
            
            if(profitability > 1){
                profitability = (profitability - 1) * 100; 
            }else{
                profitability = (profitability * 100 ) - 100;
            }

            this.profitability = profitability.toFixed(2);
            this.dolar_rate = this.retail_rate * this.purchase_rate;
        },

        calculateProfitabilityEsp(){

           let profitability_esp = this.retail_rate_especial / this.wholesale_rate;

           if(profitability_esp > 1){
                profitability_esp = (profitability_esp - 1) * 100; 
            }else{
                profitability_esp = (profitability_esp * 100 ) - 100;
            }
            this.profitability_especial = profitability_esp.toFixed(2);
            
            this.dolar_rate_especial = this.retail_rate_especial * this.purchase_rate;
        },

        updateRate(){
            this.disabled_action = true;
            Rate.update(this.$data, this.id)
            .then(resp => {
                this.calculated = false;
                window.swal("Información", resp.data.message, "success");

                this.$store.dispatch('socket_system_message', resp.data.system_message);
                this.$store.dispatch('socket_system_message', '');
                this.disabled_action = false;
            })
            .catch(error => {
                Helpers.showError(error);
                this.disabled_action = false;
            })
        },

        emptyField(){
            this.retail_rate = '';
            this.retail_rate_especial = '';
            this.profitability = '';
            this.profitability_especial = '';
            this.purchase_rate = '';
            this.dolar_rate = '';
            this.dolar_rate_especial = '';
        },

        setField(data){
            this.country = data.country_id;
            this.currency = data.currency_id;
            this.moneychanger_rate = data.moneychanger_rate;
            this.wholesale_rate = data.wholesale_rate;
            this.retail_rate = data.retail_rate;
            this.retail_rate_especial = data.retail_rate_especial;
            this.profitability = data.profitability;
            this.profitability_especial = data.profitability_especial;
            this.purchase_rate = data.purchase_rate;
            this.sale_rate = data.sale_rate;
            this.dolar_rate = data.dolar_rate;
            this.dolar_rate_especial = data.dolar_rate_especial;
        }
    }
}
</script>