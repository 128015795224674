
<template>
  <div class="row mt-2">
    <div class="col s12">
      <ul class="list-group">
        <li class="list-group-item">Lista de Usuarios Conectados</li>
        <li class="list-group-item" v-for="(user,index) in users" :key="index">{{user.email}}</li>
        
      </ul>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {

    computed:{
        ...mapGetters(['users', 'username'])
    },

    mounted(){
      this.usersList();
    },

    methods:{
      usersList(){
        this.$store.dispatch('socket_users_list');
      },

  }

    
};
</script>