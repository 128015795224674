<template>
<div class="modal fade" id="form-busqueda" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Formulario de Busqueda</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
       <div class="modal-body">
             <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Basica</a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" id="messages-tab" data-toggle="tab" href="#messages" role="tab" aria-controls="messages" aria-selected="false">Rango</a>
                </li>
            </ul>
             <div class="tab-content">
              <div class="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <br>
                <div class="card">
                  <div class="card-body">
                    <div class="form-group">
                      <label for="">Opciones</label>
                      <select class="form-control" v-model="filtro">
                        <option value="cod">CODIGO REMESA</option>
                        <option value="nombre">NOMBRE</option>
                        <option value="dni">N# DOCUMENTO</option>
                        <option value="tlf">N# TELEFONO</option>
                        <option value="email">EMAIL</option>
                        <option value="n_operation">NUM. OPERACION</option>
                        <option value="c_bancaria">N# CUENTA</option>
                        <option value="soporte">REMESAS CON SOPORTE</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="">Texto Busqueda</label>
                      <input type="text" v-model="textoBusqueda" class="form-control">
                    </div>
                  
                    <button @click="busquedaBasica()" type="button" class="btn btn-primary">Aplicar</button>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="messages" role="tabpanel" aria-labelledby="messages-tab" >
                 <div class="card">
                  <div class="card-body">
                    <div class="form-group">
                      <label for="">Opcion de Busqueda</label>
                      <select v-model="fechaBusqueda" class="form-control">
                        <option value="c">Fecha de Registro</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="">Fecha desde</label>
                      <input type="date" v-model="fechaDesde" class="form-control">
                    </div>
                    <div class="form-group">
                      <label for="">Fecha Hasta</label>
                      <input type="date" v-model="fechaHasta" class="form-control">
                    </div>


                    <button @click="busquedaRango()" type="button" class="btn btn-primary">Aplicar</button>
                  </div>
                  </div>
              </div>
            </div>
            
          </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
    
    data(){
        return {
            filtro:'cod',
            textoBusqueda:'',
            fechaBusqueda:'c',
            fechaDesde:'',
            fechaHasta:'',
        }
    },

    methods:{
        busquedaBasica(){
            this.$emit('buscar', {tipo_busqueda:'basica', filtro: this.filtro, texto_busqueda: this.textoBusqueda});
            window.jQuery("#form-busqueda").modal("hide");
        },

        busquedaRango(){
            this.$emit('buscar', {tipo_busqueda:'rango', fecha_desde: this.fechaDesde, fecha_hasta: this.fechaHasta});
            window.jQuery("#form-busqueda").modal("hide");
        }
    }
}
</script>