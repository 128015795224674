<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Agentes Autorizados
        </li>
      </ol>
    </nav>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-4 pt-3">
        <router-link
          tag="a"
          :to="'/admin/agents/thirdparty/new'"
          class="btn btn-small btn-primary"
          >Nuevo</router-link
        >
      </div>
    </div>
    <div class="row">
      <div
        v-for="(val, index) in agents"
        :key="val.agent.id"
        class="col-12 col-sm-12 col-md-6 col-lg-4 pt-3"
      >
        <div class="card">
          <div class="card-header">
            <div class="card-title text-primary">
              <router-link
                tag="a"
                :to="'/admin/agents/thirdparty/' + val.agent.id"
                ><h3 class="text-truncate">
                  {{ val.agent.business_name }}
                </h3></router-link
              >
            </div>
            <h5 class="text-truncate">{{ val.agent.email }}</h5>
          </div>
          <div class="card-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="home-tab"
                  data-toggle="tab"
                  :href="'#home' + val.agent.id"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  >Balance</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="profile-tab"
                  data-toggle="tab"
                  :href="'#profile' + val.agent.id"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  >Ordenes</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="settings-tab"
                  data-toggle="tab"
                  :href="'#settings' + val.agent.id"
                  role="tab"
                  aria-controls="settings"
                  aria-selected="false"
                  >Foto</a
                >
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="recarga-tab"
                  data-toggle="tab"
                  :href="'#recarga' + val.agent.id"
                  role="tab"
                  aria-controls="recarga"
                  aria-selected="false"
                  >Recarga</a
                >
              </li>
            </ul>
            <div class="tab-content">
              <div
                class="tab-pane active"
                :id="'home' + val.agent.id"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <table class="table">
                  <caption>
                    Billeteras
                  </caption>
                  <thead>
                    <tr>
                      <th class="text-center">Tipo</th>
                      <th class="text-center">Moneda</th>
                      <th class="text-center">Saldo</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="account in val.accounts" :key="account.id">
                      <td class="text-center">
                        {{
                          account.type_account == "orders" ? "Remesas" : "Giros"
                        }}
                      </td>
                      <td class="text-center">{{ account.currency.name }}</td>
                      <td class="text-right">{{ account.balance }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="tab-pane"
                :id="'profile' + val.agent.id"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <table class="table">
                  <thead>
                    <tr>
                      <th class="text-center">Estatus</th>
                      <th class="text-center">Moneda</th>
                      <th class="text-center">Monto</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="order in val.order_day" :key="order.id">
                      <td class="text-center">{{ order.status_name }}</td>
                      <td class="text-center">{{ order.currency_name }}</td>
                      <td class="text-right">{{ order.total }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="tab-pane"
                :id="'settings' + val.agent.id"
                role="tabpanel"
                aria-labelledby="settings-tab"
              >
                <img :src="setImage(val.agent.img)" class="img-fluid" />
              </div>
              <div
                class="tab-pane"
                :id="'recarga' + val.agent.id"
                role="tabpanel"
                aria-labelledby="recarga-tab"
              >
                <div class="container-fluid">
                  <br />
                  <div class="form-group">
                    <label for="method">Metodo de Pago</label>
                    <select
                      id="method"
                      class="form-control"
                      v-model="payment_method"
                    >
                      <option value>SELECCIONE</option>
                      <option
                        v-for="val in payment_methods"
                        :key="val.id"
                        :value="val.id"
                      >
                        {{ val.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="">Cuenta Bancaria</label>
                    <select id="account" class="form-control" v-model="account">
                      <option value>SELECCIONE</option>
                      <option
                        v-for="val in accounts"
                        :key="val.id"
                        :value="val.id"
                      >
                        {{ showAccount(val) }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="num_operation">Nro. de Operación</label>
                    <input
                      id="num_operation"
                      type="text"
                      class="form-control"
                      v-model="operation_number"
                    />
                  </div>
                    <div class="form-group">
                      <label for="_date">Fecha</label>
                      <input
                        id="_date"
                        type="date"
                        class="form-control"
                        v-model="date"
                      />
                    </div>
                  
                  <div class="form-group">
                    <label for="">Monto del Abono</label>
                    <input
                      class="form-control text-right"
                      v-model="montoAbono"
                      type="text"
                    />
                  </div>
                  <div class="form-group">
                    <button
                      class="btn btn-success"
                      @click="abonarSaldo(val.agent.id)"
                    >
                      Recargar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import AgentService from "@/service/AgentService";
import Confirmation from '@/modules/Confirmation';
import SelectListService from '@/service/SelectListService';
import ConfirmationService from '@/service/ConfirmationService';

export default {
  data() {
    return {
      agents: [],
      payment_methods:[],
      accounts:[],
      datosPago:[],
      payment_method:'',
      account:'',
      operation_number:'',
      date:'',
      montoAbono: "",
    };
  },

  async created() {
    await AgentService.get("1").then((resp) => (this.agents = resp.data));
    await SelectListService.PaymentMethod.get().then(resp => this.payment_methods = resp.data);
    await ConfirmationService.Account.get().then(resp => this.accounts = resp.data.data);
  },

  methods: {
    setImage(img) {
      return process.env.VUE_APP_AXIOS_PUBLIC_URL + "storage/agents/" + img;
    },

    showAccount(account){

        let number2 = account.account_dni.length;
        let number = parseInt(number2 - 4);
        //console.log(number2 +' '+number+' '+account.account_dni+' => '+account.account_dni.substring(number));

        return account.name +' ['+account.account_dni.substring(number)+']'
      },

    abonarSaldo(agent_id){
      let data = {
        agent_id          : agent_id,
        payment_method    : this.payment_method,
        account           : this.account,
        operation_number  : this.operation_number,
        date              : this.date,
        montoAbono        : this.montoAbono,
      };

      axios.post('admin/registrar-abono', data)
      .then(resp => {
        
      })
      
    }
  },
};
</script>