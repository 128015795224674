<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item text-primary" aria-current="page">
            <router-link tag="a" to="/admin/users">Listado Usuarios</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            Registrar
        </li>
      </ol>
    </nav>
    <div class="row mt-2">
      <div class="col-12 col-sm-12 col-md-12 col-lg-8">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              <h3>Formulario de Usuario</h3>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12">
                <label for="name">Nombre Completo</label>
                <input id="name" type="text" class="validate form-control" v-model="name" />
              </div>
              <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                <label for="name">Email</label>
                <input id="name" type="email" class="validate form-control" v-model="email" />
              </div>
              <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                <label for="name">Password</label>
                <input id="name" type="password" class="validate form-control" v-model="password" />
              </div>
              <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                <label for="name" class="active">Roles</label>
                <select v-model="role_id" class="validate form-control">
                  <option value>SELECCIONE</option>
                  <option v-for="val in roles" :key="val.id" :value="val.id">{{val.name}}</option>
                </select>
              </div>
              <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                <label for="name" class="active">Agente</label>
                <select v-model="agent_id" class="validate form-control">
                  <option value>SELECCIONE</option>
                  <option v-for="val in agents" :key="val.agent.id" :value="val.agent.id">{{val.agent.name}}</option>
                </select>
              </div>
              <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                <label for="name" class="active">Tipo de Registro</label>
                <select v-model="register_type_fk_id" class="validate form-control">
                  <option value>SELECCIONE</option>
                  <option v-for="val in registerTypes" :key="val.id" :value="val.id">{{val.name}}</option>
                </select>
              </div>

              <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                <label for="name" class="active">Pais</label>
                <select v-model="country_fk_id" class="validate form-control">
                  <option value>SELECCIONE</option>
                  <option v-for="val in countries" :key="val.id" :value="val.id">{{val.name}}</option>
                </select>
              </div>
              <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                <label for="name" class="active">Estatus</label>
                <select v-model="status" class="validate form-control">
                  <option value>SELECCIONE</option>
                  <option value="1">ACTIVO</option>
                  <option value="0">INACTIVO</option>
                </select>
              </div>
              <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                <button class="btn btn-small btn-primary" @click.prevent="registerUser">Registrar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      name: "",
      email: "",
      password: "",
      role_id: "",
      agent_id: "",
      role_name: "",
      agent_name: "",
      registerTypes:[],
      register_type_fk_id:'',
      country_fk_id:'2',
      status: "",
      roles: [],
      agents:[],
      countries:[],
    };
  },

  created() {
    this.getRoles();
    this.getAgents();
    this.getRegisterTypes();
    this.getCountries();
  },

  watch: {
      role_id: function(new_val) {
        if (new_val != "") {
            if(this.roles){

                let role = this.roles.filter(r => r.id == this.role_id)[0];
                this.role_name = role.slug;
            }
        }
      }
  },

  methods: {
    registerUser() {
      let data = {
        name: this.name,
        email: this.email,
        password: this.password,
        role_id: this.role_id,
        role: this.role_name,
        status_fk_id: this.status,
        register_type_fk_id: this.register_type_fk_id,
        country_fk_id:this.country_fk_id
      };

      axios
        .post("users", data)
        .then(response => {
          swal("Informacion", response.data.message, "success")
          this.$router.push({
              path:'/admin/users/edit/'+response.data.id
          });
        })
        .catch(error => {
          let obj = error.response.data.error.message;
          let message = ''
          for (const prop in obj) {
              message += obj[prop]+'\n';
            //console.log(`obj.${prop} = ${obj[prop]}`);
          }
          swal("Informacion", message, "error")
        });
    },

    getRoles() {
      axios.get("roles").then(response => {
        this.roles = response.data;
      });
    },

    getAgents() {
      axios.get("agents?o=0").then(response => {
        this.agents = response.data;
      });
    },

    getRegisterTypes() {
      axios.get("register-types").then(response => {
        this.registerTypes = response.data;
      });
    },

    getCountries() {
      axios.get("countries").then(response => {
        this.countries = response.data;
      });
    }
  }
};
</script>

<style scoped>
</style>