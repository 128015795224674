<template>
  <div class="row mt-2">
    <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="form-group text-left col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
             <div class="custom-file">
              <input type="file" class="custom-file-input" id="customFileLang" ref="picture" v-on:change="handlePictureUpload()" lang="es">
              <label class="custom-file-label" for="customFileLang">Seleccionar Imagen</label>
            </div>
            </div>
             <div class="form-group text-right col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                 <div class="btn-group">
                    <button class="btn btn-primary" @click="uploadImage()">Subir Imagen</button>
                 </div>
            </div>

          </div>

            <img :src="setImage" class="img-fluid" alt="Responsive image">
        </div>
       
      </div>
      
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <h2>Imagen Previa</h2>
      <img :src="setImagePrev" class="img-fluid" alt="Responsive image">
    </div>
  </div>
</template>
<script>
import Rate from "@/modules/Rate";

const url = process.env.VUE_APP_AXIOS_PUBLIC_URL;

export default {
    props:["id"],
    data(){
        return {
            image:'',
            picture:null,
            picture_prev:null,
        }
    },

    async mounted(){
      await Rate.getLastImageRate().then(resp => this.picture_prev = resp.data);
    },

    computed:{
       setImage(){
         return `${url}storage/rate/${this.picture}`
       },

      setImagePrev(){
        return `${url}storage/rate/${this.picture_prev}`
      }
       
    },

    methods:{

      handlePictureUpload() {
        this.image = this.$refs.picture.files[0];
      },

       uploadImage(){

         Rate.uploadImageRate(this.$data)
         .then(resp => {
           this.picture = resp.data.image
           alert(resp.data.message)
         })
         .catch(err => {
            alert(err.response.data.error.message)
         })
       }
    }
}
</script>