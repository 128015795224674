import AccountService from '@/service/AccountService';

export default {

    storage(data){

        return AccountService.post(this.setData(data))
    },
    
    storageInitialBalance(data){
        //ib = Initial Balance
        let query = '?opt=ib&act=ins'
        return AccountService.post(data, query)
    },
    updateInitialBalance(data){
        //ib = Initial Balance
        let query = '?opt=ib&act=upd'
        return AccountService.put(data, data.account_id, query)
    },
    confirmInitialBalance(id){
        //ib = Initial Balance
        let query = '?opt=ib&act=conf'
        return AccountService.put({}, id, query)
    },

    update(data, id){

        return AccountService.put(this.setData(data), id)
    },

    setData(data){

        return {
            country_id : data.country_id,
            pay_system_id  : data.pay_system_id,
            account_type_id : data.account_type_id,
            bank_id : data.bank_id,
            name : data.name,
            currency_id : data.currency_id,
            document_type_id : data.document_type_id,
            dni : data.dni,
            holder : data.holder,
            nationality_id : data.nationality_id,
            phone : data.phone,
            account_dni : data.account_dni,
            thirdparty : data.thirdparty,
            activate_cash: data.activate_cash,
            activate_payment: data.activate_payment
        }
    },
    
}