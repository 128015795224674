import Service from '@/service/Service';

const resource = 'admin/transactions';

export default {

    get(query){
        let url_query = (query) ? query : '';
        return Service.get(resource+url_query);
    },
    
    getById(id){
        return Service.get(resource+"/"+id);
    },

    post(query,data){

        let form_data = new FormData();
        form_data.append("account_id", data.owner_account_id);
        form_data.append("account_to", data.thirdparty_account_id);
        form_data.append("amount", data.amount);
        form_data.append("comission", data.comission);
        form_data.append("operation_number", data.operation_number);
        form_data.append("date", data.date);
        form_data.append("time", data.time);
        form_data.append("voucher", data.voucher);
        form_data.append("concept_operation_id", data.concepts_operation_id);
        form_data.append("description", data.description);

        return Service.post(resource+query, form_data, {
            headers:{
                'Content-Type' : 'multipart/form-data'
            }
        }); 
    },

    postIncome(data, query){

        return Service.post(resource+query, data,  {
            headers:{
                'Content-Type' : 'multipart/form-data'
            }
        })
    },
    postPay(query,data){

        return Service.post(resource+query, data,  {
            headers:{
                'Content-Type' : 'multipart/form-data'
            }
        })
    },

    delete(id){
        return Service.delete(resource+"/"+id);
    }

}