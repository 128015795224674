<template>
    <table>
        <thead>
            <tr>
                <th class="text-center">Fecha</th>
                <th class="text-center">Monto</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(ajuste, index) in ajustes" :key="'ajs'+index">
                <td class="text-center">{{ajuste.created_at}}</td>
                <td class="text-right">{{ajuste.total}}</td>
            </tr>
        </tbody>
    </table>

</template>

<script>
import axios from 'axios';

    export default {
        props:["cuenta_id", "mid", "tipo"],
        data(){
            return {
                ajustes:[]
            }
        },

        created(){
            this.getAjustes();
        },

        methods:{

            getAjustes(){
                axios.get(`ajustes?opt=mov-ajustes&cid=${this.cuenta_id}&mid=${this.mid}`)
                .then(resp => {
                    this.ajustes = resp.data;
                })
            }
        }

    }
</script>

<style scoped>

</style>