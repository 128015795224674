<template>
    <div>
            <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Balance de Cuentas
        </li>
      </ol>
    </nav>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Cuentas en Soles</h5>
                <table class="table table-striped">
                    <thead>
                        <th class="text-left">Cuenta</th>
                        <th class="text-center">Saldo</th>
                    </thead>
                    <tbody>
                        <tr v-for="(val, index) in cuentasSoles" :key="'soles-'+index">
                            <td><router-link tag="a" style="text-decoration: underline;" :to="{path:`/cuentas/detalle-balance/${val.cuenta_id}/${val.moneda_id}/detalle/0`}">{{nombreCuenta(val)}}</router-link></td>
                            <td class="text-right text-primary"><router-link style="text-decoration: underline;" :to="{path:'/cuentas/resumen-balance/'+val.cuenta_id+'/'+val.moneda_id}">{{val.balance | numFormat("0,0.00")}}</router-link></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="2" class="text-right"><h3>{{calcularTotalSoles | numFormat("0,0.00")}}</h3></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
         <br>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Cuentas en Dolares</h5>
                <table class="table table-striped">
                    <thead>
                        <th>Cuenta</th>
                        <th class="text-center">Saldo</th>
                    </thead>
                    <tbody>
                        <tr v-for="(val, index) in cuentasDolare" :key="'soles-'+index">
                            <td><router-link tag="a" style="text-decoration: underline;" :to="{path:`/cuentas/detalle-balance/${val.cuenta_id}/${val.moneda_id}/detalle/0`}">{{nombreCuenta(val)}}</router-link></td>
                            <td class="text-right text-primary"><router-link style="text-decoration: underline;" :to="{path:'/cuentas/resumen-balance/'+val.cuenta_id+'/'+val.moneda_id}">{{val.balance | numFormat("0,0.00")}}</router-link></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="2" class="text-right"><h3>{{calcularTotalDolares | numFormat("0,0.00")}}</h3></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <br>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Cuentas en Peso Chileno</h5>
                <table class="table table-striped">
                    <thead>
                        <th>Cuenta</th>
                        <th class="text-center">Saldo</th>
                    </thead>
                    <tbody>
                        <tr v-for="(val, index) in cuentasPesoChileno" :key="'soles-'+index">
                            <td><router-link tag="a" style="text-decoration: underline;" :to="{path:`/cuentas/detalle-balance/${val.cuenta_id}/${val.moneda_id}/detalle/0`}">{{nombreCuenta(val)}}</router-link></td>
                          <td class="text-right text-primary"><router-link style="text-decoration: underline;" :to="{path:'/cuentas/resumen-balance/'+val.cuenta_id+'/'+val.moneda_id}">{{val.balance | numFormat("0,0.00")}}</router-link></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="2" class="text-right"><h3>{{calcularTotalPesoChileno | numFormat("0,0.00")}}</h3></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <br>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Cuentas en Bolivares</h5>
                <table class="table table-striped">
                    <thead>
                        <th>Cuenta</th>
                        <th class="text-center">Saldo</th>
                    </thead>
                    <tbody>
                        <tr v-for="(val, index) in cuentasBolivares" :key="'soles-'+index">
                            <td><router-link tag="a" style="text-decoration: underline;" :to="{path:`/cuentas/detalle-balance/${val.cuenta_id}/${val.moneda_id}/detalle/0`}">{{nombreCuenta(val)}}</router-link></td>
                          <td class="text-right text-primary"><router-link style="text-decoration: underline;" :to="{path:'/cuentas/resumen-balance/'+val.cuenta_id+'/'+val.moneda_id}">{{val.balance | numFormat("0,0.00")}}</router-link></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="2" class="text-right"><h3>{{calcularTotalBs | numFormat("0,0.00")}}</h3></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

    export default {
        
        data(){
            return {
                cuentas:[]
            }
        },

        created(){
            this.getBalance();
        },

        computed:{

            calcularTotalSoles(){
                if(this.cuentas.length <= 0) return 0;

                let total = 0;

                this.cuentas.filter(c => c.moneda_id == 2).forEach(val => {
                    total += parseFloat(val.balance);
                });

                return total;
            },

            calcularTotalDolares(){
                if(this.cuentas.length <= 0) return 0;

                let total = 0;

                this.cuentas.filter(c => c.moneda_id == 4).forEach(val => {
                    total += parseFloat(val.balance);
                });

                return total;
            },

            calcularTotalPesoChileno(){
                if(this.cuentas.length <= 0) return 0;

                let total = 0;

                this.cuentas.filter(c => c.moneda_id == 6).forEach(val => {
                    total += parseFloat(val.balance);
                });

                return total;
            },

            calcularTotalBs(){
                if(this.cuentas.length <= 0) return 0;

                let total = 0;

                this.cuentas.filter(c => c.moneda_id == 1).forEach(val => {
                    total += parseFloat(val.balance);
                });

                return total;
            },

            cuentasSoles(){
                
                
                if(this.cuentas.length <= 0) return [];
                
                return this.cuentas.filter(c => c.moneda_id == 2);
            },

            cuentasDolare(){
                if(this.cuentas.length <= 0) return [];
                return this.cuentas.filter(c => c.moneda_id == 4);
            },

            cuentasPesoChileno(){
                if(this.cuentas.length <= 0) return [];
                return this.cuentas.filter(c => c.moneda_id == 6);
            },

            cuentasBolivares(){
                if(this.cuentas.length <= 0) return [];
                return this.cuentas.filter(c => c.moneda_id == 1);
            }
        },


        methods:{

            nombreCuenta(val){
                if(val.cuenta_id == 0){

                    return 'EFECTIVOJJ';
                }

                if(val.cuenta_id == -1){
                    return 'PUNTO DE VENTA';
                }

                if(val.cuenta){
                    return val.cuenta['name'];

                }

                return 'N/D ['+val.cuenta_id+']';

            },

            getBalance(){
                axios.get('/balance-cuentas')
                .then(resp => {
                    this.cuentas = resp.data.map(c => {

                        

                        return c;
                    });
                })
            }
        }
    }
</script>

<style scoped>

</style>