<template>
    <div class="card">
              <div class="card-header"><a data-toggle="collapse" class="btn btn-link" :href="'#collapseData'+currency.id">{{currency.name}}</a></div>
              <div class="card collapse" :id="'collapseData'+currency.id">
                  <div class="card-body">
                        <ul class="nav nav-tabs mt-2" id="myTab" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" :id="'exchanges-tab'+currency.id" data-toggle="tab" :href="'#exchange'+currency.id" role="tab">Cambios</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :id="'new-tab'+currency.id" data-toggle="tab" :href="'#new'+currency.id" role="tab">Nuevo</a>
                            </li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" :id="'exchange'+currency.id" role="tabpanel" :aria-labelledby="'exchanges-tab'+currency.id">
                                <br />
                                <ExchangeRateDetailView :currency="currency" :currencies="currencies"/>
                            </div>
                            <div class="tab-pane fade show" :id="'new'+currency.id" role="tabpanel" :aria-labelledby="'new-tab'+currency.id">
                                <br />
                                <ExchangeRateFormView :currency="currency" :currencies="currencies"/>
                            </div>
                        </div>
                    
                  </div>
                
                
              </div>
            </div>
</template>
<script>
import ExchangeRateDetailView from '@/components/admin/rates/ExchangeRateDetailView';
import ExchangeRateFormView from '@/components/admin/rates/ExchangeRateFormView';

export default {
    props:["currency", "currencies"],
    components:{
        ExchangeRateDetailView,
        ExchangeRateFormView
    },
    data(){
        return {

        }
    }
}
</script>
<style scoped>
#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: blue;
    background: none !important;
}
</style>