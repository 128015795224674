<template>
  <ul class="list-unstyled components">
    <!-- <p>Menu Principal</p> -->
    <li><router-link to="/">Tablero</router-link></li>
    <li><router-link to="/ordenes-pago">Ordenes de Pago</router-link></li>
    <li><router-link to="/pagos/balance-cuenta">Balance de Cuentas</router-link></li>
    <li><router-link to="/traslados">Traslados</router-link></li>
    <li><router-link to="/payment/gastos">Gastos</router-link></li>
    <li><a href="#" @click.prevent="logout">Cerrar Sesión</a></li>
  </ul>
</template>
<script>
const $ = require("jquery");
// Lo declaramos globalmente
window.$ = $;

export default {
  methods: {
     logout() {
      this.$auth.logout();
      this.$router.push({
        path:'/login'
      });
      $("#sidebar").toggleClass("active");
      //location.reload();
    },
  },
};
</script>