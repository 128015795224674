import ConfirmationService from '@/service/ConfirmationService';

export default {

    storage(data){

        let _data = {
            account: data.account,
            payment_method: data.payment_method,
            operation_number: data.operation_number,
            date: data.date,
            time: data.time,
            comission_amount: data.comission_amount,
            amount: data.amount,
        }
 
        return ConfirmationService.OperationNumbers.post(_data);
    },

    update(data, id){
        let _data = {
            account: data.account,
            payment_method: data.payment_method,
            operation_number: data.operation_number,
            date: data.date,
            time: data.time,
            comission_amount: data.comission_amount,
            amount: data.amount,
        }

        return ConfirmationService.OperationNumbers.put(_data, id);
    },

    delete(id){
        return ConfirmationService.OperationNumbers.delete(id);
    },
    
    confirm(data){
        let query = '?opt=confirm&t=order'
        let _data = {
            order_id: data.order_id,
            operation_number_id: data.operation_number_id,
        }

        return ConfirmationService.OperationNumbers.post(_data,query);
    },
    
    confirmPaymentAgent(data){
        let query = '?opt=confirm&t=payment'
        let _data = {
            id: data.payment_agent_id,
            operation_number_id: data.operation_number_id,
        }

        return ConfirmationService.OperationNumbers.post(_data,query);
    },
}