<template>
  <div>
      <OrdenDetalle v-if="detalle.tipo == 'orden'"  :data="detalle" :cuentas="cuentas"/>
  </div>
</template>

<script>
import axios from "axios";
import OrdenDetalle from '@/components/movimientos/modals/OrdenDetalleMovModal'
export default {
    props:['detalle', 'cuentas'],

    components:{
        OrdenDetalle
    },

  data() {
    return {
      
    };
  },


};
</script>

<style scoped>
</style>