<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Compras</li>
      </ol>
    </nav>
    <div>
      <router-link
        :to="{
          path: '/registrar-compra',
        }"
        class="btn btn-primary"
        >Nueva Compra</router-link
      >
    </div>
    <table>
      <thead>
        <tr>
          <th class="text-center">ID</th>
          <th class="text-center">Tasa Mayor</th>
          <th class="text-center">Total Pagado</th>
          <th class="text-center">Cuenta a Acreditar</th>
          <th class="text-center">Monto Bolivares</th>
          <th class="text-center">Fecha</th>
          <th class="text-center"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="compra in compras" :key="compra.id">
          <td class="text-center">
            <button
              class="btn btn-outline-primary m-1"
              @click="detalleCompra(compra.id)"
            >
              OC-{{ compra.id }}
            </button>
          </td>
          <td class="text-right">
            {{ compra.tasa_compra_mayor | numFormat("0,0.00") }}
          </td>
          <td class="text-right">
            {{ compra.monto_pago | numFormat("0,0.00") }}
          </td>
          <td class="text-left"><div style="margin-left:40px">{{ compra.cuenta.name }}</div></td>
          <td class="text-right">
            {{ compra.monto_moneda_compra | numFormat("0,0.00") }}
          </td>
          <td class="text-center">{{ compra.fecha }}</td>
          <td class="text-center">
            <div class="btn-group" role="group">
              <button
                v-if="!compra.pagada && $auth.check(['admin','confirm'])"
                class="btn btn-sm btn-outline-primary"
                @click="registrarPago(compra)"
              >
                Pagar
              </button>
              <button
                v-if="compra.pagos_compra.length > 0"
                class="btn btn-sm btn-outline-primary"
                @click="verPagos(compra)"
              >
                <i class="material-icons">payments</i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot class="bg-secondary">
        <tr>
          <td colspan="3" class="text-right text-white h5">
            {{ calcularTotalPagado | numFormat("0,0.00") }}
          </td>
          <td colspan="2" class="text-right text-white h5">
            {{ calcularTotalComprado | numFormat("0,0.00") }}
          </td>
          <td colspan="2"></td>
        </tr>
      </tfoot>
    </table>
    <!-- Modal Pagos-->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Listado de Pagos</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table>
              <thead>
                <tr>
                  <th class="text-center">ID</th>
                  <th class="text-center">Descripcion</th>
                  <th class="text-center">Fecha</th>
                  <th class="text-center">Monto (S/)</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="pago in pagos" :key="pago.id">
                  <td class="text-center">{{ pago.id }}</td>
                  <td>{{ pago.descripcion }}</td>
                  <td class="text-center">{{ pago.fecha }}</td>
                  <td class="text-center">
                    {{ pago.monto | numFormat("0,0.00") }}
                  </td>
                  <td>
                    <button
                      class="btn btn-sm btn-outline-primary m-2"
                      type="button"
                      @click="detallePago(pago)"
                    >
                      <i class="material-icons">keyboard_arrow_right</i>
                    </button>
                     <button
                      class="btn btn-sm btn-outline-primary m-2"
                      type="button"
                      @click="downloadImage(pago.id)"
                    >
                      <i class="material-icons">image</i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
            <button v-if="compraSeleccionada.pagada != 1" @click="registrarPago(compraSeleccionada)" type="button" class="btn btn-primary">Agregar Pago</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Detalle Pago -->
    <div
      class="modal fade"
      id="detalle-pago"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Detalle del Pago</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
                    <label for="owner_account">Cuenta Debito </label>
                    <input type="text" class="form-control" :value="(pagoSeleccionado) ? pagoSeleccionado.cuenta['name'] : ''">
                  </div>
                  <div class="form-group">
                    <label for="description">Descripción</label>
                    <input class="form-control" :value="(pagoSeleccionado) ? pagoSeleccionado.descripcion : ''"/>
                  </div>
                  <div class="form-group">
                    <label for="owner_account">Monto</label>
                    <input class="form-control text-right" :value="(pagoSeleccionado) ? pagoSeleccionado.monto : ''"/>
                  </div>
                  <div class="row">
                    <div class="col col-lg-6">
                      <div class="form-group">
                    <label for="owner_account">Comisión</label>
                    <input class="form-control text-right" :value="(pagoSeleccionado) ? pagoSeleccionado.monto_comision : ''"/>
                  </div>
                  
                    </div>
                    <div class="col col-lg-6">
                      <div class="form-group">
                    <label for="owner_account">Total</label>
                    <input class="form-control text-right" :value="(pagoSeleccionado) ? pagoSeleccionado.total : ''"/>
                  </div>
                    </div>
                  </div>
                  
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      pagos: [],
      compras: [],
      compraSeleccionada:'',
      pagoSeleccionado:null,
    };
  },

  created() {
    axios.get("compras").then((resp) => {
      this.compras = resp.data;
    });
  },

  computed: {
    calcularTotalPagado() {
      let total = 0;

      if (!this.compras) {
        return total;
      }

      this.compras.forEach((compra) => {
        total += parseFloat(compra.monto_pago);
      });

      return total;
    },

    calcularTotalComprado() {
      let total = 0;

      if (!this.compras) {
        return total;
      }

      this.compras.forEach((compra) => {
        total += parseFloat(compra.monto_moneda_compra);
      });

      return total;
    },
  },

  methods: {
    detalleCompra(compraId) {
      let compra = this.compras.filter((c) => c.id == compraId)[0];

      this.$router.push({
        name: "detalle-compra",
        params: { compraId, detalle: compra },
      });
      //   console.log(compra);
    },

    registrarPago(compra) {

      this.$router.push({
        path:'/compras/registrar-pago/'+compra.id
      });
       window.jQuery("#exampleModal").modal("hide");
    },

    detallePago(pago) {
      window.jQuery("#exampleModal").modal("hide");
      this.pagoSeleccionado = pago;
      window.jQuery("#detalle-pago").modal("show");


      // this.$router.push({
      //   name: "detalle-pago",
      //   params: { pagoId: pago.id, t:'detalle' },
      // });
      //   console.log(compra);
    },

    verPagos(compra) {
      this.compraSeleccionada = compra;
      this.pagos = compra.pagos_compra;
      window.jQuery("#exampleModal").modal("show");
      // this.$router.push({
      //   name: "pagos-compra",
      //   params: { id: compra.id },
      // });
    },

     downloadImage(id){
        axios({
        method: "get",
        url: "pagos?opt=descargar-voucher&id="+id,
        responseType: "blob" //stream // arraybuffer
        })
        .then(response => {

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.png"); //or any other extension
          link.setAttribute("target", "_blank"); //or any other extension
          document.body.appendChild(link);
          link.click(); 

        })
      }
  },
};
</script>

<style scoped>
</style>