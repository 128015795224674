import Service from './Service';

const resource = 'admin/_orders';

export default {
    
    get(){
        return Service.get(resource);
    },

    getOrder(data){
        return Service.get(resource+"?opt="+data.search_option+"&val="+data.param);
    },

    getRange(data){
        return Service.get(resource+"?fd="+data.from_date+"&td="+data.to_date+"&s="+data.status_id+"&rb="+data.register_by+"&uid="+data.user_id+"&aid="+data.agent_id+"&c="+data.currency_id);
    }
}