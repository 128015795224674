<template>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
      <div class="card">
        <div class="card-body">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Banco</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="val in banksFilter" :key="val.id">
               <!-- <td>
                   <p>
                    <label :for="val.id">
                      <input
                        type="checkbox"
                        :checked="(val.especial_rate) ? 'checked' : ''"
                        :id="val.id"
                        :value="val.id"
                        @click="setEspecialRateBank(val.id)"
                      />
                      <span></span>
                    </label>
                  </p> 
                </td>-->
                <td>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" 
                    :id="val.id"
                    :value="val.id"
                    :checked="(val.especial_rate) ? 'checked' : ''"
                    @click="setEspecialRateBank(val.id)"
                     />
                    <label class="form-check-label" for="defaultCheck1"></label>
                  </div>
                </td>
                <td>{{val.name}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import SelectList from "@/service/SelectListService";
export default {
  data() {
    return {
      banksFilter: []
    };
  },

  mounted() {
    SelectList.bank.get('?c=1').then(resp => (this.banksFilter = resp.data));
  },

  methods:{
       setEspecialRateBank(id){

      let data = {
        bank: document.getElementById(id).value,
        rate: document.getElementById(id).checked

      }

        axios.put("admin/rates/banks", data)
        .then(response => {
            this.banks = [];
            this.getBanks();
            swal("Información", response.data.message, "success");
        })
    },

    getBanks(){
        SelectList.bank.get('?c=1').then(resp => (this.banksFilter = resp.data));
    }
  }
};
</script>