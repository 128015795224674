<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/admin/rates">Tasas</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Calculadora</li>
      </ol>
    </nav>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab">Calcular</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab">Lista</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        <br />
        <div class="row mt-2">
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-12 col-md-12 col-lg-12 col-xl-12">
                    <label for>Calculos Registrados</label>
                    <select v-model="calculate_id" class="form-control">
                      <option value="">SELECCIONE</option>
                      <option v-for="val in calculateList" :key="val.id" :value="val.id">{{val.name}}</option>
                    </select>
                  </div>
                  <div class="form-group col-12 col-md-12 col-lg-12 col-xl-12">
                    <label for>Monto Enviado</label>
                    <input type="text" class="form-control text-right" v-model="sent_amount" />
                  </div>
                  <div class="form-group col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <label for>Tipo Com.</label>
                    <select class="form-control" v-model="sent_type_comission">
                      <option value="p">PORCENTAJE</option>
                      <option value="f">FIJA</option>
                    </select>
                  </div>
                  <div class="form-group col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <label for>Comisión</label>
                    <input type="text" class="form-control text-right" v-model="sent_comission" />
                  </div>
                  <div class="form-group col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <label for>Monto Com.</label>
                    <input
                      type="text"
                      class="form-control text-right"
                      v-model="sent_comission_amount"
                      disabled
                    />
                  </div>
                 <div class="form-group col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <label for>IGV</label>
                    <input type="text" class="form-control text-right" v-model="igv" />
                  </div>
                  <div class="form-group col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <label for>Monto IGV</label>
                    <input type="text" class="form-control text-right" v-model="igv_amount" disabled/>
                  </div>
                <div class="form-group col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <label for>ITF</label>
                    <input type="text" class="form-control text-right" v-model="itf" />
                  </div>
                  <div class="form-group col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                    <label for>Monto ITF</label>
                    <input type="text" class="form-control text-right" v-model="itf_amount" disabled/>
                  </div>
                  <div class="form-group col-12 col-md-6 col-lg-6 col-xl-6">
                    <label for>Otra Comisión Envío</label>
                    <input type="text" class="form-control text-right" v-model="other_comission" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-12 col-md-12 col-lg-12 col-xl-12">
                    <label for>Monto Recibido</label>
                    <input type="text" class="form-control text-right" v-model="received_amount" />
                  </div>
                  <div class="form-group col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <label for>Tipo Com.</label>
                    <select class="form-control" v-model="received_type_comission">
                      <option value="p">PORCENTAJE</option>
                      <option value="f">FIJA</option>
                    </select>
                  </div>
                  <div class="form-group col-6 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <label for>Comision</label>
                    <input type="text" class="form-control text-right" v-model="received_comission" />
                  </div>
                  <div class="form-group col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
                    <label for>Monto Com.</label>
                    <input type="text" class="form-control text-right" v-model="received_comission_amount" disabled/>
                  </div>
                  <div class="form-group col-12 col-md-12 col-lg-12 col-xl-12">
                    <label for>TRM</label>
                    <input type="text" class="form-control text-right" v-model="trm" disabled/>
                  </div>
                  <div class="form-group text-right col-12 col-md-12 col-lg-12 col-xl-12">
                    <button v-if="calculate_id" class="btn btn-sm btn-success ml-2" @click="calculateUpdate()">Actualizar</button>
                    <button class="btn btn-sm btn-secondary ml-2" @click="calculateTrm()">Calcular</button>
                    <button
                      class="btn btn-sm btn-primary ml-2"
                      @click="calculateAndRegister()"
                    >Calcular y Registar</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="home-tab">
        <div class="table-responsive">
          <table class="table table-stripe">
            <thead>
              <tr>
                <th class="th-min text-center">Nombre</th>
                <th class="th-min text-center">Comision Envio</th>
                <th class="th-min text-center">Monto Comision</th>
                <th class="th-min text-center">Comision Retiro</th>
                <th class="th-min text-center">Monto Comision</th>
                <th class="th-min text-center">TRM</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="val in calculateList" :key="val.id">
                <td class="text-center">{{val.name}}</td>
                <td class="text-right">{{val.sent_comission}}</td>
                <td class="text-right">{{val.sent_comission_amount}}</td>
                <td class="text-right">{{val.received_comission}}</td>
                <td class="text-right">{{val.received_comission_amount}}</td>
                <td class="text-right">{{val.trm}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
export default {
  data() {
    return {
      calculate_id:'',
      sent_amount: 0,
      sent_total_amount:0,
      sent_type_comission: "p",
      sent_comission: 0,
      sent_comission_amount: 0,
      other_comission: 0,
      received_amount: 0,
      received_total_amount:0,
      received_type_comission: "p",
      received_comission: 0,
      received_comission_amount: 0,
      igv:18,
      igv_amount:0,
      itf:0.004545455,
      itf_amount:0,
      trm: "",
      calculateList:[],
    };
  },

  async mounted(){
    await this.getCalculateList()
  },

  watch:{
    calculate_id(newVal){
      if(newVal){
        let calculate = this.calculateList.filter(c => c.id == newVal)[0]
        this.sent_amount = calculate.sent_amount
        this.sent_total_amount = calculate.sent_total_amount
        this.sent_type_comission = calculate.sent_type_comission
        this.sent_comission = calculate.sent_comission
        this.sent_comission_amount = calculate.sent_comission_amount
        this.other_comission = calculate.other_comission
        this.received_amount = calculate.received_amount
        this.received_total_amount = calculate.received_total_amount
        this.received_type_comission = calculate.received_type_comission
        this.received_comission = calculate.received_comission
        this.received_comission_amount = calculate.received_comission_amount
        this.igv = calculate.igv
        this.igv_amount = calculate.igv_amount
        this.itf = calculate .itf
        this.itf_amount = calculate.itf_amount
        this.trm = calculate.trm
      }
    }
  },

  methods: {
    calculateTrm() {
      let sent_comission;
      let received_comission;
      let igv_amount = 0;
      let itf_amount = 0;

      if (this.sent_type_comission == "p") {
        sent_comission = this.sent_amount - this.sent_amount / (1 + this.sent_comission / 100);
      } else {
        sent_comission = this.sent_comission;
      }

      if(this.igv){
        igv_amount = sent_comission * (this.igv/100);
      }

      if(this.itf){
        itf_amount = this.sent_amount * (this.itf/100);
      }

      if(this.received_type_comission == 'p'){
        received_comission = this.received_amount - this.received_amount / (1 + this.received_comission / 100);
      } else {
        received_comission = this.received_comission;
      }

      this.igv_amount = igv_amount
      this.itf_amount = itf_amount
      this.sent_comission_amount = sent_comission;
      this.sent_total_amount = parseFloat(this.sent_amount) + parseFloat(this.other_comission)
      this.received_comission_amount = received_comission;
      this.received_total_amount = this.received_amount - this.received_comission_amount
      this.trm = this.received_total_amount / this.sent_total_amount
    },

    calculateAndRegister() {
      let name = prompt("Ingrese el nombre del calculo");
      this.calculateTrm()
    
      axios.post('admin/trm', {
        name: name,
        sent_amount: this.sent_amount,
        sent_type_comission: this.sent_type_comission,
        sent_comission: this.sent_comission,
        igv : this.igv,
        itf : this.itf,
        received_type_comission: this.received_type_comission,
        received_amount: this.received_amount,
        received_comission: this.received_comission,
      })
      .then(resp =>{
        swal("Informacion", resp.data.message, "success")
        this.getCalculateList()
      })
    
    },

    calculateUpdate() {
   
      axios.put('admin/trm/'+this.calculate_id, {
        name: name,
        sent_amount: this.sent_amount,
        sent_type_comission: this.sent_type_comission,
        sent_comission: this.sent_comission,
        igv : this.igv,
        itf : this.itf,
        received_type_comission: this.received_type_comission,
        received_amount: this.received_amount,
        received_comission: this.received_comission,
      })
      .then(resp =>{
        swal("Informacion", resp.data.message, "success")
        this.getCalculateList()
      })
    
    },

    getCalculateList(){
      axios.get('admin/trm')
      .then(resp => this.calculateList = resp.data)
    },

    resetForm() {}
  }
};
</script>