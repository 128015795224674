<template>
  <div>
     <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            Tasas de Cambios
        </li>
        
      </ol>
    </nav>
    <div class="section">
      <table class="white">
        <thead class="thead-dark">
          <tr>
            <th class="text-center" scope="col">#</th>
            <th class="text-center" scope="col">Pais</th>
            <th class="text-center" scope="col">Moneda</th>
            <th class="text-center" scope="col">P. V. Dolar</th>
            <th class="text-center" scope="col">P. C. Dolar</th>
            <th class="text-center" scope="col">Tasa Moneda a BS</th>
            <th class="text-center" scope="col">Tasa Dolar a BS</th>
            <th class="text-center" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(val, index) in rates" :key="index">
            <td class="text-center">{{index+1}}</td>
            <td class="text-center">
              <router-link class="text-primary" tag="a" :to="{path:'/admin/rates/detail/'+val.id}" exact>{{val.country.name}}</router-link>
            </td>
            <td class="text-center">{{val.currency.name}}</td>
            <td class="text-center">{{val.sale_rate}}</td>
            <td class="text-center">{{parseFloat(val.purchase_rate).toFixed(2)}}</td>
            <td class="text-center">{{parseFloat(val.currency_rate).toFixed(2)}}</td>
            <td class="text-center">{{parseFloat(val.dolar_rate).toFixed(2)}}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import RateService from '@/service/RateService';

export default {
  data() {
    /*  country_name:'PERU',
        currency_name:'SOL',
        sale_rate: 0,
        purchase_rate:0,
        currency_rate:0,
        currency_rate_bs:0, */
    return {
      rates: []
    };
  },
/*  created() {
    if (this.$auth.user().role_name != "admin") {
        window.M.toast({ html: "Perfil no autorizado" });
        this.$auth.logout();
    }
  }, */
  async mounted() {
    await RateService.get().then(resp => this.rates = resp.data);
  },


  methods: {
    getRates() {
      axios
        .get("/admin/rates")
        .then(response => {
          this.rates = response.data;
          console.log(response.data);
        })
    }
  }
};
</script>