<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Transferencias
        </li>
      </ol>
    </nav>
    <div class="container-fluid mb-2">
        <router-link :to="{
            path:'/confirm/transferencia-saldo'
        }" class="btn btn-small btn-primary">Nueva Transferencia</router-link>
    </div>
    <div class="row mb-2">
      <div class="col col-lg-7"></div>
      <div class="col col-lg-2">
        <input type="date" class="form-control" v-model="fecha_desde" />
      </div>
      <div class="col col-lg-2">
        <input type="date" class="form-control" v-model="fecha_hasta" />
      </div>
      <div class="col col-lg-1">
        <button class="btn btn-small btn-primary" @click="getTransferenciaRango()">
          Filtrar
        </button>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <table class="table">
            <thead>
                <tr>
                    <th class="text-center"></th>
                    <th class="text-center">Fecha</th>
                    <th class="text-center">Cuenta Origen</th>
                    <th class="text-center">Cuenta Destino</th>
                    <th class="text-center">Monto</th>
                    <th class="text-center">Comision</th>
                    <th class="text-center">Total</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="val in transferencias" :key="val.id">
                    <td class="text-center">
                        <button class="btn btn-small btn-info">{{val.id}}</button>
                    </td>
                    <td class="text-center">{{val.created_at}}</td>
                    <td>{{val.cuenta_debito["name"]}}</td>
                    <td>{{val.cuenta_acreditar["name"]}}</td>
                    <td class="text-right">{{val.monto | numFormat("0,0.00")}}</td>
                    <td class="text-right">{{val.monto_comision | numFormat("0,0.00")}}</td>
                    <td class="text-right">{{val.total | numFormat("0,0.00")}}</td>
                    <td><button class="btn btn-small btn-danger" @click="eliminarTraslado(val.id)">Eliminar</button></td>
                </tr>
            </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {

    data(){
        return {
          fecha_desde:'',
          fecha_hasta:'',
          transferencias:[]
        }
    },

    created() {
        this.getTransferencias();
    },

    methods:{
        getTransferencias(){
            axios.get('transferencias')
            .then(resp => {
                this.transferencias = resp.data.filter( c => c['cuenta_debito']['currency_id'] == 2 || c['cuenta_debito']['currency_id'] == 4 );
            })
        },

         getTransferenciaRango(){
            axios.get(`transferencias?opt=traslado-rango&fd=${this.fecha_desde}&fh=${this.fecha_hasta}`)
            .then(resp => {
                this.transferencias = resp.data.filter( c => c['cuenta_debito']['currency_id'] == 2 || c['cuenta_debito']['currency_id'] == 4 );
            })
        },

        eliminarTraslado(id){

          axios.delete(`transferencias/${id}`)
            .then(resp => {

              if(resp['data']['ok']){

                 swal("Información del Sistema", resp.data.message, "success");

                this.getTransferencias();

              }
            })
            .catch( err => {
              let msg = err['error']['error']['message'].toString();
               swal("Información del Sistema", msg, "error");

            })
        }
    }
};
</script>

<style scoped>
</style>