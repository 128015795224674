import Service from '@/service/Service'
let resource = 'admin'

export default {

    report : {

        get(query){
            let url_query = (query) ? query : '';
            return Service.get(resource+'/reports'+url_query)
        },
        
    }

}