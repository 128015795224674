<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="form-group col-sm-12">
                <label for="cp_payment_method">Metodo de Pago</label>
                  <select id="cp_payment_method" class="form-control" v-model="payment_method" disabled>
                    <option value>SELECCIONE</option>
                    <option
                      v-for="val in payment_methods"
                      :key="val.id"
                      :value="val.id"
                    >{{val.name}}</option>
                  </select>
              </div>
              <div class="form-group col-sm-6">
                <label for="cp_currency">Moneda</label>
                <select id="cp_currency" class="form-control" v-model="currency" disabled>
                    <option value="">SELECCIONE</option>
                    <option v-for="val in currencies" :key="val.id" :value="val.id">{{val.name}}</option>
                </select>
              </div>
              <div class="form-group col-sm-6">
                <label for="cp_amount">Monto</label>
                <input id="cp_amount" type="text" class="form-control text-right" v-model="amount" disabled/>
              </div>
              <div class="form-group col-sm-6">
                <label for="pp_onumber">Nro. Operación</label>
                <input id="pp_onumber" type="text" class="form-control text-right" v-model="operation_number" disabled/>
              </div>
              <div class="form-group col-sm-6">
                <label for="pp_comission">% Comisión</label>
                <input id="pp_comission" type="text" class="form-control text-right" v-model="comission" disabled/>
              </div>
              <div class="form-group col-sm-6">
                <label for="pp_acomission">Monto Comisión</label>
                <input id="pp_acomission" type="text" class="form-control text-right" v-model="comission_amount" disabled/>
              </div>
              <div class="form-group col-sm-6">
                <label for="pp_acomission">Total</label>
                <input id="pp_acomission" type="text" class="form-control text-right" v-model="total" disabled/>
              </div>
              <div class="form-group col-sm-6">
                <label for="cp_rate">Tasa</label>
                <input id="cp_rate" type="text" class="form-control text-right" v-model="rate_amount" disabled/>
              </div>
              <div class="form-group col-sm-6">
                <label for="cp_amount_to_pay">Monto a Pagar</label>
                <input id="cp_amount_to_pay" type="text" class="form-control text-right" v-model="amount_to_pay" disabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/event-bus.js";
import SelectListService from "@/service/SelectListService";

export default {
    props:["order"],
    name:'CashPaymentView',
    data(){
        return {
            data:'',
            currency:'',
            amount:'',
            comission:'5%',
            comission_amount:'',
            total:'',
            rate_amount:'',
            operation_number:'',
            amount_to_pay:'',
            payment_method:'',
            payment_methods:'',
            currencies:'',
        }
    },

    async created(){
      await SelectListService.PaymentMethod.get().then(
        resp => (this.payment_methods = resp.data)
      );

      await SelectListService.Currency.get().then(
        resp => (this.currencies = resp.data)
      );
    },

    mounted(){
        this.data = this.order;
        this.setField(this.order);
    },

    watch:{
        order(new_val){
            this.data = new_val;
            this.setField(new_val);
        }
    },
 
    methods:{
        setField(data){

          //console.log(data.payments);
          let payment = data.payments[0];

          this.payment_method = payment.payment_method_id;
          this.currency = payment.currency_id;
          this.amount = payment.amount;
          this.comission_amount = payment.comision_amount_pdv;
          this.total = payment.total;
          this.operation_number = payment.operation_number;
          this.amount_to_pay = data.amount_to_pay;
          
          let currency_rate = (payment.currency_id == 4) ? data.rate.dolar_rate : data.rate.currency_rate;
          
          if(data.rate.especial_rate == true){

            currency_rate = (payment.currency_id == 4) ? data.rate.dolar_rate_especial : data.rate.currency_rate_especial;
          }
            
            this.rate_amount = currency_rate;
        }
    }
}
</script>