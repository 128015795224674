<template>
  <div>
    <br />
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Resumen de Ingresos y Gastos en efectivo</h5>
        <hr />
        <table class="table-bordered">
          <thead>
            <tr>
              <th class="text-center" rowspan="2">Sede</th>
              <th colspan="3">Sol (S/)</th>
              <th colspan="3">Dolar ($)</th>
            </tr>
            <tr>
              <th class="text-center">Ingresos</th>
              <th class="text-center">Gastos</th>
              <th class="text-center">Total</th>
              <th class="text-center">Ingresos</th>
              <th class="text-center">Gastos</th>
              <th class="text-center">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="val in resumen" :key="val.agente">
              <td class="text-center">            {{val.agente }}</td>
              <td class="text-right text-success">{{val.ingresos_soles  | numFormat("0,0.00")}}</td>
              <td class="text-right text-danger"> {{val.gastos_soles | numFormat("0,0.00")}}</td>
              <td class="text-right">             {{val.total_soles | numFormat("0,0.00")}}</td>
              <td class="text-right text-success">{{val.ingresos_dolar | numFormat("0,0.00")}}</td>
              <td class="text-right text-danger"> {{val.gastos_dolar | numFormat("0,0.00")}}</td>
              <td class="text-right">             {{val.total_dolar | numFormat("0,0.00")}}</td>
            </tr>
           
             <tr class="bg-dark">
              <td class="text-white text-center">TOTAL</td>
              <td class="text-white text-right">{{calcularTotal.totalIS}}</td>
              <td class="text-white text-right">{{calcularTotal.totalGS}}</td>
              <td class="text-white text-right">{{calcularTotal.totalS}}</td>
              <td class="text-white text-right">{{calcularTotal.totalID}}</td>
              <td class="text-white text-right">{{calcularTotal.totalGD}}</td>
              <td class="text-white text-right">{{calcularTotal.totalD}}</td>
            </tr>

          </tbody>

        </table>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

export default {

  data(){
    return {
      resumen:[]
    }
  },

  mounted(){
    this.getResumenCajas();
  },

  computed:{
    calcularTotal(){

      let totalIS = 0;
      let totalGS = 0;
      let totalS = 0;
      let totalID = 0;
      let totalGD = 0;
      let totalD = 0;

      this.resumen.forEach(v => {
          totalIS += v.ingresos_soles;
          totalGS += v.gastos_soles;
          totalS += v.total_soles;
          totalID += v.ingresos_dolar;
          totalGD += v.gastos_dolar;
          totalD += v.total_dolar;
      })

      return {
          totalIS,
          totalGS,
          totalS,
          totalID,
          totalGD,
          totalD,
      }
    }
  },

  methods: {
    getResumenCajas(){
      axios.get('resumen-cajas')
      .then(resp => {
        this.resumen = resp.data;
      })
    }
  },

};
</script>

<style scoped>
</style>