<template>
    <div>
        <div class="custom-panel">
    <div class="row">
      <div class="col-2">
        <input type="date" class="form-control" v-model="from_date">
      </div>
      <div class="col-2">
        <input type="date" class="form-control" v-model="to_date">
      </div>
      <div class="col-1">
        <a href="#" @click.prevent="searchRange" class="btn btn-small btn-primary">Filtrar</a>
      </div>
    </div>
  </div>
        <br>
        <div class="table-responsive">
            <table class="table table-strippe">
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>
                            <select v-model="agent" class="form-control">
                                <option value="">TODAS LOS AGENTES</option>
                                <option v-for="(val, index) in agentsFilter" :key="index" :value="val.name">{{val.name}}</option>
                            </select>
                        </th>
                        <th>
                            <select v-model="account" class="form-control">
                                <option value="">TODAS LAS CUENTAS</option>
                                <option v-for="(val, index) in accountFilter" :key="index" :value="val.name">{{val.name}}</option>
                            </select>
                        </th>
                        <th>
                            <select v-model="bank" class="form-control">
                                <option value="">TODOS LOS BANCOS</option>
                                <option v-for="(val, index) in bankFilter" :key="index" :value="val.name">{{val.name}}</option>
                            </select>
                        </th>
                        <th>Numero de Cuenta</th>
                        <th>Numero de Operacion</th>
                        <th>
                            <select v-model="currency" class="form-control">
                                <option value="">TODAS</option>
                                <option v-for="(val, index) in currencyFilter" :key="index" :value="val.name">{{val.name}}</option>
                            </select>
                            
                        </th>
                        <th>
                            <select v-model="confirmed" class="form-control">
                                <option value="">CONFIRMADO</option>
                                <option value="1">SI</option>
                                <option value="0">NO</option>
                            </select>
                            
                        </th>
                        <th>Monto</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="val in transactionsFilter" :key="val.id">
                        <td class="text-center">{{val.date}}</td>
                        <td class="text-center">{{val.agent_name}}</td>
                        <td class="text-center">{{val.account_name}}</td>
                        <td class="text-center">{{val.bank_name}}</td>
                        <td class="text-center">{{val.account_dni}}</td>
                        <td class="text-center">{{val.operation_number}}</td>
                        <td class="text-center">{{val.currency_name}}</td>
                        <td class="text-center">{{(val.confirmed == 1) ? 'SI' : 'NO' }}</td>
                        <td class="text-right">{{val.amount | numFormat('0,0.00')}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr class="green white-text">
                        <td colspan="8"></td>
                        <td>{{total | numFormat('0,0.00')}}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data(){
        return {
            agent:'',
            account:'',
            bank:'',
            currency:'',
            confirmed:'',
            from_date:null,
            to_date:null,
            transactions:[],
        }
    },

    mounted(){
        this.searchRange();
    },


    computed:{

        agentsFilter(){
            const data = this.transactions;
            const result = [];
            const map = new Map();

            for (const account of data) {
                if (!map.has(account.agent_name)) {
                    map.set(account.agent_name, true); // set any value to Map
                    result.push({
                        name: account.agent_name
                    });
                }
            }

            return result;
        },
        accountFilter(){
            const data = this.transactions;
            const result = [];
            const map = new Map();

            for (const account of data) {
                if (!map.has(account.account_name)) {
                    map.set(account.account_name, true); // set any value to Map
                    result.push({
                        name: account.account_name
                    });
                }
            }

            return result;
        },

        currencyFilter(){
            const data = this.transactions;
            const result = [];
            const map = new Map();

            for (const account of data) {
                if (!map.has(account.currency_name)) {
                    map.set(account.currency_name, true); // set any value to Map
                    result.push({
                        name: account.currency_name
                    });
                }
            }

            return result;
        },

        bankFilter(){
            const data = this.transactions;
            const result = [];
            const map = new Map();

            for (const account of data) {
                if (!map.has(account.bank_name)) {
                    map.set(account.bank_name, true); // set any value to Map
                    result.push({
                        name: account.bank_name
                    });
                }
            }

            return result;
        },

        
        transactionsFilter: function(){

            let data_filter = this.transactions;

            if(this.agent != ''){
                data_filter = data_filter.filter(d => d.agent_name == this.agent);
            }

            if(this.account != ''){
                data_filter = data_filter.filter(d => d.account_name == this.account);
            }

            if(this.bank != ''){
                
                data_filter = data_filter.filter(d => d.bank_name == this.bank);

            }

            if(this.currency != ''){
                
                data_filter = data_filter.filter(d => d.currency_name == this.currency);

            }
            if(this.confirmed != ''){
                
                data_filter = data_filter.filter(d => d.confirmed == this.confirmed);

            }

            this.calculateTotal(data_filter);

            return data_filter;
        },
    },

    methods:{

        calculateTotal(data_filter){

            let total = 0;
            for(let i=0 ; i < data_filter.length ; i++){
                total += parseFloat(data_filter[i].amount);
            }

            this.total = total;

        },

/*         getTtransactionsAccounts(){

            axios.get('admin/reports/accounts/transactions/'+this.from_date+'/'+this.to_date)
            .then(response => {

            })
            .catch(error => {
                console.log(error);
            })
        },
 */
        searchRange(){
            axios.get('admin/reports/payments/agents/'+this.from_date+'/'+this.to_date)
            .then(response => {
                this.transactions = response.data.map(p => {

                    let payment = JSON.parse(p.account_receive);

                    return {
                        id:p.id,
                        date: p.date,
                        account_name: payment.name,
                        bank_name: payment.bank_name,
                        account_dni: payment.account_dni,
                        operation_number: p.operation_number,
                        currency_name: p.currency_name,
                        amount: p.amount,
                        confirmed: p.confirmed,
                        agent_name: p.agent_name,
                    }
                });
            })
            .catch(error => {
                console.log(error);
            })
        }
    }
}
</script>

<style scoped>
.custom-panel {
  background-color: #ffffff;
  margin: 5px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 10px;
}
</style>