<template>
  <div><br>
       <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary"><router-link tag="a" to="/">Tablero</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">Numeros de Operación</li>
      </ol>
    </nav>
      <OperationNumbersForm class="display-hide"/>
      <div class="table-responsive">
    <table v-if="display_table" class="table-hover">
      <thead>
        <tr>
          <th class="center-align">
              <button class="btn btn-small btn-primary" @click="showSearchOption">
                  <i class="material-icons">search</i>
              </button>
          </th>
          <th class="center-align th-min">Cuenta</th>
          <th class="center-align th-min">M. Pago</th>
          <th class="center-align th-min">N# Operacion</th>
          <th class="center-align th-min">F. Operacion</th>
          <th class="center-align">Hora</th>
          <th class="center-align th-min">C. Provincia</th>
          <th class="center-align">Monto</th>
          <th class="center-align"></th>
        </tr>
      </thead>
      <tbody>
          <tr v-for="(val, index) in operation_numbers" :key="val.id">
              <td class="jj-center-align">{{index + 1}}</td>
              <td>{{JSON.parse(val.account).name}}</td>
              <td>{{JSON.parse(val.payment_method).name}}</td>
              <td class="jj-center-align text-primary">
                <router-link tag="a" :to="{path:'/confirm/operation-numbers/detail/'+val.id}">
                {{val.number}}
                
                </router-link>
                  </td>
              <td class="jj-center-align">{{val.date}}</td>
              <td class="jj-center-align">{{val.time}}</td>
              <td class="jj-right-align">{{val.comission_province_amount}}</td>
              <td class="jj-right-align">{{val.amount}}</td>
              <td class="jj-right-align">
                  <i v-if="val.order_id > 0" class="material-icons text-success" data-toggle="tooltip" data-placement="left" title="Confirmado">done_all</i>
                  <i v-if="val.order_id == 0 && val.type ==''" class="material-icons text-warning" data-toggle="tooltip" data-placement="left" title="No Confirmado">report_problem</i>
                  <i v-if="val.order_id == 0 && val.type === '0'" class="material-icons text-danger" data-toggle="tooltip" data-placement="left" title="No Confirmado">block</i>
              </td>
          </tr>
      </tbody>
    </table>
      </div>
    <SearchOption v-if="display_search"/>
  </div>
</template>
<script>
import EventBus from "@/event-bus.js";
import ConfirmationService from '@/service/ConfirmationService';
import SearchOption from '@/components/confirm/SearchOptionsView'
import OperationNumbersForm from '@/components/confirm/OperationNumbersFormView'

export default {
    components:{
        SearchOption,
        OperationNumbersForm,
    },

  data() {
    return {
         display_table:true,
        display_search:false,
        operation_numbers: [],
    };
  },

  async created(){
      EventBus.$on("searchParam", this.searchParam);
      EventBus.$on("searchRange", this.searchRange);
      EventBus.$on("refreshOperationNumbersList", this.refreshList);
      EventBus.$on("hideSearch", this.hideSearch);
      await ConfirmationService.OperationNumbers.get().then(resp => this.operation_numbers = resp.data);
  },

  methods:{
      showSearchOption(){
          this.display_search = true;
        this.display_table = false;
         /*  this.$modal.show('search') */
      },

      async refreshList(){
          this.operation_numbers = [];
          await ConfirmationService.OperationNumbers.get().then(resp => this.operation_numbers = resp.data);
      },

      searchParam(data){
          this.hideSearch();
          this.operation_numbers = [];

          let query = '?opt='+data.search_option+'&val='+data.param;

          ConfirmationService.OperationNumbers.get(query)
          .then(resp => this.operation_numbers = resp.data)
          .catch(error => {
                let obj = error.response.data.error.message;
                let message = "";

                for (const prop in obj) {

                    if(prop == 'no-confirmed'){
                        return swal("Información", obj[prop], "error")
                    }
                    message += obj[prop]+"\n";
                    //console.log(`obj.${prop} = ${obj[prop]}`);
                }
                swal("Información", message, "error")
              
          })
      },

      searchRange(data){
         this.hideSearch()
          let query = '?opt=range'+'&fd='+data.from_date+'&td='+data.to_date+'&s='+data.status_id+'&c='+data.currency_id;

          ConfirmationService.OperationNumbers.get(query)
          .then(resp => this.operation_numbers = resp.data)
          .catch(error => {
                let obj = error.response.data.error.message;
                let message = "";

                for (const prop in obj) {

                    if(prop == 'no-confirmed'){
                        return swal("Información", obj[prop], "error")
                    }
                    message += obj[prop]+"\n";
                    //console.log(`obj.${prop} = ${obj[prop]}`);
                }
                swal("Información", message, "error")
              
          })
      },
      hideSearch(){
      this.display_search = false;
      this.display_table = true;
    },
  }
};
</script>