<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Listado de Ordenes
        </li>
      </ol>
    </nav>
    <div class="container-fluid m-2">
       <div class="btn-group" role="group">
        <button class="btn btn-small btn-info" @click="getOrders"><i class="material-icons">refresh</i></button>
      </div>
      <div class="btn-group" role="group">
        <button class="btn btn-small btn-primary" @click="showSearch"><i class="material-icons">filter_list</i></button>
      </div>
      <div
        v-if="ordenesSeleccionadas.length > 0"
        class="btn-group"
        role="group"
      >
        <button class="btn btn-warning" @click="procesarOrdenes()">
          Procesar
        </button>
      </div>
      <div
        v-if="ordenesSeleccionadas.length > 0"
        class="btn-group"
        role="group"
      >
        <button class="btn btn-primary" @click="liberarOrdenes()">
          Liberar
        </button>
      </div>
      <div
        v-if="ordenesSeleccionadas.length > 0"
        class="btn-group"
        role="group"
      >
        <button class="btn btn-danger" @click="reportarOrdenes()">
          Reportar
        </button>
      </div>
    </div>
    <div class="table-responsive-sm" v-if="!cargando">
      <!-- {{ filter_order }}  -->
      <table class="table table-sm">
        <thead>
          <tr >
            <th class="text-center">
              <a href="#" @click.prevent="marcarTodos = !marcarTodos">
                <i v-if="marcarTodos" class="material-icons">check_box</i>
                <i v-if="!marcarTodos" class="material-icons"
                  >check_box_outline_blank</i
                >
              </a>
            </th>
            <th class="text-center">
              <select v-model="estatus_id" class="form-control">
                <option value="">TODOS</option>
                <option value="11">PENDIENTE</option>
                <option value="12">PROCESO DE PAGO</option>
              </select>
            </th>
            <th class="text-center">Fecha y Hora</th>
            <th>Pagar por</th>
            <th class="text-center">Cod. Orden</th>

            <th class="text-center">
              <select v-model="bank_filter_id" class="form-control">
                <option value>TODOS</option>
                <option
                  v-for="(val, index) in banksFilter"
                  :key="index"
                  :value="val.id"
                >
                  {{ val.name }}
                </option>
              </select>
            </th>
            <th class="text-center">Monto a Pagar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in ordenesFilstradas" :key="order.id" >
            <td class="text-center">
              <div v-if="$auth.check(['payment', 'admin'])">
              <div
                v-if="
                  (order.status_id == 11 && order.on_change ==0) ||
                  ((order.status_id == 12 || order.status_id == 16) &&
                    $auth.user().id == order.usuario_pago_id)
                "
                class="form-check"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="gridCheck"
                  v-model="ordenesSeleccionadas"
                  :value="order.id"
                />
                <label class="form-check-label" for="gridCheck"> </label>
              </div>
              </div>
              <i v-if=" order.on_change ==1" class="material-icons" data-toggle="tooltip" data-placement="top" title="En Modificación">lock</i>
            </td>
            <td class="text-center">
              <span
                class="badge"
                :class="{
                  'badge-warning': order.status_id == 11,
                  'badge-success': order.status_id == 12,
                  'text-white': order.status_id == 12,
                }"
                >{{ order.status['name'] }}</span
              >
               <span
                class="badge ml-2"
                :class="{
                  'text-success': order.minutos <= 10,
                  'badge-warning': order.minutos > 20 && order.minutos <= 30,
                  'badge-danger': order.minutos > 30,
                }"
                >{{order.minutos}} min</span
              >
            </td>
            <td class="text-center">
              {{ fechaConfirmacion(order) }}
            </td>
            <td class="text-center">
              {{ (order.withdrawals_by == 'pago-movil') ? 'PAGO MOVIL' : 'TRANSFERENCIA'}}
            </td>
            <td class="text-center">
              <a class="text-primary" href="#" @click.prevent="mostrarDetalle(order)">{{ order.order_code }}</a>
            </td>

            <td class="text-left">{{ (order.banco) ? order.banco['name'] : 'N/D'}}</td>
            <td class="text-right">
              {{ order.amount_to_pay | numFormat("0,0.00") }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="green white-text">
            <td colspan="5" class="text-right">
              <strong>TOTAL A PAGAR</strong>
            </td>
            <td class="text-right">{{ showTotal | numFormat("0,0.00") }}</td>
            <td></td>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="text-center">
      <div v-if="cargando" class="spinner-border" role="status">
        <span class="sr-only">Cargando datos...</span>
      </div>
    </div>
     <div class="modal fade" id="filtro-busqueda" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Opciones de Busqueda</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
             <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Basica</a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" id="messages-tab" data-toggle="tab" href="#messages" role="tab" aria-controls="messages" aria-selected="false">Rango</a>
                </li>
            </ul>
             <div class="tab-content">
              <div class="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <br>
                <div class="card">
                  <div class="card-body">
                    <div class="form-group">
                      <label for="">Opciones</label>
                      <select class="form-control">
                        <option value="cod">CODIGO REMESA</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="">Texto Busqueda</label>
                      <input type="text" v-model="textoBusqueda" class="form-control">
                    </div>
                  
                    <button @click="busquedaBasica()" type="button" class="btn btn-primary">Aplicar</button>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="messages" role="tabpanel" aria-labelledby="messages-tab" >
                 <div class="card">
                  <div class="card-body">
                    <div class="form-group">
                      <label for="">Opcion de Busqueda</label>
                      <select v-model="fechaBusqueda" class="form-control">
                        <option value="c">Fecha de Registro</option>
                        <option value="p">Fecha de Pago</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label for="">Fecha desde</label>
                      <input type="date" v-model="fechaDesde" class="form-control">
                    </div>
                    <div class="form-group">
                      <label for="">Fecha Hasta</label>
                      <input type="date" v-model="fechaHasta" class="form-control">
                    </div>


                    <button @click="busquedaRango()" type="button" class="btn btn-primary">Aplicar</button>
                  </div>
                  </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
     </div>
     <div
      class="modal fade"
      id="detalle-orden"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Detalle del Pago</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="owner_account">Beneficiario</label>
              <input type="text" class="form-control" :value="(orderSeleccionada) ? orderSeleccionada.user_receive['holder'] : ''">
            </div>
            <div class="form-group">
              <label for="description">Nacionalidad</label>
              <input class="form-control" :value="(orderSeleccionada) ? orderSeleccionada.user_receive['nationality_name']  : ''"/>
            </div>
            <div class="row">
              <div class="col col-lg-6">
                <div class="form-group">
              <label for="description">Tipo Documento</label>
              <input class="form-control" :value="(orderSeleccionada) ? orderSeleccionada.user_receive['document_type_name'] : ''"/>
            </div>
              </div>
              <div class="col col-lg-6">
                <div class="form-group">
              <label for="description">Nro. Documento</label>
              <input class="form-control" :value="(orderSeleccionada) ? orderSeleccionada.user_receive['dni'] : ''"/>
            </div>
              </div>
            </div>
            <div class="form-group">
              <label for="description">Banco</label>
              <input class="form-control" :value="(orderSeleccionada) ? (orderSeleccionada.banco) ? orderSeleccionada.banco['name'] : '' : ''"/>
            </div>
            <div class="form-group">
              <label for="description">N#. Telefono (Pago Movil)</label>
              <input class="form-control" :value="(orderSeleccionada) ? orderSeleccionada.user_receive['phone'] : ''"/>
            </div>
              <div class="form-group">
              <label for="description">N#. Cuenta</label>
              <input class="form-control" :value="(orderSeleccionada) ? orderSeleccionada.user_receive['account_dni'] : ''"/>
            </div>
            <div class="form-group">
              <label for="owner_account">Monto a Pagar</label>
              <input class="form-control text-right" :value="(orderSeleccionada) ? orderSeleccionada.amount_to_pay : '' | numFormat('0,0.00')"/>
            </div>
            <div class="form-group text-right">
               <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
            </div>
          </div>
         
        </div>
      </div>
    </div>
  </div>
</template>

<script>

window.jQuery('[data-toggle="tooltip"]').tooltip()
import axios from "axios";
import RowOrders from "@/components/payments/RowOrders";
import PaymentModal from "@/components/payments/PaymentModal";
import ReportModal from "@/components/payments/ReportModal";

export default {
  components: {
    RowOrders,
    PaymentModal,
    ReportModal,
  },
  data() {
    return {
      ordenesSeleccionadas: [],
      orderSeleccionada: null,
      marcarTodos: false,
      display_table: true,
      cargando: false,
      order_code: "",
      status: "",
      estatus_id: "",
      date_from: "",
      date_to: "",
      bank_id: "",
      bank_filter_id: "",
      fechaBusqueda: "c",
      banks: [],
      check_all: false,
      selected_orders: [],
      filter_order: [],
      orders: [],
      statuses: [],

      // Texto Busqueda
      textoBusqueda: "",
      fechaDesde: "",
      fechaHasta: "",
    };
  },

  created() {
    
    this.getOrders();
    // this.getBanks();
    // this.getStatuses();
  },

  computed: {

    banksFilter() {
      const data = this.orders;
      const result = [];
      const map = new Map();

      for (const val of data) {
        if(val.banco){

          if (!map.has(val.banco.id)) {
            map.set(val.banco.id, true); // set any value to Map
            result.push({
              id: val.banco.id,
              name: val.banco.name,
            });
          }

        }
      }

      return result;
    },

    showTotal: function () {
      let total = 0;

      this.filter_order.forEach(function (order, index) {
        total += parseFloat(order.amount_to_pay);
      });

      return total;
    },

    ordenesFilstradas() {
      let orders = this.orders;

      if (this.bank_filter_id != "") {
        orders = orders.filter((o) => {
          if(o.banco){
            
            return o.banco.id == this.bank_filter_id
          }

          return false;
        });
      }

      if (this.estatus_id != "") {
        orders = orders.filter((o) => o.status_id == this.estatus_id);
      }

      this.filter_order = orders;

      orders.sort(function (a, b) {
        if(a.banco && b.banco){
          
          return a.banco.id - b.banco.id;
        }
      });

      return orders;
    },
  },

  watch: {
    marcarTodos(newVal) {
      this.ordenesSeleccionadas = [];

      if (newVal) {
        let ordenes_selec = this.filter_order.map((o) => {
          this.ordenesSeleccionadas.push(o.id);
          return o.order_code;
        });
      } else {
        this.ordenesSeleccionadas = [];
      }
    },
  },

  methods: {

     fechaConfirmacion(order){
      // if(!order.fecha_confirmacion) return order.created_at;

      return order.fecha_confirmacion;
    },
    
    showSearch() {
      window.jQuery("#filtro-busqueda").modal("show");
    },

    busquedaBasica() {
      window.jQuery("#filtro-busqueda").modal("hide");
      this.cargando = true;

      axios
        .get("orders?opt=ordenes-pagos&order_code=" + this.textoBusqueda)
        .then((resp) => {
          this.orders = resp.data;
          this.cargando = false;
        });
    },

    busquedaRango() {
      window.jQuery("#filtro-busqueda").modal("hide");
      this.cargando = true;

      let data = {
        date_from: this.fechaDesde,
        date_to: this.fechaHasta,
      };

      axios
        .get(
          `orders?opt=ordenes-pagos&fecha_busqueda=${this.fechaBusqueda}&date_from=${this.fechaDesde}&date_to=${this.fechaHasta}`
        )
        .then((response) => {
          this.orders = response.data;
          this.cargando = false;
        });
    },

    mostrarDetalle(orden) {
      this.orderSeleccionada = orden;
      window.jQuery("#detalle-orden").modal("show");
    },

    liberarOrdenes() {
      axios
        .post("liberar-ordenes", { ordenes: this.ordenesSeleccionadas })
        .then((resp) => {
          window.swal("", "Ordenes liberadas con exito", "success");
          this.ordenesSeleccionadas = [];
          this.getOrders();
        })
        .catch((err) => {
          window.swal("Hubo un error, reportar al administrador", "", "danger");
        });
    },

    reportarOrdenes() {
      swal("Por favor ingrese el motivo de la anulación:", {
        content: "input",
      }).then((value) => {
        axios
          .post("/reportar-ordenes", {
            ordenes: this.ordenesSeleccionadas,
            nota: value,
          })
          .then((resp) => {
            this.getOrders();
            window.swal("Ordenes reportadas con exito", "", "success");
          });
      });
    },

    getOrders() {
      this.cargando = true;
      axios.get("orders?opt=ordenes-pagos&status=11,12").then((response) => {
        this.cargando = false;
        this.orders = response.data;
        this.filter_order = response.data;
      });
    },

    procesarOrdenes() {
      let ord = this.filter_order.filter((o) =>
        this.ordenesSeleccionadas.includes(o.id)
      );

      this.$router.push({
        name: "procesar-ordenes",
        params: {
          ordenes: ord,
        },
      });
    },
  },
};
</script>
<style scoped>
</style>