<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-xs-12 col-sm-12 col-xl-12">
        <div class="card border-success">
          <div class="card-header bg-success text-white">
            <div class="card-title">
              <h2>Inicio de Sesión</h2>
            </div>
          </div>
          <div class="card-body">
            <form autocomplete="on" @submit.prevent="login" method="post">
              <div class="form-group">
                <label for="email">E-mail</label>
                <input type="email" id="email" class="form-control" v-model="email" required />
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input
                  type="password"
                  id="password"
                  class="form-control"
                  v-model="password"
                  required
                />
              </div>
              <div class="form-group right-align">
                <button type="submit" class="btn btn-primary">Enviar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: null,
      password: null,
      error: false
    };
  },
  methods: {
    login() {
      var app = this;
      this.$auth.login({
        params: {
          email: app.email,
          password: app.password
        },
        success: function() {},
        error: function() {
          //this.error = true;
          window.M.toast({
            html: "Credenciales invalidas",
            classes: "rounded"
          });
        },
        rememberMe: true,
        redirect: "/dashboard",
        fetchUser: true
      });
    }
  }
};
</script>
<style scoped>
.container {
  /*position: absolute;
      top: 50%;
     left: 50%; 
     transform: translate(-50%,-50%);
     */
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
</style>