<template>
    <tr>
        <td>
            <i class="material-icons" v-if="data.on_change == 1">lock</i>
            <span v-else>{{i}}</span>
        </td>
        <td class="td-fecha text-center">{{data.created_at}}</td>
        <td class="text-center">{{data.order_code}}</td>
        <td class="text-center">
            <span class="badge" :class="{
                'badge-warning'   : (data.status.name == 'PENDIENTE'),
                'badge-success'   : (data.status.name == 'PAGADA'),
                'badge-danger'    : (data.status.name == 'REPORTADA'),
                'badge-info'      : (data.status.name == 'RECIBIDA'),
                'badge-dark'      : (data.status.name == 'ANULADA'),
                'badge-primary'   : (data.status.name == 'PROCESO DE PAGO'),
                'badge-secondary' : (data.status.name == 'DEVUELTA'),
            }">
            {{data.status.name}}
            </span>
        </td>
        <td class="td-cliente pl-3">{{data.user_send['name']}}</td>
        <td class="text-center">{{mostrarTasa | numFormat('0,0.00')}}</td>
        <td class="td-envia text-right">{{data.currency['symbol']}} {{data.amount | numFormat('0,0.00')}}</td>
        <td class="td-recibe text-right">Bs. {{data.amount_to_pay | numFormat('0,0.00')}}</td>
    </tr>
</template>

<script>

    export default {
        props:["data", "i"],

        computed:{
            mostrarTasa(){
                if(this.data.currency_id == 4){
                    return this.data.rate.dolar_rate
                }

                return this.data.rate.currency_rate
            }
        }
        
    }
</script>

<style scoped>

    .td-cliente{
        min-width: 300px;
    }
    .td-fecha{
        min-width: 200px;
    }
    .td-envia{
        min-width: 150px;
    }
    .td-recibe{
        min-width: 200px;
    }
</style>