<template>
    <ul class="list-unstyled components">
      <li><router-link to="/">Tablero</router-link></li>
      <li><router-link to="/compras">Compras</router-link></li>
      <li><router-link to="/cuentas/balance">Balance en Cuentas</router-link></li>
      <li><router-link to="/availability">Disponibilidad</router-link></li>
      <li><router-link to="/ordenes-pago">Ordenes de Pago</router-link></li>

      <li><a href="#" @click.prevent="logout">Cerrar Sesión</a></li>
    </ul> 
</template>
<script>
const $ = require("jquery");
// Lo declaramos globalmente
window.$ = $;

export default {
  methods: {
    logout() {
      this.$auth.logout();
      this.$router.push({
        path:'/login'
      });
      $("#sidebar").toggleClass("active");
      //location.reload();
    },
  },
};
</script>