import Service from '@/service/Service'

let resource = 'expenses'

export default{

    get(qry){
        let query = (qry) ? qry : ''
        return Service.get(resource+query);
    }
}