<template>
  <div class="home">
    <Admin v-if="$auth.check('admin')" />
    <Confirm v-if="$auth.check('confirm')" />
    <Compras v-if="$auth.check('compras')" />
    <Operator v-if="$auth.check('operator')" />
    <Cash v-if="$auth.check('cash')" />
    <Pagos v-if="$auth.check('payment')" />
  </div>
</template>
<script>
import Admin from "@/components/admin/Dashboard";
import Confirm from "@/views/cuentas/Balance";
import Operator from "@/components/operator/Dashboard";
import Cash from "@/views/caja/Dashboard";
import Compras from "@/components/compras/Dashboard";
import Pagos from "@/components/payments/OrdersListView";

export default {
  components: {
    Admin,
    Confirm,
    Cash,
    Operator,
    Compras,
    Pagos,
  },

  mounted() {
    // var n = new Notification("Emogotchi says");
    // setTimeout(n.close.bind(n), 5000);

    this.notificacion();
  },

  methods: {
    notificacion() {
      // if (Notification) {
      //   if (Notification.permission !== "granted") {
      //     Notification.requestPermission();
      //   }
      //   var title = "RemesasJJ";
      //   var extra = {
      //     icon: "https://remesasjj.com/assets/img/recursos/logo_remesasjj.png",
      //     body: "Notificación de prueba en App",
      //   };
      //   var noti = new Notification(title, extra);
      //   noti.onclick = {
      //     // Al hacer click
      //   };
      //   noti.onclose = {
      //     // Al cerrar
      //   };
      //   setTimeout(function () {
      //     noti.close();
      //   }, 3000);
      // }
    },
  },
};
</script>

