import Service from './Service';

const resource = 'exchange-rates';

export default {

    get(query){
        return Service.get(resource+query);
    },

    post(query, data){
        return Service.post(resource+query, data);
    },

    put(query, data){
        return Service.put(resource+query, data);
    },

    delete(query){
        return Service.delete(resource+query);
    }

}