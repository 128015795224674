<template>
  <div>
    <PrepaidPaymentView v-if="prepaid" :order="data"/>
    <PdvPaymentView v-if="pdv" :order="data"/>
    <CashPaymentView v-if="cash" :order="data"/>
    <ElectronicPaymentView v-if="electronic" :order="data"/>
    <CashdraftPaymentView v-if="cashdraft" :order="data"/>
    <CashdraftPaymentApiView v-if="cashdraft_api" :order="data"/>
  </div>
</template>
<script>
import EventBus from "@/event-bus.js";
import PrepaidPaymentView from '@/components/order/PrepaidPaymentView';
import CashPaymentView from '@/components/order/CashPaymentView';
import ElectronicPaymentView from '@/components/order/ElectronicPaymentView';
import CashdraftPaymentView from '@/components/order/CashdraftPaymentView';
import CashdraftPaymentApiView from '@/components/order/CashdraftPaymentApiView';
import PdvPaymentView from '@/components/order/PdvPaymentView';
export default {
    name:'CustomerPaymentView',
    components:{
        PrepaidPaymentView,
        CashPaymentView,
        ElectronicPaymentView,
        CashdraftPaymentView,
        CashdraftPaymentApiView,
        PdvPaymentView,
    },
    data(){
        return {
            prepaid:false,
            cash:false,
            electronic:false,
            cashdraft:false,
            cashdraft_api:false,
            pdv:false,
            data:'',
        }
    },

    created(){
        EventBus.$on("setDataCustomerPaymentView", this.setData);
    },

    methods:{
        setData(data){
          if(data.order_type_id == 1){
              this.electronic = true;
          }
          if(data.order_type_id == 2){
              this.cash = true;
          }
          if(data.order_type_id == 3){
              this.prepaid = true;
          }
          if(data.order_type_id == 4){
             if(data.cash_draft.length == 2){
                 this.cashdraft = true;
             }else{
                 this.cashdraft_api = true;

             }
          }
          if(data.order_type_id == 5){
              this.pdv = true;
          }
         
            this.data = data;
        }
    }
}
</script>