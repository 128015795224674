import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueAuth from '@websanova/vue-auth'
import App from './App.vue'
import router from './router/index'
import store from './store'
import VModal from "vue-js-modal";
import ProgressBar from 'vuejs-progress-bar'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'

import ImageMagnifier from 'vue-image-magnifier'
Vue.use(ImageMagnifier)

// console.log("Ruta IO ",process.env.VUE_APP_IO)

// Vue.use(new VueSocketIO({
//   debug: false,
//   connection: process.env.VUE_APP_IO,
//   vuex: {
//       store,
//       actionPrefix: 'socket_',
//       mutationPrefix: 'SOCKET_'
//   },
// })) 


Vue.config.productionTip = false

Vue.use(VModal)
Vue.use(ProgressBar)

/* 
* Autenticar Users
*/
Vue.use(VueAxios, axios);

axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL

App.router = Vue.router

Vue.use(VueAuth, {
    auth: require('@websanova/vue-auth/drivers/auth/bearer.js'),
    http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
    router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js')
});

/* 
 */
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format'
Vue.filter('numFormat', numFormat(numeral));

/* 
 */


/* 
* Auto importar componentes
*/
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context(
  // The relative path of the components folder
  '@/components/base',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
)

requireComponent.keys().forEach(fileName => {
  // Get component config
  const componentConfig = requireComponent(fileName)

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Strip the leading `./` and extension from the filename
      fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
    )
  )

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  )
})
  // beforeCreate(){
  //   store.commit('setSocket', this.$socket)
  // },
new Vue({
  router,
  store,

  render: h => h(App)
}).$mount('#app')
