import axios from 'axios';
<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Ordenes de Retiro</li>
      </ol>
    </nav>
    <div class="table-responsive">
      <table v-if="display_table">
        <thead>
          <tr>
            <th class="text-center">
              
            </th>
            <th class="text-center th-min">Fecha</th>
            <th class="text-center th-min">Usuario</th>
            <th class="text-center th-min">Retiro por</th>
            <th class="text-center th-min">Moneda</th>
            <th class="text-center th-min">Monto</th>
            <th class="text-center th-min">Estatus</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(val, index) in withdraws" :key="index">
            <td class="text-center">{{index + 1}}</td>
            <td class="text-center"><router-link :to="{path:'/withdraws/detail/'+val.id}" class="btn-small text-primary">{{val.created_at}}</router-link></td>
            <td class="text-center">{{val.user.name}}</td>
            <td class="text-center">Transferencia</td>
            <td class="text-center">{{val.currency.name}}</td>
            <td class="text-center">{{val.amount}}</td>
            <td class="text-center">{{ setStatus(val.status) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {

    data(){
        return {
          display_table:true,
            withdraws:[],
        }
    },

    mounted(){

        axios.get('admin/withdraws')
        .then(resp => this.withdraws = resp.data)
    },    

    methods:{
      
      setStatus(stat){
        if(stat === 1){
          return 'PENDIENTE';
        }

        if(stat === 2){
          return 'PAGADA';
        }
        
        return 'ANULADA'
      }
    }

}
</script>