<template>
    <tr>
        <td>{{currency.name}}</td>
        <td class="text-center">{{calcPurchaseRate | numFormat('0,0.000')}}</td>
        <td class="text-center">{{calcSaleRate | numFormat('0,0.000')}}</td>
        <td class="text-center">{{showRate | numFormat('0,0.00')}}</td>
        <td class="text-center">{{percentage}}</td>
        <td class="text-center">USD</td>
        <td class="text-center">OEXC</td>
    </tr>
</template>
<script>
import ExchangeRateService from "@/service/ExchangeRatesService";

export default {
    props:["currency", "percentage"],

    data(){
        return {
            exchange:'',
            purchase:'',
            sale:'',
        }
    },
    
    async mounted(){
        await this.getRate();
    },

    computed:{
        showRate(){
            if(this.exchange){

                return this.exchange.rates[this.currency.iso];
            }
        },

        calcPurchaseRate(){
            if(this.exchange){
                let rate = parseFloat(this.exchange.rates[this.currency.iso]);
                let comission = parseFloat(1 + (this.percentage /100));
                console.log("comision", comission)
                return rate / comission;
            }

            return 0;
            
        },

        calcSaleRate(){
            if(this.exchange){
                let rate = parseFloat(this.exchange.rates[this.currency.iso]);
                let comission = parseFloat(1 + (this.percentage /100));
                return rate * comission;
            }

            return 0;
            
        }
        
    },

    methods:{
        getRate(){
            ExchangeRateService.get(`?opt=api-rate&base=USD&symbol=${this.currency.iso}`)
            .then(resp => this.exchange = resp.data.response)
        },

    }
}
</script>