import RateService from '@/service/RateService';

export default {

    getHistoryMonth(){
        return RateService.get('?opt=s&t=rh&p=m');
    },

    getRateCounty(country){
        let query = '?opt=s&p=grc&val='+country;
        return RateService.get(query);
    },

    calculate(data){

        let query = `?opt=cal&co=${data.country}&cu=${data.currency}&trm=${data.trm}&pcr=${data.p_country_rate}&mr=${data.moneychanger_rate}`;

        return RateService.get(query)
    },

    calculateProfitability(data){

        let query = `?opt=cpfa&wsr=${data.wholesale_rate}&rr=${data.retail_rate}&pr=${data.purchase_rate}`;

        return RateService.get(query)
    },

    calculateProfitabilityEsp(data){

        let query = `?opt=cpfa&wsr=${data.wholesale_rate}&rr=${data.retail_rate_especial}&pr=${data.purchase_rate}`;

        return RateService.get(query)
    },

    update(data, id){

        return RateService.put(this.setData(data), id)
    },

    getLastImageRate(){
        return RateService.get('?opt=lir'); //Last Image Rate
    },


    uploadImageRate(data){
        let formData = new FormData();
        formData.append('image', data.image);
        return RateService.postImage(formData);
    },

    setData(data){

        return {
            moneychanger_rate:data.moneychanger_rate,
            wholesale_rate:data.wholesale_rate,
            retail_rate:data.retail_rate,
            retail_rate_especial:data.retail_rate_especial,
            profitability:data.profitability,
            profitability_especial:data.profitability_especial,
            purchase_rate:data.purchase_rate,
            sale_rate:data.sale_rate,
            dolar_rate:data.dolar_rate,
            dolar_rate_especial:data.dolar_rate_especial,
            update_time:data.update_time,
        }
    }
}