<template>
  <div>
     <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/admin/rates">Tasas de Cambio</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            Detalle
        </li>
        
      </ol>
    </nav>
      <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab">Tasa</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab">Tasa Especial</a>
      </li>
       <li class="nav-item">
        <a class="nav-link" id="image-tab" data-toggle="tab" href="#image" role="tab">Imagen</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="history-tab" data-toggle="tab" href="#history" role="tab">Historico</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab"><br>
         <RateBaseView :id="id"/>
      </div>
      <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="home-tab"><br>
          <EspecialRateBanks/>
      </div>
      <div class="tab-pane fade" id="image" role="tabpanel" aria-labelledby="home-tab">
          <RateImageView/>
      </div>
      <div class="tab-pane fade" id="history" role="tabpanel" aria-labelledby="home-tab">
          <RateHistory/>
      </div>
    </div>
    
  </div>
</template>
<script>
import RateBaseView from '@/components/admin/rates/RateBaseFormView';
import RateImageView from '@/components/admin/rates/RateImageView';

import EspecialRateBanks from '@/components/admin/rates/EspecialRateBanks';
import RateHistory from '@/components/admin/rates/RateHistory';

export default {
    props:["id"],
    components:{
        RateBaseView,
        EspecialRateBanks,
        RateHistory,
        RateImageView
    }
};
</script>