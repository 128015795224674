<template>
  <div>
    <div
      class="modal fade"
      id="detalle-movimiento"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Detalle del Pago</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="home-tab"
                  data-toggle="tab"
                  href="#orden"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  >Orden</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="profile-tab"
                  data-toggle="tab"
                  href="#voucher"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  >Voucher Cliente</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="contact-tab"
                  data-toggle="tab"
                  href="#pago"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                  >Actualización</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="orden" role="tabpanel" aria-labelledby="home-tab">
                <br />
                <div class="form-group">
                    <label for="owner_account">Codigo Remesa </label>
                    <input type="text" class="form-control" :value="data['meta']['order_code']" readonly/>
                </div>
                <div class="form-group">
                    <label for="description">Registrada Por</label>
                    <input class="form-control" :value="data['usuario']['name']" readonly/>
                </div>
                <div class="form-group">
                    <label for="owner_account">Cliente</label>
                    <input class="form-control" :value="data['meta']['user_send']['name']" readonly/>
                </div>
                <div class="form-group">
                    <label for="owner_account">Beneficiario</label>
                    <input class="form-control" :value="data['meta']['user_receive']['name']" readonly/>
                </div>
                <div class="form-group">
                    <label for="owner_account">Banco</label>
                    <input class="form-control" :value="data['meta']['user_receive']['bank_name']" readonly/>
                </div>
                <div class="form-group">
                    <label for="owner_account">Cuenta</label>
                    <input class="form-control" :value="data['meta']['user_receive']['account_dni']" readonly/>
                </div>
                <div class="form-group">
                    <label for="owner_account">Cuenta</label>
                    <input class="form-control" :value="data['meta']['amount_to_pay']" readonly/>
                </div>
                
            </div>
            <div class="tab-pane fade" id="voucher" role="tabpanel" aria-labelledby="profile-tab">
                <br />
                <div class="form-group">
                    <label for="owner_account">Banco</label>
                    <input type="text" class="form-control" :value="data['meta']['payment_in']['account_receive']['bank_name']" readonly/>
                </div>
                <div class="form-group">
                    <label for="owner_account">Titular</label>
                    <input type="text" class="form-control" :value="data['meta']['payment_in']['account_receive']['holder']" readonly/>
                </div>
                <div class="form-group">
                    <label for="description">Nro. Cuenta</label>
                    <input class="form-control" :value="data['meta']['payment_in']['account_receive']['account_dni']" readonly/>
                </div>
                <div class="form-group">
                    <label for="owner_account">Nro. Operación</label>
                    <input class="form-control" :value="data['meta']['payment_in']['operation_number']" readonly/>
                </div>
                <div class="form-group">
                    <label for="owner_account">Fecha</label>
                    <input class="form-control" :value="data['meta']['payment_in']['date']" readonly/>
                </div>
                <div class="form-group">
                    <label for="owner_account">Monto</label>
                    <input class="form-control" :value="data['meta']['payment_in']['total']" readonly/>
                </div>
                
              </div>
              <div class="tab-pane fade" id="pago" role="tabpanel" aria-labelledby="contact-tab">
                <br />
                 <div class="form-group">
                    <label for="owner_account">Cuenta</label>
                    <select v-model="cuentaId" @change="setCuenta($event)" class="form-control">
                        <option v-for="cuenta in cuentas" :key="cuenta.id" :value="cuenta.id">{{cuenta.name}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="owner_account">Banco</label>
                    <input class="form-control" v-model="banco" readonly/>
                </div>
                 <div class="form-group">
                    <label for="owner_account">Nro. Cuenta</label>
                    <input class="form-control" v-model="numCuenta" readonly/>
                </div>
                 <div class="form-group text-right">
                    <button class="btn btn-primary" @click="actualizarCuenta()">Actualizar Cuenta</button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  props: ["data", "cuentas"],

  data(){
      return {
          cuenta:[],
          cuentaId:null,
          numCuenta:'',
          banco:''
      }
  }, 

  methods:{
      setCuenta(event){
        let cuenta = this.cuentas.find(c => c.id == this.cuentaId);
        this.banco = cuenta['bank']['name'];
        this.numCuenta = cuenta['account_dni'];
      },

      actualizarCuenta(){

          let data = {
              order_id:this.data['meta']['id'], 
              payment_id:this.data['meta']['payment_in']['id'],
              cuenta_id:this.cuentaId
            };

          axios.post(`_confirm/payment-orders/update-account`, data)
          .then(resp => {
              swal("Información de Actualización", resp.data.message, "success");
          })
          .catch(err => {
            //   console.log(err.response.data.error)
            let error = err.response.data.error.message;
            swal("Error de Actualización", error.toString(), "error");
          })
      }
  }
};
</script>

<style scoped>
</style>