<template>
    <tr>
        <td class="text-center">Abono</td>
        <td class="jj-center-align">{{payment.created_at}}</td>
        <td>{{payment.user.name}}</td>
        <td class="text-center">N/D</td>
        <td class="text-left">
            <span class="d-inline-block text-truncate" style="max-width: 200px;">
                
                {{nombreCuenta(payment)}}
            </span>
        </td>
        <td class="text-center">
            <router-link class="text-primary" tag="a" :to="{path:'/confirm/payment-agents/detail/'+payment.id}">
                {{payment.operation_number}}
            </router-link>
        </td>
        <td class="text-center">
            <span v-if="payment.confirmed">{{payment.date}}</span>
            <span v-else>**********</span>
            </td>
        <td class="text-right">
            <span v-if="payment.confirmed">{{payment.amount}}</span>
            <span v-else>**********</span>
            </td>
        <td class="jj-right-align">
            <i v-if="payment.confirmed" class="material-icons text-success" data-toggle="tooltip" data-placement="left" title="Confirmado">done_all</i>
            <i v-else class="material-icons text-warning" data-toggle="tooltip" data-placement="left" title="No Confirmado">report_problem</i>
        </td>
    </tr>
       
</template>
<script>
export default {
props:["payment"],

    methods: {
         nombreCuenta(val){
        
        if(val.account_receive){
          let cuenta = val.account_receive;
              if(typeof(cuenta) == "string"){
                  cuenta = JSON.parse(cuenta);
              }
            return cuenta.name;

        }else{
          return 'N/D'
        }
        
      },
    },
    
}
</script>