<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/pagos">Pagos</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Registro de Pago
        </li>
      </ol>
    </nav>
    <div class="container-fluid">
      <div class="row">
        <div v-if="compraId" class="col-12 col-sm-12 col-md-6 col-lg-3">
           <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label for="datepicker">Orden de Compra </label>
                    <input
                      type="text"
                      class="form-control text-right"
                      id="oc"
                      :value="'OC-' + compraId"
                      disabled="disabled"
                    />
                  </div>
                  <div class="form-group">
                    <label for="datepicker">Monto de la Compra </label>
                    <input
                      type="text"
                      class="form-control text-right"
                      id="ocmonto"
                      v-model="montoCompra"
                      disabled="disabled"
                    />
                  </div>
                  <div class="form-group">
                    <label for="datepicker">Descripción</label>
                    <textarea
                      rows="5"
                      class="form-control"
                      id="ocmonto"
                      :value="descripcionCompra"
                      disabled="disabled"
                    ></textarea>
                  </div>
                   <div class="form-group">
                    <label for="datepicker">Restante a Pagar </label>
                    <input
                      type="text"
                      class="form-control text-right"
                      id="ocmonto"
                      :value="restante | numFormat('0,0.00')"
                      disabled="disabled"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label for="owner_account">Cuenta Debito </label
                    ><span class="text-danger"> *</span>
                    <select
                      v-model="cuenta_debito_id"
                      id="owner_account"
                      class="form-control"
                    >
                      <option value>SELECCIONE</option>
                      <option value="0">EFECTIVO REMESASJJ</option>
                      <option
                        v-for="(val, index) in cuentas"
                        :key="index"
                        :value="val.id"
                      >
                        {{ val.name }}
                      </option>
                    </select>
                  </div>
                   <div v-if="cuenta_debito_id=='0'" class="form-group">
                    <label for="moneda">Moneda</label
                    ><span class="text-danger"> *</span>
                    <select v-model="moneda_id" class="form-control">
                      <option value>SELECCIONE</option>
                      <option value="2">SOL</option>
                      <option value="4">DOLAR</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="description">Descripción</label
                    ><span class="text-danger"> *</span>
                    <input
                      class="form-control"
                      v-model="descripcion"
                      id="description"
                    />
                  </div>
                  <div class="form-group">
                      <label>¿Es un Gasto?</label>
                      <select v-model="gasto" class="form-control">
                        <option value="1">SI</option>
                        <option value="0">No</option>
                      </select>
                  </div>
                  <div v-if="gasto == 1" class="form-group">
                      <label>Gasto Fijo</label>
                      <select v-model="gasto_fijo" class="form-control">
                        <option :value="0">Seleccione</option>
                        <option v-for="gasto in gastos" :value="gasto['id']" :key="gasto['id']">{{ gasto['descripcion']}}</option>
                      </select>
                  </div>
                  <div class="form-group">
                    <label for="owner_account">Monto</label>
                    <span class="text-danger"> *</span>
                    <input class="form-control text-right" v-model="monto" />
                  </div>
                  <div class="row">
                    <div class="col col-6">
                      <div class="form-group m-2">
                        <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="gridCheck" v-model="comision"/>
                        <label class="form-check-label" for="gridCheck">Comisión</label>
                      </div>
                        <input class="form-control text-right" v-model="monto_comision" :disabled="!comision"/>
                      </div>
                    </div>
                    <div class="col col-6">
                      <div class="form-group">
                    <label for="">Total</label>
                        <input class="form-control text-right" :value="parseFloat(monto) + parseFloat(monto_comision) | numFormat('0,0.00') " :disabled="true"/>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <div v-if="!id" class="form-group">
                        <label for="file">Voucher de la Operación</label>
                        <span class="text-danger"> *</span>
                        <input
                          type="file"
                          class="form-control-file"
                          id="file"
                          ref="file"
                          @change="handlePictureUpload"
                        />
                      </div>
                    </div>
                    <div class="col-4 text-right">
                      <div class="form-group">
                        <div class="btn-group">
                          <button
                            v-if="!pagoId"
                            class="btn btn-small btn-primary"
                            @click="registrar()"
                          >
                            Registrar
                          </button>
                          <button
                            v-if="pagoId"
                            class="btn btn-small btn-danger"
                            @click.prevent="eliminarPago"
                          >
                            Eliminar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import AccountService from "@/service/AccountService";
import SelectListService from "@/service/SelectListService";
import TransactionService from "@/service/TransactionService";
/* const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/ui/widgets/datepicker.js'; */

export default {
  props: ["pagoId", "compraId"],

  data() {
    return {
      id: null,
      tipo: "pago",
      documento_id: 0,
      montoCompra: "",
      cuenta_debito_id: "",
      fecha: "",
      descripcion: "",
      gasto: 0,
      gasto_fijo:0,
      moneda_id:'',
      monto: "",
      comision:false,
      monto_comision:0,
      voucher: "",
      descripcionCompra:'',
      restante:'',
      cuentas: [],
      gastos: [],
    };
  },

  async created() {
    await AccountService.get().then((resp) => (this.cuentas = resp.data.data));

    if(this.compraId){
      this.getCompra();
    }

    if(this.pagoId){
      this.getPago();
    }

    this.getGastosFijos();

  },

  methods: {
    handlePictureUpload() {
      this.voucher = this.$refs.file.files[0];
    },

    registrar() {
      let data = new FormData();
      if(this.compraId){
        data.append("documento_id", this.compraId);
        data.append("tipo", 'pago-compra');
      }else{
        data.append("documento_id", 0);
        data.append("tipo", 'pago');
      }
      data.append("gasto", this.gasto);
      data.append("tipo_gasto", this.gasto_fijo);
      data.append("cuenta_debito_id", this.cuenta_debito_id);
      data.append("moneda_id", this.moneda_id);
      data.append("descripcion", this.descripcion);
      data.append("monto", this.monto);
      data.append("comision", this.comision);
      data.append("monto_comision", this.monto_comision);
      data.append("voucher", this.voucher);

      if(this.compraId){
        this.registrarPagoCompra(data);
      }else{
        this.registrarPago(data);
      }
    },

    registrarPago(data){

      axios
        .post("pagos", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$router.push({
            path:'/confirm/pagos'
          });
          swal("", resp.data.message, "success");
        })
        .catch((err) => {
          let mensaje = err.response.data.error.message;

          swal("Error", mensaje.toString(), "error");
        });
    },

    registrarPagoCompra(data) {
      
      swal({
        icon: "info",
        title: "Confirmación",
        text: "",
        buttons: {
          confirm: {
            text: "Registrar y Confirmar",
            value: "confirm",
            className: 'btn-success'
          },
          register: {
            text: "Registrar",
            value: "register",
            className:'btn-primary'
          },
          cancel: true
        },
      }).then((conf) => {

        if (conf == 'confirm') {
          axios
            .post("pagos?confirm=true", data)
            .then((resp) => {
              swal("Detalle de la Operación", "Se ha registrado el pago y confirmado la Orden de Compra exitosamente.", "success");
              this.$router.push({
                path: "/confirm/pagos",
              });
            })
            .catch((err) => {
              let mensaje = err.response.data.error.message;
              swal("Detalle del Error:", mensaje.toString(), "error");
            });
        }

        if (conf == 'register') {
          this.registrarPago(data);
        }
      });
    },

    getCompra(){
      axios.get('compras?opt=get-id&id='+this.compraId)
      .then(resp => {

        this.tipo = 'compra';
        this.montoCompra = '[ '+resp.data.moneda_pago['name']+']   '+resp.data.monto_pago;
        this.descripcionCompra = resp.data.descripcion;

         let restante = resp.data.monto_pago;
          // return;
          let pagos = resp.data.pagos_compra;

          if(pagos.length > 0){
            pagos.forEach((p) => {
              restante -= parseFloat(p.total);
            });
          }

          this.restante = restante;
          this.monto = restante;
      })
    },

    getPago(){
      axios.get('pagos?opt=get-id&id='+this.pagoId)
      .then(resp => {
        let detalle = resp.data;
        this.cuenta_debito_id = detalle.cuenta_debito_id;
        this.descripcion = detalle.descripcion;
        this.monto = detalle.monto;
        this.comision = detalle.comision;
        this.monto_comision = detalle.monto_comision;
        this.moneda_id = detalle.moneda_id;
        this.gasto = (detalle.tipo == 'gasto') ? 1 : 0;
        this.gasto_fijo = detalle.tipo_gasto_id;
      })
    },

    getGastosFijos(){
      axios.get('gastos?opt=get-gastos-fijos')
      .then(resp => {
        this.gastos = resp['data'];
      })
    },

    eliminarPago(){

      swal({
        icon: "info",
        title: "Confirmación",
        text: "",
        buttons: {
          
          register: {
            text: "Continuar",
            value: "conf",
            className:'btn-danger'
          },
          cancel: true
        },
      }).then((conf) => {

        if (conf == 'conf') {
          axios.delete('pagos/'+this.pagoId)
          .then(resp => {
            swal("",resp.data.message, "success");
            this.$router.push({
              path:'/confirm/pagos'
            });
          })
        }
      });
    }
  },
};
</script>