<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Traslados
        </li>
      </ol>
    </nav>
    <div class="container-fluid mb-2">
        <router-link :to="{
            path:'/trasladar-saldo'
        }" class="btn btn-small btn-primary">Traslado de Saldo</router-link>
    </div>
    <div class="card">
      <div class="card-body">
        <table class="table">
            <thead>
                <tr>
                    <th class="text-center"></th>
                    <th class="text-center">Fecha</th>
                    <th class="text-center">Cuenta Origen</th>
                    <th class="text-center">Cuenta Destino</th>
                    <th class="text-center">Monto</th>
                    <th class="text-center">Comision</th>
                    <th class="text-center">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="val in traslados" :key="val.id">
                    <td class="text-center">
                        <button class="btn btn-small btn-primary">TR-{{val.id}}</button>
                    </td>
                    <td class="text-center">{{val.created_at}}</td>
                    <td>{{val.cuenta_debito.name}}</td>
                    <td>{{val.cuenta_acreditar.name}}</td>
                    <td class="text-right">{{val.monto | numFormat('0,0.00')}}</td>
                    <td class="text-right">{{val.monto_comision | numFormat('0,0.00')}}</td>
                    <td class="text-right">{{val.total | numFormat('0,0.00')}}</td>
                </tr>
                
            </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {

    data(){
        return {
            traslados:[]
        }
    },

    created(){
        this.getTraslados();
    },

    methods:{

        getTraslados(){
            axios.get('/traslados')
            .then(resp => {
                this.traslados = resp.data;
            })
        }
    }
};
</script>

<style scoped>
</style>