<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            Disponibilidad
        </li>
      </ol>
    </nav>
    <div class="row mt-2">
      <div class="col-12 col-sm-10 col-md-10 col-lg-6">
        <div class="row">
          <div class="col-12">
            <div class="card">
            <div class="card-header">
              <div class="card-title text-primary">
                <h3>Disponibilidad en Bancos</h3>
              </div>
            </div>
            <div class="card-body">
              <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12">
                <label for="bank" class="active">Banco</label>
                <select id="bank" v-model="bank_id" class="form-control">
                  <option value>SELECCIONE</option>
                  <option v-for="val in banks" :key="val.id" :value="val.id">{{val.name}}</option>
                </select>
              </div>
              <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12">
                <label for="d" class="active">Monto Disponibilidad</label>
                <input id="d" type="text" v-model="availability_amount" class="form-control" />
              </div>
              <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12">
                <a href="#" class="btn btn-small btn-primary" @click.prevent="addAvailabilityBank">
                  <i class="material-icons">add</i>
                </a>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-10 col-lg-6">
          <BalanceAvailable
            @refresh="getAvailabilityBanks"
            v-for="val in banks_available"
            :bank="val"
            :key="val.id"
          />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import BalanceAvailable from "@/components/admin/BalanceAvailable";

export default {
  components: {
    BalanceAvailable
  },

  data() {
    return {
      bank_id: "",
      banks_available: [],
      banks: [],
      availability_amount: "",
      
    };
  },

  mounted() {
    this.getAvailabilityBanks();
    this.getBanks();
  },

  methods: {
    addAvailabilityBank() {
      if (this.bank_id == "") {
        return window.M.toast({ html: "Seleccione el Banco" });
      }

      axios
        .post("banks/availability", {
          id: this.bank_id,
          availability_amount: this.availability_amount
        })
        .then(response => {
          swal('Información', response.data.message, 'success')
          this.bank_id = "";
          this.availability_amount = "";
          this.getAvailabilityBanks();
        })
    },
    getAvailabilityBanks() {
      axios.get("banks/availability").then(response => {
        this.banks_available = response.data;
      });
    },

    getBanks() {
      axios.get("banks?c=1").then(response => {
        this.banks = response.data;
      });
    }
  }
};
</script>