<template>
  <div>
    <h5 v-if="currency.exchange_rates.length > 0" class="card-title text-primary">Tipos de Cambio</h5>
    <h5 v-else class="card-title">No hay tipos de cambios</h5>

    <div class="card" v-if="currency.exchange_rates.length > 0">
      <table :class="{hide:display_hide}">
        <thead>
          <tr>
            <th>Moneda {{display_hide}}</th>
            <th>Compra</th>
            <th>Venta</th>
            <th>Local</th>
            <th>API</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="exc in currency.exchange_rates" :key="exc.id">
            <td class="text-center">
                <a href="#" class="btn btn-link"
                    @click.prevent="showEditForm(exc)">
                    {{`${exc.currency.iso} (${exc.currency.symbol})`}}
                    </a></td>
            <td class="text-center">{{exc.purchase_rate}}</td>
            <td class="text-center">{{exc.sale_rate}}</td>
            <td class="text-center">{{(exc.activated_local) ? 'SI' : 'NO'}}</td>
            <td class="text-center">{{(exc.activated_api) ? 'SI' : 'NO'}}</td>
          </tr>
        </tbody>
      </table>

      <ExchangeRateFormView @cancel="display_hide=false; edit=false" :class="{hide:!display_hide}" :edit="edit" :exchange="exchange" :currency="currency" :currencies="currencies"/>
    </div>
  </div>
</template>
<script>
import ExchangeRateFormView from '@/components/admin/rates/ExchangeRateFormView';
export default {
    props:["currency", "currencies"],
    components:{
        ExchangeRateFormView
    },
    data(){
        return {
            display_hide:false,
            edit:false,
            exchange:'',
        }
    },

    methods:{
        showEditForm(data){
            this.display_hide=true; 
            this.edit=true,
            this.exchange = data;
        }
    }

}
</script>
<style scoped>
.hide{
    display: none !important;
}
</style>