<template>
  <div>
    <div class="row mt-2">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="form-group col-sm-12">
                <label for="ca_account">Nombre del Beneficiario</label>
                <input id="ca_account" type="text" class="form-control" v-model="account.holder" disabled/>
              </div>
              <div class="form-group col-sm-6">
                <label for="ca_type_document">Tipo Documento</label>
                <input id="ca_type_document" type="text" class="form-control" v-model="account.document_type.name" disabled/>
              </div>
              <div class="form-group col-sm-6">
                <label for="ca_dni">Nro. Documento</label>
                <input id="ca_dni" type="text" class="form-control" v-model="account.dni" disabled/>
              </div>
              <div class="form-group col-sm-6">
                <label for="ca_nationality">Nacionalidad</label>
                <input id="ca_nationality" type="text" class="form-control" v-model="account.nationality.name" disabled/>
              </div>
              <div class="form-group col-sm-6">
                <label for="ca_currency">Moneda</label>
                <input id="ca_currency" type="text" class="form-control" v-model="account.currency.name" disabled/>
              </div>
              <div class="form-group col-sm-12">
                <label for="ca_bank">Banco</label>
                <input id="ca_bank" type="text" class="form-control" v-model="account.bank.name" disabled/>
              </div>
              
              <div class="form-group col-sm-12">
                <label for="ca_account_number">Número de Cuenta</label>
                <input id="ca_account_number" type="text" class="form-control" v-model="account.account_dni" disabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                  <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12">
                    <label for="owner_account">Cuenta Debito</label>
                    <select v-model="owner_account_id" id="owner_account" class="form-control">
                      <option value>SELECCIONE</option>
                      <option v-for="(val, index) in owner_accounts" :key="index" :value="val.id">{{val.name}}</option>
                    </select>
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                    <label for="concepts_operation">Fecha de la Operaion</label>
                    <input type="date" v-model="date" class="form-control">
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                    <label for="description">Hora</label>
                    <input type="time" class="form-control" v-model="time" id="description"/>
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12">
                    <label for="owner_account">Nro. Operación</label>
                    <input class="form-control" v-model="operation_number"/>
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12">
                    <label for="owner_account">Monto</label>
                    <input class="form-control" v-model="amount" disabled/>
                  </div>
                  <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12">
                      <button v-if="status === 1" @click="withdrawOrderPay()">Pagar</button>
                  </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import AccountService from "@/service/AccountService";

export default {
    props:["id"],
    data(){
        return {
            account:{},
            owner_account_id: "",
            amount: "",
            operation_number: "",
            date: "",
            time: "",
            amount:'',
            status:'',
            owner_accounts:[],
        }
    },

    async created() {
        await AccountService.get().then(
        resp => (this.owner_accounts = resp.data.data)
        );

        await this.getWithdrawId();
    },

    methods:{
        withdrawOrderPay(){
            let data = {
                account_id : this.owner_account_id,
                operation_number : this.operation_number,
                date: this.date,
                time: this.time,
                
            }

            axios.put('admin/withdraws/'+this.id, data)
            .then(resp => {

            })
        },

        getWithdrawId(){
            axios.get("admin/withdraws/"+this.id)
            .then(resp => {
               this.account = resp.data.data.account;
               this.amount = resp.data.data.amount;
               this.status = resp.data.data.status;
            });
        }
    }
}
</script>