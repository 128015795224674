<template>
  <modal
    name="report-modal"
    :width="500"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
    @opened="opened"
  >
    <div class="content-modal">
      <h4 class="center-align np-nm">Reportar Orden</h4>
      <hr />
      <div class="section">
        <div class="row np-nm">
          <div class="input-field left-align col s12 np-nm">
            <label for="a" class="active">Codigo de Orden</label>
            <input type="text" id="a" v-model="order_code" />
          </div>
        </div>
        <div class="row np-nm">
          <div class="input-field left-align col s12 np-nm">
            <label for="note">Nota</label>
            <textarea id="note" class="materialize-textarea" v-model="note" data-length="120"></textarea>
          </div>
        </div>
        <div class="row np-nm">
          <div class="col s12 np-nm right-align pa">
            <a href="#" class="btn-small red" @click.prevent="reportOrder">Reportar</a>
            <a href="#" class="btn-small blue" @click.prevent>Cancelar</a>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import XLSX from "xlsx";
export default {
  data() {
    return {
      order: "",
      order_code: "",
      note: ""
    };
  },

  updated() {
    window.M.FormSelect.init(document.querySelectorAll("select"));
  },

  methods: {
    beforeOpen(event) {
      this.order = event.params.order;
      this.order_code = event.params.order.order_code;
    },

    opened() {
      M.CharacterCounter.init(document.querySelectorAll("#note"));
    },

    reportOrder() {
      let data = {
        id: this.order.id,
        note: this.note
      };

      axios
        .post("payment/orders/report", data)
        .then(response => {
         window. M.toast({ html: response.data.msg });
          this.$emit("refresh");

          this.$modal.hide("report-modal");
        })
        .catch(error => {});
    }
  }
};
</script>

<style scoped>
.np-nm {
  padding: 5px;
  margin: 0;
}

.pa a {
  margin-left: 10px;
}
</style>