<template>
    <div><br>
      <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary"><router-link tag="a" to="/">Tablero</router-link></li>
        <li class="breadcrumb-item text-primary"><router-link tag="a" to="/admin/orders">Ordenes</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">Detalle</li>
      </ol>
    </nav>
        <OrderView :id="id"/>
    </div>
</template>
<script>
import OrderView from '@/components/order/OrderView';

export default {
    props:["id"],
    components:{
        OrderView,
    }
}
</script>