<template>
    <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/cuentas/balance"
            >Balance de Cuentas</router-link
          >
        </li>
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" :to="{path:`/cuentas/resumen-balance/${id}/${mid}`}">Balance Resumen</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Detalle {{nombreCuenta}}
        </li>
      </ol>
    </nav>
        <div class="card">
            <div class="card-body">
                <h3 class="card-title">Movimientos [{{tipo}}]</h3>
                <MovimientosCompra :cuenta_id="id" v-if="tipo == 'compra'" />
                <TrasladoSaldo :cuenta_id="id" :inv_act="inv" v-if="tipo == 'traslado-saldo'" />
                <Transferencia :cuenta_id="id" :inv_act="inv" v-if="tipo == 'transferencia'" />
                <PagoOrden :cuenta_id="id" :mid="mid" v-if="tipo == 'pago-orden'" />
                <Ingreso :cuenta_id="id" :mid="mid" v-if="tipo == 'ingreso'" />
                <Ordenes :cuenta_id="id" :mid="mid" v-if="tipo == 'orden'" />
                <Pago :cuenta_id="id" :mid="mid" v-if="tipo == 'pago'" />
                <Vale :cuenta_id="id" :mid="mid" v-if="tipo == 'vale'" />
                <Gasto :cuenta_id="id" :mid="mid" v-if="tipo == 'gasto'" />
                <Ajuste :cuenta_id="id" :mid="mid" v-if="tipo == 'ajuste'" />
                <Egreso :cuenta_id="id" :mid="mid" v-if="tipo == 'egreso'" />
                <Movimiento :cuenta_id="id" :mid="mid" v-if="tipo == 'detalle'" />

            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import MovimientosCompra from '@/components/movimientos/Compras';
import TrasladoSaldo from '@/components/movimientos/TrasladoSaldos';
import Transferencia from '@/components/movimientos/Transferencias';
import PagoOrden from '@/components/movimientos/PagoOrdenes';
import Pago from '@/components/movimientos/Pagos';
import Vale from '@/components/movimientos/Vales';
import Gasto from '@/components/movimientos/Gastos';
import Ingreso from '@/components/movimientos/IngresoCuenta';
import Ordenes from '@/components/movimientos/OrdenesPago';
import Ajuste from '@/components/movimientos/Ajustes';
import Egreso from '@/components/movimientos/Egresos';
import Movimiento from '@/components/movimientos/Movimientos';

export default {

        props:["id", "mid", "tipo", "inv"],
        components:{
            MovimientosCompra,
            TrasladoSaldo,
            Transferencia,
            PagoOrden,
            Pago,
            Vale,
            Gasto,
            Ajuste,
            Ingreso,
            Egreso,
            Ordenes,
            Movimiento
        },    
        data(){
            return {
                nombreCuenta:'',
                resumen:[]
            }
        },

        created(){
            // this.getCuenta();
            this.getBalance();
        },

        computed:{
            total(){
                if(this.resumen.length <= 0) return 0;

                let total = 0;

                this.resumen.forEach(r => {
                    total += parseFloat(r.balance);
                });

                return total;
            }
        },

        methods:{

            getCuenta(){

                if(this.id == 0) return this.nombreCuenta = 'EFECTIVO CAJA';

                axios.get(`cuentas/${this.id}`)
                .then(resp => {
                    this.nombreCuenta = resp.data.name;
                })  
            },

            mostrarIngreso(val){

                if(val.balance > 0){
                    return val.balance;
                }

                return '';
            },

            mostrarEgreso(val){

                if(val.balance < 0){
                    return val.balance;
                }

                return '';
            },

            getBalance(){
                axios.get('/balance-resumen-cuenta/'+this.id+'/'+this.mid)
                .then(resp => {
                    this.resumen = resp.data;

                    let c = resp.data[0];
                    
                    if(c.cuenta_id == 0){

                        if(this.mid == 2){
                            this.nombreCuenta = 'Efectivo Caja [Soles]';

                        }else{
                            this.nombreCuenta = 'Efectivo Caja [Dolares]';
                        }
                    }else if(c.cuenta){
                        
                        this.nombreCuenta = c.cuenta['name'];

                    }else{

                        this.nombreCuenta = 'N/D ['+c.cuenta_id+']';
                    }

                    
                })
            }
        }
    }
</script>

<style scoped>

</style>