<template>
  <!-- Modal -->
<!--   <modal
    name="search"
    :width="500"
    :height="'auto'"
    @before-open="beforeOpen"
    @opened="opened"
    @closed="closed"
  > -->
  <div class="row">

  
    <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4"><br>
    <div class="card">
      <div class="card-body">
        <h5>Opciones de Busqueda</h5>
        <hr />
        <!-- Nav tabs -->
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Basica</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" >Rango</a>
          </li>
        </ul>
      <!-- Tab panes -->
      <div class="tab-content">
        <div class="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
          <br />
          <div class="form-group">
            <label for="option"><strong>Buscar por</strong></label>
            <select class="form-control" id="option" v-model="search_option">
              <option value>SELECCIONE</option>
              <option value="order_code">COD REMESA</option>
              <option value="operation_number">NUM. OPERACION</option>
              <option value="dni">N# DOCUMENTO</option>
              <option value="phone">N# TELEFONO</option>
              <option value="email">EMAIL</option>
              <option value="account_dni">N# CUENTA</option>
              <option value="customer_name">NOMBRE CLIENTE</option>
            </select>
          </div>
          <div class="form-group">
            <label for="param"><strong>Parametro</strong></label>
            <input type="text" class="form-control" id="param" v-model="param" />
          </div>
          <div class="form-group jj-right-align">
            <button class="btn btn-primary" @click="searchOrders">Buscar</button>
            <button type="buttom" class="btn btn-danger" @click="hideSearch">Cerrar</button>
          </div>
        </div>
        <div class="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <br />
          <div class="row">
            <div class="form-group col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <label for="from_date">Fecha desde</label>
              <input type="date" class="form-control" v-model="from_date" id="from_date" />
            </div>
            <div class="form-group col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <label for="to_date">Fecha Hasta</label>
              <input type="date" class="form-control" v-model="to_date" id="to_date" />
            </div>
            <div class="form-group col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <label for="status">Estatus</label>
              <select class="form-control" v-model="status_id" id="status">
                <option value selected>TODOS</option>
                <option value="pending">PENDIENTE</option>
                <option value="received">RECIBIDA</option>
                <option value="process">EN PROCESO DE PAGO</option>
                <option value="reported">REPORTADA</option>
                <option value="returned">DEVUELTA</option>
                <option value="annulled">ANULADA</option>
                <option value="paid">PAGADA</option>
                <option value="annulled-process">EN PROCESO DE ANULACION</option>
              </select>
            </div>
            <div class="form-group col-sm-12 col-md-12 col-lg-6 col-xl-6">
              <label for="register_by">Registrado por</label>
              <select class="form-control" v-model="register_by" id="register_by">
                <option value="all">TODOS</option>
                <option value="agent">AGENTES</option>
                <option value="employes">EMPLEADOS</option>
              </select>
            </div>
            <div v-if="register_by =='employes'" class="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <label for="employes">Empleados</label>
              <select class="form-control" id="user_id" v-model="user_id">
                <option value selected>TODOS</option>
                <option v-for="val in employes" :key="val.id" :value="val.id">{{val.name}}</option>
              </select>
            </div>
            <div v-if="register_by =='agent'" class="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <label for="agent">Agentes</label>
              <select class="form-control" v-model="agent_id" id="agent">
                <option value>TODOS</option>
                <option v-for="val in agents" :key="val.id" :value="val.id">{{val.name}}</option>
              </select>
            </div>
            <div class="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <label for="currency">Moneda</label>
              <select class="form-control" v-model="currency_id" id="currency">
                <option value>TODOS</option>
                <option value="2">SOL</option>
                <option value="4">DOLAR</option>
              </select>
            </div>
            <div class="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12">
              <button type="buttom" class="btn btn-primary" @click="applyFilter">Aplicar Filtro</button>
              <button type="buttom" class="btn btn-danger" @click="hideSearch">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
      
    </div>
    </div>
 <!--  </modal> -->
</template>
<script>
import EventBus from "@/event-bus.js";
import Employes from "@/service/EmployeService";
import Agent from "@/service/AgentService";

export default {
  data() {
    return {
      search_option: "",
      param: "",
      from_date: "",
      to_date: "",
      status_id: "",
      register_by: "all", 
      user_id: "",
      agent_id: "",
      currency_id: "",
      employes: [],
      agents: []
    };
  },

  created() {
    Employes.get().then(resp => (this.employes = resp.data));
    Agent.get('').then(resp => (this.agents = resp.data));
  },

  methods: {
    beforeOpen() {},

    opened() {},

    closed() {},

    setSearchModule(data) {
      console.log(data);
    },

    hideSearch(){
      EventBus.$emit("hideSearch");
    },

    applyFilter() {
      EventBus.$emit("searchRange", {
        from_date: this.from_date,
        to_date: this.to_date,
        status_id: this.status_id,
        register_by: this.register_by,
        user_id: this.user_id,
        agent_id: this.agent_id,
        currency_id: this.currency_id
      });
    },

    searchOrders() {
      EventBus.$emit("searchOrder", {
        search_option: this.search_option,
        param: this.param
      });
      this.$modal.hide('search')
    }
  }
};
</script>