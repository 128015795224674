<template>
  <div id="app">
    <div class="wrapper">
      <!-- Sidebar -->
      <nav id="sidebar" class="active">
        <Sidebar/>
        
      </nav>

      <!-- Page Content -->
      <div class="container-fluid p-0 m-0" id="content">
        <!-- We'll fill this with dummy content -->
        <nav v-if="$auth.check()" class="navbar bg-success">
          <button v-if="$auth.check()" type="button" id="sidebarCollapse" class="btn btn-light" @click="showSidebar">
            <i class="material-icons">menu</i>
            
          </button>
          <div v-if="$auth.check()" class=" ">
            <label for="" class="badge bg-light text-dark"><span class="h4">{{$auth.user().name}}</span></label>
        </div>
        </nav>
        <div class="container-fluid">

          <router-view/>
          
        </div>
      </div>
    </div>
    
    {{notificaciones}}
  </div>
</template>
<script>
import Sidebar from "@/components/menu/Sidebar";
const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $


export default {
  components: {
    Sidebar
  },

  data() {
    return {
      tono: require('@/assets/samsung_charming_bell.wav'),
      publicPath: process.env.BASE_URL
    };
  },

  created(){
     
  },

  computed : {

    notificaciones(){
      let notificacion = this.$store.state.notificationModule.socketMessage;

      console.log("recibi: ", notificacion);
     
      if(notificacion){

        if(notificacion.grupo == 0 && notificacion.usuario == 0 && notificacion.role == 0){
          console.log("opcion 1")
          let audio = document.createElement("audio"); 
          audio.src = this.tono; 
          audio.play();
          this.mostrarNotificacion(notificacion.header, notificacion.mensaje, notificacion.tipo);
          return;
        }

        if(notificacion.grupo == 1 && notificacion.role == this.$auth.user().roles){
          console.log("opcion 2")
          let audio = document.createElement("audio"); 
          audio.src = this.tono; 
          audio.play();
          this.mostrarNotificacion(notificacion.header, notificacion.mensaje, notificacion.tipo);
          return;
        }

        if( notificacion.usuario == this.$auth.user().id ){
          console.log("opcion 3")
           let audio = document.createElement("audio"); 
            audio.src = this.tono; 
            audio.play();
            this.mostrarNotificacion(notificacion.header, notificacion.mensaje, notificacion.tipo);
            return;

        }

      }
      
      
      return;
    }
  },

  mounted() {
    /* if(!this.$auth.check()){
      this.$auth.logout();
      this.$router.push({path:'/login'})
    } */
  },
  methods:{
    
    mostrarNotificacion(header, msg, tipo){
       swal(header, msg, tipo);
    },

    showSidebar(){
       $('#sidebar').toggleClass('active');
    }
  },
};
</script>
<style lang="scss">
@import url("./assets/layout.css");
</style>
