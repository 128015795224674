<template>
    <div>
    <br>
      <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary"><router-link tag="a" to="/">Tablero</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">Lista de Remesas</li>
      </ol>
    </nav>
    <div class="container-fluid mb-2">
        <button class="btn btn-small btn-info mr-3" @click="getOrdenes()">
            <i class="material-icons">refresh</i>
        </button>
         <button class="btn btn-small btn-warning mr-3" @click="mostrarFormBusqueda()">
            <i class="material-icons">search</i>
        </button>
        <button class="btn btn-small btn-primary">
            Nueva Remesa
        </button>
    </div>
    <div class="table-responsive">
    <table class="table table-striped table-sm">
        <thead>
            <tr>
                <th></th>
                <th class="text-center">Fecha y Hora</th>
                <th class="text-center">Orden</th>
                <th class="text-center">Estatus</th>
                <th class="text-center">Cliente</th>
                <th class="text-center">Tasa</th>
                <th class="text-center">Envia</th>
                <th class="text-center">Recibe</th>
            </tr>
        </thead>
        <tbody>
            <Orden v-for="(orden,index) in ordenes" :key="orden.id" :data="orden" :i="index+1" />
        </tbody>
        <tfoot v-if="calcTotal > 0">
            <tr>
                <td colspan="7" class="text-right">{{ totalEnvio | numFormat('0,0.00') }}</td>
                <td class="text-right">{{ totalRecibe | numFormat('0,0.00') }}</td>
            </tr>
        </tfoot>
    </table>
    </div>
    <div v-if="cargando" class="text-center">
      <div  class="spinner-border" role="status">
        <span class="sr-only">Cargando datos...</span>
      </div>
    </div>
    <FormBusqueda v-if="busqueda" @buscar="buscarOrdenes" />
    </div>
</template>

<script>
import FormBusqueda from '@/components/caja/BusquedaModal'
import Orden from '@/components/caja/OrdenLista'
import axios from 'axios'
    export default {
        components:{
            FormBusqueda,
            Orden
        },

        data(){
            return {
                totalEnvio:0,
                totalRecibe:0,
                cargando: false,
                busqueda:false,
                ordenes:[]
            }
        },

        computed:{
            calcTotal(){
                if(this.ordenes.length <= 0) return 0;

                let totalRecibe = 0;
                let totalEnvio = 0;

                this.ordenes.forEach(o => {
                    totalEnvio += parseFloat(o.amount);
                    totalRecibe += parseFloat(o.amount_to_pay);
                });

                this.totalRecibe = totalRecibe;
                this.totalEnvio = totalEnvio;

                return totalEnvio;
            }
        },

        created(){
            this.getOrdenes();
        },

        methods:{
            mostrarFormBusqueda(){
                this.busqueda = true;
                setTimeout(() => {
                    window.jQuery("#form-busqueda").modal("show");
                    
                }, 500);
            },

            buscarOrdenes({tipo_busqueda, filtro, texto_busqueda, fecha_desde, fecha_hasta}){
                this.ordenes = [];
                this.cargando = true;
                let url = '';
                if(tipo_busqueda == 'basica'){
                    url = `/orders?opt=busqueda-caja&tipo=${tipo_busqueda}&filtro=${filtro}&texto=${texto_busqueda}`;
                }else{
                    url = `/orders?opt=busqueda-caja&tipo=${tipo_busqueda}&fecha_desde=${fecha_desde}&fecha_hasta=${fecha_desde}`;
                }

                axios.get(`${url}`)
                .then(resp => {
                    this.cargando = false;
                    this.ordenes = resp.data
                })
                .catch(err => {
                    this.cargando = false;
                })
            },

            getOrdenes(){
                this.cargando = true;

                axios.get('orders')
                .then(resp => {
                    this.cargando = false;
                    this.ordenes = resp.data;
                })
                .catch(err => {
                    this.cargando = false;
                    console.log(err)
                })
            }
        }
        
    }
</script>

<style scoped>

</style>