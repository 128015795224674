
<template>
  <div class="row mt-2">
    <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
      <div class="card">
        <div class="card-header">
          <div class="card-title">Opciones</div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="form-group col-sm-6">
              <label for="from_date">Fecha desde</label>
              <input type="date" class="form-control" v-model="from_date" id="from_date" />
            </div>
            <div class="form-group col-sm-6">
              <label for="to_date">Fecha Hasta</label>
              <input type="date" class="form-control" v-model="to_date" id="to_date" />
            </div>
          </div>

          <button type="buttom" class="btn btn-primary" @click="applyFilter">Aplicar Filtro</button>
          <button type="buttom" class="btn btn-danger" @click="hideSearch">Cerrar</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/event-bus.js";
import Employes from "@/service/EmployeService";
import AccountService from "@/service/AccountService";

export default {
  data() {
    return {
      from_date: "",
      to_date: "",
     
    };
  },

  methods: {
    applyFilter() {
      EventBus.$emit("t_searchRange", {
        from_date: this.from_date,
        to_date: this.to_date,
      });
    },

    hideSearch() {
      EventBus.$emit("t_hideSearch");
    }
  }
};
</script>