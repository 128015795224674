<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="form-group col-sm-12">
                <label for="ca_account">Nombre del Beneficiario</label>
                <input id="ca_account" type="text" class="form-control" v-model="data.holder" />
              </div>
              <div class="form-group col-sm-6">
                <label for="ca_type_document">Tipo Documento</label>
                <input id="ca_type_document" type="text" class="form-control" v-model="data.document_type_name" />
              </div>
              <div class="form-group col-sm-6">
                <label for="ca_dni">Nro. Documento</label>
                <input id="ca_dni" type="text" class="form-control" v-model="data.dni" />
              </div>
              <div class="form-group col-sm-6">
                <label for="ca_nationality">Nacionalidad</label>
                <input id="ca_nationality" type="text" class="form-control" v-model="data.nationality_name" />
              </div>
              <div class="form-group col-sm-6">
                <label for="ca_currency">Moneda</label>
                <input id="ca_currency" type="text" class="form-control" v-model="data.currency_name" />
              </div>
              <div class="form-group col-sm-12">
                <label for="ca_bank">Banco</label>
                <input id="ca_bank" type="text" class="form-control" v-model="data.bank_name" />
              </div>
              
              <div class="form-group col-sm-12">
                <label for="ca_account_number">Número de Cuenta</label>
                <input id="ca_account_number" type="text" class="form-control" v-model="data.account_dni" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/event-bus.js";
export default {
    name:'CustomerAccountView',
    name:'CustomerView',
    data(){
        return {
            data:'',
        }
    },

    created(){
        EventBus.$on("setDataCustomerAccountView", this.setData);
    },

    methods:{
        setData(data){
           this.data = data;
        }
    }
}
</script>