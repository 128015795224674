<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Saldos Iniciales en Cuentas</li>
      </ol>
    </nav>
    <div class="row mt-2">
      <div class="form-group col-12 col-sm-12 col-md-6 col-lg-3">
        <label for>Pais</label>
        <select class="form-control" v-model="country">
          <option value>Seleccione</option>
          <option v-for="val in countries" :key="val.id" :value="val.id">{{val.name}}</option>
        </select>
      </div>
    </div>
    <div class="card mt-2">
      <div class="card-header">
        <div class="card-title">
          <h3>Listado de Cuentas</h3>
        </div>
      </div>
      <div class="card-body">
        <ListInitialBalanceAccount v-for="val in accountsFilter" :key="val.id" :account="val" />
      </div>
    </div>
  </div>
</template>
<script>
import AccountService from "@/service/AccountService";
import SelectList from "@/service/SelectListService";
import ListInitialBalanceAccount from "@/components/admin/account/ListInitialBalanceAccount";

export default {
  components: {
    ListInitialBalanceAccount
  },
  data() {
    return {
      country: "",
      countries: [],
      accounts: []
    };
  },

  async created() {
    await SelectList.country.get().then(resp => (this.countries = resp.data));
    await AccountService.get().then(resp => (this.accounts = resp.data.data));
  },

  computed: {
    accountsFilter() {
      return this.accounts.filter(a => a.bank.country_id == this.country);
    }
  }
};
</script>