<template>
  <div class="col col-lg-6 mb-5">
    <div class="card border-primary">
        <div class="card-header bg-primary text-white"><h3>{{index + 1 }} - Orden [{{orden.order_code}}]</h3></div>
      <div class="card-body">
        <div class="row">
          <div class="col col-lg-6">
            <div class="img-fluid">

            <image-magnifier
              :src="
                path + 'storage/payments/thumbnail/' + orden.payment_in.voucher
              "
              :zoom-src="
                path + 'storage/payments/thumbnail/' + orden.payment_in.voucher
              "
              width="350"
              height="400"
              zoom-width="400"
              zoom-height="400"
            ></image-magnifier>
            </div>
          </div>
          <div class="col col-lg-6 p-3">
            <div class="row">
              <div class="col col-lg-7">
                <div class="form-group">
                  <label for="">Registro</label>
                  <input type="text" class="form-control" disabled :value="orden.created_at"/>
                </div>
              </div>
              <div class="col col-lg-5">
                <div class="form-group">
                  <label for="">Cod. Orden</label>
                  <input type="text" class="form-control" readonly :value="orden.order_code"/>
                </div>
              </div>
            </div>

            <div class="form-group">
              <label for="">Cuenta</label>
               <select class="form-control" v-model="cuenta_id" :disabled="edicion">
                <option value="">Cuentas</option>
                <option v-for="val in cuentas" :key="val.id" :value="val.id">
                  {{ val.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="">Nro. Operacion</label>
               <input class="form-control" v-model="numero_operacion" :disabled="edicion" />
            </div>
            <div class="row">
              <div class="col col-lg-6">
                  <div class="form-group">
                    <label for="">Fecha</label>
                    <input type="date" class="form-control" v-model="fecha" :disabled="edicion" />
                </div>
              </div>
              <div class="col col-lg-6">
                <div class="form-group">
                  <label for="">Monto</label>
                  <input class="form-control text-right" v-model="monto" :disabled="edicion" />
                </div>
              </div>
            </div>
            <div class="form-group text-right">
              <div v-if="!orden.on_change" class="btn-group" role="group">
                <button v-if="edicion" class="btn btn-outline-primary" @click="edicion = !edicion" >
                  <i class="material-icons">edit</i>
                </button>
                <button v-if="!edicion" class="btn btn-outline-success" @click="actualizarPago()" >
                  <i class="material-icons">save</i>
                </button>
                <button v-if="!edicion" class="btn btn-outline-danger" @click="anularOrden()" >
                  <i class="material-icons">delete</i>
                </button>
                <button v-if="!edicion" class="btn btn-outline-secondary" @click="edicion = true" >
                  <i class="material-icons">keyboard_backspace</i>
                </button>
                <button v-if="edicion" @click="confirmarOrden()" type="button" class="btn btn-outline-success">
                  Confirmar
                </button>
              </div>
              <button v-if="orden.on_change" type="button" class="btn btn-outline-secondary" >
                <i class="material-icons">lock</i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfirmationService from "@/service/ConfirmationService";

export default {
  props: ["index", "orden", "cuentas"],

  data() {
    return {
      path: process.env.VUE_APP_AXIOS_PUBLIC_URL,
      edicion: true,
      cuentasBancarias: [],
      cuenta_id: "",
      numero_operacion: "",
      fecha: "",
      monto: "",
    };
  },

  created() {
    let cuenta = this.orden["payment_in"]["account_receive"];

    if (typeof cuenta == "string") {
      cuenta = JSON.parse(cuenta);
    }
    this.cuenta_id = cuenta["account_id"];
    this.fecha = this.orden["payment_in"]["date"];
    this.numero_operacion = this.orden["payment_in"]["operation_number"];
    this.monto = this.orden["payment_in"]["total"];
    this.monto = parseFloat(this.monto).toFixed(2);
  },

  watch: {
    cuentas: (actual, modificada) => {
      // console.log("actual "+actual+" modificada "+modificada)
    },
  },

  methods: {
    confirmarOrden() {
      ConfirmationService.paymentOrders
        .post(
          "/confirm/" +
            this.orden.id +
            "?payment_id=" +
            this.orden["payment_in"]["id"],
          {}
        )
        .then((r) => {
          this.$emit("remover");
          swal("Orden confirmada exitosamente", "", "success");
        })
        .catch((err) => {
          // console.log(err.response.data.error.message.toString());
          swal("Error", err.response.data.error.message.toString(), "error");
        });
    },

    actualizarPago() {
      swal({
        icon: "warning",
        title: "",
        text: "¿Desea continuar con la operacion, modificar los datos de pago?",
        buttons: {
          cancel: true,
          confirm: "Continuar",
        },
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result) {
          let data = {
            cuenta_id: this.cuenta_id,
            fecha: this.fecha,
            numero_operacion: this.numero_operacion,
            monto: this.monto,
          };

          ConfirmationService.paymentOrders
            .update(
              "/" +
                this.orden.id +
                "?opt=update&payment_id=" +
                this.orden["payment_in"]["id"],
              data
            )
            .then((r) => {
              swal("Datos guardados exitosamente", "", "success");
            })
            .catch((err) => {
              // console.log(err.response.data.error.message.toString());
              swal(
                "Error",
                err.response.data.error.message.toString(),
                "error"
              );
            });
        }
      });
    },

    anularOrden() {
      swal({
        icon: "warning",
        title: "",
        text: "¿Desea anular la orden?",
        // buttons: {

        //   cancel: true,
        //   confirm: "Continuar",

        // },
        dangerMode: true,
        buttons: true,
      }).then((result) => {
        if (result) {
          swal({
            content: {
              element: "input",
              attributes: {
                placeholder: "Ingrese la nota de Anulación",
                type: "text",
              },
            },
          }).then((resp) => {
            if (resp.length == 0) {
              swal("Debe ingresar la nota de anulación", "", "info");
            } else {
              ConfirmationService.Order.delete(this.orden.id, resp)
                .then((r) => {
                  this.$emit("remover");
                  swal("Orden anulada exitosamente", "", "success");
                })
                .catch((err) => {
                  console.log(err);
                  swal("Error", "La orden no pudo ser anulada", "error");
                });
            }
          });
        }
      });
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.img-magnifier-container {
  position: relative;
}

.img-magnifier-glass {
  position: absolute;
  border: 3px solid #000;
  border-radius: 50%;
  cursor: none;
  /*Set the size of the magnifier glass:*/
  width: 100px;
  height: 100px;
}
</style>