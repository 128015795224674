<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/admin/accounts/owner">Cuentas</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Movimientos</li>
      </ol>
    </nav>
    <button class="btn btn-small btn-primary" @click="showSearch">
        <i class="material-icons">filter_list</i>
      </button>
    <button href="#" @click.prevent="exportToExcel" class="btn btn-small btn-success">Exportar</button>
    <br />
    
    <div v-if="display" class="table-responsive mt-2">
      <table class="table">
        <thead>
          <tr>
            <th>Fecha</th>
            <th>
              <select v-model="account" class="form-control">
                <option value>TODAS LAS CUENTAS</option>
                <option
                  v-for="(val, index) in accountFilter"
                  :key="index"
                  :value="val.name"
                >{{val.name}}</option>
              </select>
            </th>
            <th>
              <select v-model="bank" class="form-control">
                <option value>TODOS LOS BANCOS</option>
                <option
                  v-for="(val, index) in bankFilter"
                  :key="index"
                  :value="val.name"
                >{{val.name}}</option>
              </select>
            </th>
            <th>Numero de Cuenta</th>
            <th>Numero de Operacion</th>
            <th>
              <select v-model="currency" class="form-control">
                <option value>TODAS</option>
                <option
                  v-for="(val, index) in currencyFilter"
                  :key="index"
                  :value="val.name"
                >{{val.name}}</option>
              </select>
            </th>
            <th>
              <select v-model="confirmed" class="form-control">
                <option value>CONFIRMADO</option>
                <option value="1">SI</option>
                <option value="0">NO</option>
              </select>
            </th>
            <th>Monto</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="val in transactionsFilter" :key="val.id">
            <td>{{val.date}}</td>
            <td>{{val.account_name}}</td>
            <td>{{val.bank_name}}</td>
            <td>{{val.account_dni}}</td>
            <td>{{val.operation_number}}</td>
            <td>{{val.currency_name}}</td>
            <td>{{(val.confirmed == 1) ? 'SI' : 'NO' }}</td>
            <td class="text-right">{{val.amount | numFormat('0,0.00')}}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="bg-success text-white">
            <td colspan="7" class="text-right">TOTAL</td>
            <td class="text-right">{{total | numFormat('0,0.00')}}</td>
          </tr>
        </tfoot>
      </table>
    </div>
    <Search v-if="!display" />
  </div>
</template>

<script>
import EventBus from "@/event-bus.js";
import axios from "axios";
import Search from "@/components/admin/account/SearchView";
import XLSX from "xlsx";
export default {
  components: {
    Search
  },
  data() {
    return {
      display: true,
      account: "",
      bank: "",
      currency: "",
      confirmed: "",
      from_date: null,
      to_date: null,
      transactions: []
    };
  },

  created() {
    EventBus.$on("t_searchRange", this.searchRange);
    EventBus.$on("t_hideSearch", this.hideSearch);
  },


  computed: {
    accountFilter() {
      const data = this.transactions;
      const result = [];
      const map = new Map();

      for (const account of data) {
        if (!map.has(account.account_name)) {
          map.set(account.account_name, true); // set any value to Map
          result.push({
            name: account.account_name
          });
        }
      }

      return result;
    },

    currencyFilter() {
      const data = this.transactions;
      const result = [];
      const map = new Map();

      for (const account of data) {
        if (!map.has(account.currency_name)) {
          map.set(account.currency_name, true); // set any value to Map
          result.push({
            name: account.currency_name
          });
        }
      }

      return result;
    },

    bankFilter() {
      const data = this.transactions;
      const result = [];
      const map = new Map();

      for (const account of data) {
        if (!map.has(account.bank_name)) {
          map.set(account.bank_name, true); // set any value to Map
          result.push({
            name: account.bank_name
          });
        }
      }

      return result;
    },

    transactionsFilter: function() {
      let data_filter = this.transactions;

      if (this.account != "") {
        data_filter = data_filter.filter(d => d.account_name == this.account);
      }

      if (this.bank != "") {
        data_filter = data_filter.filter(d => d.bank_name == this.bank);
      }

      if (this.currency != "") {
        data_filter = data_filter.filter(d => d.currency_name == this.currency);
      }
      if (this.confirmed != "") {
        data_filter = data_filter.filter(d => d.confirmed == this.confirmed);
      }

      this.calculateTotal(data_filter);

      return data_filter;
    }
  },

  methods: {

    hideSearch() {
      this.display = true;
    },

    showSearch() {
      this.display = false;
    },

    calculateTotal(data_filter) {
      let total = 0;
      for (let i = 0; i < data_filter.length; i++) {
        total += parseFloat(data_filter[i].amount);
      }

      this.total = total;
    },

    searchRange(data) {
      this.display = true
      axios
        .get(
          "admin/reports/accounts/transactions/" +
            data.from_date +
            "/" +
            data.to_date
        )
        .then(response => {
          this.transactions = response.data.map(p => {
            let payment = JSON.parse(p.account_receive);

            return {
              id: p.id,
              date: p.date,
              account_name: payment.name,
              bank_name: payment.bank_name,
              account_dni: payment.account_dni,
              operation_number: p.operation_number,
              currency_name: p.currency_name,
              amount: p.amount,
              confirmed: p.confirmed
            };
          });
        })
        .catch(error => {
          console.log(error);
        });
    },

    exportToExcel() {
      let transactions = this.transactionsFilter.map(c => {
        return {
          date: c.date,
          account_name: c.account_name,
          bank_name: c.bank_name,
          account_dni: c.account_dni,
          operation_number: c.operation_number,
          currency_name: c.currency_name,
          confirmed: c.confirmed == 1 ? "SI" : "NO",
          amount: c.amount
        };
      });

      let data = XLSX.utils.json_to_sheet(transactions, {
        header: [
          "date",
          "account_name",
          "bank_name",
          "account_dni",
          "operation_number",
          "currency_name",
          "confirmed",
          "amount"
        ]
      });

      data["A1"].v = "Fecha";
      data["B1"].v = "Cuenta";
      data["C1"].v = "Banco";
      data["D1"].v = "Nro de Cuenta";
      data["E1"].v = "Nro de Operacion";
      data["F1"].v = "Moneda";
      data["G1"].v = "Confirmado";
      data["H1"].v = "Monto";

      const workbook = XLSX.utils.book_new();
      const filename = "Estado de Cuenta";

      XLSX.utils.book_append_sheet(workbook, data, filename);

      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }
  }
};
</script>

<style scoped>
.custom-panel {
  background-color: #ffffff;
  margin: 5px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 10px;
}
</style>