<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item text-primary" aria-current="page">
            <router-link tag="a" to="/admin/agents/owner">Listado de Agentes</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
            Detalle
        </li>
      </ol>
    </nav>
    <div class="mt-2 mb-2">
      <a
        href="#"
        v-if="!id"
        class="btn btn-small"
        :disabled="button_disabled"
        @click.prevent="registerAgent()"
      >Registrar</a>
      <a
        v-if="id > 0"
        href="#"
        class="btn btn-small btn-success"
        @click.prevent="updateAgent()"
      >Actualizar</a>
    </div>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="home-tab"
          data-toggle="tab"
          href="#home"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >Agente</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="profile-tab"
          data-toggle="tab"
          href="#profile"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
        >Fachada</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="settings-tab"
          data-toggle="tab"
          href="#settings"
          role="tab"
          aria-controls="settings"
          aria-selected="false"
        >Cuenta</a>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
        <div class="row">
          <div class="col-12 col-sm-6 col-md-6 col-lg-l6">
            <div class="card mt-2">
              <div class="card-body">
                <div class="form-group">
                  <label for="agent_name">Nombre del Agente</label>
                  <input
                    type="text"
                    class="form-control"
                    id="agent_name"
                    v-model="agent_name"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="business_name">Nombre Comercial</label>
                  <input
                    type="text"
                    class="form-control"
                    id="business_name"
                    v-model="business_name"
                    required
                  />
                </div>
                <div class="form-group">
                  <label for="contact_name">Nombre de Contacto</label>
                  <input
                    type="text"
                    class="form-control"
                    id="contact_name"
                    v-model="contact_name"
                    required
                  />
                </div>
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label for="document_type" class="active">Tipo de Documento</label>
                      <select v-model="document_type" id="document_type" class="form-control">
                        <option
                          v-for="(val, index) in documentTypes"
                          :key="index"
                          :value="val.id"
                        >{{val.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label for="dni">Nro. Documento</label>
                      <input type="text" class="form-control" id="dni" v-model="dni" required />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label for="phone">Telefono</label>
                      <input type="text" class="form-control" id="phone" v-model="phone" required />
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label for="mobile">Celular</label>
                      <input type="text" class="form-control" id="mobile" v-model="mobile" required />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="position">Cargo</label>
                  <input type="text" class="form-control" id="position" v-model="position" required />
                </div>
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group">
                      <label for="phone">Latitud</label>
                      <input type="text" class="form-control" id="phone" v-model="lt" disabled />
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <div class="form-group np-nm">
                      <label for="mobile">Longitud</label>
                      <input type="text" class="form-control" id="mobile" v-model="ln" disabled />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <button class="btn btn-small btn-primary" @click="getLocation">Obtener Ubicación</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-6 col-lg-l6">
            <div class="card mt-2">
              <div class="card-body">
                <div class="row">
                  <div class="col s12 m6">
                    <div class="form-group">
                      <label for="country" class="active">Pais</label>
                      <select v-model="country" id="country" class="form-control">
                        <option value>SELECCIONE</option>
                        <option
                          v-for="(val, index) in countries"
                          :key="index"
                          :value="val.id"
                        >{{val.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col s12 m6">
                    <div class="form-group">
                      <label for="city" class="active">Ciudad</label>
                      <select id="city" v-model="city" class="form-control">
                        <option value>SELECCIONE</option>
                        <option
                          v-for="(val, index) in cities"
                          :key="index"
                          :value="val.id"
                        >{{val.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="address">Dirección</label>
                  <input type="text" class="form-control" id="address" v-model="address" required />
                </div>
                <div class="row">
                  <div class="col s12 m6">
                    <div class="form-group">
                      <label for="postal_code">Codigo Postal</label>
                      <input
                        type="text"
                        class="form-control"
                        id="postal_code"
                        v-model="postal_code"
                        required
                      />
                    </div>
                  </div>
                  <div class="col s12 m6">
                    <div class="form-group">
                      <label for="business_type" class="active">Rubro de Negocio</label>
                      <select v-model="business_type" id="business_type" class="form-control">
                        <option value>SELECCIONE</option>
                        <option
                          v-for="(val, index) in businessSectors"
                          :key="index"
                          :value="val.id"
                        >{{val.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col s12 m6">
                    <div class="form-group">
                      <label for="status" class="active">Estatus</label>
                      <select id="status" v-model="status" class="form-control">
                        <option value>SELECCIONE</option>
                        <option
                          v-for="(val, index) in statuses"
                          :key="index"
                          :value="val.id"
                        >{{val.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col s12 m6">
                    <div class="form-group">
                      <label for="address">Porcentaje de Ganancia</label>
                      <input
                        type="text"
                        class="form-control"
                        id="address"
                        v-model="profit"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <label for="status" class="active">Tipo de Agente</label>
                  <select id="status" v-model="agent_type" class="form-control">
                    <option value>SELECCIONE</option>
                    <option value="0">PROPIO</option>
                    <option value="1">AUTORIZADO</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
        <div class="row mt-2">
          <div class="col-12 col-sm-9 col-md-9 col-lg-8">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="file-field col s12 m12 l12">
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          type="file"
                          class="custom-file-input"
                          id="inputGroupFile04"
                          v-on:change="handlePictureUpload()"
                        />
                        <label class="custom-file-label" for="inputGroupFile04">Seleccionar Imagen</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="container">
                  <img class="responsive-img" :src="setImage" />
                </div>
                <a
                  v-if="id && image"
                  class="btn btn-small btn-danger text-white"
                  @click.prevent="removeImage"
                >Remover Imagen</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane" id="settings" role="tabpanel" aria-labelledby="settings-tab">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-sm-9 col-md-9 col-lg-6">
                <div class="card-panel">
                  <div class="form-group">
                    <label for="account_name">Nombre</label>
                    <input
                      type="text"
                      class="form-control"
                      id="account_name"
                      v-model="account_name"
                    />
                  </div>
                  <div class="form-group">
                    <label for="email">Email</label>
                    <input type="text" class="form-control" id="email" v-model="email" />
                  </div>
                  <div class="form-group">
                    <label for="passwd">Password</label>
                    <input type="password" id="passwd" class="form-control" v-model="password" required />
                  </div>
                  <div class="form-group">
                    <button
                      v-if="id > 0"
                      @click.prevent="updateAccountAgent"
                      class="btn btn-small btn-success"
                    >Actualizar Cuenta</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["id"],
  data() {
    return {
      button_disabled: false,
      profile_id: "",
      image: "",
      /* agente tab_1 */
      agent_name: "",
      business_name: "",
      contact_name: "",
      document_type: "",
      dni: "",
      phone: "",
      mobile: "",
      position: "",
      lt: "",
      ln: "",
      /* direccion tab_1 */
      country: "",
      city: "",
      address: "",
      postal_code: "",
      business_type: "",
      status: "",
      profit: "",
      agent_type: "",
      /* fachada tab_2 */
      picture: "",
      /* cuenta tab_3 */
      account_name: "",
      email: "",
      password: "",

      //Listas
      documentTypes: [],
      countries: [],
      cities: [],
      statuses: [],
      businessSectors: []
    };
  },
  watch: {
    country: function(new_val) {
      if (new_val > 0) {
        this.getCities(new_val);
      }
    },

    id: function(new_val) {
      if (new_val) {
        this.getAgent();
        this.getDocumentTypes();
        this.getCountries();
        this.getBusinessSectors();
        this.getStatuses();
      }
    }
  },

  mounted() {
    if (this.id) {
      this.getAgent();
    }
    this.getDocumentTypes();
    this.getCountries();
    this.getBusinessSectors();
    this.getStatuses();
  },

  updated() {},

  computed: {
    setImage() {
      if (this.image) {
        return process.env.VUE_APP_AXIOS_PUBLIC_URL+'storage/agents/thumbnail/' + this.image

      }
    }
  },

  methods: {
    handlePictureUpload() {
      this.picture = this.$refs.picture.files[0];
    },

    removeImage() {
      axios
        .post("admin/agents/remove-image", { id: this.id })
        .then(response => {
          this.image = "null";
          window.M.toast({ html: response.data.msg });
        })
        .catch(error => {
          console.log(error);
        });
    },

    editAccount: function() {
      document.documentElement.scrollTop = 0;
    },

    cancelEdit: function() {
      this.edit_mode = false;
    },

    getLocation() {
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };

      navigator.geolocation.getCurrentPosition(
        this.setLocation,
        this.errorLocation,
        options
      );
    },

    errorLocation(err) {
      console.warn("ERROR(" + err.code + "): " + err.message);
    },

    setLocation(pos) {
      var crd = pos.coords;

      /* console.log('Your current position is:');
      console.log('Latitude : ' + crd.latitude);
      console.log('Longitude: ' + crd.longitude);
      console.log('More or less ' + crd.accuracy + ' meters.');
 */
      this.lt = crd.latitude;
      this.ln = crd.longitude;
    },

    registerAgent: function() {
      this.button_disabled = true;

      let data = new FormData();

      data.append("agent_name", this.agent_name);
      data.append("business_name", this.business_name);
      data.append("contact_name", this.contact_name);
      data.append("document_type", this.document_type);
      data.append("dni", this.dni);
      data.append("phone", this.phone);
      data.append("mobile", this.mobile);
      data.append("position", this.position);
      data.append("lt", this.lt);
      data.append("ln", this.ln);
      data.append("country", this.country);
      data.append("city", this.city);
      data.append("address", this.address);
      data.append("postal_code", this.postal_code);
      data.append("business_type", this.business_type);
      data.append("status", this.status);
      data.append("profit", this.profit);
      data.append("agent_type", this.agent_type);
      data.append("picture", this.picture);
      data.append("account_name", this.account_name);
      data.append("email", this.email);
      data.append("password", this.password);

      axios
        .post("admin/agents", data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          window.M.toast({
            html: response.data.message
          });
          this.button_disabled = false;
          return this.$router.push({
            path: "/agents/detail/" + response.data.id
          });
        })
        .catch(error => {
          this.button_disabled = false;
          let obj = error.response.data.error.message;
          for (const prop in obj) {
            window.M.toast({ html: obj[prop] });
            //console.log(`obj.${prop} = ${obj[prop]}`);
          }
        });
    },

    updateAgent: function() {
      let data = new FormData();

      data.append("agent_name", this.agent_name);
      data.append("business_name", this.business_name);
      data.append("contact_name", this.contact_name);
      data.append("document_type", this.document_type);
      data.append("dni", this.dni);
      data.append("phone", this.phone);
      data.append("mobile", this.mobile);
      data.append("position", this.position);
      data.append("lt", this.lt);
      data.append("ln", this.ln);
      data.append("country", this.country);
      data.append("city", this.city);
      data.append("address", this.address);
      data.append("postal_code", this.postal_code);
      data.append("business_type", this.business_type);
      data.append("status", this.status);
      data.append("profit", this.profit);
      data.append("agent_type", this.agent_type);
      data.append("picture", this.picture);

      axios
        .post("admin/agents/" + this.id, data, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.data.img) {
            this.image = response.data.img;
          }
          window.M.toast({ html: response.data.message });
        })
        .catch(error => {
          let obj = error.response.data.error.message;
          for (const prop in obj) {
            window.M.toast({ html: obj[prop] });
            //console.log(`obj.${prop} = ${obj[prop]}`);
          }
        });
    },

    updateAccountAgent: function() {
      let data = {
        name: this.account_name,
        email: this.email,
        password: this.password
      };

      axios
        .put("admin/agents/account/" + this.id, data)
        .then(response => {
          window.M.toast({ html: response.data.message });
        })
        .catch(error => {
          let obj = error.response.data.error.message;
          for (const prop in obj) {
            window.M.toast({ html: obj[prop] });
            //console.log(`obj.${prop} = ${obj[prop]}`);
          }
        });
    },

    getCities: function(id) {
      axios.get("/mod_mant/get-cities-country/" + id).then(response => {
        this.cities = response.data;
      });
    },

    getCountries: function() {
      axios.get("/mod_mant/get-countries").then(response => {
        response.data.unshift({
          id: "",
          name: "SELECCIONE"
        });
        this.countries = response.data;

        /* console.log(response.data) */
      });
    },
    getDocumentTypes: function() {
      axios.get("/mod_mant/get-document-type").then(response => {
        response.data.unshift({
          id: "",
          name: "SELECCIONE"
        });
        this.documentTypes = response.data;
      });
    },

    getBusinessSectors: function() {
      axios.get("/mod_mant/get-business-sectors").then(response => {
        this.businessSectors = response.data;

        /* console.log(response.data) */
      });
    },

    getStatuses: function() {
      axios.get("/mod_mant/get-statuses/mod_agent").then(response => {
        this.statuses = response.data;

        /* console.log(response.data) */
      });
    },

    getAgent() {
      axios.get("admin/agents/" + this.id).then(response => {
        this.setInputs(response.data);
      });
    },

    setInputs(data) {
      this.agent_name = data.name;
      this.business_name = data.business_name;
      this.document_type = data.document_type_id;
      this.dni = data.dni;
      this.contact_name = data.contact_name;
      this.position = data.position;
      this.phone = data.phone;
      this.mobile = data.mobile;
      this.country = data.country_id;
      this.city = data.city_id;
      this.address = data.address;
      this.postal_code = data.postal_code;
      this.business_type = data.business_sector_id;
      this.agent_type = data.agent_type;
      this.status = data.status_id;
      this.profit = data.profit;
      this.image = data.img;
      this.email = data.user.email;
      this.account_name = data.user.name;
    }
  }
};
</script>