<template>
  <div>
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab">Orden</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab">Cliente</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab">Beneficiario</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-payment" role="tab">Pago</a>
      </li>
      <li v-if="order_data.status_id==11" class="nav-item">
        <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-process" role="tab">Procesar</a>
      </li>
      <li v-if="order_data.status_id==16" class="nav-item">
        <a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-process" role="tab">Procesado</a>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
        <OrderBaseView/>
      </div>
       <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
        <CustomerView/>
      </div>
      <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
        <CustomerAccountView/>
      </div>
     <div class="tab-pane fade" id="pills-payment" role="tabpanel" aria-labelledby="pills-contact-tab">
        <CustomerPaymentView/>
      </div>
     <div v-if="order_data.status_id==11 && order_data.order_type=='GIRO'" class="tab-pane fade" id="pills-process" role="tabpanel" aria-labelledby="pills-contact-tab">
        <ProcessPaymentView :order_id="id" :amount_to_pay="order_data.amount_to_pay"/>
      </div>
     <div v-if="order_data.status_id==16" class="tab-pane fade" id="pills-process" role="tabpanel" aria-labelledby="pills-contact-tab">
        <ProcessPaymentDetailView :data="payment_out"/>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/event-bus.js";
import ConfirmationService from "@/service/ConfirmationService";
import CustomerView from '@/components/order/CustomerView';
import CustomerAccountView from '@/components/order/CustomerAccountView';
import CustomerPaymentView from '@/components/order/CustomerPaymentView';
import ProcessPaymentView from '@/components/order/ProcessPaymentView';
import ProcessPaymentDetailView from '@/components/order/ProcessPaymentDetailView';
import OrderBaseView from '@/components/order/OrderBaseView';

export default {
  
  components:{
    OrderBaseView,
    CustomerView,
    CustomerAccountView,
    CustomerPaymentView,
    ProcessPaymentView,
    ProcessPaymentDetailView
  },

  props:["id"],

  data(){
    return {
      order_data:'',
      payment_out:'',
      customer_data:'',
    }
  },

  mounted(){
    if(this.id){

      this.getOrder(this.id)
    }
  },

  watch:{
    $route(to, from) {
      this.getOrder(to);
    },
    id(new_val){
      this.getOrder(new_val);
    }
  },

  methods:{
    getOrder(id){
      //EventBus.$emit("setDataOrderBase","asdasd");
      
      ConfirmationService.Order.getById(id)
      .then(resp => {
        this.payment_out = resp.data.transaction
        this.order_data = {
          amount_to_pay :resp.data.amount_to_pay,
          order_code:resp.data.order_code,
          order_type:resp.data.order_type.name,
          status:resp.data.status.name,
          status_id:resp.data.status.id,
          user_name:resp.data.user.name,
        };
        console.log(this.order_data)

        EventBus.$emit("setDataOrderBase", {
          order_id:id,
          order_code:resp.data.order_code,
          order_type:resp.data.order_type.name,
          status:resp.data.status.name,
          user_name:resp.data.user.name,
          created_at:resp.data.created_at,
          confirmed:resp.data.confirmed,
        });
        
        EventBus.$emit("setDataCustomerView", {
          dni: resp.data.user_send.dni,
          name: resp.data.user_send.name,
          email: resp.data.user_send.email,
          phone: resp.data.user_send.phone,
          document_type_name: resp.data.user_send.document_type_name
        });

        EventBus.$emit("setDataCustomerAccountView", {
          dni: resp.data.user_receive.dni,
          holder: resp.data.user_receive.holder,
          email: resp.data.user_receive.email,
          phone: resp.data.user_receive.phone,
          document_type_name: resp.data.user_receive.document_type_name,
          bank_name: resp.data.user_receive.bank_name,
          account_dni: resp.data.user_receive.account_dni,
          currency_name: resp.data.user_receive.currency_name,
          nationality_name: resp.data.user_receive.nationality_name,
        });

        EventBus.$emit("setDataCustomerPaymentView", resp.data);

      });

    }
  }
}
</script>