<template>
  <div>
    <div class="row mb-2">
      <div class="col col-lg-7"></div>
      <div class="col col-lg-2">
        <input type="date" class="form-control" v-model="fecha_desde" />
      </div>
      <div class="col col-lg-2">
        <input type="date" class="form-control" v-model="fecha_hasta" />
      </div>
      <div class="col col-lg-1">
        <button class="btn btn-small btn-primary" @click="filtrar()">
          Filtrar
        </button>
      </div>
    </div>
    <table class="table-striped">
      <thead>
        <tr>
          <th class="text-center"></th>
          <th class="text-center">Fecha</th>
          <th class="text-center">Usuario</th>
          <th class="text-center">T. Mov</th>
          <th class="text-center">Lote</th>
          <th class="text-center">Cuenta</th>
          <th class="text-center">Monto</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="mov in movimientos" :key="mov.id">
          <td class="text-center">
            <button class="btn btn-primary btn-sm" @click="verDetalle(mov)">Detalle</button>
          </td>
          <td class="text-center">{{ mov.created_at }}</td>
          <td>{{ mov.usuario.name }}</td>
          <td class="text-center">{{ mov.tipo }}</td>
          <td class="text-center">{{ mov.lote_id }}</td>
          <td>{{ cuenta_id == 0 ? "EFECTIVO CAJA" : mov.cuenta.name }}</td>
          <td class="text-right" :class="{
             'text-success' : (mov.inv_act >= 0),
             'text-danger' : (mov.inv_act < 0)
          }">{{ mov.total | numFormat("0,0.00") }}</td>
        </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col-8"></div>
      <div class="col-4">
        <div class="card mt-3">
          <div class="card-body">
            <div class="row">
              <div class="col-6 text-right">Balance Inicial</div>
              <div class="col-6 text-right text-primary">{{balance_inicial | numFormat("0,0.00")}}</div>
            </div>
            <div class="row">
              <div class="col-6 text-right">Ingresos</div>
              <div class="col-6 text-right text-success">{{ingresos | numFormat("0,0.00")}}</div>
            </div>
            <div class="row">
              <div class="col-6 text-right">Egresos</div>
              <div class="col-6 text-right text-danger">{{egresos | numFormat("0,0.00")}}</div>
            </div>
            <div class="row">
              <div class="col-12"><hr /></div>
            </div>
            <div class="row">
              <div class="col-6 text-right">Saldo</div>
              <div class="col-6 text-right">{{saldo | numFormat("0,0.00")}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalComponent :detalle="detalle" :cuentas="cuentas" />
  </div>
</template>

<script>
import axios from "axios";
import ModalComponent from '@/components/movimientos/Modal';
import ConfirmationService from "@/service/ConfirmationService";

export default {
  props: ["cuenta_id", "mid"],

  components:{
    ModalComponent
  },

  data() {
    return {
      detalle: '',
      modal:'',
      fecha_desde: "",
      fecha_hasta: "",
      balance_inicial:0,
      movimientos: [],
      cuentas:[]
    };
  },

  async created(){
    await ConfirmationService.Account.get('?country=2').then(
      (resp) => (this.cuentas = resp.data.data)
    );
  },

  computed:{
      ingresos(){
          if(this.movimientos.length <= 0) return 0;
          let ingresos = 0;
          this.movimientos.forEach(m => {
              if(m.inv_act >= 0){
                  ingresos += parseFloat(m.total);
              }
          });

          return parseFloat(ingresos);
      },

      egresos(){
        if(this.movimientos.length <= 0) return 0;
        let egresos = 0;
        this.movimientos.forEach(m => {
            if(m.inv_act < 0){
                egresos += parseFloat(m.total);
            }
        });

        return parseFloat(egresos);
      },

      saldo(){
          return (this.balance_inicial + this.ingresos) - this.egresos;
      }
  },

  methods: {

    verDetalle(mov){
      this.detalle = mov;

      setTimeout(function(){
        window.jQuery("#detalle-movimiento").modal("show");

      }, 500)
    },

    filtrar() {
      axios
        .get(
          `/movimientos?cid=${this.cuenta_id}&mid=${this.mid}&fd=${this.fecha_desde}&fh=${this.fecha_hasta}`
        )
        .then((resp) => {
          this.movimientos = resp.data.mov;
          this.balance_inicial = parseFloat(resp.data.balance_inicial);
        });
    },
  },
};
</script>

<style scoped>
</style>