<template>
  <div class="container-report">
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Reporte Rentabilidad</li>
      </ol>
    </nav>
    <div>
      <button class="btn btn-small btn-primary" @click="showSearch">
        <i class="material-icons">filter_list</i>
      </button>
    </div>
    <br />
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="home-tab"
          data-toggle="tab"
          href="#home"
          role="tab"
          aria-controls="home"
          aria-selected="true"
        >Resumen</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="profile-tab"
          data-toggle="tab"
          href="#profile"
          role="tab"
          aria-controls="profile"
          aria-selected="false"
        >Detalle</a>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
        <div v-if="display" class="table-responsive">
          <template v-for="(currencies, index) in totales">
            
            <table class="table" :key="index">
              <thead>
                <tr>
                  <th class="text-center th-min">Moneda</th>
                  <th class="text-center th-min">Tipo</th>
                  <th class="text-center th-min">Monto</th>
                  <th class="text-center th-min">M. Rentabilidad</th>
                  <th class="text-center th-min">M. Rentabilidad Esp.</th>
                  <th class="text-center th-min">Cambio</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val,idx) in currencies" :key="val.id">
                  <td class="text-center">{{index}}</td>
                  <td class="text-center">{{idx}}</td>
                  <td class="text-right">{{val.amount | numFormat('0,0.000')}}</td>
                  <td class="text-right">{{val.profitability_amount | numFormat('0,0.000')}}</td>
                  <td class="text-right">{{val.profitability_especial_amount | numFormat('0,0.000')}}</td>
                  <td class="text-right">{{val.amount_to_pay | numFormat('0,0.000')}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr class="bg-success text-white">
                  <td colspan="2" class="text-right">TOTALES</td>
                  <td class="text-right">{{showGranTotal(index).ta | numFormat('0,0.000')}}</td>
                  <td class="text-right">{{showGranTotal(index).tpa | numFormat('0,0.000')}}</td>
                  <td class="text-right">{{showGranTotal(index).tpaea | numFormat('0,0.000')}}</td>
                  <td class="text-right">{{showGranTotal(index).tatp | numFormat('0,0.000')}}</td>
                </tr>
              </tfoot>
            </table>
          </template>
          
        </div>
      </div>
      <div class="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
        <div v-if="display" class="table-responsive">
          <template v-for="(currencies, index) in orders">
            <table class="table" :key="index">
              <thead>
                <tr>
                  <th class="text-center th-min">Fecha</th>
                  <th class="text-center th-min">Moneda</th>
                  <th class="text-center th-min">Tipo</th>
                  <th class="text-center th-min">T. Venta</th>
                  <th class="text-center th-min">T. Compra</th>
                  <th class="text-center th-min">T. Moneda</th>
                  <th class="text-center th-min">T. Mon. Especial</th>
                  <th class="text-center th-min">T. Dolar</th>
                  <th class="text-center th-min">T. Dolar Especial</th>
                  <th class="text-center th-min">T. Especial</th>
                  <th class="text-center th-min">% Rentabilidad</th>
                  <th class="text-center th-min">% Rentabilidad Esp.</th>
                  <th class="text-center th-min">M. Rentabilidad</th>
                  <th class="text-center th-min">M. Rentabilidad Esp.</th>
                  <th class="text-center th-min">Monto</th>
                  <th class="text-center th-min">Cambio</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="val in currencies" :key="val.id">
                  <td class="text-center">{{val.date}}</td>
                  <td class="text-center">{{index}}</td>
                  <td class="text-center">{{val.order_type}}</td>
                  <td class="text-center">{{val.sale_rate | numFormat('0,0.000')}}</td>
                  <td class="text-center">{{val.purchase_rate | numFormat('0,0.000')}}</td>
                  <td class="text-center">{{val.currency_rate | numFormat('0,0.000')}}</td>
                  <td class="text-center">{{val.currency_rate_especial | numFormat('0,0.000')}}</td>
                  <td class="text-center">{{val.dolar_rate | numFormat('0,0.000')}}</td>
                  <td class="text-center">{{val.dolar_rate_especial | numFormat('0,0.000')}}</td>
                  <td class="text-center">{{(val.especial_rate) ? 'SI' : 'NO'}}</td>
                  <td class="text-center">{{val.profitability | numFormat('0,0.000')}}</td>
                  <td class="text-center">{{val.profitability_especial | numFormat('0,0.000')}}</td>
                  <td class="text-right">{{val.profitability_amount | numFormat('0,0.000')}}</td>
                  <td class="text-right">{{val.profitability_especial_amount | numFormat('0,0.000')}}</td>
                  <td class="text-right">{{val.amount | numFormat('0,0.000')}}</td>
                  <td class="text-right">{{val.amount_to_pay | numFormat('0,0.000')}}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr class="bg-success text-white">
                  <td colspan="12" class="text-right">TOTALES</td>
                  <td class="text-right">{{showTotal(index).tpa | numFormat('0,0.000')}}</td>
                  <td class="text-right">{{showTotal(index).tpaea | numFormat('0,0.000')}}</td>
                  <td class="text-right">{{showTotal(index).ta | numFormat('0,0.000')}}</td>
                  <td class="text-right">{{showTotal(index).tatp | numFormat('0,0.000')}}</td>
                </tr>
              </tfoot>
            </table>
          </template>
        </div>
      </div>

    </div>

    <Search v-if="!display" />
  </div>
</template>
<script>
import EventBus from "@/event-bus.js";
import Search from "@/components/admin/reports/SearchView";
import axios from "axios";

export default {
  components: {
    Search
  },

  data() {
    return {
      display: true,
      orders: [],
      totales:[],
    };
  },

  created() {
    EventBus.$on("r_searchRange", this.searchRange);
    EventBus.$on("r_hideSearch", this.hideSearch);
  },

  computed: {},
  methods: {
    hideSearch() {
      this.display = true;
    },

    showSearch() {
      this.display = false;
    },

    searchRange(data) {
      let query =
        "?opt=pa&fd=" +
        data.from_date +
        "&td=" +
        data.to_date +
        "&cid=" +
        data.currency_id;
      axios
        .get("admin/reports" + query)
        .then(resp => {
          this.orders = resp.data.detail
          this.totales = resp.data.totales
          
          });
      this.display = true;
    },

    showTotal(c) {
      let tpa = 0;
      let tpaea = 0;
      let ta = 0;
      let tatp = 0;

      this.orders[c].forEach(function(currency) {
        tpa += parseFloat(currency.profitability_amount);
        tpaea += parseFloat(currency.profitability_especial_amount);
        ta += parseFloat(currency.amount);
        tatp += parseFloat(currency.amount_to_pay);
      });

      return {
        tpa,
        tpaea,
        ta,
        tatp
      };
    },

    showGranTotal(c) {
      let tpa = 0;
      let tpaea = 0;
      let ta = 0;
      let tatp = 0;

     if(this.totales[c]){
       let obj = this.totales[c]
      for (const prop in obj) {
        tpa += parseFloat(obj[prop].profitability_amount);
        tpaea += parseFloat(obj[prop].profitability_especial_amount);
        ta += parseFloat(obj[prop].amount);
        tatp += parseFloat(obj[prop].amount_to_pay);
      }
       /*  this.totales[c].forEach(function(val){
            tpa += parseFloat(val.profitability_amount);
            tpaea += parseFloat(val.profitability_especial_amount);
            ta += parseFloat(val.amount);
            tatp += parseFloat(val.amount_to_pay);
        }) */
      }

      return {
        tpa,
        tpaea,
        ta,
        tatp
      };
    }
  }
};
</script>
<style scoped>
.container-report {
  height: 100vh;
}
</style>