<template>
    <div class="row p-2">
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-5">{{account.name}}</div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-3">
            <div class="input-group">
                <input type="text" class="form-control text-right" placeholder="Ingrese el Saldo" v-model="amount">
                <div class="input-group-append">
                    <span v-if="status == 0" class="input-group-text bg-white"><i class="material-icons text-warning">report_problem</i></span>
                    <span v-if="status == 1" class="input-group-text bg-white"><i class="material-icons text-success">check</i></span>
                    <span v-if="status == 2" class="input-group-text bg-white"><i class="material-icons text-success">done_all</i></span>
                    <button v-if="status == 0" class="btn btn-primary" type="button" @click="registerInitialBalance">Registrar</button>
                    <button v-if="status == 1" class="btn btn-success" type="button" @click="updateInitialBalance">Actualizar</button>
                    <button v-if="status == 1 && $auth.check(['admin'])" class="btn btn-info" type="button" @click="confirmInitialBalance">Confirmar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import AccountModule from '@/modules/Account'
export default {
    props:["account"],
    data(){
        return {
            amount:'',
            status:0,
        }
    },

    mounted(){
        this.setIcon()
    },

    methods:{
        setIcon(){
            if(null !== this.account.initial_balance){
                this.amount = this.account.initial_balance.amount
                if(this.account.initial_balance.confirmed){
                    this.status = 2
                    
                    return
                }

                this.status = 1
                return
            }

            this.status = 0
        },

        registerInitialBalance(){
            let data = {
                account_id: this.account.id,
                amount:this.amount,
            }
            AccountModule.storageInitialBalance(data).then(resp => this.status = resp.data.status)
            .catch(error => {
                let obj = error.response.data.error.message;
                let message = "";

                for (const prop in obj) {
                    message += obj[prop]+"\n";
                }

                swal("Información", message, "error")
            })
        },

        confirmInitialBalance(){
            AccountModule.confirmInitialBalance(this.account.id).then(resp => this.status = resp.data.status)
            .catch(error => {
                let obj = error.response.data.error.message;
                let message = "";

                for (const prop in obj) {
                    message += obj[prop]+"\n";
                }

                swal("Información", message, "error")
            })
        },
        
        updateInitialBalance(){
            let data = {
                account_id: this.account.id,
                amount:this.amount,
            }
            AccountModule.updateInitialBalance(data).then(resp => {

                swal("Información", resp.data.message, "success")
            })
            .catch(error => {
                let obj = error.response.data.error.message;
                let message = "";

                for (const prop in obj) {
                    message += obj[prop]+"\n";
                }

                swal("Información", message, "error")
            })
        }

    }
}
</script>