var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',[(_vm.data.on_change == 1)?_c('i',{staticClass:"material-icons"},[_vm._v("lock")]):_c('span',[_vm._v(_vm._s(_vm.i))])]),_c('td',{staticClass:"td-fecha text-center"},[_vm._v(_vm._s(_vm.data.created_at))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.data.order_code))]),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge",class:{
            'badge-warning'   : (_vm.data.status.name == 'PENDIENTE'),
            'badge-success'   : (_vm.data.status.name == 'PAGADA'),
            'badge-danger'    : (_vm.data.status.name == 'REPORTADA'),
            'badge-info'      : (_vm.data.status.name == 'RECIBIDA'),
            'badge-dark'      : (_vm.data.status.name == 'ANULADA'),
            'badge-primary'   : (_vm.data.status.name == 'PROCESO DE PAGO'),
            'badge-secondary' : (_vm.data.status.name == 'DEVUELTA'),
        }},[_vm._v(" "+_vm._s(_vm.data.status.name)+" ")])]),_c('td',{staticClass:"td-cliente pl-3"},[_vm._v(_vm._s(_vm.data.user_send['name']))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm._f("numFormat")(_vm.mostrarTasa,'0,0.00')))]),_c('td',{staticClass:"td-envia text-right"},[_vm._v(_vm._s(_vm.data.currency['symbol'])+" "+_vm._s(_vm._f("numFormat")(_vm.data.amount,'0,0.00')))]),_c('td',{staticClass:"td-recibe text-right"},[_vm._v("Bs. "+_vm._s(_vm._f("numFormat")(_vm.data.amount_to_pay,'0,0.00')))])])}
var staticRenderFns = []

export { render, staticRenderFns }