const notificationModule = {

    state: {
        isConnected: false,
        socketMessage: ''
      },

    mutations:{
        SOCKET_NOTIFICACION(state, message) {
            state.socketMessage = message
          }
    },

    actions:{
        socket_system_message: ({rootState}, message) => {
            rootState.io.emit('systemMessage', message);
        },
    },
}

export default notificationModule;