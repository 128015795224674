<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Reporte Balance General</li>
      </ol>
    </nav>
    <button class="btn btn-small btn-primary" @click="showSearch">
        <i class="material-icons">filter_list</i>
      </button>
    <button class="btn btn-small btn-primary ml-2" @click="searchRange">Enviar</button><br><br>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Resumen</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Cuentas</a>
      </li>
    </ul>
    <div class="tab-content">
        <div class="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <div v-if="display" class="row mt-2">
                <div class="col-12">
                    <div v-if="display" class="table-responsive">
                        <table class="table table-bordered">
                            <caption>Remesas</caption>
                            <thead>
                                <tr>
                                    <th class="text-center th-min" rowspan="2">Moneda</th>
                                    <th class="text-center th-min bg-success text-white" >Ingresos</th>
                                    <th class="text-center th-min bg-primary text-white" colspan="2">Rentabilidad</th>
                                    <th class="text-center th-min bg-info text-white" rowspan="2">Total</th>
                                </tr>
                                <tr>
                                    <th class="text-center th-min">Efectivo / Cuentas</th>
                                    <th class="text-center th-min">Tasa Estandar</th>
                                    <th class="text-center th-min">Tasa Especial</th>
                                    
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(val,index) in summary" :key="val.id">
                                    <td>{{index}}</td>
                                    <td class="text-right">{{val.amount | numFormat('0,0.00')}}</td>
                                    <td class="text-right">{{val.profitability_amount | numFormat('0,0.00')}}</td>
                                    <td class="text-right">{{val.profitability_especial_amount | numFormat('0,0.00')}}</td>
                                    <td class="text-right">{{val.profitability_amount + val.profitability_especial_amount | numFormat('0,0.00')}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th class="text-center th-min" rowspan="2">Moneda</th>
                                    <th class="text-center th-min bg-success text-white" colspan="3">Ingresos</th>
                                    <th class="text-center th-min bg-secondary text-white" colspan="2">Egresos</th>
                                    <th class="text-center th-min bg-primary text-white" colspan="2">Totales</th>
                                </tr>
                                <tr>
                                    <th class="text-center th-min">PDV</th>
                                    <th class="text-center th-min">Efectivo</th>
                                    <th class="text-center th-min">Cuentas Bancarias</th>
                                    <th class="text-center th-min">Efectivo</th>
                                    <th class="text-center th-min">Cuentas Bancarias</th>
                                    <th class="text-center th-min">Efectivo</th>
                                    <th class="text-center th-min">Cuentas Bancarias</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(val,index) in currency_balance" :key="val.id">
                                    <td>{{index}}</td>
                                    <td class="text-right">{{val.pdv | numFormat('0,0.00')}}</td>
                                    <td class="text-right">{{val.cash | numFormat('0,0.00')}}</td>
                                    <td class="text-right">{{val.account | numFormat('0,0.00')}}</td>
                                    <td class="text-right">{{val.charges_cash | numFormat('0,0.00')}}</td>
                                    <td class="text-right">{{val.charges_account | numFormat('0,0.00')}}</td>
                                    <td class="text-right">{{val.cash - val.charges_cash | numFormat('0,0.00')}}</td>
                                    <td class="text-right">{{calcResta((val.pdv + val.account), val.charges_account) | numFormat('0,0.00')}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
            <div v-if="display" class="row mt-2">
                <div class="col-12">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-center th-min">Banco</th>
                                    <th class="text-center th-min">Nombre</th>
                                    <th class="text-center th-min">Saldo Inicial</th>
                                    <th class="text-center th-min">Abonos</th>
                                    <th class="text-center th-min">Cargos</th>
                                    <th class="text-center th-min">Saldo Final</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="val in accounts_balance" :key="val.id">
                                    <td>{{val.bank_name}}</td>
                                    <td>{{val.name}}</td>
                                    <td class="text-right">{{val.initial_balance | numFormat('0,0.00')}}</td>
                                    <td class="text-right">{{val.income_amount | numFormat('0,0.00')}}</td>
                                    <td class="text-right">{{val.charges_amount | numFormat('0,0.00')}}</td>
                                    <td class="text-right">{{val.current_balance | numFormat('0,0.00')}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Search v-if="!display" />
    
  </div>
</template>
<script>
import EventBus from "@/event-bus.js";
import Search from "@/components/admin/reports/SearchView";
import axios from 'axios'
export default {
    components:{
        Search
    },
    data(){
        return {
            display:true,
            summary:[],
            accounts_balance:[],
            currency_balance:[],
        }
    },
    created() {
        EventBus.$on("r_searchRange", this.searchRange);
        EventBus.$on("r_hideSearch", this.hideSearch);
    },

    mounted(){
        this.searchRange()
    },
    methods:{

        calcResta(a,b){

            if(b < 0){
                b = b * -1;
            }

            return a - b;

        },
        hideSearch() {
            this.display = true;
        },

        showSearch() {
            this.display = false;
        },

        searchRange(data) {

            if(!data)
                return;


            let query ="?opt=gb&fd="+data.from_date +"&td="+data.to_date +"&cid=" +data.currency_id;
            // let query ="?opt=gb"
        
            axios.get("admin/reports" + query).then(resp => {
            this.summary = resp.data.summary
            this.accounts_balance = resp.data.account_balance
            this.currency_balance = resp.data.currency_balance

            });
        },
    }
}
</script>