<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/confirm/ingresos-cuenta"
            >Ingresos</router-link
          >
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Ingreso a Cuenta
        </li>
      </ol>
    </nav>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label for="owner_account">Cuenta Origen </label
                    ><span class="text-danger"> *</span>
                    <select
                      v-model="cuenta_debito_id"
                      id="owner_account"
                      class="form-control"
                      :disabled="disInput"
                    >
                      <option value="">SELECCIONE</option>
                      <option value="E">EFECTIVO REMESASJJ</option>
                      <option value="T">TERCEROS</option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="currency_id" class="active">Moneda</label>
                    <select
                      v-model="moneda_id"
                      id="c"
                      class="form-control"
                      :disabled="disInput"
                    >
                      <option value="">SELECCIONE</option>
                      <option
                        v-for="(val, index) in monedas"
                        :key="index"
                        :value="val.id"
                      >
                        {{ val.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="owner_account">Cuenta Acreditar </label
                    ><span class="text-danger"> *</span>
                    <select
                      v-model="cuenta_acreditar_id"
                      id="owner_account"
                      class="form-control"
                      :disabled="disInput"
                    >
                      <option value>SELECCIONE</option>
                      <option v-if="this.cuenta_debito_id !== 'E'" value="0">
                        EFECTIVO REMESASJJ
                      </option>
                      <option
                        v-for="(val, index) in cuentasDestino"
                        :key="index"
                        :value="val.id"
                      >
                        {{ val.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="datepicker">Fecha </label>
                    <span class="text-danger"> *</span>
                    <input
                      type="date"
                      class="form-control"
                      id="datepicker"
                      v-model="fecha"
                      :disabled="disInput"
                    />
                  </div>

                  <div class="form-group">
                    <label for="description">Descripción</label
                    ><span class="text-danger"> *</span>
                    <input
                      class="form-control"
                      v-model="descripcion"
                      id="description"
                      :disabled="disInput"
                    />
                  </div>
                  <div class="form-group">
                    <label for="owner_account">Monto</label>
                    <span class="text-danger"> *</span>
                    <input
                      class="form-control text-right"
                      v-model="monto"
                      :disabled="disInput"
                    />
                  </div>
                  <div class="form-group text-right">
                    <div class="btn-group">
                      <button
                        v-if="!id"
                        class="btn btn-small btn-primary"
                        @click="registrar()"
                      >
                        Registrar
                      </button>
                      <button
                        v-if="id"
                        class="btn btn-small btn-danger"
                        @click.prevent="eliminarIngreso()"
                      >
                        Eliminar Ingreso
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import AccountService from "@/service/AccountService";
import SelectList from "@/service/SelectListService";
import TransactionService from "@/service/TransactionService";
/* const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/ui/widgets/datepicker.js'; */

export default {
  props: ["id"],

  data() {
    return {
      moneda_id: "",
      cuenta_debito_id: "",
      cuenta_acreditar_id: "",
      fecha: "",
      descripcion: "",
      monto: "",
      cuentas: [],
      monedas: [],
    };
  },

  async created() {
    await SelectList.Currency.get().then((resp) => (this.monedas = resp.data));
    await AccountService.get().then((resp) => (this.cuentas = resp.data.data));

    if (this.id) {
      // console.info("cargando detalle ingreso");
      this.getIngreso();
    }
  },

  computed: {
    disInput() {
      if (this.id) return true;

      return false;
    },

    cuentasDestino() {
      if (
        (this.cuenta_debito_id === "E" || this.cuenta_debito_id === "T") &&
        this.moneda_id !== ""
      ) {
        return this.cuentas.filter((c) => c.currency_id == this.moneda_id);
      }

      return [];
    },

    totalMonto() {
      return parseFloat(this.monto) + parseFloat(this.monto_comision);
    },
  },

  methods: {
    handlePictureUpload() {
      this.voucher = this.$refs.file.files[0];
    },

    registrar() {
      let data = new FormData();
      data.append("cuenta_debito_id", this.cuenta_debito_id);
      data.append("cuenta_acreditar_id", this.cuenta_acreditar_id);
      data.append("fecha", this.fecha);
      data.append("moneda_id", this.moneda_id);
      data.append("descripcion", this.descripcion);
      data.append("monto", this.monto);
      data.append("comision", this.comision);
      data.append("monto_comision", this.monto_comision);
      data.append("voucher", this.voucher);

      axios
        .post("ingreso-cuenta", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$router.push({
            path: "/confirm/ingresos-cuenta",
          });
          swal("", resp.data.message, "success");
        })
        .catch((err) => {
          let mensaje = err.response.data.error.message;

          swal("Error", mensaje.toString(), "error");
        });
    },

    eliminarIngreso() {
      axios.delete("ingreso-cuenta/" + this.id).then((resp) => {
        this.$router.push({
            path: "/confirm/ingresos-cuenta",
          });
          swal("", resp.data.message, "success");
        })
        .catch((err) => {
          let mensaje = err.response.data.error.message;

          swal("Error", mensaje.toString(), "error");
        });
    },

    getIngreso() {
      axios.get("ingreso-cuenta/" + this.id).then((resp) => {
        this.setData(resp.data);
      });
    },

    setData(data) {
      this.moneda_id = data.moneda_id;
      this.cuenta_debito_id = data.origen == "EFECTIVO" ? "E" : "T";
      this.cuenta_acreditar_id = data.cuenta_acreditar_id;
      let fecha_esp = data.created_at.split(" ");
      let fecha_g = fecha_esp[0].split("-");
      this.fecha = fecha_g[0] + "-" + fecha_g[1] + "-" + fecha_g[2];
      this.descripcion = data.descripcion;
      this.monto = data.monto;
    },
  },
};
</script>