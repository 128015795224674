import Service from '@/service/Service';

const resource = 'admin/agents';

export default {
    
    get(type){
        return Service.get(resource+"?o="+type);
    },

}