<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Ingreso a Cuenta</li>
      </ol>
    </nav>
    <div class>
      <div class="btn-group">
        <button v-if="!id" class="btn btn-small btn-primary" @click="registerTransaction">Registrar</button>
        <button
          v-if="id && !annlt && !fk_id"
          class="btn btn-small btn-danger"
          @click.prevent="deleteTransaction"
        >Anular</button>
      </div>

      <div class="row mt-2">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label for>Pais</label>
                    <select class="form-control" v-model="country">
                      <option value>Seleccione</option>
                      <option v-for="val in countries" :key="val.id" :value="val.id">{{val.name}}</option>
                    </select>
                  </div>
                   <div class="form-group">
                        <label for="owner_account">Cuenta a Acreditar</label>
                        <select v-model="owner_account_id" id="owner_account" class="form-control">
                          <option value>SELECCIONE</option>
                          <option
                            v-for="val in accountsFilter"
                            :key="val.id"
                            :value="val.id"
                          >{{val.name}}</option>
                        </select>
                      </div>
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                       <div class="form-group">
                    <label for="concepts_operation">Concepto</label>
                    <select
                      v-model="concepts_operation_id"
                      id="concepts_operation"
                      class="form-control"
                    >
                      <option value>SELECCIONE</option>
                      <option
                        v-for="(val, index) in concepts_operation"
                        :key="index"
                        :value="val.id"
                      >{{val.name}}</option>
                    </select>
                  </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                        <div class="form-group">
                          <label for="purchase_rate">Tasa de Compra</label>
                          <input class="form-control" v-model="purchase_rate" id="purchase_rate" />
                        </div>
                    </div>
                  </div>

                 
                  <div class="form-group">
                    <label for="description">Descripción</label>
                    <input class="form-control" v-model="description" id="description" />
                  </div>
                  <div class="form-group">
                    <label for="owner_account">Nro. Operación</label>
                    <input class="form-control" v-model="operation_number" />
                  </div>
                  <div v-if="id" class="form-group">
                    <label for="register_by">Registrado por</label>
                    <input class="form-control" v-model="register_by" id="register_by" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label for="datepicker">Fecha</label>
                    <input type="date" class="form-control" id="datepicker" v-model="date" />
                  </div>
                  <div class="form-group">
                    <label for="time">Hora</label>
                    <input type="time" id="time" class="form-control" v-model="time" />
                  </div>
                  <div class="form-group">
                    <label for="owner_account">Monto a Transferir</label>
                    <input class="form-control" v-model="amount" />
                  </div>
                  <div class="form-group">
                    <label for="owner_account">Comisión</label>
                    <input class="form-control" v-model="comission" />
                  </div>
                  <div v-if="!id" class="form-group">
                    <label for="file">Voucher de la Operación</label>
                    <input
                      type="file"
                      class="form-control-file"
                      id="file"
                      ref="file"
                      @change="handlePictureUpload"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AccountService from "@/service/AccountService";
import SelectListService from "@/service/SelectListService";
import TransactionService from "@/service/TransactionService";
import Transaction from "@/modules/Transaction";
/* const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/ui/widgets/datepicker.js'; */

export default {
  props: ["id", "detail"],
  data() {
    return {
      fk_id: true,
      annlt: true,
      owner_account_id: "",
      country: "",
      amount: "",
      comission: "",
      operation_number: "",
      date: "",
      time: "",
      voucher: "",
      thirdparty_account_id: "",
      register_by: "",
      purchase_rate:'',
      owner_accounts: [],
      concepts_operation_id: "",
      description: "",
      countries: [],
      thirdparty_accounts: [],
      concepts_operation: []
    };
  },

  async created() {
    await SelectListService.country
      .get()
      .then(resp => (this.countries = resp.data));
    await AccountService.get().then(
      resp => (this.owner_accounts = resp.data.data)
    );
    await AccountService.get("?tp=1").then(
      resp => (this.thirdparty_accounts = resp.data.data)
    );
    await SelectListService.conceptOperation
      .get()
      .then(resp => (this.concepts_operation = resp.data));

    if (this.detail) {
      this.setData(this.detail);
    }
  },

  computed: {
    accountsFilter() {
      return this.owner_accounts.filter(a => a.bank.country_id == this.country);
    }
  },

  methods: {
    handlePictureUpload() {
      this.voucher = this.$refs.file.files[0];
    },

    registerTransaction() {
      Transaction.storageTransactionIncome(this.$data)
        .then(resp => {
          window.swal("Información del Registro", resp.data.message, "success");
        })
        .catch(error => {
          let obj = error.response.data.error.message;
          let message = "";
          for (const prop in obj) {
            message += obj[prop] + "\n";
            //console.log(`obj.${prop} = ${obj[prop]}`);
          }
          window.swal("Información", message, "error");
        });
    },

    deleteTransaction() {
      window.swal({
        title: "¿Segur@ de anular la transacción?",
        //text: "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then(willDelete => {
        if (willDelete) {
          TransactionService.delete(this.id)
            .then(resp => {
              window.swal("Transacción anulada con exito!", {
                icon: "success"
              });
            })
            .catch(error => {
              let obj = error.response.data.error.message;
              let message = "";
              for (const prop in obj) {
                message += obj[prop] + "\n";
                //console.log(`obj.${prop} = ${obj[prop]}`);
              }

              window.swal("Información", message, "error");
            });
        }
      });
    }
  }
};
</script>