<template>
  <div><br>
      <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary"><router-link tag="a" to="/">Tablero</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">Listado de Abonos</li>
      </ol>
    </nav>
      <OperationNumbersForm class="display-hide"/>
      <div class="table-responsive">
        <table v-if="display_table" class="table table-hover">
        <thead>
            <tr>
            <th class="center-align">
                <button class="btn btn-small btn-primary" @click="showSearchOption">
                    <i class="material-icons">search</i>
                </button>
            </th>
            <th class="center-align th-min">Registrada el</th>
            <th class="center-align th-min">Registrada por</th>
            <th class="center-align th-min">Cuenta</th>
            <th class="center-align th-min">Nro. de Operación</th>
            <th class="center-align th-min">F. Operacion</th>
            <th class="center-align">Monto</th>
            <th class="center-align"></th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(val, index) in payments" :key="val.id">
                <td class="jj-center-align">{{index + 1}}</td>
                <td class="jj-center-align">{{val.created_at}}</td>
                <td>{{val.user.name}}</td>
                <td class="text-left">
                    <span class="d-inline-block text-truncate" style="max-width: 200px;">
                        {{JSON.parse(val.account_receive).name}}
                    </span>
                </td>
                <td class="text-center">
                    <router-link class="text-primary" tag="a" :to="{path:'/confirm/payment-agents/detail/'+val.id}">
                        {{val.operation_number}}
                    </router-link>
                </td>
                <td class="text-center">
                    <span v-if="val.confirmed">{{val.date}}</span>
                    <span v-else>**********</span>
                    </td>
                <td class="text-right">
                    <span v-if="val.confirmed">{{val.amount}}</span>
                    <span v-else>**********</span>
                    </td>
                <td class="jj-right-align">
                    <i v-if="val.confirmed" class="material-icons text-success" data-toggle="tooltip" data-placement="left" title="Confirmado">done_all</i>
                    <i v-else class="material-icons text-warning" data-toggle="tooltip" data-placement="left" title="No Confirmado">report_problem</i>
                </td>
            </tr>
        </tbody>
        </table>
      </div>
    <SearchOption v-if="display_search"/>
  </div>
</template>
<script>
import EventBus from "@/event-bus.js";
import ConfirmationService from '@/service/ConfirmationService';
import SearchOption from '@/components/confirm/SearchOptionsView'
import OperationNumbersForm from '@/components/confirm/OperationNumbersFormView'

export default {
    name:'PaymentAgentList',
    components:{
        SearchOption,
        OperationNumbersForm,
    },

  data() {
    return {
        display_table:true,
        display_search:false,
        payments: [],
    };
  },

  async created(){
      EventBus.$on("searchParam", this.searchParam);
      EventBus.$on("searchRange", this.searchRange);
      EventBus.$on("refreshOperationNumbersList", this.refreshList);
      EventBus.$on("hideSearch", this.hideSearch);
      await ConfirmationService.PaymentAgent.get().then(resp => this.payments = resp.data);
  },

  methods:{
      showSearchOption(){
         this.display_search = true;
        this.display_table = false;
         /*  this.$modal.show('search') */
      },

      async refreshList(){
          this.payments = [];
          await ConfirmationService.PaymentAgent.get().then(resp => this.payments = resp.data);
      },

      searchParam(data){
        this.display_search = false;
        this.display_table = true;
        let query = '?opt='+data.search_option+'&val='+data.param;

          ConfirmationService.PaymentAgent.get(query)
          .then(resp => {
              this.payments= []; 
              if(resp.data.length <= 0){
                  swal("Información", "No se encontraron resultados.", "warning")
              }
              this.payments = resp.data
              
              })
          .catch(error => {
                let obj = error.response.data.error.message;
                let message = "";

                for (const prop in obj) {

                    if(prop == 'no-confirmed'){
                        return swal("Información", obj[prop], "error")
                    }
                    message += obj[prop]+"\n";
                    //console.log(`obj.${prop} = ${obj[prop]}`);
                }
                swal("Información", message, "error")
              
          })
      },
      hideSearch(){
      this.display_search = false;
      this.display_table = true;
    },

      searchRange(data){
          this.display_search = false;
          this.display_table = true;
          this.payments = '';
          let query = '?opt=range'+'&fd='+data.from_date+'&td='+data.to_date+'&s='+data.status_id+'&c='+data.currency_id;

          ConfirmationService.PaymentAgent.get(query)
          .then(resp => this.payments = resp.data)
          .catch(error => {
                let obj = error.response.data.error.message;
                let message = "";

                for (const prop in obj) {
                    message += obj[prop]+"\n";
                    //console.log(`obj.${prop} = ${obj[prop]}`);
                }
                swal("Información", message, "error")
              
          })
      }
  }
};
</script>