import Vue from 'vue'
import Vuex from 'vuex'
import notificationModule from '@/modules/Notification';


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    io :{}
  },
  mutations:{
    setSocket : (state, socket) => {
      state.io = socket;
      console.log("socket conectado")
    }
  },
  actions: {
  
  },

  modules:{
    notificationModule
  }
})
