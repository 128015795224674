<template>
    <div>
        <div class="form-group">
            <label for="">Moneda Base</label>
            <select v-model="currency_base" class="form-control">
                <option v-for="val in currencies" :key="val.id" :value="val.id">{{val.name}}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="">Tasa Cambista</label>
            <input v-model="moneychanger_rate" type="text" class="form-control">
        </div>
        <div class="row">
            <div class="form-group col-6">
            <label for="">Tasa Compra</label>
            <input v-model="purchase_rate" type="text" class="form-control">
        </div>
        <div class="form-group col-6">
            <label for="">Tasa Venta</label>
            <input v-model="sale_rate" type="text" class="form-control">
        </div>
        </div>
        
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="activated_by" value="local" id="exampleRadios1">
            <label class="form-check-label" for="exampleRadios1">
                Activado Localmente
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" v-model="activated_by" value="api" id="exampleRadios2">
            <label class="form-check-label" for="exampleRadios2">
                Activado por API
            </label>
        </div>
        <div class="form-group text-right">
            <button v-if="!edit" class="btn btn-small btn-success" @click="registerExchange()">Registrar</button>
            <button v-if="edit" class="btn btn-small btn-success" @click="updateExchange()">Actualizar</button>
            <button v-if="edit" class="btn btn-small btn-secondary" @click="$emit('cancel')">Cerrar</button>
            <button v-if="edit" class="btn btn-small btn-danger" @click="deleteExchange()">Eliminar</button>
        </div>
    </div>
</template>
<script>

import ExchangeRateService from "@/service/ExchangeRatesService";
import EventBus from '@/event-bus.js';
import Helpers from '@/Helpers';

export default {
    props:["currency", "currencies", "edit", "exchange"],
    data(){
        return {
            currency_base:'4',
            moneychanger_rate:'',
            purchase_rate:'',
            sale_rate:'',
            activated_by:'local',
        }
    },

    watch:{
        edit(new_val){
            if(new_val){
                this.currency_base = this.exchange.currency_base_id;
                this.moneychanger_rate = this.exchange.moneychanger_rate;
                this.purchase_rate = this.exchange.purchase_rate;
                this.sale_rate = this.exchange.sale_rate;
                this.activated_by = (this.exchange.activated_local) ? 'local' : 'api';
            }
        }
    },
    methods:{
        registerExchange(){
            let data = {
                currency_id:this.currency.id,
                currency_base:this.currency_base,
                moneychanger_rate:this.moneychanger_rate,
                purchase_rate:this.purchase_rate,
                sale_rate:this.sale_rate,
                activated_by:this.activated_by,
            }

            ExchangeRateService.post('?opt=register', data).then(resp => {
                swal("Información", resp.data.message, "success")
                EventBus.$emit("refreshExchangeRateList");
            })
            .catch(error => {
                Helpers.showError(error)
            });
        },

        updateExchange(){
            let data = {
                currency_id:this.currency.id,
                currency_base:this.currency_base,
                moneychanger_rate:this.moneychanger_rate,
                purchase_rate:this.purchase_rate,
                sale_rate:this.sale_rate,
                activated_by:this.activated_by,
            }

            ExchangeRateService.put('/'+this.exchange.id, data).then(resp => {
                swal("Información", resp.data.message, "success")
                EventBus.$emit("refreshExchangeRateList");
            })
            .catch(error => {
                Helpers.showError(error)
            });
        },

        deleteExchange(){
            swal({
                title: "¿Segur@ de Eliminar?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                })
                .then((resp) => {

                    if (resp) {
                        ExchangeRateService.delete('/'+this.exchange.id).then(resp => {
                            swal("Información", resp.data.message, "success")
                            EventBus.$emit("refreshExchangeRateList");
                            this.$emit('cancel')
                        })
                        .catch(error => {
                            Helpers.showError(error)
                        });
                    }
                });
            }
        }
}
</script>