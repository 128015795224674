<template>
  <div>
    <div class="container-fluid">
      <div class="row mt-2">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label for="owner_account">Cuenta Debito</label>
                    <select
                      v-model="owner_account_id"
                      id="owner_account"
                      class="form-control"
                      @change="setBalance"
                      disabled
                    >
                      <option value>SELECCIONE</option>
                      <option
                        v-for="(val, index) in owner_accounts"
                        :key="index"
                        :value="val.id"
                      >{{val.name}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="concepts_operation">Concepto</label>
                    <select
                      v-model="concepts_operation_id"
                      id="concepts_operation"
                      class="form-control"
                      disabled
                    >
                      <option value>SELECCIONE</option>
                      <option
                        v-for="(val, index) in concepts_operation"
                        :key="index"
                        :value="val.id"
                      >{{val.name}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="description">Descripción</label>
                    <input class="form-control" v-model="description" id="description" disabled/>
                  </div>
                  <div class="form-group">
                    <label for="owner_account">Nro. Operación</label>
                    <input class="form-control" v-model="operation_number" disabled/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label for="datepicker">Fecha</label>
                    <input type="date" class="form-control" id="datepicker" v-model="date" disabled/>
                  </div>
                  <div class="form-group">
                    <label for="time">Hora</label>
                    <input type="time" id="time" class="form-control" v-model="time" disabled/>
                  </div>
                  <div class="form-group">
                    <label for="owner_account">Monto a Transferir</label>
                    <input class="form-control text-right" v-model="amount" disabled/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AccountService from "@/service/AccountService";
import SelectListService from "@/service/SelectListService";
import Transaction from "@/modules/Transaction";
/* const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/ui/widgets/datepicker.js'; */

export default {
  props: ["data",],
  data() {
    return {
      id: false,
      owner_account_id: "",
      balance: "",
      amount: "",
      comission: "",
      operation_number: "",
      date: "",
      time: "",
      voucher: "",
      thirdparty_account_id: "",
      owner_accounts: [],
      concepts_operation_id: "",
      description: "",
      thirdparty_accounts: [],
      concepts_operation: []
    };
  },

  async created() {
    await AccountService.get().then(
      resp => (this.owner_accounts = resp.data.data)
    );
    await AccountService.get("?tp=1").then(
      resp => (this.thirdparty_accounts = resp.data.data)
    );
    await SelectListService.conceptOperation
      .get()
      .then(resp => (this.concepts_operation = resp.data));

      this.amount = this.amount_to_pay
      if(this.data){

        this.owner_account_id = this.data.account_id
        this.amount = this.data.amount
        this.comission = this.data.comission
        this.operation_number = this.data.operation_number
        this.date = this.data.date
        this.time = this.data.time
        this.voucher = this.data.voucher
        this.concepts_operation_id = this.data.concept_operation_id
        this.description = this.data.description
      }
  },

  methods: {
    handlePictureUpload() {
      this.voucher = this.$refs.file.files[0];
    },

    setBalance() {
      this.balance = "";
      let account = this.owner_accounts.filter(
        a => a.id == this.owner_account_id
      )[0];

      if (account) {
        this.balance = account.balance;
      }
    },
    registerTransaction() {
      Transaction.registerPayTransaction(
        "?tt=payment&id=" + this.order_id,
        this.$data
      )
        .then(resp => {
          this.id = resp.data.id
          swal("Información del Registro", resp.data.message, "success");
        })
        .catch(error => {
          let obj = error.response.data.error.message;
          let message = "";
          for (const prop in obj) {
            message += obj[prop] + "\n";
            //console.log(`obj.${prop} = ${obj[prop]}`);
          }
          swal("Información", message, "error");
        });
    }
  }
};
</script>