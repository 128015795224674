<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/confirm/transferencias"
            >Transferencias Entre Cuentas</router-link
          >
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Registro de Transferencia
        </li>
      </ol>
    </nav>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label for="owner_account">Cuenta Debito </label
                    ><span class="text-danger"> *</span>
                    <select
                      v-model="cuenta_debito_id"
                      id="owner_account"
                      class="form-control"
                    >
                      <option value>SELECCIONE</option>
                      <option
                        v-for="(val, index) in cuentas"
                        :key="index"
                        :value="val.id"
                      >
                        {{ val.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="owner_account">Cuenta Acreditar </label
                    ><span class="text-danger"> *</span>
                    <select
                      v-model="cuenta_acreditar_id"
                      id="owner_account"
                      class="form-control"
                    >
                      <option value>SELECCIONE</option>
                      <option
                        v-for="(val, index) in cuentasDestino"
                        :key="index"
                        :value="val.id"
                      >
                        {{ val.name }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label for="description">Descripción</label
                    ><span class="text-danger"> *</span>
                    <input
                      class="form-control"
                      v-model="descripcion"
                      id="description"
                    />
                  </div>
                  <div class="form-group">
                    <label for="owner_account">Monto</label>
                    <span class="text-danger"> *</span>
                    <input class="form-control text-right" v-model="monto" />
                  </div>
                  <div class="row">
                    <div class="col col-6">
                      <div class="form-group">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            id="gridCheck"
                            v-model="comision"
                          />
                          <label class="form-check-label" for="gridCheck"
                            >Comisión</label
                          >

                        </div>
                         <input
                          class="form-control text-right"
                          v-model="monto_comision"
                          :disabled="!comision"
                        />
                      </div>
                    </div>
                    <div class="col col-6">
                     <div class="form-group">
                    <label for="">Total</label>
                    <input
                      class="form-control text-right"
                      :value="totalMonto | numFormat('0,0.00')"
                      :disabled="true"
                    />
                  </div>
                    </div>
                  </div>
                  
                  <div class="row">
                    <div class="col-8">
                      <div v-if="!id" class="form-group">
                        <label for="file">Voucher de la Operación</label>
                        <span class="text-danger"> *</span>
                        <input
                          type="file"
                          class="form-control-file"
                          id="file"
                          ref="file"
                          @change="handlePictureUpload"
                        />
                      </div>
                    </div>
                    <div class="col-4 text-right">
                      <div v-if="!id" class="form-group">
                        <div class="btn-group">
                          <button
                            v-if="!id"
                            class="btn btn-small btn-primary"
                            @click="registrar()"
                          >
                            Registrar
                          </button>
                          <button
                            v-if="id"
                            class="btn btn-small btn-danger"
                            @click.prevent="deleteTransaction"
                          >
                            Anular
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import AccountService from "@/service/AccountService";
import SelectListService from "@/service/SelectListService";
import TransactionService from "@/service/TransactionService";
/* const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/ui/widgets/datepicker.js'; */

export default {
  props: ["pagoId", "detalle", "t", "docid"],

  data() {
    return {
      id: null,
      tipo: "pago",
      documento_id: 0,
      montoCompra: "",
      cuenta_debito_id: "",
      cuenta_acreditar_id: "",
      fecha: "",
      descripcion: "",
      monto: "",
      comision: false,
      monto_comision: 0,
      voucher: "",
      cuentas: [],
    };
  },

  async created() {
    await AccountService.get().then((resp) => (this.cuentas = resp.data.data));

    if (this.detalle) {
      console.log(this.detalle);
      let detalle = this.detalle;
      this.id = this.pagoId;
      this.cuenta_debito_id = detalle.cuenta_debito_id;
      this.fecha = detalle.fecha;
      this.descripcion = detalle.descripcion;
      this.monto = detalle.monto;
      this.comision = detalle.comision;
      this.monto_comision = detalle.monto_comision;
      this.voucher = detalle.voucher;
    }
  },

  computed: {

      cuentasDestino(){

          if(this.cuenta_debito_id == "") return [];

          let cs = this.cuentas.filter(c => c.id == this.cuenta_debito_id)[0];

          return this.cuentas.filter(c => c.currency_id == cs.currency_id);
      },

    totalMonto() {
      return parseFloat(this.monto) + parseFloat(this.monto_comision);
    },
  },

  methods: {
    handlePictureUpload() {
      this.voucher = this.$refs.file.files[0];
    },

    registrar() {
      let data = new FormData();
      data.append("cuenta_debito_id", this.cuenta_debito_id);
      data.append("cuenta_acreditar_id", this.cuenta_acreditar_id);
      data.append("fecha", this.fecha);
    //   data.append("tipo", this.tipo);
      data.append("descripcion", this.descripcion);
      data.append("monto", this.monto);
      data.append("comision", this.comision);
      data.append("monto_comision", this.monto_comision);
      data.append("voucher", this.voucher);

      axios
        .post("transferencias", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$router.push({
            path: "/confirm/transferencias",
          });
          swal("", resp.data.message, "success");
        })
        .catch((err) => {
          let mensaje = err.response.data.error.message;

          swal("Error", mensaje.toString(), "error");
        });
    },
  },
};
</script>