<template>
  <div class="row">
    <div class="col col-5">
      <div class="card">
        <div class="card-body">
            <div class="form-group">
            <label for="asd" class="active">Usuario</label>
            <select v-model="usuario" class="form-control">
              <option value="40">Adriana Spin</option>
              <option value="39">Sr. Sonia</option>
            </select>
          </div>
          <div class="form-group">
            <label for="asd" class="active">Cuenta a Debitar</label>
            <select
              v-model="cuenta_debito_id"
              @change="lotesSeleccionados = []"
              class="form-control"
            >
              <option value>SELECCIONE</option>
              <option
                v-for="(val, index) in cuentasLotes"
                :key="index"
                :value="val.id"
              >
                {{ val.name }}
              </option>
            </select>
          </div>
          <table>
            <thead>
              <tr>
                <th></th>
                <th class="text-center">ID</th>
                <th class="text-center">Banco</th>
                <th class="text-center">Disponible</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(lote, index) in lotesFiltrados">
                <tr :key="index">
                  <td class="text-center">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="radio"
                        v-model="lotesSeleccionados"
                        :value="lote.lote_id"
                      />
                      <label class="form-check-label" for="gridCheck"> </label>
                    </div>
                  </td>
                  <td class="text-center">LT-{{ lote.lote_id }}</td>
                  <td>{{ lote.cuenta["bank"]["name"] }}</td>
                  <td class="text-right">
                    {{ lote.balance | numFormat("0,0.00") }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <!-- asd -->
    </div>
    <div class="col col-4">
      <div class="card">
        <div class="card-body">
          <div class="form-group">
            <label for="description">Descripción</label>

            <input
              class="form-control"
              v-model="descripcion"
              id="description"
            />
          </div>
          <div class="form-group">
            <label for="owner_account">Monto</label>

            <input
              class="form-control text-right"
              v-model="monto"
              type="number"
            />
          </div>
          <button
            class="btn btn-success"
            @click="registrarComision()"
          >
            Registrar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AccountService from "@/service/AccountService";

export default {
  data() {
    return {
      usuario:40,
      lotesSeleccionados: [],
      cuenta_debito_id: "",
      cuentas: [],
      lotes: [],
      cuenta_destino: "",
      descripcion: "comision-traslado",
      monto: "",
      comision_activa: true,
      porcentaje_comision: 0,
      monto_comision: "",
      voucher: "",
      metodo: "",
      total: 0,
      recargo: 0,
      tipo: "",
    };
  },

  async created() {
    await AccountService.get("?country=1").then(
      (resp) => (this.cuentas = resp.data.data)
    );
    this.getLotesDisponibles();
  },

  computed:{

      cuentasLotes(){
      const data = this.lotes;
      const result = [];
      const map = new Map();

      for (const val of data) {
        if (!map.has(val.cuenta_id)) {
          map.set(val.cuenta_id, true); // set any value to Map
          result.push({
            id: val.cuenta_id,
            name: val.cuenta.name,
          });
        }
      }

      return result;
    },

    lotesFiltrados(){

      if(this.cuenta_debito_id == ''){
        return [];
      }

      return this.lotes.filter(l => l.cuenta_id == this.cuenta_debito_id);
    },
  },

  methods: {
    getLotesDisponibles() {
      let i = 1;
      axios.get("/lotes-disponibles").then((resp) => {
        this.lotes = resp.data.map(l => {
          l.id = i; i++; 
          return l;
        });
      });
    },

    registrarComision(){

        // let lote = this.lotes[this.lotesSeleccionados];

        let data = new FormData();
        data.append('usuario_id',    this.usuario);
        data.append('lote_id',       this.lotesSeleccionados);
        data.append('cuenta_origen', this.cuenta_debito_id);
        data.append('descripcion',   this.descripcion);
        data.append('monto',         this.monto);
        

        axios.post('/registrar-comision-traslado', data)
        .then(resp => {
         
          swal("", "Traslado de saldo realizado con exito.", "success")
        })
    }
  },
};
</script>

<style scoped>
</style>