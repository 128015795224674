<template>
 <table class="table">
            <thead>
                <tr>
                    <th class="text-center"></th>
                    <th class="text-center">Fecha</th>
                    <th class="text-center">Cuenta Origen</th>
                    <th class="text-center">Cuenta Destino</th>
                    <th class="text-center">Monto</th>
                    <th class="text-center">Comision</th>
                    <th class="text-center">Total</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="val in traslados" :key="val.id">
                    <td class="text-center">
                        <button class="btn btn-small btn-primary">TR-{{val.id}}</button>
                    </td>
                    <td class="text-center">{{val.created_at}}</td>
                    <td>{{val.cuenta_debito.name}}</td>
                    <td>{{val.cuenta_acreditar.name}}</td>
                    <td class="text-right">{{val.monto | numFormat('0,0.00')}}</td>
                    <td class="text-right">{{val.monto_comision | numFormat('0,0.00')}}</td>
                    <td class="text-right">{{val.total | numFormat('0,0.00')}}</td>
                </tr>
                
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="7" class="text-right">{{calculateTotal | numFormat('0,0.00')}}</td>
                </tr>
            </tfoot>
        </table>
</template>

<script>
import axios from 'axios';
export default {

    props:["cuenta_id", "tipo", "inv_act"],

    data(){
        return {
            traslados:[]
        }
    },

    created(){
        this.getTraslados();
    },

    computed:{
        calculateTotal(){

            if(this.traslados.length <= 0) return 0;

            let total = 0;

            this.traslados.forEach(t => {
                total += parseFloat(t.total);
            });

            return total;
        }
    },

    methods:{

        getTraslados(){
            axios.get('/traslados?opt=cuenta&cid='+this.cuenta_id+'&inv_act='+this.inv_act)
            .then(resp => {
                this.traslados = resp.data;
            })
        }
    }
};
</script>

<style scoped>
</style>