<template>
  <div><br>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary"><router-link tag="a" to="/confirm/payment-orders">Ordenes de Pago</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">Vista Detalle</li>
      </ol>
    </nav>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab">Datos de Confirmación</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab">Datos de la Remesa</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        <br />
        <div class="row">
          <div class="col-sm-6">
            <div class="card">
              <div class="card-body">
                <div class="row">
                    <div v-if="confirmed != 1" class="form-group col-sm-12">
                  <label for="account">Nro. Operacion No Confirmados </label>
                  <select id="account" class="form-control" v-model="operation_number_id" @change="setDataForm">
                    <option value>SELECCIONE</option>
                    <option v-for="val in operation_numbers" :key="val.id" :value="val.id">{{JSON.parse(val.account).name +' | Nro. Operación '+val.number}}</option>
                  </select>
                </div>
                <div class="form-group col-sm-12">
                  <label for="account">Cuentas de Recepción</label>
                  <select id="account" class="form-control" v-model="account" :disabled="confirmed">
                    <option value>SELECCIONE</option>
                    <option v-for="val in accounts" :key="val.id" :value="val.id">{{val.name}}</option>
                  </select>
                </div>
                <div class="form-group col-sm-12">
                  <label for="payment_method">Metodo de Pago</label>
                  <select id="payment_method" class="form-control" v-model="payment_method" :disabled="confirmed">
                    <option value>SELECCIONE</option>
                    <option
                      v-for="val in payment_methods"
                      :key="val.id"
                      :value="val.id"
                    >{{val.name}}</option>
                  </select>
                </div>
                <div class="form-group col-sm-12">
                  <label for="noperation">Nro. de Operación</label>
                  <input id="noperation" type="text" class="form-control" v-model="operation_number" :disabled="confirmed"/>
                </div>
                <div class="form-group col-sm-6">
                  <label for="date">Fecha de la Operación</label>
                  <input id="date" type="date" class="form-control" v-model="date" :disabled="confirmed"/>
                </div>
                <div class="form-group col-sm-6">
                  <label for="time">Hora</label>
                  <input id="time" type="time" class="form-control" v-model="time" :disabled="confirmed"/>
                </div>
                <div class="form-group col-sm-12">
                  <label for="comission">Comisión de Provincia</label>
                  <input id="comission" type="text" class="form-control" v-model="comission_amount" :disabled="confirmed"/>
                </div>
                <div class="form-group col-sm-12">
                  <label for="amount">Monto</label>
                  <input id="amount" type="text" class="form-control" v-model="amount" :disabled="confirmed"/>
                </div>
                <div v-if="confirmed == 0" class="form-group col-sm-12 text-right">
                    <div class="btn-group">
                      <button v-if="this.operation_number_id != ''" class="btn btn-primary" @click="updateOperationNumber">Actualizar Número de Operación</button>
                      <button v-if="this.operation_number_id != ''" class="btn btn-success" @click="confirmOperationNumber">Confirmar Orden</button>
                    </div>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="voucher" class="col-sm-6">
              <!-- <img :src="setImage(voucher)" class="img-fluid" alt="Voucher de Pago"> -->
              <div class="img-zoom-container row">
                  <div id="lens" class="img-zoom-lens" style="width: 40px; height: 40px;"></div>
                  <img id="myimage" class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" :src="setImage(voucher)" width="300" height="500">
                  <div id="myresult" class="img-zoom-result col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" ></div>
              </div>
              </div>
          </div>
        </div>
      <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
        <br />
        <OrderView v-if="order_id != 0" :id="order_id"/>
        <span v-else>No hay datos disponibles</span>
        <!-- <PaymentAgentView v-if="payment_id"/> -->
      </div>
    </div>
    </div>
</template>
<script>
//import ImageZoom from 'js-image-zoom'

import EventBus from "@/event-bus.js";
import Confirmation from "@/modules/Confirmation";
import ConfirmationService from "@/service/ConfirmationService";
import SelectListService from "@/service/SelectListService";
import OrderView from '@/components/order/OrderView';


export default {
  components:{
    OrderView,
  },
  props: ["id"],

  data() {
    return {
      order_id:null,
      operation_number_id:'',
      payment_id:null,
      account: "",
      payment_method: "",
      operation_number: "",
      date: "",
      time: "",
      comission_amount:'',
      amount: "",
      voucher:false,
      confirmed:false,
      accounts: [],
      payment_methods: [],
      operation_numbers: [],
        img:'',
        lens:'',
        result:'', 
        cx:'', 
        cy:'',
    };
  },

  async created() {
    await SelectListService.PaymentMethod.get().then(
      resp => (this.payment_methods = resp.data)
    );
    await ConfirmationService.Account.get().then(
      resp => (this.accounts = resp.data.data)
    );
    await ConfirmationService.OperationNumbers.get('?opt=nc').then(
      resp => (this.operation_numbers = resp.data)
    );
    await ConfirmationService.Order.getById(this.id).then(resp =>
      this.setField(resp.data)
    );

    this.order_id = this.id;

    this.imageZoom("myimage", "myresult")
  },

  methods: {

    setImage(img){
      return process.env.VUE_APP_AXIOS_PUBLIC_URL+'storage/payments/thumbnail/'+img
      //return 'http://apiv2.remesasjj.com/storage/payments/thumbnail/vCliente%2013885_5e7ce528edb44.jpeg'
    },
    setField(_data) {
        
        this.voucher = (_data.payments[0]) ? _data.payments[0].voucher: '';

        if(_data.operation_number){
            let data = _data.operation_number;
            this.confirmed = _data.confirmed;
            this.account = JSON.parse(data.account).account_id;
            this.payment_method = JSON.parse(data.payment_method).id;
            this.operation_number = data.number;
            this.date = data.date;
            this.time = data.time;
            this.amount = data.amount;
        }
    },

    setDataForm(){
        if(this.operation_number_id != ''){
            let data = this.operation_numbers.filter(on => on.id == this.operation_number_id)[0];
            this.account = JSON.parse(data.account).account_id;
            this.payment_method = JSON.parse(data.payment_method).id;
            this.operation_number = data.number;
            this.date = data.date;
            this.time = data.time;
            this.amount = data.amount;
            this.comission_amount = data.comission_province_amount;
        }
    },

    updateOperationNumber(){
        Confirmation.update(this.$data, this.operation_number_id)
        .then(resp => {
          if(this.confirmed != 0){
            this.confirmed = resp.data.confirmed;
          }
          swal("Información", resp.data.message, "success");
        })
        .catch(error => {
            let obj = error.response.data.error.message;
                let message = "";
                for (const prop in obj) {
                    message += obj[prop]+"\n";
                }
                swal("Información", message, "error")
        })
    },

    confirmOperationNumber(){

        Confirmation.confirm(this.$data)
        .then(resp => {
            this.confirmed = resp.data.confirmed;
            swal("Información", resp.data.message, "success");
        })
        .catch(error => {
            let obj = error.response.data.error.message;
                let message = "";
                for (const prop in obj) {
                    message += obj[prop]+"\n";
                }
                swal("Información", message, "error")
        })
    },

    imageZoom(imgID, resultID) {
      //let img, lens, result, cx, cy;
      this.img = document.getElementById(imgID);

      this.result = document.getElementById(resultID);
      /* Create lens: */
      this.lens = document.getElementById("lens");

      //this.lens.setAttribute("class", "img-zoom-lens");
      /* Insert lens: */
      this.img.parentElement.insertBefore(this.lens, this.img);
      /* Calculate the ratio between result DIV and lens: */
      this.cx = this.result.offsetWidth / this.lens.offsetWidth;
      this.cy = this.result.offsetHeight / this.lens.offsetHeight;
      
      /* Set background properties for the result DIV */
      this.result.style.backgroundImage = "url('" + this.img.src + "')";
      this.result.style.backgroundSize = (this.img.width * this.cx) + "px " + (this.img.height * this.cy) + "px";

      
      /* Execute a function when someone moves the cursor over the image, or the lens: */
      this.lens.addEventListener("mousemove", this.moveLens);
      this.img.addEventListener("mousemove", this.moveLens);
      /* And also for touch screens: */
      this.lens.addEventListener("touchmove", this.moveLens);
      this.img.addEventListener("touchmove", this.moveLens);
    },

    moveLens: function(e) {
        let pos, x, y;
        /* Prevent any other actions that may occur when moving over the image */
        e.preventDefault();
        /* Get the cursor's x and y positions: */
        pos = this.getCursorPos(e);
  
        /* Calculate the position of the lens: */
        x = pos.x - (this.lens.offsetWidth / 2);
        y = pos.y - (this.lens.offsetHeight / 2);
        /* Prevent the lens from being positioned outside the image: */
        if (x > this.img.width - this.lens.offsetWidth) { x = this.img.width - this.lens.offsetWidth; }
        if (x < 0) { x = 0; }
        if (y > this.img.height - this.lens.offsetHeight) { y = this.img.height - this.lens.offsetHeight; }
        if (y < 0) { y = 0; }
        /* Set the position of the lens: */
        this.lens.style.left = x + "px";
        this.lens.style.top = y + "px";
        /* Display what the lens "sees": */
        this.result.style.backgroundPosition = "-" + (x * this.cx) + "px -" + (y * this.cy) + "px";
    },

    getCursorPos: function(e) {
        var a, x = 0, y = 0;
        e = e || window.event;
        /* Get the x and y positions of the image: */
        a = this.img.getBoundingClientRect();
        /* Calculate the cursor's x and y coordinates, relative to the image: */
        x = e.pageX - a.left;
        y = e.pageY - a.top;
        /* Consider any page scrolling: */
        x = x - window.pageXOffset;
        y = y - window.pageYOffset;
        return { x: x, y: y };
    }
  }
};
</script>
<style scoped>


.img-zoom-container {
  position: relative;
}

.img-zoom-lens {
  position: absolute;
  z-index: 50000;
  border: 1px solid blue;
  /*set the size of the lens:*/
  width: 40px;
  height: 40px;
}

.img-zoom-result {
  border: 1px solid blue;
  /*set the size of the result div:*/
  width: 300px;
  height: 300px;
}
</style>