<template>
  <div>
    <div class="row">
      <div class="col-sm-6">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="form-group col-sm-6">
                <label for="ep_payment_method">Pais que Envia</label>
                <input class="form-control" v-model="country_send" disabled>
              </div>
               <div class="form-group col-sm-6">
                <label for="ep_payment_method">Moneda que Envia</label>
                <input class="form-control" v-model="currency_send" disabled>
              </div>
               <div class="form-group col-sm-6">
                <label for="ep_payment_method">Pais que Recibe</label>
                <input class="form-control" v-model="country_receive" disabled>
              </div>
               <div class="form-group col-sm-6">
                <label for="ep_payment_method">Moneda que Recibe</label>
                <input class="form-control" v-model="currency_receive" disabled>
              </div>
              <div v-if="country_receive == 'PERU'" class="form-group col-sm-12">
                <label for="ep_account">Recibe pago por</label>
                  <input class="form-control" v-model="withdrawals_by" disabled>
              </div>
              <div v-if="payment_method == 1 || payment_method == 4" class="form-group col-sm-12">
                <label for="ep_account">Cuenta de Recepción</label>
                  <select id="ep_account" class="form-control" v-model="account" disabled>
                    <option value>SELECCIONE</option>
                    <option v-for="val in accounts" :key="val.id" :value="val.id">{{val.name}}</option>
                  </select>
              </div>
              <div class="form-group col-sm-6">
                <label for="ep_payment_method">Metodo de Pago</label>
                  <select id="ep_payment_method" class="form-control" v-model="payment_method" disabled>
                    <option value>SELECCIONE</option>
                    <option
                      v-for="val in payment_methods"
                      :key="val.id"
                      :value="val.id"
                    >{{val.name}}</option>
                  </select>
              </div>
              
              <div class="form-group col-sm-3">
                <label for="ep_date">Fecha</label>
                <input id="ep_date" type="date" class="form-control text-right" v-model="date" disabled/>
              </div>
              <div class="form-group col-sm-3">
                <label for="ep_time">Hora</label>
                <input id="ep_time" type="time" class="form-control text-right" v-model="time" disabled/>
              </div>
              <div class="form-group col-sm-6">
                <label for="ep_onumber">Nro. Operacion</label>
                <input id="ep_onumber" type="text" class="form-control text-right" v-model="operation_number" disabled/>
              </div>
              <div class="form-group col-sm-6">
                <label for="ep_amount">Monto</label>
                <input id="ep_amount" type="text" class="form-control text-right" v-model="amount" disabled/>
              </div>
              <div class="form-group col-sm-6">
                <label for="ep_comission_op">% Comisión Operación</label>
                <input id="ep_comission_op" type="text" class="form-control text-right" v-model="percentage_comission" disabled/>
              </div>
              <div class="form-group col-sm-6">
                <label for="ep_comission_op">Monto Comisión</label>
                <input id="ep_comission_op" type="text" class="form-control text-right" v-model="comission_amount" disabled/>
              </div>
              <div class="form-group col-sm-6">
                <label for="ep_comission_province">Comisión Provincia</label>
                <input id="ep_comission_province" type="text" class="form-control text-right" v-model="comission_province_amount" disabled/>
              </div>
              <div class="form-group col-sm-6">
                <label for="ep_comission_province">Comisión PDV</label>
                <input id="ep_comission_province" type="text" class="form-control text-right" v-model="comission_province_amount" disabled/>
              </div>
             
              <div class="form-group col-sm-6">
                <label for="ep_total">Total</label>
                <input id="ep_total" type="text" class="form-control text-right" v-model="total" disabled/>
              </div>
               <div class="form-group col-sm-6">
                <label for="ep_rate">Tasa de Cambio</label>
                <input id="ep_rate" type="text" class="form-control text-right" v-model="rate_amount" disabled/>
              </div>
              <div class="form-group col-sm-6">
                <label for="ep_amount_to_pay">Monto a Pagar</label>
                <input id="ep_amount_to_pay" type="text" class="form-control text-right" v-model="amount_to_pay" disabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/event-bus.js";
import SelectListService from "@/service/SelectListService";
import ConfirmationService from "@/service/ConfirmationService";

export default {
    name:'ElectronicPaymentView',
    props:["order"],
    data(){
        return {
            data:'',
            country_send:'',
            country_receive:'',
            currency_receive:'',
            currency_send:'',
            payment_method:'',
            percentage_comission:'',
            withdrawals_by:'',
            account:'',
            currency:'',
            date:'',
            time:'',
            operation_number:'',
            amount:'',
            comission:'',
            comission_amount:'',
            comission_province_amount:'',
            total:'',
            rate_amount:'',
            amount_to_pay:'',
            payment_methods:'',
            currencies:'',
            accounts:'',
        }
    },

    async created() {
      await SelectListService.PaymentMethod.get().then(
        resp => (this.payment_methods = resp.data)
      );

      await ConfirmationService.Account.get().then(
        resp => (this.accounts = resp.data.data)
      );
      await SelectListService.Currency.get().then(
        resp => (this.currencies = resp.data)
      );

  },

    mounted(){
        this.data = this.order;
        this.setField(this.order);
    },

    methods:{
        setField(data){

          //console.log(data.payments);
          let payment = data.payments[0];
          let account = JSON.parse(data.payments[0].account_receive);

          this.country_send = data.country_send.name;
          this.country_receive = data.country_receive.name;
          this.currency_send = data.currency_send.name;
          this.currency_receive = data.currency_receive.name;
          this.payment_method = payment.payment_method_id;
          this.account = account.account_id;
          this.currency = payment.currency_id;
          this.date = payment.date;
          this.time = payment.time;
          this.operation_number = payment.operation_number;
          this.amount = payment.amount;
          this.percentage_comission = data.comission.comission_percentage + '%';
          this.comission_amount = data.comission_amount;
          this.comission_province_amount = payment.comission_province_amount;
          this.total = data.total;
          this.amount_to_pay = data.amount_to_pay;
          
          if(data.withdrawals_by == 'o'){
            this.withdrawals_by = 'OFICINA'
          }else{
            this.withdrawals_by = 'TRANSFERENCIA'

          }
          this.rate_amount = data.comission.exchange_rate;
        }
    }
}
</script>