<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/confirm/transferencias"
            >Traslado</router-link
          >
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Registror Traslado
        </li>
      </ol>
    </nav>
    <br />
    <div class="row">
      <div class="col col-5">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label for="asd" class="active">Cuenta a Debitar</label>
              <select
                v-model="cuenta_debito_id"
                @change="lotesSeleccionados = []"
                class="form-control"
              >
                <option value>SELECCIONE</option>
                <option
                  v-for="(val, index) in cuentasLotes"
                  :key="index"
                  :value="val.id"
                >
                  {{ val.name }}
                </option>
              </select>
            </div>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th class="text-center">ID</th>
                  <th class="text-center">Banco</th>
                  <th class="text-center">Disponible</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(lote, index) in lotesFiltrados">
                  <tr :key="index">
                    <td class="text-center">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          v-model="lotesSeleccionados"
                          :value="lote.lote_id"
                        />
                        <label class="form-check-label" for="gridCheck">
                        </label>
                      </div>
                    </td>
                    <td class="text-center">LT-{{ lote.lote_id }}</td>
                    <td>{{ lote.cuenta["bank"]["name"] }}</td>
                    <td class="text-right">
                      {{ lote.balance | numFormat("0,0.00") }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          {{ this.lotesSeleccionados }}
        </div>
        <!-- asd -->
      </div>
      <div class="col col-4">
        <div class="card">
          <div class="card-body">
            <!-- <h5 class="card-title">Datos del Traslado</h5> -->
            <div class="form-group">
              <label for="owner_account">Cuenta Destino </label>

              <select
                v-model="cuenta_destino"
                id="owner_account"
                class="form-control"
                :disabled="lotesSeleccionados.length <= 0"
              >
                <option value>SELECCIONE</option>
                <option
                  v-for="(val, index) in cuentas"
                  :key="index"
                  :value="val.id"
                >
                  {{ val.bank.name + " -- " + val.name }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label for="description">Descripción</label>

              <input
                class="form-control"
                v-model="descripcion"
                id="description"
              />
            </div>

            <div class="form-group">
              <label for="owner_account">Metodo Traslado</label>
              <select v-model="metodo" class="form-control">
                <option value="">SELECCIONE</option>
                <option value="PM">PAGO MOVIL</option>
                <option value="T">TRANSFERENCIA</option>
              </select>
            </div>

            <div v-if="metodo == 'PM'" class="form-group">
              <label for="description">Tipo</label>
              <select v-model="tipo" class="form-control">
                <option value="">SELECCIONE</option>
                <option value="NN">NATURAL -> NATURAL</option>
                <option value="NJ">NATURAL -> JURIDICA</option>
                <option value="JN">JURIDICA -> NATURAL</option>
                <option value="NJ">JURIDICA -> JURIDICA</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="col col-3">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label for="owner_account">Monto</label>

              <input
                class="form-control text-right"
                v-model="monto"
                type="number"
              />
            </div>
             <div class="form-group">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="comision_activa" id="defaultCheck1">
                <label class="form-check-label" for="defaultCheck1">
                  Comisión Activa
                </label>
              </div>
            </div>
            <div class="form-group">
              <label for="description">Porcentaje Comision</label>
              <input
                class="form-control text-right"
                v-model="porcentaje_comision"
                disabled="disabled"
              />
            </div>
            <div class="form-group">
              <label for="description">Monto Comision</label>
              <input
                class="form-control text-right"
                :value="calComision | numFormat('0,0.00')"
                disabled="disabled"
              />
            </div>
            <div class="form-group">
              <label for="description">Recargo</label>
              <input
                class="form-control text-right"
                :value="recargo | numFormat('0,0.00')"
                disabled="disabled"
              />
            </div>
            <div class="form-group">
              <label for="description">Total</label>
              <input
                class="form-control text-right"
                :value="total | numFormat('0,0.00')"
                disabled="disabled"
              />
            </div>
            <button
              v-if="actBtn"
              class="btn btn-success"
              @click="registrarTraslado()"
            >
              Registrar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AccountService from "@/service/AccountService";

export default {
  data() {
    return {
      lotesSeleccionados: [],
      cuenta_debito_id:'',
      cuentas:[],
      lotes: [],
      cuenta_destino:'',
      descripcion:'',
      monto:'',
      comision_activa: true,
      porcentaje_comision:0,
      monto_comision:'',
      voucher: "",
      metodo:'',
      total:0,
      recargo:0,
      tipo:'',

    };
  },

  async created() {
      await AccountService.get('?country=1').then((resp) => (this.cuentas = resp.data.data));
    this.getLotesDisponibles();
  },

  computed:{

      actBtn(){
          if(this.lotesSeleccionados == '') return false;

          if(this.cuenta_destino == '') return false;

          if(this.metodo == '') return false;

          if(this.monto == '' || this.monto <= 0) return false;

          return true;
      },

      calComision() {

        let cd = this.cuentas.filter(c => c.id == this.cuenta_debito_id)[0];
        let ca = this.cuentas.filter(c => c.id == this.cuenta_destino)[0];
        let recargo = 0;

        if(!cd || !ca) return 0;

        if(!this.comision_activa){
          this.porcentaje_comision = 0;
          this.recargo = 0;
          this.monto_comision = 0;
          this.total = this.monto;
          return 0;
        }

        if(this.metodo == 'T'){

         
          
          if(cd['bank_id'] == ca['bank_id']){
            
            this.porcentaje_comision = 0;
          }else{

             if(this.monto > 300000000){

              recargo = 67800;
              this.recargo = recargo;
            }

            this.porcentaje_comision = 0.12;
            
          }

        }else if(this.metodo == 'PM'){

            if(this.tipo == 'NN'){

              this.porcentaje_comision = 0.3;
            }else if(this.tipo == 'NJ'){

              this.porcentaje_comision = 0;
            }else if(this.tipo == 'JN'){

              this.porcentaje_comision = 0.3;
            }else if(this.tipo == 'JJ'){

              this.porcentaje_comision = 0;
            }
            
        }else{

          this.porcentaje_comision = 0
        }

        this.monto_comision = (this.monto * parseFloat(this.porcentaje_comision)) / 100;
        
        this.monto_comision = this.monto_comision;

        this.total = parseFloat(this.monto) + parseFloat(this.monto_comision) + parseFloat(recargo);

        return this.monto_comision;
        
      },

      cuentasLotes(){
      const data = this.lotes;
      const result = [];
      const map = new Map();

      for (const val of data) {
        if (!map.has(val.cuenta_id)) {
          map.set(val.cuenta_id, true); // set any value to Map
          result.push({
            id: val.cuenta_id,
            name: val.cuenta.name,
          });
        }
      }

      return result;
    },

    lotesFiltrados(){

      if(this.cuenta_debito_id == ''){
        return [];
      }

      return this.lotes.filter(l => l.cuenta_id == this.cuenta_debito_id);
    },
  },

  methods: {
    handlePictureUpload() {
      this.voucher = this.$refs.file.files[0];
    },
    
    getLotesDisponibles() {
      let i = 1;
      axios.get("/lotes-disponibles").then((resp) => {
        this.lotes = resp.data.map(l => {
          l.id = i; i++; 
          return l;
        });
      });
    },

 
    registrarTraslado(){

        // let lote = this.lotes[this.lotesSeleccionados];

        let data = new FormData();
        data.append('lote_id',         this.lotesSeleccionados);
        data.append('cuenta_origen',   this.cuenta_debito_id);
        data.append('cuenta_destino',  this.cuenta_destino);
        data.append('descripcion',     this.descripcion);
        data.append('monto',           this.monto);
        data.append('monto_comision',  this.monto_comision);
        data.append('metodo',          this.metodo);
        data.append('tipo',            this.tipo);
        data.append('comision_activa', this.comision_activa);
        

        axios.post('/registrar-traslado', data)
        .then(resp => {
          this.$router.push({
            path:'/traslados'
          });
          swal("", "Traslado de saldo realizado con exito.")
        })
    }
  },
};
</script>

<style scoped>
</style>