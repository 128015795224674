import Service from './Service';

const resource = 'admin/employes';

export default {
    
    get(){
        return Service.get(resource);
    },

}