<template>
  <div>
    <br />
    <div class="">
      <div class="row mb-2">
        <div class="col-lg-6 d-none d-lg-block">
          <h3>Dashboard</h3>
        </div>
        <div class="col-6 col-sm-4 col-lg-2 text-right mb-2">
          <input type="date" v-model="fechaDesde" class="form-control" />
        </div>
        <div class="col-6 col-sm-4 col-lg-2 text-right">
          <input type="date" v-model="fechaHasta" class="form-control" />
        </div>
        <div class="col-12 col-sm-4 col-lg-2">
          <div class="btn-toolbar float-right" role="toolbar">
            <div class="btn-group" role="group">
              <select class="form-control" v-model="moneda" @change="setSimboloMoneda()">
                <option value="2" selected>S/.</option>
                <option value="4">$</option>
                <option value="1">Bs.</option>
                <option value="">€</option>
              </select>
              <button type="button" class="btn btn-small btn-primary" @click="initData()">
                <i class="material-icons">search</i>
              </button>
              <button type="button" class="btn btn-small btn-info">
                <i class="material-icons">refresh</i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6 col-lg-6 col-xl-3">
          <div class="card mb-2">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <h3 class="mb-2 text-success">{{ingresos | numFormat("0,0.00")}}</h3>
                  <p class="mb-2">Ingresos</p>
                  <!-- <div class="mb-0">
                    <span class="badge badge-soft-success mr-2">
                      <i class="mdi mdi-arrow-bottom-right"></i> +5.35%
                    </span>
                    <span class="text-muted">Since last week</span>
                  </div> -->
                </div>
                <div class="d-inline-block ml-3">
                  <div class="stat">
                    <!-- <i class="material-icons">attach_money</i> -->
                    {{simboloMoneda}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-6 col-xl-3">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <h3 class="mb-2 text-danger">{{egresos | numFormat("0,0.00")}}</h3>
                  <p class="mb-2">Egresos</p>
                  <!-- <div class="mb-0">
                    <span class="badge badge-soft-success mr-2">
                      <i class="mdi mdi-arrow-bottom-right"></i> +5.35%
                    </span>
                    <span class="text-muted">Since last week</span>
                  </div> -->
                </div>
                <div class="d-inline-block ml-3">
                  <div class="stat">
                    <!-- <i class="material-icons">attach_money</i> -->
                    {{simboloMoneda}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-6 col-xl-3">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <h3 class="mb-2 text-success">{{ganancia | numFormat("0,0.00")}}</h3>
                  <p class="mb-2">Ganancia</p>
                  <!-- <div class="mb-0">
                    <span class="badge badge-soft-success mr-2">
                      <i class="mdi mdi-arrow-bottom-right"></i> +5.35%
                    </span>
                    <span class="text-muted">Since last week</span>
                  </div> -->
                </div>
                <div class="d-inline-block ml-3">
                  <div class="stat">
                    <!-- <i class="material-icons">attach_money</i> -->
                    Bs.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-lg-6 col-xl-3">
          <div class="card">
            <div class="card-body">
              <div class="media">
                <div class="media-body">
                  <h3 class="mb-2 text-primary">{{ordenesProcesadas}}</h3>
                  <p class="mb-2">Ordenes Procesadas</p>
                  <!-- <div class="mb-0">
                    <span class="badge badge-soft-success mr-2">
                      <i class="mdi mdi-arrow-bottom-right"></i> +5.35%
                    </span>
                    <span class="text-muted">Since last week</span>
                  </div> -->
                </div>
                <div class="d-inline-block ml-3">
                  <div class="stat">
                    <i class="material-icons text-primary">article</i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 col-lg-6">
          <div class="card">
            <div class="card-body">
              <div id="grafica-ingresos"></div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="card">
            <div class="card-body">
              <div id="grafica-egresos"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-12 col-lg-6">
          <div class="card">
            <div class="card-body">
              <div id="grafica-pendientes"></div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <div class="card">
                    <div id="grafica-estatus"></div>
                  </div>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-12 col-lg-12">
          <div class="card">
            <div class="card-body">
              <div id="grafica-compra-venta"></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';

const Highcharts = require("highcharts");
require("highcharts/modules/exporting")(Highcharts);

export default {

  data(){
    return {
      moneda:'2',
      simboloMoneda:'S/.',
      ingresos:0,
      egresos:0,
      ganancia:0,
      ordenesProcesadas:0,
      fechaDesde:'',
      fechaHasta:'',
    }
  },

   sockets: {
    connect() {
      // Fired when the socket connects.
      console.log("Cliente Conectado con exito, dashboard");
      // this.isConnected = true;
    },

    disconnect() {
      // this.isConnected = false;
      console.log("Cliente Desconectado con exito, dashboard");
    },

    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      console.log('Data recibida desde el server', data);
      // this.socketMessage = data
    }
  },

  mounted() {
    this.initData();
  },

  methods:{

    initData(){
      this.getIngresos();
      this.getEgresos();
      this.getGanancia();
      this.getOrdenesProcesadas();
      this.getGraficaIngresos();
      this.getGraficaEgresos();
      this.getGraficaPagosPendientes();
      this.getGraficaEstatusOrdenes();
      this.getGraficaHistorialTasaCompraVenta();
    },

    setSimboloMoneda(){
      switch(this.moneda){
        case '1':
          this.simboloMoneda ='Bs';
          break;

        case '2':
          this.simboloMoneda = 'S/.';
          break;
        
        case '4':
          this.simboloMoneda = '$';
          break;
      }
    },

    getIngresos(){
      let params = `opt=dashboard-ingresos&mid=${this.moneda}`;
      
      if(this.fechaDesde != '' && this.fechaHasta !=''){
        params += `&fd=${this.fechaDesde}&fh=${this.fechaHasta}`;
      }
      axios.get(`/ingresos?${params}`)
      .then(resp => this.ingresos = resp.data)
    },

    getEgresos(){
      let params = `opt=dashboard-egresos&mid=${this.moneda}`;
      
      if(this.fechaDesde != '' && this.fechaHasta !=''){
        params += `&fd=${this.fechaDesde}&fh=${this.fechaHasta}`;
      }
      axios.get(`/egresos?${params}`)
      .then(resp => this.egresos = resp.data)
    },

    getGanancia(){
      let params = `opt=dashboard-ganancia&mid=${this.moneda}`;
      
      if(this.fechaDesde != '' && this.fechaHasta !=''){
        params += `&fd=${this.fechaDesde}&fh=${this.fechaHasta}`;
      }
      axios.get(`/ingresos?${params}`)
      .then(resp => this.ganancia = resp.data)
    },

    getOrdenesProcesadas(){

      let params = `opt=dashboard-procesadas&mid=${this.moneda}`;

      if(this.fechaDesde != '' && this.fechaHasta !=''){
        params += `&fd=${this.fechaDesde}&fh=${this.fechaHasta}`;
      }

      axios.get(`/ordenes?${params}`).then(resp => this.ordenesProcesadas = resp.data)
    },

    getGraficaIngresos(){
      Highcharts.chart("grafica-ingresos", {
       chart: {
        type: 'column'
    },
    title: {
        text: 'Ingresos a Cuenta'
    },
    // subtitle: {
    //     text: 'Source: WorldClimate.com'
    // },
    xAxis: {
        categories: [

            'Bancos'
            
        ],
        crosshair: true
    },
    yAxis: {
        min: 0,
        title: {
            text: 'Monto'
        }
    },

    plotOptions: {
        column: {
            pointPadding: 0.2,
            borderWidth: 0
        }
    },
    series: [{
        name: 'BCP',
        data: [49.9]

    }, {
        name: 'ScotiaBank',
        data: [83.6]

    }, {
        name: 'Interbank',
        data: [48.9]

    }, {
        name: 'BBVA',
        data: [42.4]

    }]
    });
    },

    getGraficaEgresos(){
      Highcharts.chart("grafica-egresos", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
          text: 'Egresos'
      },
      tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
          point: {
              valueSuffix: '%'
          }
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: false
              },
              showInLegend: true
          }
      },
      series: [{
          name: '',
          colorByPoint: true,
          data: [
            {
              name: 'Compras',
              y: 80.41,
              sliced: true,
              selected: true
            }, {
              name: 'Gastos',
              y: 15.84
            }, {
              name: 'Vales',
              y: 4.67
            },
          ] 
        }
      ]});
    },

    getGraficaPagosPendientes(){
      Highcharts.chart('grafica-pendientes', {
        chart: {
            type: 'bar'
        },
        title: {
            text: 'Ordenes Pendientes'
        },
        xAxis: {
            categories: ['BANCO BANESCO', 'BANCO VENEZUELA', 'BANCO PROVINCIAL', 'BANCO MERCANTIL', 'BOD']
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Cantidad de Bolivares'
            }
        },
        legend: {
            reversed: true
        },
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        series: [{
            name: 'Recibidas',
            data: [17587000, 13250000, 0, 0, 0]
        },
        {
            name: 'Pendiente',
            data: [283991000, 738650000, 302700000, 144550000, 21000000]
        }
    ]
});
    },

    getGraficaEstatusOrdenes(){
      Highcharts.chart("grafica-estatus", {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
          text: 'Estatus Ordenes'
      },
      tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
          point: {
              valueSuffix: '%'
          }
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: false
              },
              showInLegend: true
          }
      },
      series: [{
          name: '',
          colorByPoint: true,
          data: [
            {
              name: 'Pendientes',
              y: 50,
              sliced: true,
              selected: true
            }, {
              name: 'Recibida',
              y: 15
            }, {
              name: 'Proceso de Pagos',
              y: 30
            },
            {
              name: 'Pagadas',
              y: 100
            },
            {
              name: 'Reportadas',
              y: 2
            },
          ] 
        }
      ]});
    },

    getGraficaHistorialTasaCompraVenta(){
      Highcharts.chart('grafica-compra-venta', {
        chart: {
            type: 'area'
        },
        accessibility: {
            description: ''
        },
        title: {
            text: 'Historial Compra - Venta'
        },
       
        xAxis: {
            allowDecimals: false,
            labels: {
                formatter: function () {
                    return this.value; // clean, unformatted number for year
                }
            },
            accessibility: {
                rangeDescription: ''
            }
        },
        yAxis: {
            title: {
                text: 'Monto'
            },
            labels: {
                formatter: function () {
                    return this.value / 1000 + 'k';
                }
            }
        },
        tooltip: {
            pointFormat: '{series.name} had stockpiled <b>{point.y:,.0f}</b><br/>warheads in {point.x}'
        },
        plotOptions: {
            area: {
                pointStart: 1,
                marker: {
                    enabled: false,
                    symbol: 'circle',
                    radius: 2,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                }
            }
        },
        series: [{
            name: 'Compra',
            data: [
                300000,320000,290000,300000,330000
            ]
        }, {
            name: 'Venta',
            data: [
              220000,250000,300000,280000,300000
            ]
        }]
    });
    }
  }
};
</script>

<style scoped>
</style>