import Service from '@/service/Service';

const resourceON = '_confirm/operation-numbers';
const resourcePA = '_confirm/payment-agents';
const resourcePO = '_confirm/payment-orders';
const resourceA = '_confirm/accounts';
const resourceO = '_confirm/orders';

export default {

    OperationNumbers : {
        get(query){
            let url_query = (query) ? query : '';
            return Service.get(resourceON+url_query);
        },
        
        getById(id){
            return Service.get(resourceON+"/"+id);
        },
    
        post(data, _query){
            let query ='';

            if(_query){
                query = _query; 
            }

            return Service.post(resourceON+query, data);
        },
        
        put(data, id){
    
            return Service.put(resourceON+'/'+id, data);
        },
    
        delete(id){
            return Service.delete(resourceON+"/"+id);
        }
    },
    
    PaymentAgent : {
        get(query){
            let url_query = (query) ? query : '';
            return Service.get(resourcePA+url_query);
        },
        
        getById(id){
            return Service.get(resourcePA+"/"+id);
        },
    
        post(query,data){
    
            return Service.post(resourcePA+query, data);
        },
    
        delete(id){
            return Service.delete(resourcePA+"/"+id);
        }
    },
    
    paymentOrders : {
        get(query){
            let url_query = (query) ? query : '';
            return Service.get(resourcePO+url_query);
        },
        
        getById(id){
            return Service.get(resourcePO+"/"+id);
        },
    
        post(query,data){
    
            return Service.post(resourcePO+query, data);
        },

        update(query, data){
            return Service.post(resourcePO+query, data);
        },
    
        delete(id){
            return Service.delete(resourcePO+"/"+id);
        }
    },

    Account : {
        get(query){
            let url_query = (query) ? query : '';
            return Service.get(resourceA+url_query);
        },
        
        getById(id){
            return Service.get(resourceA+"/"+id);
        },
    
        post(query,data){
    
            return Service.post(resourceA+query, data);
        },
    
        delete(id){
            return Service.delete(resourceA+"/"+id);
        }
    },

    Order: {
        get(query){
            let qry = (query) ? query : ''
            return Service.get(resourceO+qry);
        },

        getById(id){
            return Service.get(resourceO+'/'+id);
        },

        delete(id, note){
            return Service.delete(resourceO+'/'+id, {
                data: {
                  note
                }
              });
        }
    },

    

}