<template>
    <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/cuentas/balance"
            >Balance de Cuentas</router-link
          >
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Balance Resumen
        </li>
      </ol>
    </nav>
        <div class="card">
            <div class="card-body">
                <h3 class="card-title">{{nombreCuenta}}</h3>
                <table class="table table-striped">
                    <thead>
                        <th class="text-center">Tipo</th>
                        <th class="text-center">Ingresos</th>
                        <th class="text-center">Egresos</th>
                    </thead>
                    <tbody>
                        <tr v-for="(val, index) in resumenIngresos" :key="'ingresos'+index">
                            <td class="text-center"><h3>{{val.tipo}}</h3></td>
                            <td class="text-center text-success"><h3>
                               <router-link style="text-decoration: underline;" :to="{path:`/cuentas/detalle-balance/${id}/${mid}/${val.tipo}/${1}`}"> {{mostrarIngreso(val) | numFormat("0,0.00")}}</router-link>

                               </h3>
                            </td>
                            <td class="text-center text-danger"></td>
                        </tr>
                         <tr v-for="(val, index) in resumenEgresos" :key="'egresos'+index">
                            <td class="text-center"><h3>{{val.tipo}}</h3></td>
                            <td class="text-center text-success"></td>
                            <td class="text-center text-danger"><h3>
                                <router-link style="text-decoration: underline;" :to="{path:`/cuentas/detalle-balance/${id}/${mid}/${val.tipo}/${-1}`}">{{mostrarEgreso(val) | numFormat("0,0.00")}}</router-link>
                                </h3></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td></td>
                            <td colspan="2" class="text-center"><h3>{{total | numFormat("0,0.00")}}</h3></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

    export default {

        props:["id", "mid"],
        
        data(){
            return {
                nombreCuenta:'',
                resumen:[]
            }
        },

        created(){
            this.getBalance();
        },

        computed:{

            resumenIngresos(){
                return this.resumen.filter(r => r.balance >= 0);
            },

            resumenEgresos(){
                return this.resumen.filter(r => r.balance < 0);
            },

            total(){
                if(this.resumen.length <= 0) return 0;

                let total = 0;

                this.resumen.forEach(r => {
                    total += parseFloat(r.balance);
                });

                return total;
            }
        },

        methods:{

            mostrarIngreso(val){

                if(val.balance > 0){
                    return val.balance;
                }

                return '';
            },

            mostrarEgreso(val){

                if(val.balance < 0){
                    return val.balance;
                }

                return '';
            },

            getBalance(){
                axios.get('/balance-resumen-cuenta/'+this.id+'/'+this.mid)
                .then(resp => {
                    this.resumen = resp.data;

                    let c = resp.data[0];
                    
                    if(c.cuenta_id == 0){

                        if(this.mid == 2){
                            this.nombreCuenta = 'EFECTIVOJJ [SOLES]';

                        }else{
                            this.nombreCuenta = 'EFECTIVOJJ [DOLARES]';
                        }
                    }else if(c.cuenta){
                        
                        this.nombreCuenta = c.cuenta['name'];

                    }else{

                        this.nombreCuenta = 'N/D ['+c.cuenta_id+']';
                    }

                    
                })
            }
        }
    }
</script>

<style scoped>

</style>