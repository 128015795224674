import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: require('@/components/Login').default,
  },
  {
    path: '/abc',
    component: require('@/components/Chat').default,
  },
  {
    path: '/',
    component: Home,
    meta:{
      auth:true
    }
  },
  {
    path: '/dashboard',
    component: Home,
    meta:{
      auth:true
    }
  },
  {
    path: '/admin/orders',
    name: 'adm-orders',
    component: require('@/components/order/OrderListView').default,
    props:true,
    meta: {
        auth: {roles: ['admin','manager','payment'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }

  },
  {
    path:'/admin/orders/detail/:id',
    component: require('@/components/order/OrderDetail').default,
    props:true,
    meta: {
      auth: {roles: ['admin','manager','payment'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  }, 
  {
    path:'/admin/exchange-rates',
    component: require('@/components/admin/rates/ExchangeRatesListView').default,
    props:true,
    meta:{
      auth: {roles: ['admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path:'/admin/rates',
    component: require('@/components/admin/rates/RatesListView').default,
    props:true,
    meta: {
      auth: {roles: ['admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
  }
  },
  {
    path: '/admin/rates/calculator',
    component: require('@/components/admin/rates/CalculatorView').default,
    props: true,
    meta: {
      auth: { roles: ['admin'], redirect: { path: '/' }, forbiddenRedirect: { path: '/401' } }
    }
  },
  {
    path:'/admin/rates/detail/:id',
    component: require('@/components/admin/rates/RatesView').default,
    props:true,
    meta: {
      auth: {roles: ['admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
  }
  },
  {
    path: '/admin/accounts/owner',
    component: require('@/components/admin/account/AccountOwnerListView').default,
    meta: {
      auth: {roles: ['admin', 'confirm'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/admin/accounts/thirdparty',
    component: require('@/components/admin/account/AccountThirdpartyListView').default,
    meta: {
      auth: {roles: ['admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/admin/accounts/register',
    component: require('@/components/admin/account/AccountView').default,
    props:true,
    meta: {
      auth: {roles: ['admin', 'confirm'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/admin/accounts/detail/:id',
    component: require('@/components/admin/account/AccountView').default,
    props:true,
    meta: {
      auth: {roles: ['admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/admin/transferences',
    component: require('@/components/admin/transaction/TransactionListView').default,
    props:true,
    meta: {
      auth: {roles: ['admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/admin/transferences/detail/:id',
    component: require('@/components/admin/transaction/TransactionDetailView').default,
    props:true,
    meta: {
      auth: {roles: ['admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/admin/transferences/register/thirdparty',
    component: require('@/components/admin/transaction/TransactionThirdpartyView').default,
    props:true,
    meta: {
      auth: {roles: ['admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/admin/transferences/register/owner',
    component: require('@/components/admin/transaction/TransactionOwnerView').default,
    props:true,
    meta: {
      auth: {roles: ['admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/admin/agents/affiliate',
    component: require('@/components/agents/AgentsAffiliateView').default,
    props:true,
    meta: {
        auth: {roles: ['admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }

  },
  {
    path: '/admin/agents/thirdparty',
    component: require('@/components/agents/AgentsThirdpartyListView').default,
    props:true,
    meta: {
        auth: {roles: ['admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }

  },
  
  {
    path: '/admin/agents/thirdparty/new',
    component: require('@/components/agents/AgentThirdpartyView').default,
    props:true,
    meta: {
        auth: {roles: ['admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }

  },
  {
    path: '/admin/agents/thirdparty/:id',
    component: require('@/components/agents/AgentThirdpartyView').default,
    props:true,
    meta: {
        auth: {roles: ['admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }

  },
  {
    path: '/admin/agents/owner/:id',
    component: require('@/components/agents/AgentOwnerView').default,
    props:true,
    meta: {
        auth: {roles: ['admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }

  },
  {
    path: '/admin/agents/owner',
    component: require('@/components/agents/AgentsOwnerListView').default,
    props:true,
    meta: {
        auth: {roles: ['admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }

  },
  {
    path: '/admin/agents/payments',
    component: require('@/components/agents/PaymentAgents').default,
    props:true,
    meta: {
        auth: {roles: ['admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }

  },
  {
    path: '/admin/availability',
    component:  require('@/components/admin/Availability').default,
    props:true,
    meta: {
      auth: {roles: ['admin','compras'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/availability',
    component:  require('@/components/admin/Availability').default,
    props:true,
    meta: {
      auth: {roles: ['admin','compras'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/admin/users',
    component:  require('@/components/admin/users/UsersListView').default,
    props:true,
    meta: {
      auth: {roles: ['admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/admin/users/register',
    component: require('@/components/admin/users/FormRegister').default,
    meta: {
      auth: {roles: ['admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }

  },
  {
    path: '/admin/users/edit/:id',
    component: require('@/components/admin/users/FormDetail').default,
    props: true,
    meta: {
      auth: true
    }

  }, 
  {
    path: '/withdraws',
    component: require('@/components/payments/WithdrawOrderListView').default,
    props: true,
    meta: {
      auth: true
    }

  }, 
  {
    path: '/withdraws/detail/:id',
    component: require('@/components/payments/WithdrawOrderDetailView').default,
    props: true,
    meta: {
      auth: true
    }

  }, 
  {
    path: '/expenses',
    component: require('@/components/expenses/ExpensesListView').default,
    props: true,
    meta: {
      auth: true
    }

  }, 
  {
    path: '/expenses/register',
    component: require('@/components/expenses/FormExpense').default,
    props: true,
    meta: {
      auth: true
    }

  }, 
  {
    path: '/expenses/detail/:id',
    component: require('@/components/expenses/FormExpense').default,
    props: true,
    meta: {
      auth: true
    }

  }, 
  {
    path: '/office/expenses',
    component: require('@/components/expenses/OfficeExpensesListView').default,
    props: true,
    meta: {
      auth: true
    }

  }, 
  {
    path: '/office/expenses/detail/:id',
    component: require('@/components/expenses/FormOfficeExpense').default,
    props: true,
    meta: {
      auth: true
    }

  }, 
  {
    path: '/payments/cashdrafts',
    component: require('@/components/payments/CashdraftsListView').default,
    props: true,
    meta: {
      auth: true
    }

  }, 
  {
    path: '/ordenes-pago',
    component: require('@/components/payments/OrdersListView').default,
    props: true,
    meta: {
      auth: {roles: ['admin', 'payment', 'compras'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  }, 
  {
    path: '/procesar-ordenes',
    name:'procesar-ordenes',
    component: require('@/components/payments/ProcesarOrdenes').default,
    props: true,
    meta: {
      auth: {roles: ['admin', 'payment'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  }, 
  {
    path: '/admin/accounts/initial-balances',
    component: require('@/components/admin/account/InitialBalanceAccountView').default,
    props: true,
    meta: {
      auth: {roles: ['admin', 'manager'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }

  }, 
  {
    path: '/admin/accounts/income',
    component: require('@/components/admin/account/IncomeAccountView').default,
    props: true,
    meta: {
      auth: {roles: ['admin', 'manager'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }

  }, 
  {
    path: '/admin/accounts/transactions',
    component: require('@/components/admin/account/TransactionsListView').default,
    props: true,
    meta: {
      auth: {roles: ['admin', 'manager'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }

  }, 
  {
    path: '/admin/reports/general-balance',
    component: require('@/components/admin/reports/GeneralBalanceView.vue').default,
    props: true,
    meta: {
      auth: true
    }

  }, 
  {
    path: '/admin/reports/profitability',
    component: require('@/components/admin/reports/ProfitabilityView.vue').default,
    props: true,
    meta: {
      auth: true
    }

  }, 
  {
    path: '/confirm/operation-numbers',
    component: require('@/components/confirm/OperationNumbersListView').default,
    props:true,
    meta: {
      auth: {roles: ['admin','confirm'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/confirm/operation-numbers/detail/:id',
    component: require('@/components/confirm/OperationNumbersView').default,
    props:true,
    meta: {
      auth: {roles: ['admin', ,'confirm'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/confirm/payment-orders',
    component: require('@/components/confirm/PaymentOrdersListView').default,
    props:true,
    meta: {
      auth: {roles: ['admin','confirm'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/confirm/depositos',
    component: require('@/views/confirmacion/Ordenes').default,
    props:true,
    meta: {
      auth: {roles: ['admin','confirm'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/confirm/tablero',
    component: require('@/views/confirmacion/ResumenCajas').default,
    props:true,
    meta: {
      auth: {roles: ['admin','confirm'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/confirm/payment-orders/detail/:id',
    component: require('@/components/confirm/PaymentOrdersView').default,
    props:true,
    meta: {
      auth: {roles: ['admin','confirm'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/confirm/payment-agents',
    component: require('@/components/confirm/PaymentAgentsListView').default,
    props:true,
    meta: {
      auth: {roles: ['admin','confirm'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/confirm/payment-agents/detail/:id',
    component: require('@/components/confirm/PaymentAgentsView').default,
    props:true,
    meta: {
      auth: {roles: ['admin','confirm'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path:'/confirm/transferencias',
    component: require('@/views/confirmacion/Transferencias').default,
    props:true,
    meta:{
      auth: { roles: ['admin', 'confirm'], redirect: {path:'/'}, forbiddenRedirect:{path:'/401'}}
    }
  },
  
  {
    path:'/confirm/transferencia-saldo',
    component: require('@/views/confirmacion/TransferenciaSaldo').default,
    props:true,
    meta:{
      auth: { roles: ['admin', 'confirm'], redirect: {path:'/'}, forbiddenRedirect:{path:'/401'}}
    }
  },
  {
    path:'/confirm/ingresos-cuenta',
    component: require('@/views/confirmacion/Ingresos').default,
    props:true,
    meta:{
      auth: { roles: ['admin', 'confirm'], redirect: {path:'/'}, forbiddenRedirect:{path:'/401'}}
    }
  },
  {
    path:'/confirm/registrar-ingreso',
    component: require('@/views/confirmacion/RegistrarIngreso').default,
    props:true,
    meta:{
      auth: { roles: ['admin', 'confirm'], redirect: {path:'/'}, forbiddenRedirect:{path:'/401'}}
    }
  },
  {
    path:'/confirm/ingreso/detail/:id',
    component: require('@/views/confirmacion/RegistrarIngreso').default,
    props:true,
    meta:{
      auth: { roles: ['admin', 'confirm'], redirect: {path:'/'}, forbiddenRedirect:{path:'/401'}}
    }
  },
  {
    path:'/traslados',
    component: require('@/views/pagos/Traslados').default,
    props:true,
    meta:{
      auth: { roles: ['admin', 'payment'], redirect: {path:'/'}, forbiddenRedirect:{path:'/401'}}
    }
  },
  {
    path:'/trasladar-saldo',
    component: require('@/views/pagos/TrasladarSaldo').default,
    props:true,
    meta:{
      auth: { roles: ['admin', 'confirm', 'payment'], redirect: {path:'/'}, forbiddenRedirect:{path:'/401'}}
    }
  },

  {
    path:'/payment/gastos',
    component: require('@/views/pagos/Gastos').default,
    props:true,
    meta:{
      auth: { roles: ['admin', 'confirm', 'payment'], redirect: {path:'/'}, forbiddenRedirect:{path:'/401'}}
    }
  },
  {
    path:'/payment/detalle-gasto/:id',
    component: require('@/components/payments/FormExpense').default,
    props:true,
    meta:{
      auth: { roles: ['admin', 'confirm', 'payment'], redirect: {path:'/'}, forbiddenRedirect:{path:'/401'}}
    }
  },

  {
    path:'/payment/registrar-gasto',
    component: require('@/components/payments/FormExpense').default,
    props:true,
    meta:{
      auth: { roles: ['admin', 'confirm', 'payment'], redirect: {path:'/'}, forbiddenRedirect:{path:'/401'}}
    }
  },

  {
    path:'/caja/ordenes',
    component: require('@/views/caja/Ordenes').default,
    props:true,
    meta:{
      auth:{roles: ['admin','cash'], redirect:{path:'/'}, forbiddenRedirect:{path:'/401'}}
    }
  },

  // Rutas Nuevas
  {
    path: '/confirm/pagos',
    component: require('@/components/pagos/index.vue').default,
    props:true,
    meta: {
      auth: {roles: ['admin', 'confirm'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/pagos/detalle/:pagoId',
    name:'detalle-pago',
    component: require('@/components/pagos/FormularioPago').default,
    props:true,
    meta: {
      auth: {roles: ['admin', 'confirm'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/pagos-compra/:id',
    name:'pagos-compra',
    component: require('@/components/pagos/PagosCompra.vue').default,
    props:true,
    meta: {
      auth: {roles: ['admin', 'confirm', ,'compras'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/registrar-pago',
    component: require('@/components/pagos/FormularioPago').default,
    props:true,
    meta: {
      auth: {roles: ['admin', 'confirm','compras'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/compras/',
    component: require('@/components/compras/index').default,
    props:true,
    meta: {
      auth: {roles: ['admin', 'confirm', 'compras'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/compras/registrar-pago/:compraId',
    component: require('@/components/pagos/FormularioPago').default,
    props:true,
    meta: {
      auth: {roles: ['admin', 'confirm'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/compras/detalle/:compraId',
    name:'detalle-compra',
    component: require('@/components/compras/FormularioCompra').default,
    props:true,
    meta: {
      auth: {roles: ['admin', 'confirm', 'compras'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/registrar-compra/',
    component: require('@/components/compras/FormularioCompra').default,
    props:true,
    meta: {
      auth: {roles: ['admin', 'confirm', 'compras'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/cuentas/balance',
    component: require('@/views/cuentas/Balance').default,
    props:true,
    meta: {
      auth: {roles: ['admin','confirm', 'compras', 'payment'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/pagos/balance-cuenta',
    component: require('@/views/pagos/BalanceCuentas').default,
    props:true,
    meta: {
      auth: {roles: ['payment'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path: '/comision',
    component: require('@/views/FormularioComision').default,
    props:true,
    meta: {
      auth: {roles: ['payment', 'admin'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path:'/cuentas/resumen-balance/:id/:mid',
    component: require('@/views/cuentas/BalanceResumen').default,
    props:true,
    meta: {
      auth: {roles: ['admin','confirm'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path:'/cuentas/detalle-balance/:id/:mid/:tipo/:inv',
    component: require('@/views/cuentas/BalanceDetalle').default,
    props:true,
    meta: {
      auth: {roles: ['admin','confirm', 'payment'], redirect: {path: '/'}, forbiddenRedirect: {path:'/401'}}
    }
  },
  {
    path:'/admin',
    component: require('@/views/admin/Dashboard.vue').default,

  },
  {
    path:'/401',
    component: require('@/views/401.vue').default,

  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
]

const router = new VueRouter({
  /* linkExactActiveClass: 'is-active', */
  routes
})

Vue.router = router;

export default router
