<template>
<div class="table-responsive">
    <table class="table-hover table-sm">
      <thead>
        <tr>
          <th class="text-left" colspan="2">
            <div class="form-group">
              <label for="">Cuenta Bancaria</label>
              <select id="account" class="form-control" v-model="account">
                <option value>SELECCIONE</option>
                <option v-for="val in accounts" :key="val.id" :value="val.id">{{showAccount(val)}}</option>
              </select>
            </div>
          </th>
          <th class="text-left">
            <div class="form-group">
                <label for="method">Metodo de Pago</label>
              <select id="method" class="form-control" v-model="payment_method">
                <option value>SELECCIONE</option>
                <option v-for="val in payment_methods" :key="val.id" :value="val.id">{{val.name}}</option>
              </select>
            </div>
          </th>
          <th class="text-left">
            <div class="form-group">
              <label for="num_operation">Nro. de Operación</label>
              <input id="num_operation" type="text" class="form-control" v-model="operation_number"/>
            </div>
          </th>
          <th class="text-left">
            <div class="form-group">
                <label for="_date">Fecha</label>
              <input id="_date" type="date" class="form-control" v-model="date" />
            </div>
          </th>
          <th class="text-left">
            <div class="form-group">
                <label for="_time">Hora</label>
              <input id="_time" type="time" class="form-control" v-model="time" />
            </div>
          </th>
          <th class="text-left">
            <div class="form-group">
                <label for="c_province">C. Provincia</label>
              <input id="c_province" type="text" class="form-control" v-model="comission_amount"/>
            </div>
          </th>
          <th class="text-left">
            <div class="form-group">
                <label for="on_amount">Monto</label>
              <input id="on_amount" type="text" class="form-control" v-model="amount"/>
            </div>
          </th>
          <th class="text-left">
            <div class="form-group"><br>
              <button class="btn btn-small btn-primary" @click="registerON">
                <i class="material-icons">add</i>
              </button>
            </div>
          </th>
        </tr>
      </thead>
    </table>
</div>
</template>
<script>
import EventBus from "@/event-bus.js";
import Confirmation from '@/modules/Confirmation';
import ConfirmationService from '@/service/ConfirmationService';
import SelectListService from '@/service/SelectListService';


export default {
    components:{
    },

  data() {
    return {
        account:'',
        payment_method:'',
        operation_number:'',
        date:'',
        time:'',
        comission_amount:'',
        amount:'',
        accounts:[],
        payment_methods:[],
    };
  },

  async created(){
      await SelectListService.PaymentMethod.get().then(resp => this.payment_methods = resp.data);
      await ConfirmationService.Account.get().then(resp => this.accounts = resp.data.data);
  },

  methods:{

      showAccount(account){

        let number2 = account.account_dni.length;
        let number = parseInt(number2 - 4);
        //console.log(number2 +' '+number+' '+account.account_dni+' => '+account.account_dni.substring(number));

        return account.name +' ['+account.account_dni.substring(number)+']'
      },
      registerON(){
          Confirmation.storage(this.$data)
          .then(resp => {
              swal("Información", resp.data.message, "success")
              EventBus.$emit("refreshOperationNumbersList")

          }) 
          .catch(error => {
            let obj = error.response.data.error.message;
            let message = "";

            for (const prop in obj) {
                message += obj[prop]+"\n";
            }
            swal("Información", message, "error")
          })
      }
  }
};
</script>