<template>
    <div>
         <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Transferencias</li>
      </ol>
    </nav>
        <button class="btn btn-small btn-primary" @click="showSearch">
            <i class="material-icons">filter_list</i>
        </button>
        <div v-if="display" class="table-responsive">
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th class="text-center th-min">Cuenta</th>
                        <th class="text-center th-min">Concepto</th>
                        <th class="text-center th-min">Fecha</th>
                        <th class="text-center th-min">Hora</th>
                        <th class="text-center th-min">Nro. Operacion</th>
                        <th class="text-center th-min">Moneda</th>
                        <th class="text-center th-min">Monto</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="val in transactions" :key="val.id">
                        <td>{{val.account.name}}</td>
                        <td>

                            <router-link v-if="val.concept_operation_id != 9 && val.concept_operation_id != 6 &&val.concept_operation_id != 100" tag="a" :to="{
                                path:'/admin/transferences/detail/'+val.id}">
                            {{val.concept_operation.name}}
                            </router-link>
                            <span v-else>{{val.concept_operation.name}}</span>
                            </td>
                        <td class="jj-center-align">{{val.date}}</td>
                        <td class="jj-center-align">{{val.time}}</td>
                        <td class="jj-center-align">{{val.operation_number}}</td>
                        <td class="jj-center-align">{{val.currency.name}}</td>
                        <td class="jj-right-align" :class="setTextColor(val.amount)">{{val.amount}}</td>
                    </tr>
                </tbody>
                <!-- <tfoot>
                    <tr class="bg-secondary">
                        <td colspan="6" class="jj-right-align text-light">TOTAL</td>
                        <td class="jj-right-align text-light">{{totalEntry}}</td>
                        <td class="jj-right-align text-light">{{totalEgress}}</td>
                    </tr>
                </tfoot> -->
            </table>
        </div>
        <TransactionFilterOptions v-if="!display"/>
    </div>
</template>
<script>
import EventBus from "@/event-bus.js";
import TransactionService from '@/service/TransactionService';
import TransactionFilterOptions from '@/components/admin/transaction/TransactionFilterOptions';

export default {
    name:'TransactionListView',
    components:{
        TransactionFilterOptions
    },
    data(){
        return {
            display:true,
            transactions:[],
            filter_option:false,
        }
    },

    async created(){
        EventBus.$on("searchRange", this.searchRange);
        EventBus.$on("hideSearch", this.hideSearch)
        await TransactionService.get().then(resp => this.transactions = resp.data)

    },

    computed:{
        totalEntry(){
            let total = 0;
            this.transactions.forEach(element => {
                
            });
        },
        totalEgress(){

        }
    },

    methods:{
        hideSearch(){
            this.display = true

        },

        showSearch(){
            this.display = false
        },

        searchParam(data){
            this.display = true

            let query = '?opt='+data.search_option+'&val='+data.param+'&o=1';

            ExpenseService.get(query)
            .then(resp => this.expenses = resp.data)

        },

        setTextColor(amount){
            if(amount >= 0){
                return 'text-success';
            }

            return 'text-danger';
        },

        searchRange(data){
            let query = '?fd='+data.from_date+'&td='+data.to_date+'&aid='+data.account_id+'&uid='+data.user_id+'&cid='+data.currency_id;
            TransactionService.get(query).then(resp => this.transactions = resp.data)

            this.display = true
        },

        showFilterOptions(){
            this.$modal.show('filter-option-transactions')
        },
    }
}
</script>