<template>
  <div class="row">
    <div class="col-12 col-sm-12 col-md-8 col-lg-6 col-xl-6">
      <div class="card">
        <div class="card-body">
          <div class="form-group">
            <label for="obv_cod_order">Cod. Orden</label>
            <input id="obv_cod_order" type="text" class="form-control" :value="data.order_code"/>
          </div>
          <div class="form-group">
            <label for="obv_type_order">Tipo de Orden</label>
            <input id="obv_type_order" type="text" class="form-control" :value="data.order_type" />
          </div>
          <div class="form-group">
            <label for="obv_status">Estatus</label>
            <input id="obv_status" type="text" class="form-control" :value="data.status"/>
          </div>

          <div class="form-group">
            <label for="obv_register_by">Registrado por</label>
            <input id="obv_register_by" type="text" class="form-control" :value="data.user_name" />
          </div>
          <div class="form-group">
            <label for="obv_created_at">Creada el</label>
            <input id="obv_created_at" type="text" class="form-control" :value="data.created_at" />
          </div>
          <div v-if="!data.confirmed" class="form-group text-right">
            <button class="btn btn-small btn-danger" @click="deleteOrder">Anular Orden</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from '@/event-bus';
import ConfirmationService from "@/service/ConfirmationService";

export default {
    name:'OrderBaseView',
    data(){
        return {
            data:'',
        }
    },

    created(){
        EventBus.$on("setDataOrderBase", this.setData);
    },

    methods:{
        setData(data){
          console.log(data);
           this.data = data;
        },

        deleteOrder(){
          let note = '';
          window.swal("Motivo de la anulación", {
            content: "input",
          })
          .then((value) => {
            note = value;

            ConfirmationService.Order.delete(this.data.order_id, note)
           .then(resp => {
             window.swal('Información', resp.data.message, 'info')
             this.$router.push({
               path:'/confirm/payment-orders'
             });
           })
           .catch(error => {
             console.log(error.response.data);
             let obj = error.response.data.error.message;
             let message = '';
             for(let prop in obj){
               message += obj[prop]+'\n';
             }
             return window.swal("Información", message, "error");
           }) 
          });


           
        }
    }
}
</script>