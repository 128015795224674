<template>
  <ul class="list-unstyled components">
    <!-- <p>Menu Principal</p> -->
    <li><router-link to="/">Tablero</router-link></li>
    <li> 
      <a
        href="#menu-rates"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
        >Tasa</a
      >
      <ul class="collapse list-unstyled" id="menu-rates">
        <li v-if="$auth.check(['admin'])">
          <router-link to="/admin/rates">Tasa</router-link>
        </li>
        <li v-if="$auth.check(['admin'])">
          <router-link to="/admin/exchange-rates">Tipos de Cambios</router-link>
        </li>
        <li v-if="$auth.check(['admin'])">
          <router-link to="/admin/rates/calculator"
            >Calculadora TRM</router-link
          >
        </li>
      </ul>
    </li>
    <li v-if="$auth.check(['admin'])">
      <a
        href="#menu-agents"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
        >Agentes</a
      >
      <ul class="collapse list-unstyled" id="menu-agents">
        <li v-if="$auth.check(['admin'])">
          <router-link to="/admin/agents/affiliate">Enviar Invitación</router-link>
        </li>
        <li v-if="$auth.check(['admin'])">
          <router-link to="/admin/agents/owner">Agentes Propios</router-link>
        </li>
        <li v-if="$auth.check(['admin'])">
          <router-link to="/admin/agents/thirdparty"
            >Agentes Autorizados</router-link
          >
        </li>
        <li v-if="$auth.check(['admin'])">
          <router-link to="/admin/agents/payments">Abonos</router-link>
        </li>
      </ul>
    </li>
    <li v-if="$auth.check(['admin', 'manager'])">
      <router-link to="/admin/availability">Disponibilidad</router-link>
    </li>
    <li v-if="$auth.check(['admin', 'manager'])">
      <router-link to="/admin/orders">Ordenes</router-link>
    </li>
    <li v-if="$auth.check(['admin'])">
      <router-link to="/admin/users">Usuarios</router-link>
    </li>
    <li v-if="$auth.check(['admin', 'manager'])">
      <router-link to="/expenses">Vales</router-link>
    </li>
    <li v-if="$auth.check(['admin', 'manager'])">
      <router-link to="/office/expenses">Gastos de Oficina</router-link>
    </li>
    <li v-if="$auth.check(['admin', 'manager', 'confirm'])">
      <a
        href="#homeSubmenu1"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
        >Cuentas</a
      >
      <ul class="collapse list-unstyled" id="homeSubmenu1">
        <li v-if="$auth.check(['admin', 'manager', 'confirm'])">
          <router-link to="/cuentas/balance">Balance Cuentas</router-link>
        </li>
        <li v-if="$auth.check(['admin', 'manager', 'confirm'])">
          <router-link to="/confirm/ingresos-cuenta"
            >Ingresos a Cuenta</router-link
          >
        </li>
        <li v-if="$auth.check(['admin', 'manager', 'confirm'])">
          <router-link to="/confirm/transferencias"
            >Transferencia entre Cuentas</router-link
          >
        </li>
        <li v-if="$auth.check(['admin', 'manager', 'confirm'])">
          <router-link to="/confirm/pagos">Pagos</router-link>
        </li>
        <li v-if="$auth.check(['admin', 'manager'])">
          <router-link tag="a" to="/admin/accounts/register"
            >Cuenta Nueva</router-link
          >
        </li>
        <li v-if="$auth.check(['admin', 'manager'])">
          <router-link to="/admin/accounts/owner">Propias</router-link>
        </li>
      </ul>
    </li>

    <li v-if="$auth.check(['admin', 'confirm'])">
      <a
        href="#homeSubmenu3"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
        >Confirmación</a
      >
      <ul class="collapse list-unstyled" id="homeSubmenu3">
        <!-- <li><router-link to="/confirm/tablero">Resumen Efectivo</router-link></li> -->
        <li>
          <router-link to="/confirm/operation-numbers"
            >Numeros de Operación</router-link
          >
        </li>
        <li>
          <router-link to="/confirm/depositos">Ordenes (Nuevo)</router-link>
        </li>
        <li>
          <router-link to="/confirm/payment-orders"
            >Ordenes (Viejo)</router-link
          >
        </li>
        <li><router-link to="/confirm/payment-agents">Abonos</router-link></li>
      </ul>
    </li>
    <li v-if="$auth.check(['admin', 'payment'])">
      <a
        href="#homeSubmenu4"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
        >Pagos</a
      >
      <ul class="collapse list-unstyled" id="homeSubmenu4">
        <li><router-link to="/payment/gastos">Gastos</router-link></li>
        <li><router-link to="/ordenes-pago">Ordenes de Pago</router-link></li>
        <li><router-link to="/traslados">Traslados</router-link></li>
      </ul>
    </li>
    <li v-if="$auth.check(['admin', 'compras', 'confirm'])">
      <router-link to="/compras">Compras</router-link>
    </li>
    <li v-if="$auth.check(['admin', 'cash'])">
      <a
        href="#homeSubmenu3"
        data-toggle="collapse"
        aria-expanded="false"
        class="dropdown-toggle"
        >Caja</a
      >
      <ul class="collapse list-unstyled" id="homeSubmenu3">
        <!-- <li><router-link to="/confirm/tablero">Resumen Efectivo</router-link></li> -->
        <li><router-link to="/caja/ordenes">Remesas</router-link></li>
      </ul>
    </li>
    <li><a href="#" @click.prevent="logout">Cerrar Sesión</a></li>
  </ul>
</template>
<script>
const $ = require("jquery");
// Lo declaramos globalmente
window.$ = $;

export default {
  methods: {
    logout() {
      this.$auth.logout();
      this.$router.push({
        path:'/login'
      });
      $("#sidebar").toggleClass("active");
      //location.reload();
    },
  },
};
</script>