<template>
  <div><br>
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab">Datos de Confirmación</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab">Datos de la Remesa</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        <br />
        
        <div class="row">
          <div class="col-sm-6">
            <div v-if="type =='0'" class="alert alert-warning" role="alert">
              Número de Operación Anulado
            </div>
            <div class="card">
              <div class="card-body">
                <div class="row">
                <div class="form-group col-sm-12">
                  <label for="account">Cuentas de Recepción</label>
                  <select id="account" class="form-control" v-model="account" :disabled="type =='0'">
                    <option value>SELECCIONE</option>
                    <option v-for="val in accounts" :key="val.id" :value="val.id">{{val.name}}</option>
                  </select>
                </div>
                <div class="form-group col-sm-12">
                  <label for="payment_method">Metodo de Pago</label>
                  <select id="payment_method" class="form-control" v-model="payment_method" :disabled="type =='0'">
                    <option value>SELECCIONE</option>
                    <option
                      v-for="val in payment_methods"
                      :key="val.id"
                      :value="val.id"
                    >{{val.name}}</option>
                  </select>
                </div>
                <div class="form-group col-sm-12">
                  <label for="noperation">Nro. de Operación</label>
                  <input id="noperation" type="text" class="form-control" v-model="operation_number" :disabled="type =='0'"/>
                </div>
                <div class="form-group col-sm-6">
                  <label for="date">Fecha de la Operación</label>
                  <input id="date" type="date" class="form-control" v-model="date" :disabled="type =='0'"/>
                </div>
                <div class="form-group col-sm-6">
                  <label for="time">Hora</label>
                  <input id="time" type="time" class="form-control" v-model="time" :disabled="type =='0'"/>
                </div>
                <div class="form-group col-sm-12">
                  <label for="amount">Monto</label>
                  <input id="amount" type="text" class="form-control" v-model="amount" :disabled="type =='0'"/>
                </div>
                <div v-if="order_id == 0 && type !='0'" class="form-group col-sm-12 text-right">
                  <div class="btn-group">
                    <button class="btn btn-danger" @click="deleteOperationNumber">Eliminar</button>
                    <button class="btn btn-success" @click="updateOperationNumber">Actualizar Datos</button>

                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
        <br />
        <OrderView v-if="order_id != 0" :id="order_id"/>
        <span v-else>No hay datos disponibles</span>
        <!-- <PaymentAgentView v-if="payment_id"/> -->
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/event-bus.js";
import Confirmation from "@/modules/Confirmation";
import ConfirmationService from "@/service/ConfirmationService";
import SelectListService from "@/service/SelectListService";
import OrderView from '@/components/order/OrderView';

export default {
  components:{
    OrderView
  },
  props: ["id"],

  data() {
    return {
      order_id:null,
      payment_id:null,
      account: "",
      payment_method: "",
      operation_number: "",
      date: "",
      time: "",
      type:'',
      comission_amount:'',
      amount: "",
      accounts: [],
      payment_methods: []
    };
  },

  async created() {
    await SelectListService.PaymentMethod.get().then(
      resp => (this.payment_methods = resp.data)
    );
    await ConfirmationService.Account.get().then(
      resp => (this.accounts = resp.data.data)
    );
    await ConfirmationService.OperationNumbers.getById(this.id).then(resp =>
      this.setField(resp.data)
    );
  },

  methods: {
    setField(data) {
      
      this.account = JSON.parse(data.account).account_id;
      this.payment_method = JSON.parse(data.payment_method).id;
      this.operation_number = data.number;
      this.date = data.date;
      this.type = data.type;
      this.time = data.time;
      this.amount = data.amount;
      this.order_id = data.order_id;
    },

    deleteOperationNumber(){
      Confirmation.delete(this.id)
        .then(resp => {
          this.type = 'annulment';
          swal("Información", resp.data.message, "success");
        })
        .catch(error => {
            let obj = error.response.data.error.message;
                let message = "";
                for (const prop in obj) {
                    message += obj[prop]+"\n";
                }
                swal("Información", message, "error")
        })
    },

    updateOperationNumber(){
      Confirmation.update(this.$data, this.id)
        .then(resp => {
            this.confirmed = resp.data.confirmed;
            swal("Información", resp.data.message, "success");
        })
        .catch(error => {
            let obj = error.response.data.error.message;
                let message = "";
                for (const prop in obj) {
                    message += obj[prop]+"\n";
                }
                swal("Información", message, "error")
        })
    },
  }
};
</script>