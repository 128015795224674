<template>
  <div class="">
      <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/ordenes-pago">Ordenes de Pago</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Proceso de Pago
        </li>
      </ol>
    </nav>
    <!-- Nav tabs -->
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a
          class="nav-link active"
          id="home-tab"
          data-toggle="tab"
          href="#home"
          role="tab"
          aria-controls="home"
          aria-selected="true"
          >Ordenes de Pago</a
        >
      </li>
      <li class="nav-item" role="presentation">
        <a
          class="nav-link"
          id="messages-tab"
          data-toggle="tab"
          href="#messages"
          role="tab"
          aria-controls="messages"
          aria-selected="false"
          >Resumen</a
        >
      </li>
    </ul>

    <!-- Tab panes -->
    <div class="tab-content">
      <div
        class="tab-pane active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <br />
        <div v-if="noProcesadas.length > 0" class="alert alert-warning alert-dismissible fade show" role="alert">
          <strong>Las siguientes ordenes no pudieron se cambiadas de estatus, fueron removidas de la lista:</strong><br>
            <ul>
                <li v-for="o in noProcesadas" :key="o">{{o}}</li>
            </ul>
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <button class="btn btn-success m-2" @click="descargarPlanilla()">
          Descargar Planilla
        </button>
        <table>
          <thead>
            <tr>
              <th class="text-center">Codigo</th>
              <th class="text-center">Beneficiario</th>
              <th class="text-center">Nacionalidad</th>
              <th class="text-center">T. Documento</th>
              <th class="text-center">N# Documento</th>
              <th class="text-center">Banco</th>
              <th class="text-center">N# Cuenta</th>
              <th class="text-center">Monto a Pagar</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="orden in ordenesParaProcesar" :key="orden.id">
              <td class="text-center">{{ orden.order_code }}</td>
              <td>{{ orden.user_receive['holder'] }}</td>
              <td class="text-center">{{ orden.user_receive['nationality_name'] }}</td>
              <td class="text-center">{{ orden.user_receive['document_type_name'] }}</td>
              <td class="text-center">{{ orden.user_receive['dni'] }}</td>
              <td class="text-left">{{ orden.banco['name'] }}</td>
              <td class="text-center">{{ orden.user_receive['account_dni'] }}</td>
              <td class="text-right">
                {{ orden.amount_to_pay | numFormat("0,0.00") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
       <div
        class="tab-pane"
        id="messages"
        role="tabpanel"
        aria-labelledby="messages-tab"
      >
        <br />
        
        <div class="row">
           <div class="col col-12 col-xs-12 col-sm-12 col-lg-6">
             <div class="card">
                <div class="card-body">
                  <div class="form-group">
                  <label for="asd" class="active">Cuenta a Debitar</label>
                  <select v-model="cuenta_debito_id" @change="lotesSeleccionados=[]" class="form-control">
                    <option value>SELECCIONE</option>
                    <option v-for="(val, index) in cuentasLotes" :key="index" :value="val.id">{{val.name}}</option>
                  </select>
                </div>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th class="text-center">ID</th>
                  <th class="text-center">Banco</th>
                  <th class="text-center">Disponible</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(lote, index) in lotesFiltrados">
                <tr :key="index">
                  <td class="text-center">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="gridCheck"
                        v-model="lotesSeleccionados"
                        :value="lote.id"
                      />
                      <label class="form-check-label" for="gridCheck"> </label>
                    </div>
                  </td>
                  <td class="text-center">LT-{{ lote.lote_id }}</td>
                  <td>{{ lote.cuenta["bank"]["name"] }}</td>
                  <td class="text-right">{{ lote.balance | numFormat("0,0.00") }}</td>
                </tr>
                </template>
              </tbody>
            </table>
                </div>
              </div>
             
            <br />
            <div class="text-right">
              <div class="row">
                    <div class="col col-lg-4">
                      <div class="form-group">
                      <label>Monto a Pagar</label>
                        <input type="text" class="form-control text-right" readonly="readonly" :value="totalPago | numFormat('0,0.00')">
                      </div>
                    </div>
                    
                    <div class="col col-lg-4"><label>Balance</label>
                      <input type="text" readonly="readonly" class="form-control text-right" :value="totalBalance | numFormat('0,0.00') ">
                    </div>
                    <div class="col col-lg-4"><label>Saldo</label>
                      <input type="text" class="form-control text-right" :class="{
                          'text-success': totalSaldo > 0,
                          'text-danger': totalSaldo < 0,
                        }" readonly="readonly" :value="totalSaldo | numFormat('0,0.00')">
                     
                    </div>
                  </div>
              
            </div>
          </div>
          <div class="col col-12 col-xs-12 col-sm-12 col-lg-6">
            <div class="card">
              <div class="card-body">
                <div class="form-group">
                  <label for="">Numero de Confirmación</label>
                  <input type="text" class="form-control" v-model="numeroConfirmacion" />
                </div>
                <div class="form-group">
                  <label for="">Proceso de Pago</label>
                  <select class="form-control" v-model="procesoPago">
                    <option value="">Seleccione</option>
                    <option value="PLANILLA">PLANILLA</option>
                    <option value="INDIVIDUAL">INDIVIDUAL</option>
                  </select>
                </div>
                 <div v-if="actComision" class="form-group">
                  <label for="">Comisión</label>
                  <input type="text" class="form-control text-right" v-model="monto_comision" />
                </div>
                <div class="form-group">
                  <label for="">Voucher de Pago</label>
                  <input type="file" class="form-control " id="inputGroupFile02" ref="picture" @change="handlePictureUpload()"/>
                </div>
                <div class="row">
                  <div class="col col-lg-6">
                    <div class="form-group">
                      <label for="">Total Ordenes</label>
                      <input
                        type="text"
                        class="form-control text-right"
                        readonly
                        :value="ordenesParaProcesar.length"
                      />
                    </div>
                  </div>
                  <div class="col col-lg-6">
                    <div class="form-group">
                      <label for="">Total a Pagar (Bs.)</label>
                      <input
                        type="text"
                        class="form-control text-right"
                        :value="totalPago | numFormat('0,0.00')"
                        readonly
                      />
                    </div>
                  </div>
                </div>

                <div v-if="totalSaldo >= 0" class="form-group text-right">
                  <button class="btn btn-success" @click="procesarOrdenes()" :disabled="cargando">Pagar Ordenes</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import SelectList from "@/service/SelectListService";

export default {
  props: ["ordenes"],

  data() {
    return {
      cargando:false,
      bancos:[],
      noProcesadas: [],
      compras: [],
      lotes:[],
      lotesSeleccionados: [],
      cuenta_debito_id:'',
      numeroConfirmacion:'',
      monto_comision:'',
      procesoPago:'',
      voucher:''
    };
  },

  async created() {
    // console.log(this.ordenes);
    await SelectList.bank.get().then(resp => {
      this.bancos = resp.data.filter(b => b.country_id == 1);
      
    });

    if (!this.ordenes) {
      this.$router.push({
        path: "/ordenes-pago",
      });

      return;
    }

    this.data = this.ordenes;

    this.getOrdenesCompra();

    this.actualizarEstatus();
  },

  watch:{
    procesoPago(newVal){
      if(newVal == 'PLANILLA'){
        this.monto_comision = (this.totalPago * 0.25) / 100;
      }
      
      if(newVal == 'INDIVIDUAL'){

        let lote = this.lotes.filter((c) => this.lotesSeleccionados.includes(c.id))[0];

        let terceros = false;

        this.ordenes.forEach(o => {
          if(o.bank_id != lote.cuenta.bank_id){
            terceros = true;
          }
        });


        if(terceros){
          this.monto_comision = (this.totalPago * 0.12) / 100;
        }else{
          this.monto_comision ='';
        }
      }
    }
  },

  computed: {

    actComision(){
      if(this.procesoPago=='PLANILLA'){
        return true;
      }

      if(this.procesoPago == 'INDIVIDUAL'){

        if(this.lotesSeleccionados.length <= 0) return false;

        let lote = this.lotes.filter((c) => this.lotesSeleccionados.includes(c.id))[0];

        let terceros = false;

        this.ordenes.forEach(o => {
          if(o.bank_id != lote.cuenta.bank_id){
            terceros = true;
          }
        });


        if(terceros){
          return true;
        }

        return false;
      }
      
    },

    ordenesParaProcesar(){
      if (!this.ordenes) {
        return [];
      }
        return this.ordenes.filter(o => !this.noProcesadas.includes(o.order_code));
    },

    totalPago() {
       if (!this.ordenes) {
          return 0;
        }

      let total = 0;

      this.ordenesParaProcesar.forEach((element) => {
        total += parseFloat(element.amount_to_pay);
      });

      return total;
    },

    totalBalance() {
       if (!this.ordenes) {
        return 0;
      }
      let total = 0;

      this.lotes.filter((c) => this.lotesSeleccionados.includes(c.id)).forEach((val) => {
          total += parseFloat(val.balance);
        });

      return total;
    },

    totalSaldo() {
       if (!this.ordenes) {
        return 0;
      }
      return this.totalBalance - this.totalPago;
    },

    bancoFiltrados(){

      this.bancos.sort(function (a, b) {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        // a must be equal to b
        return 0;

      });

      return this.bancos;
    },

    lotesFiltrados(){

      if(this.cuenta_debito_id == ''){
        return [];
      }

      return this.lotes.filter(l => l.cuenta_id == this.cuenta_debito_id);
    },

    cuentasLotes(){
      const data = this.lotes;
      const result = [];
      const map = new Map();

      for (const val of data) {
        if (!map.has(val.cuenta_id)) {
          map.set(val.cuenta_id, true); // set any value to Map
          result.push({
            id: val.cuenta_id,
            name: val.cuenta.name,
          });
        }
      }

      return result;
    }
  },

  methods: {

    handlePictureUpload() {
      this.voucher = this.$refs.picture.files[0];
    },
    
    getOrdenesCompra() {
      let i = 1;
      axios.get("/lotes-disponibles").then((resp) => {
        this.lotes = resp.data.map(l => {
          l.id = i; i++; 
          return l;
        });
      });
    },

    actualizarEstatus() {
      let ordenes = this.data.map((o) => {
        return o.id;
      });

      axios
        .post("/ordenes/actualizar-estatus", { ordenes: ordenes })
        .then((resp) => {
            this.noProcesadas = resp.data.no_procesadas;
        });
    },

    procesarOrdenes(){
      this.cargando = true;
      let ordenes = this.ordenesParaProcesar.map(o => {
        return o.id;
      });

      let lotes = this.lotes.filter(l => this.lotesSeleccionados.includes(l.id)).map(lt => {
        return lt.lote_id;
      })

      // return console.log(lotes);

      let data = new FormData();
      data.append('ordenes', JSON.stringify(ordenes));
      data.append('lotes', JSON.stringify(lotes));
      data.append('cuenta_debito_id', this.cuenta_debito_id);
      data.append('numero_confirmacion', this.numeroConfirmacion);
      data.append('monto_comision', this.monto_comision);
      data.append('proceso_pago', this.procesoPago);
      data.append('voucher', this.voucher);

      axios.post('procesar-ordenes-pago', data, {
        headers: {
          'Content-Type' : 'multipart/form-data'
        }
      })
      .then(resp => {
        this.cargando = false;
        this.$router.push({
          path:'/ordenes-pago'
        });
        swal('', 'Ordenes Procesadas Exitosamente', 'success');
      })
      .catch(err => {
        this.cargando = false;
        swal('', 'Hubo un error. Contacte al administrador', 'danger');
      })


    },

    descargarPlanilla() {

      console.log(this.ordenesParaProcesar[0].user_receive);

      let cuentas = this.ordenesParaProcesar.map(o => {
        
        return {id: o.id, cuenta_id: o.user_receive['account_id']};
      });

      // return console.log(cuentas);

      axios.post('descargar-planilla', {cuentas}, {
        responseType: "blob"
      })
      .then(resp => {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Planilla-Pagos.xlsx"); //or any other extension
          link.setAttribute("target", "_blank"); //or any other extension
          document.body.appendChild(link);
          link.click(); 
      })
    },

   
  },
};
</script>

<style scoped>
</style>