<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Pagos</li>
      </ol>
    </nav>
    <div>
      <router-link :to="{
        path:'/registrar-pago'
      }" class="btn btn-primary">Nuevo Pago</router-link>
    </div>
    <div class="row mb-2">
      <div class="col col-lg-7"></div>
      <div class="col col-lg-2">
        <input type="date" class="form-control" v-model="fecha_desde" />
      </div>
      <div class="col col-lg-2">
        <input type="date" class="form-control" v-model="fecha_hasta" />
      </div>
      <div class="col col-lg-1">
        <button class="btn btn-small btn-primary" @click="getPagosFiltro()">
          Filtrar
        </button>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th class="text-center">ID</th>
          <th class="text-center">Fecha</th>
          <th class="text-center">Tipo</th>
          <th class="text-center">Descripción</th>
          <th class="text-center">Moneda</th>
          <th class="text-center">Monto</th>
          <th class="text-center"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="pago in pagos" :key="pago.id">
          <td class="text-center">
            <button
              class="btn btn-outline-primary m-1"
              @click="detallePago(pago.id)"
            >
              ID - {{ pago.id }}
            </button>
          </td>
          <td class="text-center">{{ pago.fecha }}</td>
          <td class="text-center">{{ pago.tipo }}</td>
          <td>{{ pago.descripcion }}</td>
          <td>{{ pago.moneda_pago['name'] }}</td>
          <td class="text-right">{{ pago.monto | numFormat("0,0.00") }}</td>
          <td class="text-right"><button
                      class="btn btn-sm btn-outline-primary m-2"
                      type="button"
                      @click="downloadImage(pago.id)"
                    >
                      <i class="material-icons">image</i>
                    </button></td>
        </tr>
      </tbody>
      <!-- <tfoot class="bg-secondary">
        <tr>
          <td colspan="3" class="text-right text-white h5">
            {{ calcularTotalPagado | numFormat("0,0.00") }}
          </td>
          <td colspan="2" class="text-right text-white h5">
            {{ calcularTotalComprado | numFormat("0,0.00") }}
          </td>
          <td colspan="2"></td>
        </tr>
      </tfoot> -->
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      fecha_desde: '',
      fecha_hasta: '',
      pagos: [],
    };
  },

  created() {
    axios.get("pagos").then((resp) => {
      this.pagos = resp.data;
    });
  },

  computed: {

    calcularTotalPagado() {
      let total = 0;

      if (!this.pagos) {
        return total;
      }

      this.pagos.forEach((pago) => {
        total += parseFloat(pago.monto_pago);
      });

      return total;
    },

    calcularTotalComprado() {
      let total = 0;

      if (!this.pagos) {
        return total;
      }

      this.pagos.forEach((pago) => {
        total += parseFloat(pago.monto_moneda_compra);
      });

      return total;
    },
  },

  methods: {

    getPagosFiltro(){
      axios.get(`pagos?opt=pagos-rango&fd=${this.fecha_desde}&fh=${this.fecha_hasta}`).then((resp) => {
        this.pagos = resp.data;
      });
    },


    detallePago(pagoId) {
      let pago = this.pagos.filter((p) => p.id == pagoId)[0];

      this.$router.push({
        name: 'detalle-pago',
        params: { pagoId},
      });
      //   console.log(compra);
    },

    registrarPago(compra){

      this.$router.push({
          path: 'registrar-pago',
          // params: { compraId, detalle: compra },
        });
    },

    downloadImage(id){
        axios({
        method: "get",
        url: "pagos?opt=descargar-voucher&id="+id,
        responseType: "blob" //stream // arraybuffer
        })
        .then(response => {

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.png"); //or any other extension
          link.setAttribute("target", "_blank"); //or any other extension
          document.body.appendChild(link);
          link.click(); 

        })
      }
  },
};
</script>

<style scoped>
</style>