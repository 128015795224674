<template>
  <div>
    <br />
    <div class="container-fluid">
      <router-link
        :to="{
          path: '/confirm/registrar-ingreso',
        }"
        class="btn btn-primary"
        >Registrar Ingreso</router-link
      >
    </div>
    <div class="row mb-2">
      <div class="col col-lg-7"></div>
      <div class="col col-lg-2">
        <input type="date" class="form-control" v-model="fecha_desde" />
      </div>
      <div class="col col-lg-2">
        <input type="date" class="form-control" v-model="fecha_hasta" />
      </div>
      <div class="col col-lg-1">
        <button class="btn btn-small btn-primary" @click="getIngresosRango()">
          Filtrar
        </button>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <table>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Origen</th>
              <th>Cuenta</th>
              <th>Descripción</th>
              <th>Monto</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="val in ingresos" :key="val.id">
              <td class="text-center">{{ val.created_at }}</td>
              <td class="text-center">{{ val.origen }}</td>
              <td class="text-left">
                {{
                  val.cuenta_acreditar_id != 0 ? val.cuenta["name"] : "EFECTIVO"
                }}
              </td>
              <td class="text-left">{{ val.descripcion }}</td>
              <td class="text-right">{{ val.monto | numFormat("0,0.00") }}</td>
              <td class="text-right">
                <router-link
                  class="btn btn-small btn-info m-1"
                  :to="{ path: '/confirm/ingreso/detail/' + val.documento_id }"
                >
                  Detalle
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
        fecha_desde: '',
        fecha_hasta: '',
        ingresos: [],
    };
  },

  created() {
    this.getIngresos();
  },

  methods: {
    getIngresos() {
      axios.get("ingresos").then((resp) => {
        this.ingresos = resp.data;
      });
    },

    getIngresosRango() {
      axios.get(`ingresos?fd=${this.fecha_desde}&fh=${this.fecha_hasta}`).then((resp) => {
        this.ingresos = resp.data;
      });
    },
  },
};
</script>

<style scoped>
</style>