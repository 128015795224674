<template>
  <div>
    <ul class="nav nav-tabs mt-2" id="myTab" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab">Monedas</a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          id="profile-tab"
          data-toggle="tab"
          href="#profile"
          role="tab"
        >T. Cambios Api</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
        <br />
        <div class="row">
          <div class="col-12 col-sm-12 col-md-8 col-lg-4 col-xl-4">
            <ExchangeRateView v-for="val in currencies" :key="val.id" :currency="val" :currencies="currencies"/>
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="home-tab">
        <br />
        <div class="row">
          <div class="col-12 col-sm-12 col-md-8 col-lg-12 col-xl-12">
                <div class="row">
                    <div class="form-group col-6 col-sm-6 col-md-6 col-lg-2 col-xl-2">
                        <label for="">Porcentaje</label>
                        <div class="input-group mb-3">
                        <input type="text" class="form-control" v-model="percentage">
                        <div class="input-group-append">
                            <button class="btn btn-outline-success" type="button" @click.prevent="updateRatePercentage">Actualizar</button>
                        </div>
                    </div>   
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th class="text-center th-min">Moneda</th>
                                <th class="text-center">Compra</th>
                                <th class="text-center">Venta</th>
                                <th class="text-center th-min">Tasa</th>
                                <th class="text-center">%</th>
                                <th class="text-center">Base</th>
                                <th class="text-center">API</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ExchangeRateApiView v-for="val in currencies" :key="val.id" :currency="val" :percentage="percentage"/>
                        </tbody>
                    </table>
                </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ExchangeRateService from "@/service/ExchangeRatesService";
import ExchangeRateView from "@/components/admin/rates/ExchangeRateView"
import ExchangeRateApiView from "@/components/admin/rates/ExchangeRateApiView"
import Helpers from '@/Helpers';
import EventBus from '@/event-bus.js';

export default {
    components:{
        ExchangeRateView,
        ExchangeRateApiView
    },
  data() {
    return {
        percentage:0,
        currencies: []
    };
  },

  async created() {
        EventBus.$on('refreshExchangeRateList', this.getExchangeCurrencies);
        await this.getRatePercentage();
        await this.getExchangeCurrencies();

  },

  methods:{
      getExchangeCurrencies(){
        ExchangeRateService.get("?opt=currencies").then(
            resp => (this.currencies = resp.data)
        );
      },
      getRatePercentage(){
          ExchangeRateService.get("?opt=api-percentage").then(
            resp => (this.percentage = resp.data)
        );
      },

      updateRatePercentage(){
        ExchangeRateService.post("?opt=upd-api-percentage", {amount:this.percentage}).then(resp => {
            swal("Información", resp.data.message, "success")
        })
        .catch(error => {
            Helpers.showError(error)
        });
      }
  }
};
</script>