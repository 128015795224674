<template>
  <div>
     <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item text-primary" aria-current="page">
           <router-link tag="a" to="/office/expenses">Lista de Gastos</router-link>
        </li>
        <li class="breadcrumb-item" aria-current="page">
            Detalle
        </li>
      </ol>
    </nav>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6">
        <div class="card">
          <div class="card-body">
            <div v-if="id" class="form-group">
              <label for="register_by" class="active">Registrado por</label>
              <input type="text" id="register_by" v-model="register_by" :disabled="(id) ? true:false" class="form-control"/>
            </div>
            <div v-if="id" class="form-group">
              <label for="expense_date" class="active">Fecha</label>
              <input type="text" id="expense_date" v-model="expense_date" :disabled="(id) ? true:false" class="form-control"/>
            </div>
            <div class="form-group">
              <label for="concept" class="active">Concepto</label>
              <input type="text" id="concept" v-model="concept" :disabled="(id) ? true:false" class="form-control"/>
            </div>
            <div class="form-group">
              <label for="currency" class="active">Moneda</label>
              <select id="currency" v-model="currency_id" :disabled="(id) ? true:false" class="form-control">
                <option value>SELECCIONE</option>
                <option v-for="(val,index) in filterCurrencies" :key="index" :value="val.id">{{val.name}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="amount" class="active">Monto</label>
              <input type="text" id="amount" v-model="amount" :disabled="(id) ? true:false" class="form-control"/>
            </div>
            <div class="form-group text-right">
              <a v-if="id && expense.confirmed == 0 && expense.status_id == 25" href="#" class="btn btn-small btn-success" @click.prevent="confirmExpenses">Confirmar</a>
              <a v-if="id && expense.confirmed == 0 && expense.status_id == 18" href="#" class="btn btn-small btn-danger" @click.prevent="deleteExpense">Anular</a>
            </div>
            

            <!-- <a href="#" @click.prevent="login">Login</a>
            <a href="#" @click.prevent="test">Test</a> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {

    props:["id"],

  data(){
    return {
      registered: false,
      search_filter:'',
      expense:'',
      employe:'',
      register_by:'',
      expense_date:'',
      concept:'',
      amount:'',
      token:'',
      currency_id:'',      
      currencies:[],
    }
  },

/* created() {
    if (this.$auth.user().role_name != "admin") {
      if (this.$auth.user().role_name != "cash") {
        window.M.toast({ html: "Perfil no autorizado" });
        this.$auth.logout();
      }

      if (this.$auth.user().register_type_id != 2) {
        window.M.toast({ html: "Perfil no autorizado" });
        this.$auth.logout();
      }
    }
  }, */
mounted() {

    if(this.id){
        this.getExpense(); 
               
        //window.M.toast({html:this.id});
    }

    this.getCurrencies();

  },


  computed:{

    filterCurrencies(){

      if(this.currencies){

        return this.currencies.filter(
          currency => currency.id == 2 || currency.id == 4
          );
      }

      return;
    }
  },

  methods: {

    showAlert(tittle, msg, type) {
      // Use sweetalert2
      this.$swal({
        type: type, //warning, error, success, info, and question
        title: tittle,
        text: msg,
        footer: ""
      });
    },

    
    deleteExpense(){
      axios.post('admin/offices/expenses/annulment', {id:this.id})
      .then(response => {
          window.M.toast({html:response.data.msg});
           this.expense.confirmed = response.data.confirmed;
          this.expense.status_id = response.data.status_id;
      })
      .catch(error => {
        if (error.response.status == 403) {
             window.M.toast({html: "La operacion no puede ser procesada"})
            this.stat_registering = false;
          }else{

            this.showAlert('Error', error.response.data.msg, 'error')
          }
      })
    },

    confirmExpenses(){
      axios.post('admin/offices/expenses/confirm', {id:this.id})
      .then(response => {
          // window.M.toast({html:response.data.msg});
          // this.expense.confirmed = response.data.confirmed;
          // this.expense.status_id = response.data.status_id;

          this.$router.push({
            path:'/office/expenses'
          });

          swal('', 'Gasto confirmado con exito.', 'success');
          // alert("Gasto confirmado con exito.");
      })
      .catch(error => {
        if (error.response.status == 403) {
             window.M.toast({html: "La operacion no puede ser procesada"})
            this.stat_registering = false;
          }else{

            this.showAlert('Error', error.response.data.msg, 'error')
          }
      })
    },

    

    getCurrencies() {
      axios.get("mod_mant/get-currencies").then(response => {
        this.currencies = response.data;
      });
    },

    getExpense(){
      axios.get('agent/office/expenses/id/'+this.id)
      .then(response => {
        this.expense = response.data
        this.currency_id = response.data.currency_id;
        this.concept = response.data.concept;
        this.amount = response.data.amount;
        this.register_by = response.data.created_by;
        this.expense_date = response.data.expense_date;
      })
    }
  }
};
</script>

<style scoped>
</style>