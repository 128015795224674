import Service from './Service';

const resource = 'cashdraft';

export default {
    
    get(qry){
        let query = (qry) ? qry : ''
        return Service.get(resource+query);
    },

    
}