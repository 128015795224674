<template>
  <div>
    <br />
    <div class="row">
      <div class="col-12 col-sm-12 col-md-6 col-lg-6">
        <div class="position-fixed bottom-0 left-0 p-3" style="z-index: 5; right: 0; bottom: 0">
          <div id="liveToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true" data-delay="2000">
            <div class="toast-header">
              <strong class="mr-auto">Portapapeles</strong>
              <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="toast-body">Enlace Copiado!</div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div v-if="token" class="form-group">
              <label for="">Enlace</label>
              <div class="input-group">
                <input id="textUrl" type="text" class="form-control" v-model="url" readonly/>
                <div class="input-group-append">
                  <button class="btn btn-secondary btn-sm" @click="copiarClipboard()">
                    <i class="material-icons">content_copy</i>
                  </button>
                </div>
              </div>
            </div>
            <div v-if="token" class="form-group">
              <label for="">Correo Electronico</label>
              <input type="text" class="form-control" v-model="email"/>
            </div>
            <div class="form-group">
              <button v-if="!token" class="btn btn-primary" @click="generarEnlace()">
                Generar Enlace
              </button>
              <button v-if="token" class="btn btn-info" @click="token = !token">
                Nuevo Enlace
              </button>
              <button v-if="token" class="btn btn-warning ml-2" @click="enviarCorreo()" :disabled="btnDisable">
                Enviar Correo
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      btnDisable:false,
      token: false,
      url: "",
      email:''
    };
  },

  methods: {
    generarEnlace() {
      axios.get("generar-enlace").then((resp) => {
        console.log(resp);
        this.token = true;
        this.url = `https://remesasjj.com/p/afiliados?tn=${resp.data.token}`;
      });
    },

    enviarCorreo(){
      this.btnDisable = true;
      axios.post("enviar-enlace", {email:this.email, url: this.url})
      .then((resp) => {
        this.btnDisable = false;
        swal("Correo enviado con exito.", "", "success");
      })
      .catch(err => {
        swal("Hubo un error al enviar el correo. Intente de nuevo.", "", "error");
        this.btnDisable = false;
      })
    },

    copiarClipboard() {
        let text = document.getElementById("textUrl");

        text.select();
        document.execCommand("Copy");
        window.jQuery('#liveToast').toast('show');
    },
  },
};
</script>

<style scoped>
</style>