<template>
  <table class="table">
    <thead>
      <tr>
        <th class="text-center"></th>
        <th class="text-center">Fecha</th>
        <th class="text-center">Cuenta Origen</th>
        <th class="text-center">Cuenta Destino</th>
        <th class="text-center">Monto</th>
        <th class="text-center">Comision</th>
        <th class="text-center">Total</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="val in transferencias" :key="val.id">
        <td class="text-center">
          <button class="btn btn-small btn-info">{{ val.id }}</button>
        </td>
        <td class="text-center">{{ val.created_at }}</td>
        <td>{{ val.cuenta_debito["name"] }}</td>
        <td>{{ val.cuenta_acreditar["name"] }}</td>
        <td class="text-right">{{ val.monto | numFormat("0,0.00") }}</td>
        <td class="text-right">
          {{ val.monto_comision | numFormat("0,0.00") }}
        </td>
        <td class="text-right">{{ val.total | numFormat("0,0.00") }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from "axios";

export default {
    props:["cuenta_id", "tipo", "inv_act"],

    data() {
        return {
        transferencias: [],
        };
    },

    created() {
        this.getTransferencias();
    },

  methods: {
    getTransferencias() {
      axios.get('transferencias?opt=detalle-cuenta&cid='+this.cuenta_id+'&inv_act='+this.inv_act).then((resp) => {
        this.transferencias = resp.data;
      });
    },
  },
};
</script>

<style scoped>
</style>