<template>
 <div>
       <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/payment/gastos"
            >Listar Gastos</router-link
          >
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Registrar Gasto
        </li>
      </ol>
    </nav>
    <div class="row">
      <div class="col col-lg-5">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label for="asd" class="active">Cuenta a Debitar</label>
              <select v-model="cuenta_debito_id" @change="lotesSeleccionados = ''" class="form-control">
                <option value>SELECCIONE</option>
                <option v-for="(val, index) in cuentasLotes" :key="index" :value="val.id">
                  {{ val.name }}
                </option>
              </select>
            </div>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th class="text-center">ID</th>
                  <th class="text-center">Banco</th>
                  <th class="text-center">Disponible</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(lote, index) in lotesFiltrados">
                  <tr :key="index">
                    <td class="text-center">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          v-model="lotesSeleccionados"
                          :value="lote.lote_id"
                        />
                        <label class="form-check-label" for="gridCheck">
                        </label>
                      </div>
                    </td>
                    <td class="text-center">LT-{{ lote.lote_id }}</td>
                    <td>{{ lote.cuenta["bank"]["name"] }}</td>
                    <td class="text-right">
                      {{ lote.balance | numFormat("0,0.00") }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
         
        </div>
      </div>
      <div class="col col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col col-lg-6">
                <div class="form-group">
                  <label for="date">Fecha</label>
                  <input class="form-control" type="date" id="date" v-model="date" />
                </div>
              </div>
              <div class="col col-lg-6">
                <div class="form-group">
                  <label for="operation_number">N# Operación</label>
                  <input class="form-control" id="operation_number" v-model="operation_number" type="text"/>
                </div>
              </div>
            </div>
          
            <div class="form-group">
              <label for="amount">Monto</label>
              <input class="form-control" type="number" id="amount" v-model="amount" />
            </div>
          <div class="form-group">
            <label for="concept" >Concepto</label>
            <input class="form-control" type="text" id="concept" v-model="concept" />
          </div>
          <div v-if="!id" class="form-group">
              <label for="file">Voucher de la Operación</label>
              <input type="file" class="form-control-file" id="file" ref="file" @change="handlePictureUpload"/>
          </div>
          <div v-if="id" class="form-group">
            <label for="status">Estatus</label>
            <input class="form-control" type="text" id="status" v-model="status" disabled />
          </div>
          <div class="form-group right-align">
              <a v-if="!registered && !id" href="#" class="btn btn-small btn-success" @click.prevent="registerExpense" >Registrar</a>
              <a v-if="registered && !id" href="#" class="btn btn-small btn-primary" @click.prevent="newExpense" >Nuevo</a>
              <a v-if="id && confirmed == false && status_id == 25" href="#" class="btn btn-small btn-danger" @click.prevent="deleteExpense" >Anular</a>
           </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const public_path = process.env.VUE_APP_AXIOS_PUBLIC_URL
export default {
  props: ["id"],

  data() {
    return {
      lotesSeleccionados: '',
      cuenta_debito_id:'',
      lotes: [],
      registered: false,
      confirmed:false,
      expense: "",
      account:"",
      date:'',
      time:'',
      operation_number:'',
      amount: "",
      voucher: "",
      concept: "",
      currency_id: "",
      status:'',
      status_id:'',
      currencies: [],
      accounts:[],
      msgToast:'',
    };
  },

  mounted() {
    if (this.id) {
      this.getExpense();

      //window.M.toast({html:this.id});
    }
    // this.getAccounts();
    this.getLotesDisponibles();

  },


  computed: {
    setImage: function() {
      if(this.voucher && this.id){

        return public_path + "storage/expenses/vouchers/thumbnail/" + this.voucher;
      }

      return null;
    },

    lotesFiltrados(){

      if(this.cuenta_debito_id == ''){
        return [];
      }

      return this.lotes.filter(l => l.cuenta_id == this.cuenta_debito_id);
    },

    cuentasLotes(){
      const data = this.lotes;
      const result = [];
      const map = new Map();

      for (const val of data) {
        if (!map.has(val.cuenta_id)) {
          map.set(val.cuenta_id, true); // set any value to Map
          result.push({
            id: val.cuenta_id,
            name: val.cuenta.name,
          });
        }
      }

      return result;
    },
  },

  methods: {
   handlePictureUpload() {
      this.voucher = this.$refs.file.files[0];
    },
    showAlert(tittle, msg, type) {
      // Use sweetalert2
      this.$swal.fire({
        type: type, //warning, error, success, info, and question
        title: tittle,
        text: msg,
        footer: ""
      });
    },

    newExpense() {
      this.currency_id = "";
      this.concept = "";
      this.amount = "";

      this.registered = false;
    },

    registerExpense() {
      let error = false;
      if (this.cuenta_debito_id == "") {
        this.msgToast = "Seleccione la Cuenta";
        error = true;
      }else if (this.lotesSeleccionados == "") {
        this.msgToast = "Seleccione el Lote";
        error = true;
      }else if (this.date == "") {
        this.msgToast = "Ingrese la fecha";
        error = true;
      }else if (this.operation_number == "") {
        this.msgToast = "Ingrese el numero de operacion";
        error = true;
      }else if (this.amount == "") {
        this.msgToast = "Ingrese el monto";
        error = true;
      }else if (this.concept == "") {
        this.msgToast = "Ingrese el concepto";
        error = true;
      }else if (this.voucher == "") {
        this.msgToast = "Ingrese el voucher";
        error = true;
      }

      if(error == true){
        // return window.jQuery('.toast').toast('show');
        return swal("Error", this.msgToast, "error");
      }

      let formData = new FormData();
      formData.append("cuenta_debito_id", this.cuenta_debito_id);
      formData.append("lote_id", this.lotesSeleccionados);
      formData.append("date", this.date);
      formData.append("operation_number", this.operation_number);
      formData.append("amount", this.amount);
      formData.append("concept", this.concept);
      formData.append("voucher", this.voucher);


      axios
        .post("payment/office/expenses/register",  formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          this.registered = true;
          this.$router.push({
            path:'/payment/gastos'
          });
          swal("","Gasto registrado exitosamente", "success");
        })
        .catch(error => {
          if (error.response.status == 403) {
            swal("Error", "Se ha producido un error contacte al administrador", "error")
          } else {
            swal("Error", error.response.data.msg, "error")
          }
        });
    },

    deleteExpense() {
      axios
        .post("payment/office/expenses/annulment", { id: this.id })
        .then(response => {
           swal("","Gasto eliminado exitosamente", "success");
          this.$router.push({ path: "/payment/gastos" });
        })
        .catch(error => {
          if (error.response.status == 403) {
            swal("Error", "Se ha producido un error contacte al administrador", "error")
          } else {
            swal("Error", error.response.data.msg, "error")
          }
        });
    },

  getLotesDisponibles() {
      let i = 1;
      axios.get("/lotes-disponibles").then((resp) => {
        this.lotes = resp.data.map(l => {
          l.id = i; i++; 
          return l;
        });
      });
    },

    getExpense() {
      axios.get("payment/office/expenses/id/" + this.id).then(response => {
        this.expense = response.data;
        this.account = response.data.account_id;
        this.date = response.data.date;
        this.time = response.data.time;
        this.operation_number = response.data.operation_number;
        this.amount = response.data.amount;
        this.concept = response.data.concept;
        this.voucher = response.data.voucher;
        this.status = response.data.status_name;
        this.confirmed = response.data.confirmed;
        this.status_id = response.data.status_id;

      });
    },

    getAccounts_() {
      axios
        .post("payment/accounts/country/" + this.$auth.user().country_id)
        .then(response => {
          this.accounts = response.data.map(account => {
            return {
              id: account.id,
              currency: account.currency_id,
              name: account.name,
              holder: account.holder,
              account_dni: account.account_dni
            };
          });
        });
    },
     getAccounts() {
      axios
        .post("payment/accounts/country", {
          country: "venezuela",
          owner: true
        })
        .then(response => {
          this.accounts = response.data.map(account => {
            return {
              id: account.id,
              currency: account.currency_id,
              name: account.name,
              holder: account.holder,
              account_dni: account.account_dni
            };
          });
        });
    }
  }
};
</script>

<style scoped>
</style>