<template>
  <table>
    <thead>
      <tr>
        <th>Fecha</th>
        <th>Origen</th>
        <th>Cuenta</th>
        <th>Descripción</th>
        <th>Monto</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="val in egresos" :key="val.id">
        <td class="text-center">{{ val.created_at }}</td>
        <td class="text-center">{{ val.origen }}</td>
        <td class="text-left">
          {{ val.cuenta_acreditar_id != 0 ? val.cuenta["name"] : "EFECTIVO" }}
        </td>
        <td class="text-left">{{ val.descripcion }}</td>
        <td class="text-right">{{ val.monto | numFormat("0,0.00") }}</td>
        <td class="text-right">
          <router-link
            class="btn btn-small btn-info m-1"
            :to="{ path: '/confirm/ingreso/detail/' + val.id }"
          >
            Detalle
          </router-link>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import axios from "axios";

export default {
    props:["cuenta_id", "mid", "tipo"],
  
    data() {
        return {
        egresos: [],
        };
    },

  created() {
    this.getEgresos();
  },

  methods: {
    getEgresos() {
      axios.get(`egresos?mid=${this.mid}`).then((resp) => {
        this.egresos = resp.data;
      });
    },
  },
};
</script>

<style scoped>
</style>