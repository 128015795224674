export default {

    showError(error){
        let obj = error.response.data.error.message;
        let message = "";
        for (const prop in obj) {
            message += obj[prop]+"\n";
        }
        swal("Información", message, "error")
    }
}