<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Agentes Propios</li>
      </ol>
    </nav>
    <div class="row">
      <div v-for="val in agents" :key="val.agent.id" class="col-12 col-sm-12 col-md-6 col-lg-4 pt-3">
        <div class="card">
          <div class="card-header">
            <div class="card-title text-primary">
              <router-link tag="a" :to="'/admin/agents/owner/'+val.agent.id"><h3 class="text-truncate">{{val.agent.business_name}}</h3></router-link>
            </div>
          </div>
          <div class="card-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" id="profile-tab" data-toggle="tab" :href="'#profile'+val.agent.id" role="tab" aria-controls="profile" aria-selected="false">Ordenes</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="settings-tab" data-toggle="tab" :href="'#settings'+val.agent.id" role="tab" aria-controls="settings" aria-selected="false">Vales</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="office-tab" data-toggle="tab" :href="'#office'+val.agent.id" role="tab" aria-controls="office" aria-selected="false">Gastos</a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane active" :id="'profile'+val.agent.id" role="tabpanel" aria-labelledby="profile-tab">
                <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th class="text-center">Estatus</th>
                      <th class="text-center">Moneda</th>
                      <th class="text-center">Monto</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="order in val.order_day" :key="order.id">
                      <td class="text-center">{{order.status_name}}</td>
                      <td class="text-center">{{order.currency_name}}</td>
                      <td class="text-right">{{order.total}}</td>
                    </tr>
                  </tbody>
                </table>
                </div>
              </div>
              <div class="tab-pane" :id="'settings'+val.agent.id" role="tabpanel" aria-labelledby="settings-tab">
                  <table class="table">
                  <thead>
                    <tr>
                      <th class="text-center">Cant.</th>
                      <th class="text-center">Moneda</th>
                      <th class="text-center">Monto</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="expense in val.expenses" :key="expense.id">
                      <td class="text-center">{{expense.count}}</td>
                      <td class="text-center">{{expense.currency_name}}</td>
                      <td class="text-right">{{expense.total}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="tab-pane" :id="'office'+val.agent.id" role="tabpanel" aria-labelledby="settings-tab">
                  <table class="table">
                  <thead>
                    <tr>
                      <th class="text-center">Cant</th>
                      <th class="text-center">Moneda</th>
                      <th class="text-center">Monto</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="o_expense in val.office_expenses" :key="o_expense.id">
                      <td class="text-center">{{o_expense.count}}</td>
                      <td class="text-center">{{o_expense.currency_name}}</td>
                      <td class="text-right">{{o_expense.total}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</template>
<script>
import AgentService from "@/service/AgentService";

export default {
  data() {
    return {
      agents: []
    };
  },

  async created() {
    await AgentService.get("0").then(resp => (this.agents = resp.data));
  },

  methods:{
    setImage(img){
      return process.env.VUE_APP_AXIOS_PUBLIC_URL+'storage/agents/'+img
    }
  },
};
</script>