<template>
  <div class="row">
    <div class="col-sm-6">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="form-group col-sm-12">
              <label for="customer_name">Nombre del Cliente</label>
              <input id="customer_name" type="text" class="form-control" v-model="data.name" />
            </div>
            <div class="form-group col-sm-12">
              <label for="document_type">Tipo Documento</label>
              <input id="document_type" type="text" class="form-control" v-model="data.document_type_name" />
            </div>
            <div class="form-group col-sm-12">
              <label for="dni">Nro. Documento</label>
              <input id="dni" type="text" class="form-control" v-model="data.dni" />
            </div>
            <div class="form-group col-sm-12">
              <label for="phone">Número de Teléfono</label>
              <input id="phone" type="text" class="form-control" v-model="data.phone" />
            </div>
            <div class="form-group col-sm-12">
              <label for="user">Email</label>
              <input id="user" type="text" class="form-control" v-model="data.email" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EventBus from "@/event-bus.js";
export default {
    name:'CustomerView',
    data(){
        return {
            data:'',
        }
    },

    created(){
        EventBus.$on("setDataCustomerView", this.setData);
    },

    methods:{
        setData(data){
           this.data = data;
        }
    }
}
</script>