<template>
  <div>
    <br>
      <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary"><router-link tag="a" to="/">Tablero</router-link></li>
        <li class="breadcrumb-item active" aria-current="page">Listado de Ordenes</li>
      </ol>
    </nav>
    <div class="table-responsive">
      <table v-if="display_table">
        <thead>
          <tr> 
            <th class="text-center"> <button type="button" class="btn btn-sm btn-primary" @click="showSearch">
            <i class="material-icons">search</i>
          </button></th>
            <th class="text-center th-min">Cod. Orden</th>
            <th>Tipo</th>
            <th class="text-center th-min">Estatus</th>
            <th class="text-center th-min">Fecha</th>
            <th class="text-center th-min">Cliente</th>
            <th class="text-center th-min">Moneda</th>
            <th class="text-center th-min">M. Recibido</th>
            <th class="text-center th-min">M. Cambio</th>
            <th class="text-center th-min"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(val, index) in orders" :key="index">
            <td class="text-center">{{index + 1}}</td>
            <td class="jj-center-align">
              <router-link
                :to="{path:'/admin/orders/detail/'+val.id}"
                class="btn-small text-primary"
              >{{val.order_code}}</router-link>
            </td>
            <td class="jj-center-align">{{val.order_type.name}}</td>
            <td class="jj-center-align">{{val.status.name}}</td>
            <td class="jj-center-align">{{val.created_at}}</td>
            <td class="jj-center-align">{{val.user_send.name}}</td>
            <td class="jj-center-align">{{val.currency.name}}</td>
            <td class="jj-right-align">{{val.amount}}</td>
            <td class="jj-right-align">{{val.amount_to_pay}}</td>
            <td class="jj-center-align" v-if="val.status.id !=15">
              <i
                v-if="val.on_change"
                class="material-icons tooltipped"
                data-position="left"
                data-tooltip="En Modificación"
              >hourglass_empty</i>
            </td>
            <td v-else></td>
          </tr>
        </tbody>
      </table>
    </div>
    <Search v-if="display_search"/>
  </div>
</template>
<script>
import EventBus from "@/event-bus.js";
import OrderService from "@/service/OrderService";
import Search from "@/components/SearchModalView";

export default {
  name: "order-list-view",
  components: {
    Search,
  },
  data() {
    return {
      display_table: true,
      display_search:false,
      orders: []
    };
  },
  created() {
    EventBus.$on("searchRange", this.searchRange);

    EventBus.$on("searchOrder", this.searchOrder);
    
    EventBus.$on("hideSearch", this.hideSearch);

    OrderService.get().then(resp => (this.orders = resp.data));
  },

  methods: {
    hideSearch(){
      this.display_search = false;
      this.display_table = true;
    },
    searchRange(data) {
      this.display_search = false;
      this.display_table = true;
      OrderService.getRange(data).then(resp => (this.orders = resp.data));
    },

    searchOrder(data) {
      this.display_search = false;
      this.display_table = true;
      OrderService.getOrder(data).then(resp => (this.orders = resp.data));
    },
    showSearch() {
      this.display_search = true;
      this.display_table = false;
      /* this.$modal.show("search", { search_module: "orders" }); */
    }
  }
};
</script>