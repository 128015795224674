<template>
    <div>
        <Owner v-if="owner" :id="id" :detail="transaction"/>
        <Thirdparty v-if="thirdparty" :id="id" :detail="transaction"/>
    </div>
</template>
<script>
import EventBus from "@/event-bus.js";
import TransactionService from '@/service/TransactionService';
import Owner from '@/components/admin/transaction/TransactionOwnerView';
import Thirdparty from '@/components/admin/transaction/TransactionThirdpartyView';

export default {
    props:["id"],

    components:{
        Owner,
        Thirdparty,
    },

    data(){
        return {
            owner:false,
            thirdparty:false,
            transaction:null,
        }
    },

    async created(){
        await TransactionService.getById(this.id)
        .then(resp => {
            
            if(resp.data.concept_operation_id == 5){
                this.owner = true;
                
            }else{
                this.thirdparty = true;
            }

            this.transaction = resp.data;
        })

        

    }
    
}
</script>