<template>
    <div>
        <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item" aria-current="page">
            Listado de Usuarios
        </li>
      </ol>
    </nav>
        <div class="mt-2">
            <router-link class="btn btn-small btn-primary" tag="a" :to="{path:'users/register'}">Nuevo Usuario</router-link>
        </div>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th class="text-center th-min">Nombre</th>
                        <th class="text-center th-min">Email</th>
                        <th class="text-center th-min">Agente</th>
                        <th class="text-center th-min">Role</th>
                        <th class="text-center th-min">Estatus</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="val in users" :key="val.id">
                        <td>
                             <router-link tag="a" :to="{path:'users/edit/'+val.id}" class="text-primary">
                                {{val.name}}
                            </router-link>
                            </td>
                        <td>{{val.email}}</td>
                        <td>{{(val.agent) ? val.agent.name : 'N/D'}}</td>
                        <td>{{(val.role) ? val.role.name : 'N/D'}}</td>
                        <td  class="text-center">{{(val.status_fk_id == 1) ? 'ACTIVO' : 'INACTIVO'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    
    data(){
        return {
            users:[],
        }
    },

    mounted(){

        this.getUsers();
    },

    methods:{


        getUsers(){
            axios.get('users')
            .then(response => {
                this.users = response.data;
            })
            .catch(error => {
               console.log(error)
            })
        }
    }
}
</script>

<style scoped>

</style>