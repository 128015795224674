<template>
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <br>
      <div class="row">
        <div class="col-12">
          <div class="card border-primary">
            <div class="card-header text-primary">
             <div class="row">
               <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5">
                  <h3>Ingreso en Cuentas</h3>
               </div>
              
             </div>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table table-stripe">
                  <thead>
                    <tr>
                      <th class="text-center th-min"></th>
                      <th class="text-center th-min">Estatus</th>
                      <th class="text-center th-min">SOL</th>
                      <th class="text-center th-min">DOLAR</th>
                      <th class="text-center th-min">EUROS</th>
                    </tr>
                  </thead>
                  <tbody v-if="!loading">
                   
                    <tr>
                      <td>Cuentas Bancarias</td>
                      <td>Remesas Confirmadas</td>
                      <td
                        class="text-right"
                      >{{incomeAmount.operationNumberWithOrder.pen | numFormat('0,0.00')}}</td>
                      <td
                        class="text-right"
                      >{{incomeAmount.operationNumberWithOrder.usd | numFormat('0,0.00')}}</td>
                      <td
                        class="text-right"
                      >{{incomeAmount.operationNumberWithOrder.eur | numFormat('0,0.00')}}</td>
                    </tr>
                   
                    <tr>
                      <td>Cuentas Bancarias</td>
                      <td>Remesas no Confirmadas</td>
                      <td
                        class="text-right"
                      >{{incomeAmount.orderWithoutConfirm.pen | numFormat('0,0.00')}}</td>
                      <td
                        class="text-right"
                      >{{incomeAmount.orderWithoutConfirm.usd | numFormat('0,0.00')}}</td>
                      <td
                        class="text-right"
                      >{{incomeAmount.orderWithoutConfirm.eur | numFormat('0,0.00')}}</td>
                    </tr>
                   
                    <tr>
                      <td>Puntos de Venta</td>
                      <td>Confirmado</td>
                      <td class="text-right">{{incomeAmount.orderPdv.pen | numFormat('0,0.00')}}</td>
                      <td class="text-right">{{incomeAmount.orderPdv.usd | numFormat('0,0.00')}}</td>
                      <td class="text-right">{{incomeAmount.orderPdv.eur | numFormat('0,0.00')}}</td>
                    </tr>
                    <tr>
                      <td>Caja Callao</td>
                      <td>Confirmado</td>
                      <td
                        class="text-right"
                      >{{incomeAmount.orderAgentCallao.pen | numFormat('0,0.00')}}</td>
                      <td
                        class="text-right"
                      >{{incomeAmount.orderAgentCallao.usd | numFormat('0,0.00')}}</td>
                      <td
                        class="text-right"
                      >{{incomeAmount.orderAgentCallao.eur | numFormat('0,0.00')}}</td>
                    </tr>
                    <tr>
                      <td>Caja Lima</td>
                      <td>Confirmado</td>
                      <td
                        class="text-right"
                      >{{incomeAmount.orderAgentLima.pen | numFormat('0,0.00')}}</td>
                      <td
                        class="text-right"
                      >{{incomeAmount.orderAgentLima.usd | numFormat('0,0.00')}}</td>
                      <td
                        class="text-right"
                      >{{incomeAmount.orderAgentLima.eur | numFormat('0,0.00')}}</td>
                    </tr>
                     <tr>
                      <td>Caja Surco</td>
                      <td>Confirmado</td>
                      <td
                        class="text-right"
                      >{{incomeAmount.orderAgentSurco.pen | numFormat('0,0.00')}}</td>
                      <td
                        class="text-right"
                      >{{incomeAmount.orderAgentSurco.usd | numFormat('0,0.00')}}</td>
                      <td
                        class="text-right"
                      >{{incomeAmount.orderAgentSurco.eur | numFormat('0,0.00')}}</td>
                    </tr>
                   
                  </tbody>
                  <tfoot v-if="!loading" class="bg-success text-white">
                    <tr>
                      <td colspan="2" class="text-right">TOTALES</td>
                      <td class="text-right">{{incomeAmount.total.pen | numFormat('0,0.00')}}</td>
                      <td class="text-right">{{incomeAmount.total.usd | numFormat('0,0.00')}}</td>
                      <td class="text-right">{{incomeAmount.total.eur | numFormat('0,0.00')}}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="row pt-3">
        <div class="col-12">
          <div class="card border-primary">
            <div class="card-header text-primary">
              <h3>Ordenes</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div class="card">
                    <div class="card-header">
                      <h3>Día de Hoy</h3>
                    </div>
                    <ul v-if="!loading" class="list-group">
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <h5>
                              Recibidas
                              <span class="badge badge-secondary">{{(statusOrders.received) ? statusOrders.received.count : 0}}</span>
                            </h5>
                          </div>
                          <div
                            class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right"
                          >{{(statusOrders.received) ? statusOrders.received.amount : 0 | numFormat('0,0.00')}}</div>
                        </div>
                      </li>
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <h5>
                              Pendientes
                              <span class="badge badge-warning">{{(statusOrders.pending) ? statusOrders.pending.count : 0}}</span>
                            </h5>
                          </div>
                          <div
                            class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right"
                          >{{(statusOrders.pending) ? statusOrders.pending.amount : 0 | numFormat('0,0.00')}}</div>
                        </div>
                      </li>
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <h5>
                              P. de Pago
                              <span class="badge badge-primary">{{(statusOrders.payment_p) ? statusOrders.payment_p.count : 0}}</span>
                            </h5>
                          </div>
                          <div
                            class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right"
                          >{{(statusOrders.payment_p) ? statusOrders.payment_p.amount : 0 | numFormat('0,0.00')}}</div>
                        </div>
                      </li>
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <h5>
                              Pagadas
                              <span class="badge badge-success">{{(statusOrders.paid) ? statusOrders.paid.count : 0}}</span>
                            </h5>
                          </div>
                          <div
                            class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right"
                          >{{(statusOrders.paid) ? statusOrders.paid.amount : 0 | numFormat('0,0.00')}}</div>
                        </div>
                      </li>
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <h5>
                              Reportadas
                              <span class="badge badge-danger">{{(statusOrders.reported) ? statusOrders.reported.count : 0}}</span>
                            </h5>
                          </div>
                          <div
                            class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right"
                          >{{(statusOrders.reported) ? statusOrders.reported.amount : 0 | numFormat('0,0.00')}}</div>
                        </div>
                      </li>
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <h5>
                              Anuladas
                              <span class="badge badge-danger">{{(statusOrders.annulled) ? statusOrders.annulled.count : 0}}</span>
                            </h5>
                          </div>
                          <div
                            class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right"
                          >{{(statusOrders.annulled) ? statusOrders.annulled.amount : 0 | numFormat('0,0.00')}}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div class="card">
                    <div class="card-header">
                      <h3>Días Previos</h3>
                    </div>
                    <ul v-if="!loading" class="list-group">
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <h5>
                              Recibidas
                              <span class="badge badge-secondary">{{(statusOrders.p_received) ? statusOrders.p_received.count : 0}}</span>
                            </h5>
                          </div>
                          <div
                            class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right"
                          >{{(statusOrders.p_received) ? statusOrders.p_received.amount : 0 | numFormat('0,0.00')}}</div>
                        </div>
                      </li>
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <h5>
                              Pendientes
                              <span class="badge badge-warning">{{(statusOrders.p_pending) ? statusOrders.p_pending.count : 0}}</span>
                            </h5>
                          </div>
                          <div
                            class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right"
                          >{{(statusOrders.p_pending) ? statusOrders.p_pending.amount : 0 | numFormat('0,0.00')}}</div>
                        </div>
                      </li>
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <h5>
                              P. de Pago
                              <span class="badge badge-primary">{{(statusOrders.p_payment_p) ? statusOrders.p_payment_p.count : 0}}</span>
                            </h5>
                          </div>
                          <div
                            class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right"
                          >{{(statusOrders.p_payment_p) ? statusOrders.p_payment_p.amount : 0 | numFormat('0,0.00')}}</div>
                        </div>
                      </li>
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <h5>
                              Pagadas
                              <span class="badge badge-success">{{(statusOrders.p_paid) ? statusOrders.p_paid.count : 0}}</span>
                            </h5>
                          </div>
                          <div
                            class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right"
                          >{{(statusOrders.p_paid) ? statusOrders.p_paid.amount : 0 | numFormat('0,0.00')}}</div>
                        </div>
                      </li>
                      <li class="list-group-item">
                        <div class="row">
                          <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                            <h5>
                              Reportadas
                              <span class="badge badge-danger">{{(statusOrders.p_reported) ? statusOrders.p_reported.count : 0}}</span>
                            </h5>
                          </div>
                          <div
                            class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-right"
                          >{{(statusOrders.p_reported) ? statusOrders.p_reported.amount : 0 | numFormat('0,0.00')}}</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row pt-3">
        <div class="col-12">
          <div class="card border-primary">
            <div class="card-header text-primary">
              <h3>Bolivares Pendientes</h3>
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                      <tr>
                          <th class="text-center th-min">Cant. Ordenes</th>
                          <th class="text-center th-min">Estatus</th>
                          <th class="text-center th-min">Banco</th>
                          <th class="text-center th-min">Por Pagar (Bs)</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="(val, index) in bolivares" :key="index">
                          <td class="text-center">{{val.count}}</td>
                          <td class="text-center">{{val.status_name}}</td>
                          <td class="text-left">{{val.bank_name}}</td>
                          <td class="text-right">{{val.amount | numFormat('0,0.00')}}</td>
                      </tr>
                  </tbody>
                  <tfoot>
                      <tr class="bg-success">
                          <td colspan="3" class="text-right text-white">TOTAL</td>
                          <td class="text-right"><span class="text-white">{{showTotal | numFormat('0,0.00')}}</span></td>
                      </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import axios from 'axios'
import Rate from "@/modules/Rate";
import AdminService from "@/service/AdminService";

export default {
  name: "admin-dashboard",

  components: {},

  data() {
    return {
      loading: true,
      rate: "",
      balance:"",
      bolivares: [],
      incomeAmount: {},
      statusOrders: {},
      statusCashdrafts: []
    };
  },

  async created() {
    await AdminService.report
      .get("?opt=sbdb")
      .then(resp => (this.incomeAmount = resp.data));
    await AdminService.report
      .get("?opt=sodb")
      .then(resp => (this.statusOrders = resp.data));

    this.loading = false;

    this.getPendingPayment()

    this.loading = false;
  },

  computed:{
    showTotal(){
        let total = 0.00;
        if(this.bolivares){
            this.bolivares.forEach(function(val){
                total += parseFloat(val.amount);
            })

            return total.toFixed(2);
        }

        return total;
    }
},

  methods: {

    getPendingPayment(){
        axios.get('admin/payments/pending') //Ordenes pendientes por pagar
        .then(response => {

            this.bolivares = response.data;
        })
    },

  }
};
</script>
