<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item text-primary" aria-current="page">
           <router-link tag="a" to="/expenses">Lista Vales</router-link>
        </li>
        <li class="breadcrumb-item" aria-current="page">
            Detalle
        </li>
      </ol>
    </nav>
    <div class="row mt-2">
      <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-6">
        <div class="card">
          <div class="card-body">
            <div class="form-group">
              <label for="employes" class="active">Solicitante</label>
              <select id="employes" v-model="employe" :disabled="(id) ? true : false" class="form-control">
                <option value>SELECCIONE</option>
                <option v-for="val in employesFilter" :key="val.id" :value="val.id">{{'['+val.agent_name+'] '+val.name}}</option>
              </select>
            </div>
            <div class="form-group">
              <label for="concept" class="active">Concepto</label>
              <input type="text" id="concept" v-model="concept" :disabled="(id) ? true : false" class="form-control"/>
            </div>
            <div v-if="id" class="form-group">
              <label for="currency_id" class="active">Moneda</label>
              <input type="text" id="currency_id" v-model="currency_name" :disabled="(id) ? true : false" class="form-control"/>
            </div>
            <div class="form-group">
              <label for="amount" class="active">Monto</label>
              <input type="text" id="amount" v-model="amount" :disabled="(id) ? true : false" class="form-control"/>
            </div>
            <div class="form-group text-right">
              <a v-if="!registered && !id" href="#" class="btn btn-small btn-success" @click.prevent="registerExpense">Registrar</a>
              <a v-if="registered && !id" href="#" class="btn btn-small btn-primary ml-2" @click.prevent="newExpense">Nuevo</a>
              <a v-if="id && expense.confirmed == 0 && expense.status_id == 18" href="#" class="btn btn-small btn-danger ml-2" @click.prevent="deleteExpense">Anular</a>
              <a v-if="id && expense.confirmed == 0 && expense.status_id == 18" href="#" class="btn btn-small btn-success ml-2" @click.prevent="confirmExpense">Confirmar</a>
              <a v-if="id && expense.confirmed == 1 && expense.status_id == 18" href="#" class="btn btn-small btn-primary ml-2" @click.prevent="cancellExpense">Cancelar Vale</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {

    props:["id"],

  data(){
    return {
      registered: false,
      search_filter:'',
      expense:'',
      employe:'',
      concept:'',
      currency_name:'',
      amount:'',
      token:'',
      employes:[],
    }
  },
/* 
 created() {
    if (this.$auth.user().role_name != "admin") {
        window.M.toast({ html: "Perfil no autorizado" });
        this.$auth.logout();
    }
  }, */
mounted() {

    if(this.id){
        this.getExpense(); 
               
        //window.M.toast({html:this.id});
    }

    this.getEmployes();

  },

  computed:{

    employesFilter(){

/*       if(this.employes){

          if(this.search_filter.trim() != ''){
            return this.employes.filter(e => e.name.toLocaleLowerCase().includes(this.search_filter.toLocaleLowerCase()))
          }
      } */
      return this.employes;
    }
  },


  methods: {

    showAlert(tittle, msg, type) {
      // Use sweetalert2
      this.$swal.fire({
        type: type, //warning, error, success, info, and question
        title: tittle,
        text: msg,
        footer: ""
      });
    },

    newExpense(){
      this.search_filter = '';
      this.employe = '';
      this.concept = '';
      this.amount = '';

      this.registered = false;
    },

    registerExpense(){

      let data = {
         user_request_id: this.employe,
         payment_method_id: 2,
         concept: this.concept,
         amount: this.amount,
      }

      if(this.employe == ''){
        return window.M.toast({html:'Seleccione el empleado'});
      }else if(this.concept == ''){
        return window.M.toast({html:'Ingrese el concepto del vale'});
      }else if(this.amount == ''){
        return window.M.toast({html:'Ingrese el monto del vale'});
      }

      axios.post('admin/expenses/register', data)
      .then(response => {
        this.registered = true;
        swal("Informacion",response.data.msg, "success")
      })
      .catch(error => {
        if (error.response.status == 403) {
             alert("La operacion no puede ser procesada")
            this.stat_registering = false;
          }else{

            alert(error.response.data.msg)
          }
      })
    },

    deleteExpense(){
      axios.post('agent/expenses/annulment', {id:this.id})
      .then(response => {
          swal("Informacion",response.data.msg, "success")
          this.$router.push({ path: '/expenses' })
      })
      .catch(error => {
        if (error.response.status == 403) {
             alert("La operacion no puede ser procesada")
            this.stat_registering = false;
          }else{

            alert(error.response.data.msg)
          }
      })
    },

    confirmExpense(){
      axios.post('admin/expenses/confirm', {id:this.id})
      .then(response => {
         swal("Informacion",response.data.msg, "success")
          
          this.$router.push({ path: '/expenses' })
      })
      .catch(error => {
         if (error.response.status == 403) {
             alert("La operacion no puede ser procesada")
            this.stat_registering = false;
          }else{

            alert(error.response.data.msg)
          }
      })
    },

    cancellExpense(){
      axios.post('admin/expenses/cancell', {id:this.id})
      .then(response => {
         swal("Informacion",response.data.msg, "success")
          
          this.$router.push({ path: '/expenses' })
      })
      .catch(error => {
        if (error.response.status == 403) {
             alert("La operacion no puede ser procesada")
            this.stat_registering = false;
          }else{

            alert(error.response.data.msg)
          }
      })
    },

    

    getEmployes(){

        axios.get('agent/employes')
        .then(response => {
          this.employes = response.data;
        })
    },

    getExpense(){
      axios.get('admin/expenses/'+this.id)
      .then(response => {
        this.expense = response.data
        this.employe = response.data.user_request_id;
        this.concept = response.data.concept;
        this.currency_name = response.data.currency_name;
        this.amount = parseFloat(response.data.amount).toFixed(2);
      })
    }
  }
};
</script>

<style scoped>
</style>