<template>
  <modal
    name="payment-modal"
    :width="500"
    height="auto"
    :scrollable="true"
    @before-open="beforeOpen"
    @opened="opened"
  >
    <div class="content-modal">
      <h4 class="center-align np-nm">Formulario: Pago</h4>
      <hr />
      <div class="section">
        
        <div class="row np-nm">
          <div class="input-field col s12">
            <label for="ca" class="active">Cuenta a debitar</label>
            <select v-model="account" id="ca">
              <option value>SELECCIONE</option>
              <option v-for="(val, index) in accounts" :key="index" :value="val.id">{{val.name}}</option>
            </select>
          </div>
        </div>
        <div class="row np-nm">
          <div class="input-field left-align col s12 np-nm">
            <label for="nc">Nro. Confirmación</label>
            <input type="text" id="nc" v-model="confirmation_number" />
          </div>
        </div>
        <div class="row np-nm">
          <div class="file-field col s12">
            <div class="btn-small">
              <span>Voucher</span>
              <input
                type="file"
                ref="picture"
                v-on:change="handlePictureUpload()"
              />
            </div>
            <div class="file-path-wrapper">
              <input class="file-path validate" type="text" />
            </div>
          </div>
        </div>
        <div class="row np-nm">
          <div class="input-field offset-s6 col s6 np-nm">
            <label for class="active">Total Ordenes</label>
            <input class="right-align" type="text" :value="(orders) ? orders.length: 0" disabled />
          </div>
        </div>
        <div class="row np-nm">
          <div class="input-field col offset-s6 s6 np-nm">
            <label class="active">Tota Bs. a Pagar</label>
            <input class="right-align" type="text" :value="showTotal | numFormat('0,0.00')" disabled />
          </div>
        </div>
        <div class="row np-nm">
          <div class="col s12 np-nm right-align pa">
            <a href="#" class="btn-small blue" @click="processOrders">Procesar</a>
            <a href="#" class="btn-small orange" @click="exportExcel">Exportar</a>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import XLSX from "xlsx";
var numeral = require('numeral');
export default {
  data() {
    return {
      account: "",
      confirmation_number: "",
      orders: [],
      accounts: [],
    };
  },
  mounted() {
  },
  updated() {
  },
  computed: {
    showTotal: function() {
      let total = 0;

      if (this.orders) {
        this.orders.forEach(function(order, index) {
          total += parseFloat(order.amount_to_pay);
        });

        return total;
      }
      return 0;
    }
  },
  methods: {
    beforeOpen(event) {
      this.orders = event.params.orders;

      this.getAccounts();
    },

    opened() {
    },

    processOrders() {
      if (this.account == "") {
        return window.M.toast({ html: "Debe seleccionar la cuenta a debitar" });
      }

      if (this.confirmation_number == "") {
        return window.M.toast({ html: "Debe ingresar el número de confirmación" });
      }

      let orders_data = this.orders.map(o => {
        return {
          id: o.id
        }
      });

      let formData = new FormData();
      formData.append("account", this.account);
      formData.append("orders", JSON.stringify(orders_data));
      formData.append("confirmation_number", this.confirmation_number);
      formData.append("voucher", this.voucher);

      axios
        .post("payment/orders/process", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          M.toast({ html: response.data.msg });
            this.$emit('refresh');
            this.$emit('setCheck', false);
            this.$modal.hide('payment-modal');
        })
        .catch(error => {
          window.M.toast({html:error.response.data.msg});
        });
    },

    exportExcel() {
      let orders_to_pay = this.orders.map(order => {
        let document_type_name = "";
        switch (order.document_type_name) {
          case "CEDULA":
             if(order.nationality_id && order.nationality_id == 1){

                document_type_name = "V";
               }else if(order.nationality_id != null){
                document_type_name = "E";
              }else{
                document_type_name = "NULL";
              }
            break;

          case "RIF":
            document_type_name = "J";
            break;

          case "PASAPORTE":
            document_type_name = "P";
            break;
        }

        return {
          order_code: order.order_code,
          amount_to_pay: order.amount_to_pay,
          account_type_name: document_type_name + order.dni,
          holder: order.holder,
          email: order.email,
          account_dni: order.account_dni,
          bank_name: order.bank_name
        };
      });
      let data = XLSX.utils.json_to_sheet(orders_to_pay, {
        header: [
          "order_code",
          "amount_to_pay",
          "account_type_name",
          "holder",
          "email",
          "account_dni",
          "bank_name"
        ]
      });

      data["A1"].v = "Referencia del Credito";
      data["B1"].v = "Monto a Pagar";
      data["C1"].v = "R.I.F.";
      data["D1"].v = "Nombre del Proveedor";
      data["E1"].v = "Correo Electronico del Proveedor";
      data["F1"].v = "Numero de Cuenta a Acreditar";
      data["G1"].v = "Banco Receptor";

      const workbook = XLSX.utils.book_new();
      const filename = "Ordenes";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },

    getAccounts() {
      axios
        .post("payment/accounts/country", {
          country: "venezuela",
          owner: true
        })
        .then(response => {
          this.accounts = response.data.map(account => {
            return {
              id: account.id,
              name: account.name,
              holder: account.holder,
              account_dni: account.account_dni
            };
          });
        });
    }
  }
};
</script>

<style scoped>
.np-nm {
  padding: 5px;
  margin: 0;
}

.pa a {
  margin-left: 10px;
}
</style>