import Service from '@/service/Service';

const resourceCountry   = 'admin/countries';
const resourcePaySystem = 'admin/pay-systems';
const resourceAccountType = 'admin/account-types';
const resourceBank = 'admin/banks';
const resourceCurrency = 'admin/currencies';
const resourceDocumentType = 'admin/document-types';
const resourceConceptsOperation = 'admin/concepts-operation';
const resourcePM = 'payment-methods';

export default {

 country : {
    get(){
        return Service.get(resourceCountry)
    }
},

 paySystem : {
    get(){
        return Service.get(resourcePaySystem)
    }
},

accountType : {
    get(){
        return Service.get(resourceAccountType)
    }
},

bank : {
    get(query){
        let qry = (query) ? query : '';
        return Service.get(resourceBank+qry)
    }
},

Currency : {
    get(){
        return Service.get(resourceCurrency)
    }
},

documentType : {
    get(){
        return Service.get(resourceDocumentType)
    }
},

conceptOperation : {
    get(){
        return Service.get(resourceConceptsOperation)
    }
},

PaymentMethod : {
    get(){
        return Service.get(resourcePM)
    }
}

}
/* export default {
    country,
    paySystem,
    accountType,
    bank,
    currency,
    documentType
} */