<template>
  <div>
    <br />
    <!-- <div class="card">
      <div class="card-body">
        <div class="form-group row">
          <label for="inputEmail3" class="col-sm-1 col-form-label">Email</label>
          <div class="col-sm-2">
            <input type="email" class="form-control" id="inputEmail3" />
          </div>
          <label for="inputEmail3" class="col-sm-1 col-form-label">Email</label>
          <div class="col-sm-2">
            <input type="email" class="form-control" id="inputEmail3" />
          </div>
        </div>
      </div>
    </div> -->
    <table class="table-hover table-responsive-lg table-striped">
        <thead>
            <tr>
                <th class="text-center"><button class="btn btn-sm btn-primary"><i class="material-icons">search</i></button></th>
                <th class="text-center">Moneda</th>
                <th class="text-center">TRM</th>
                <th class="text-center">% Tasa</th>
                <th class="text-center">M. Tasa</th>
                <th class="text-center">T. Cambista</th>
                <th class="text-center">T. Mayorista</th>
                <th class="text-center">T. Detal</th>
                <th class="text-center">Compra</th>
                <th class="text-center">Venta</th>
                <th class="text-center">Rentabilidad</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="val in rates" :key="val.id">
                <td class="text-center" colspan="2">SOL</td>
                <td class="text-center">{{val.trm}}</td>
                <td class="text-center">{{val.percentage_country_rate}}</td>
                <td class="text-center">{{val.rate_exchange_country}}</td>
                <td class="text-center">{{val.moneychanger_rate}}</td>
                <td class="text-center">{{val.wholesale_rate}}</td>
                <td class="text-center">{{val.retail_rate}}</td>
                <td class="text-center">{{val.purchase_rate}}</td>
                <td class="text-center">{{val.sale_rate}}</td>
                <td class="text-center">{{val.profitability}}</td>
            </tr>
        </tbody>
    </table>
  </div>
</template>
<script>
import Rate from '@/modules/Rate'
export default {
    data(){
        return {
            rates:[]
        }
    },

    mounted(){
        Rate.getHistoryMonth().then(resp => this.rates = resp.data)
    }
};
</script>