import Service from '@/service/Service';

const resource = 'admin/accounts';

export default {

    get(query){
        let url_query = (query) ? query : '';
        return Service.get(resource+url_query);
    },
    
    getById(id){
        return Service.get(resource+"/"+id);
    },

    post(data, qry){
        let query = (qry) ? qry : ''
        return Service.post(resource+query, data);
    },

    put(data, id, qry){
        let query = (qry) ? qry : ''
        return Service.put(resource+"/"+id+query, data);
    },

    delete(id){
        return Service.delete(resource+"/"+id);
    }

}