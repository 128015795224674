<template>
  <ul class="list-unstyled components">
    <!-- <p>Menu Principal</p> -->
    <li><router-link to="/">Tablero</router-link></li>
    <li><router-link to="/compras">Compras</router-link></li>
    <li><router-link to="/confirm/pagos">Pagos</router-link></li>
    <li><router-link to="/admin/accounts/owner">Cuentas</router-link></li>
    <li><router-link to="/confirm/ingresos-cuenta">Ingresos</router-link></li>
    <li><router-link to="/confirm/transferencias">Transferencias</router-link></li>
    <li><router-link to="/confirm/operation-numbers">Numeros de Operación</router-link></li>
    <li><router-link to="/confirm/depositos">Ordenes (Nuevo)</router-link></li>
    <li><router-link to="/confirm/payment-orders">Ordenes (Viejo)</router-link></li>
    <li><router-link to="/confirm/payment-agents">Abonos</router-link></li>
    <li><router-link to="/expenses">Vales</router-link></li>
    <li><router-link to="/office/expenses">Gastos</router-link></li>
    <li><a href="#" @click.prevent="logout">Cerrar Sesión</a></li>
  </ul>
</template>
<script>
const $ = require("jquery");
// Lo declaramos globalmente
window.$ = $;

export default {
  methods: {
     logout() {
      this.$auth.logout();
      this.$router.push({
        path:'/login'
      });
      $("#sidebar").toggleClass("active");
      //location.reload();
    },
  },
};
</script>