<template>
  <tr>
    <td class="text-center">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="gridCheck"/>
        <label class="form-check-label" for="gridCheck"> Check me out </label>
      </div>
    </td>
    <td class="text-center">
      <span
        class="badge"
        :class="{
          'badge-warning': order.status_id == 11,
          'badge-primary': order.status_id == 12,
          'text-white': order.status_id == 12,
        }"
        >{{ order.status_name }}</span
      >
    </td>
    <td class="text-center">{{ order.order_date }}</td>
    <td class="text-center">{{ order.time }}</td>
    <td class="text-center">
      <router-link tag="a" :to="'/order/detail/' + order.id">{{
        order.order_code
      }}</router-link>
    </td>

    <td class="text-left">{{ order.bank_name }}</td>
    <td class="text-right">{{ order.amount_to_pay | numFormat("0,0.00") }}</td>
  </tr>
</template>

<script>
import axios from "axios";
export default {
  props: ["data", "check_all"],
  data() {
    return {
      checked: false,
      order: this.data,
    };
  },

  methods: {
    orderDetail(id) {
      this.$router.push({ path: "/order/detail/" + id });
    },

    addOrder() {
      axios
        .post("payment/orders/select-to-pay", { id: this.order.id })
        .then((response) => {
          if (response.data.success) {
            this.checked = true;
            /* this.order.status_name = "PROCESO DE PAGO";
                    this.order.status_slug == 'process' */
            this.order = "";
            this.order = response.data.order;
            this.$emit("addOrder", this.order.id);
            //this.$emit('refresh')
          } else {
            window.M.toast({
              html:
                "Remesa " + this.order.order_code + " no puede ser procesada.",
            });
          }
        })
        .catch((error) => {
          window.M.toast({
            html:
              "Remesa " + this.order.order_code + " no puede ser procesada.",
          });
        });
    },

    deleteOrder() {
      axios
        .post("payment/orders/unselect-to-pay", { id: this.order.id })
        .then((response) => {
          if (response.data.success) {
            this.checked = false;
            this.order.status_name = "PENDIENTE";
            this.order.status_slug == "pending";
            this.$emit("deleteOrder", this.order.id);
            //this.$emit('refresh')
          }
        });
    },
    unlockOrder() {
      axios
        .post("payment/orders/unselect-to-pay", { id: this.order.id })
        .then((response) => {
          if (response.data.success) {
            this.checked = false;
            window.M.toast({ html: "Remesa ha sido liberada" });
            this.$emit("deleteOrder", this.order.id);
            this.$emit("refresh");
          }
        });
    },

    showReportOrder() {
      this.$emit("showReportOrder", this.order);
    },
  },

  watch: {
    check_all: function (new_val, old_val) {
      if (new_val) {
        if (
          (this.order.status_slug == "process" ||
            this.order.status_slug == "pending") &&
          this.order.on_change == false
        ) {
          this.checked = true;
          this.addOrder();
        }
      } else {
        if (
          (this.order.status_slug == "process" ||
            this.order.status_slug == "pending") &&
          this.$auth.user().id == this.order.user_payment_id
        ) {
          this.checked = false;
          this.deleteOrder();
        }
      }
    },
  },
};
</script>

<style scoped>
.npm {
  padding: 0;
  margin: 0;
}
.np {
  padding: 5px;
  margin: 0;
}
</style>