<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="error-template">
            <h1>Oops!</h1>
            <h2>401 Usuario no Autorizado</h2>
            <div class="error-details">
              Por favor contactar al administrador del sitio.
            </div>
            <div class="error-actions">
              <router-link
                :to="{path:'/'}"
                class="btn btn-primary btn-lg"
                ><span class="glyphicon glyphicon-home"></span> Volver al Tablero </router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.error-template {padding: 40px 15px;text-align: center;}
.error-actions {margin-top:15px;margin-bottom:15px;}
.error-actions .btn { margin-right:10px; }
</style>