<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/admin/transferences">Transferencias</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Terceros</li>
      </ol>
    </nav>
    <div class="container-fluid">
      <div class="btn-group">
        <button v-if="!id" class="btn btn-small btn-primary" @click="registerTransaction">Registrar</button>
         <button
          v-if="id"
          class="btn btn-small btn-danger"
          @click.prevent="deleteTransaction"
        >Anular</button>
      </div>
      
      <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div class="form-group">
                    <label for="owner_account">Cuenta Debito</label>
                    <select
                      v-model="owner_account_id"
                      id="owner_account"
                      class="form-control"
                      @change="setBalance"
                    >
                      <option value>SELECCIONE</option>
                      <option
                        v-for="(val, index) in owner_accounts"
                        :key="index"
                        :value="val.id"
                      >{{val.name}}</option>
                    </select>
                  </div>
                  <div v-if="!id" class="form-group">
                    <label for="owner_account">Balance</label>
                    <input class="form-control" v-model="balance" disabled />
                  </div>
                  <div class="form-group">
                    <label for="thirdparty_account">Cuenta a Acreditar</label>
                    <select
                      v-model="thirdparty_account_id"
                      id="thirdparty_account"
                      class="form-control"
                    >
                      <option value>SELECCIONE</option>
                      <option
                        v-for="(val, index) in thirdparty_accounts"
                        :key="index"
                        :value="val.id"
                      >{{val.name}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="concepts_operation">Concepto</label>
                    <select
                      v-model="concepts_operation_id"
                      id="concepts_operation"
                      class="form-control"
                    >
                      <option value>SELECCIONE</option>
                      <option
                        v-for="(val, index) in concepts_operation"
                        :key="index"
                        :value="val.id"
                      >{{val.name}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="description">Descripción</label>
                    <input class="form-control" v-model="description" id="description" />
                  </div>
                   <div v-if="id" class="form-group">
                    <label for="register_by">Registrado por</label>
                    <input class="form-control" v-model="register_by" id="register_by" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="form-group">
                    <label for="owner_account">Nro. Operación</label>
                    <input class="form-control" v-model="operation_number" />
                  </div>
                  <div class="form-group">
                    <label for="datepicker">Fecha</label>
                    <input type="date" class="form-control" id="datepicker" v-model="date"/>
                  </div>
                  <div class="form-group">
                    <label for="time">Hora</label>
                    <input type="time" id="time" class="form-control" v-model="time" />
                  </div>
                  <div class="form-group">
                    <label for="owner_account">Monto a Transferir</label>
                    <input class="form-control" v-model="amount" />
                  </div>
                  <div class="form-group">
                    <label for="owner_account">Comisión</label>
                    <input class="form-control" v-model="comission" />
                  </div>
                  <div v-if="!id" class="form-group">
                    <label for="file">Voucher de la Operación</label>
                    <input type="file" class="form-control-file" id="file" ref="file" @change="handlePictureUpload">
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AccountService from "@/service/AccountService";
import SelectListService from "@/service/SelectListService";
import TransactionService from "@/service/TransactionService";
/* const $ = require('jquery')
// Lo declaramos globalmente
window.$ = $
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/theme.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/ui/widgets/datepicker.js'; */

export default {
  props: ["id","detail"],
  data() {
    return {
      fk_id:true,
      annlt:true,
      owner_account_id: "",
      balance: "",
      amount: "",
      comission: "",
      operation_number: "",
      date: "",
      time: "",
      voucher:"",
      thirdparty_account_id: "",
      register_by:'',
      owner_accounts: [],
      concepts_operation_id: "",
      description: "",
      thirdparty_accounts: [],
      concepts_operation: []
    };
  },

  async created() {

    await AccountService.get().then(resp => this.owner_accounts = resp.data.data);
    await AccountService.get("?tp=1").then(resp => this.thirdparty_accounts = resp.data.data);
    await SelectListService.conceptOperation.get().then(resp => this.concepts_operation = resp.data);

    if(this.detail){
      this.setData(this.detail);
    }
  },


  methods: {
     handlePictureUpload() {
      this.voucher = this.$refs.file.files[0];
    },

    setBalance() {
      this.balance = "";
      let account = this.owner_accounts.filter(a => a.id == this.owner_account_id)[0];

      if (account) {
        this.balance = account.balance;
      }
    },
    registerTransaction() {
        TransactionService.post("?tt=thirdparty",this.$data)
        .then(resp => {
            swal("Información del Registro", resp.data.message, "success")
        })
        .catch(error => {
            let obj = error.response.data.error.message;
            let message = "";
            for (const prop in obj) {
                message += obj[prop]+"\n";
              //console.log(`obj.${prop} = ${obj[prop]}`);
            }
            swal("Información", message, "error")
        })
    },

     setData(data){
      this.fk_id = data.origin_fk_id;
      this.annlt = data.annulment;
      this.owner_account_id = data.account_id;
      this.amount = data.amount;
      this.comission = data.comission;
      this.operation_number = data.operation_number;
      this.date = data.date;
      this.time = data.time;
      this.voucher = data.voucher;
      this.thirdparty_account_id = data.account_to;
      this.concepts_operation_id = data.concept_operation_id;
      this.description = data.description;
      this.register_by = data.user_name;
      
    },

    deleteTransaction() {
      swal({
        title: "¿Segur@ de anular la transacción?",
        //text: "Once deleted, you will not be able to recover this imaginary file!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          TransactionService.delete(this.id)
          .then(resp => {

            swal("Transacción anulada con exito!", {
              icon: "success",
            });

          })
          .catch(error => {
            let obj = error.response.data.error.message;
            let message = "";
            for (const prop in obj) {
                message += obj[prop]+"\n";
              //console.log(`obj.${prop} = ${obj[prop]}`);
            }
            
            swal("Información", message, "error")
          })

        }
      });
    }
  }
};
</script>