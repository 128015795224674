import TransactionService from '@/service/TransactionService';

export default {

    storage(data){

        return TransactionService.post({
            data
        })
    },

    storageTransactionIncome(data){
        let form_data = new FormData();
        form_data.append("account_id", data.owner_account_id);
        form_data.append("account_to", 0);
        form_data.append("amount", data.amount);
        form_data.append("comission", data.comission);
        form_data.append("operation_number", data.operation_number);
        form_data.append("date", data.date);
        form_data.append("time", data.time);
        form_data.append("voucher", data.voucher);
        form_data.append("concept_operation_id", data.concepts_operation_id);
        form_data.append("purchase_rate", data.purchase_rate);
        form_data.append("description", data.description);

        return TransactionService.postIncome(form_data,'?tt=income');
    },

    registerPayTransaction(query, data){
        let form_data = new FormData();
        form_data.append("account_id", data.owner_account_id);
        form_data.append("account_to", 0);
        form_data.append("amount", data.amount);
        form_data.append("comission", data.comission);
        form_data.append("operation_number", data.operation_number);
        form_data.append("date", data.date);
        form_data.append("time", data.time);
        form_data.append("voucher", data.voucher);
        form_data.append("concept_operation_id", data.concepts_operation_id);
        form_data.append("description", data.description);

        return TransactionService.postPay(query,form_data);
    }
}