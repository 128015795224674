<template>
    <div>
            <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Balance de Cuentas
        </li>
      </ol>
    </nav>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Cuentas en Bolivares</h5> 
                <button v-if="cuentasSeleccionadas.length > 0" class="btn btn-small btn-primary mb-2" @click="visualizarSaldo()">Visualizar Saldo</button>
                <table class="table table-striped">
                    <thead>
                        <th></th>
                        <th>Cuenta</th>
                        <th class="text-center">Saldo</th>
                    </thead>
                    <tbody>
                        <tr v-for="(val, index) in cuentasBolivares" :key="'soles-'+index">
                            <td>
                                <input type="checkbox" :value="val" v-model="cuentasSeleccionadas">
                            </td>
                            <td><router-link tag="a" style="text-decoration: underline;" :to="{path:`/cuentas/detalle-balance/${val.cuenta_id}/${val.moneda_id}/detalle/0`}">{{nombreCuenta(val)}}</router-link></td>
                          <td class="text-right text-primary"><router-link style="text-decoration: underline;" :to="{path:'/cuentas/resumen-balance/'+val.cuenta_id+'/'+val.moneda_id}">{{val.balance | numFormat("0,0.00")}}</router-link></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="3" class="text-right"><h3>{{calcularTotalBs | numFormat("0,0.00")}}</h3></td>
                        </tr>
                    </tfoot>
                </table>
                <!-- {{cuentasSeleccionadas}} -->
                <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Saldo Cuentas Bancarias</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-striped">
                    <thead>
                        <th>Cuenta</th>
                        <th class="text-center">Saldo</th>
                    </thead>
                    <tbody>
                        <tr v-for="(val, index) in cuentasSeleccionadas" :key="'soles-'+index">
                            <td>{{nombreCuenta(val)}}</td>
                           <td class="text-right text-primary">{{val.balance | numFormat("0,0.00")}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="2" class="text-right"><h3>{{calcularTotalBsSelect | numFormat("0,0.00")}}</h3></td>
                        </tr>
                    </tfoot>
                </table>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
            
          </div>
        </div>
      </div>
    </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

    export default {
        
        data(){
            return {
                cuentasSeleccionadas:[],
                seleccionadas:[],
                cuentas:[]
            }
        },

        created(){
            this.getBalance();
        },

        computed:{

            calcularTotalBs(){
                if(this.cuentas.length <= 0) return 0;

                let total = 0;

                this.cuentas.filter(c => c.moneda_id == 1).forEach(val => {
                    total += parseFloat(val.balance);
                });

                return total;
            },

            calcularTotalBsSelect(){
                 if(this.cuentasSeleccionadas.length <= 0) return 0;

                let total = 0;

                this.cuentasSeleccionadas.forEach(val => {
                    total += parseFloat(val.balance);
                });

                return total;
            },

            cuentasBolivares(){
                if(this.cuentas.length <= 0) return [];
                return this.cuentas.filter(c => c.moneda_id == 1);
            }
        },


        methods:{

            visualizarSaldo(){

                this.cuentasSeleccionadas.forEach(idx => {
                    let c = this.cuentas[idx]
                    this.seleccionadas.push(c);
                });

                window.jQuery("#exampleModal").modal("show");
            },

            nombreCuenta(val){
                if(val.cuenta_id == 0){

                    return 'EFECTIVOJJ';
                }

                if(val.cuenta_id == -1){
                    return 'PUNTO DE VENTA';
                }

                if(val.cuenta){
                    return val.cuenta['name'];

                }

                return 'N/D ['+val.cuenta_id+']';

            },

            getBalance(){
                axios.get('/balance-cuentas')
                .then(resp => {
                    this.cuentas = resp.data;
                })
            }
        }
    }
</script>

<style scoped>

</style>