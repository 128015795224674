<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Cuentas de Terceros</li>
      </ol>
    </nav>
    <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th class="text-center th-min">Banco</th>
          <th class="text-center th-min">Nombre de la Cuenta</th>
          <th class="text-center th-min">Titular</th>
          <th class="text-center th-min">Nro. Cuenta</th>
          <th class="text-center th-min">Estatus</th>
          <th class="text-center th-min">Balance</th>
          <th class="text-center th-min"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="val in accounts" :key="val.id">
          <td class="">
            <router-link tag="a" :to="{path:'/admin/accounts/detail/'+val.id}" class="text-primary">{{val.bank.name}}</router-link>
            </td>
          <td class="">{{val.name.substr(0,10)}}</td>
          <td class="">{{val.holder.substr(0,10)}}</td>
          <td class="jj-center-align">{{val.account_dni}}</td>
          <td class="jj-center-align">{{(val.status == 1) ? 'ACTIVA' : 'INACTIVA'}}</td>
          <td class="jj-right-align">{{val.balance}}</td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
</template>
<script>
import AccountService from '@/service/AccountService'

export default {
  
  data(){
    return {
      accounts:[],
    }
  },

  created(){
    AccountService.get('?tp=1')
    .then(resp => this.accounts = resp.data.data)
  }
}
</script>
