<template>
  <div>
     <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Cuentas Nueva</li>
      </ol>
    </nav>
    <div class="container-fluid">
      <div class="btn-group">
        <router-link
          v-if="id"
          tag="a"
          :to="{path:'/admin/accounts/register'}"
          class="btn btn-small btn-secondary"
        >Nuevo</router-link>
        <button v-if="!id" class="btn btn-small btn-primary" @click="registerAccount">Regitrar</button>
        <button
          v-if="id"
          class="btn btn-small btn-success"
          @click.prevent="updateAccount"
        >Actualizar</button>
        <button v-if="id" class="btn btn-small btn-danger" @click.prevent="deleteAccount">Eliminar</button>
      </div>
      <hr />
      <div class="row">
        <div class="col-lg-8">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                  <label for="country">Pais</label>
                  <select v-model="country_id" id="country" class="form-control">
                    <option value>SELECCIONE</option>
                    <option
                      v-for="(val, index) in countries"
                      :key="index"
                      :value="val.id"
                    >{{val.name}}</option>
                  </select>
                </div>
                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                  <label for="pay_system_id" class="control-label">Sistema de Pago</label>
                  <select v-model="pay_system_id" id="pay_system_id" class="form-control">
                    <option value>SELECCIONE</option>
                    <option
                      v-for="(val, index) in pay_systems"
                      :key="index"
                      :value="val.id"
                    >{{val.name}}</option>
                  </select>
                </div>
                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                  <label for="account_type_id" class="active">Tipo de Cuenta</label>
                  <select v-model="account_type_id" id="account_type_id" class="form-control">
                    <option value>SELECCION</option>
                    <option
                      v-for="(val, index) in account_types"
                      :key="index"
                      :value="val.id"
                    >{{val.name}}</option>
                  </select>
                </div>
                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                  <label for="bank_id" class="active">Banco</label>
                  <select v-model="bank_id" id="bank_id" class="form-control">
                    <option value>SELECCIONE</option>
                    <option v-for="(val, index) in FilterBanks" :key="index" :value="val.id">{{val.name}}</option>
                  </select>
                </div>
                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                  <label for="name">Nombre de la Cuenta</label>
                  <input id="name" type="text" v-model="name" class="form-control" required />
                </div>
                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                  <label for="currency_id" class="active">Moneda</label>
                  <select v-model="currency_id" id="c" class="form-control">
                    <option value>SELECCIONE</option>
                    <option
                      v-for="(val, index) in currencies"
                      :key="index"
                      :value="val.id"
                    >{{val.name}}</option>
                  </select>
                </div>
                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                  <label for="document_type_id" class="active">Tipo de Documento</label>
                  <select v-model="document_type_id" id="document_type_id" class="form-control">
                    <option value>SELECCIONE</option>
                    <option
                      v-for="(val, index) in document_types"
                      :key="index"
                      :value="val.id"
                    >{{val.name}}</option>
                  </select>
                </div>
                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                  <label for="num_doc">N# Documento</label>
                  <input
                    type="text"
                    id="num_doc"
                    v-model="dni"
                    @keyup.enter="getCustomerByDni"
                    class="form-control"
                  />
                </div>
                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                  <label for="holder">Titular</label>
                  <input type="text" id="holder" v-model="holder" class="form-control" />
                </div>
                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                  <label for="nationality" class="active">Nacionalidad</label>
                  <select v-model="nationality_id" class="form-control">
                    <option value>SELECCIONE</option>
                    <option
                      v-for="(val, index) in countries"
                      :key="index"
                      :value="val.id"
                    >{{val.of_place}}</option>
                  </select>
                </div>
                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                  <label for="phone">Telefono de Contacto</label>
                  <input type="text" id="phone" v-model="phone" class="form-control" />
                </div>
                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                  <label for="balance">Cuenta de Terceros?</label>
                   <select v-model="thirdparty" id="document_type_id" class="form-control">
                    <option value=0>NO</option>
                    <option value=1>SI</option>
                  </select>
                </div>
                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                  <label for="activate_agent">Listar en Caja</label>
                  <select v-model="activate_cash" id="activate_cash" class="form-control">
                    <option value=0>NO</option>
                    <option value=1>SI</option>
                  </select>
                </div>
                <div class="form-group col-12 col-sm-12 col-md-6 col-lg-6">
                  <label for="activate_cash">Listar en Agentes</label>
                   <select v-model="activate_payment" id="activate_agent" class="form-control">
                    <option value="0">NO</option>
                    <option value="1">SI</option>
                  </select>
                </div>
                <div class="form-group col-12 col-sm-12 col-md-12 col-lg-12">
                  <label for="account_dni">ID Cuenta</label>
                  <input
                    type="text"
                    id="account_dni"
                    v-model="account_dni"
                    class="form-control"
                    required
                    maxlength="20"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectList from "@/service/SelectListService";
import AccountService from "@/service/AccountService";
import Account from "@/modules/Account";

export default {
  props: ["id"],
  data() {
    return {
      country_id: "",
      pay_system_id: "",
      account_type_id: "",
      bank_id: "",
      name: "",
      currency_id: "",
      document_type_id: "",
      dni: "",
      holder: "",
      nationality_id: "",
      phone: "",
      thirdparty: "",
      activate_cash:0,
      activate_payment:0,
      account_dni: "",
      /*  */
      countries: [],
      pay_systems: [],
      account_types: [],
      banks: [],
      currencies: [],
      document_types: []
    };
  },

  async created() {
    
    await SelectList.country.get().then(resp => (this.countries = resp.data));
    await SelectList.paySystem.get().then(resp => (this.pay_systems = resp.data));
    await SelectList.accountType.get().then(resp => (this.account_types = resp.data));
    await SelectList.bank.get().then(resp => (this.banks = resp.data));
    await SelectList.Currency.get().then(resp => (this.currencies = resp.data));
    await SelectList.documentType.get().then(resp => (this.document_types = resp.data));
 
    if (this.id) {
      await AccountService.getById(this.id).then(resp => {
        this.country_id = (resp.data.bank) ? resp.data.bank.country_id : '';
        this.pay_system_id = resp.data.pay_system_id,
        this.account_type_id = resp.data.account_type_id;
        this.bank_id = resp.data.bank_id;
        this.name = resp.data.name;
        this.currency_id = resp.data.currency_id;
        this.document_type_id = resp.data.document_type_id;
        this.dni = resp.data.dni;
        this.holder = resp.data.holder;
        this.nationality_id = resp.data.nationality_id;
        this.phone = resp.data.phone;
        this.thirdparty = resp.data.thirdparty;
        this.activate_cash = resp.data.activate_cash;
        this.activate_payment = resp.data.activate_payment;
        this.account_dni = resp.data.account_dni;
      });
    }
  },

  computed:{
    FilterBanks(){
      let filterData = this.banks

      if(this.country_id != ''){
        filterData = filterData.filter(b => b.country_id == this.country_id)
      }

      return filterData

    }
  },

  methods: {
    registerAccount() {
      Account.storage(this.$data)
        .then(resp => {
          //swal("Informacion de Regitro", resp.data.message, "success");
          alert(resp.data.message)
          this.$router.replace('/admin/accounts/detail/'+resp.data.id)
        })
        .catch(error => {
           let obj = error.response.data.error.message;
            let message = ''
            for (const prop in obj) {
                //console.log(obj[prop]);
                message += obj[prop]
            }
            alert(message)
        });
    },

    updateAccount() { 
      Account.update(this.$data, this.id)
        .then(resp => {
          alert(resp.data.message)
        })
        .catch(error => {
           let obj = error.response.data.error.message;
            let message = ''
            for (const prop in obj) {
                //console.log(obj[prop]);
                message += obj[prop]
            }
            alert(message)
        });
    },

    deleteAccount(){
      AccountService.delete(this.id)
        .then(resp => {
          swal("Información",resp.data.message, "success");
          this.$router.push({path:'/admin/accounts/owner'});
        })
        .catch(error => {
           let obj = error.response.data.error.message;
            let message = ''
            for (const prop in obj) {
                //console.log(obj[prop]);
                message += obj[prop]
            }
            alert(message)
        });
    }
  }
};
</script>
