<template>
  <div>
    <div class="sidebar-header">
      <img src="@/assets/Logo.png" width="220" height="80" />
    </div>
    <MenuCaja v-if="$auth.check(['cash'])" />
    <MenuAdmin v-if="$auth.check(['admin'])" />
    <MenuConfirmacion v-if="$auth.check(['confirm'])" />
    <MenuPagos v-if="$auth.check(['payment'])" />
    <MenuCompras v-if="$auth.check(['compras'])" />
  </div>
</template>
<script>
import MenuAdmin from "@/components/menu/Admin";
import MenuConfirmacion from "@/components/menu/Confirmacion";
import MenuPagos from "@/components/menu/Pagos";
import MenuCompras from "@/components/menu/Compras";
import MenuCaja from "@/components/menu/Caja";

export default {
  components: {
    MenuAdmin,
    MenuCaja,
    MenuConfirmacion,
    MenuPagos,
    MenuCaja,
    MenuCompras,
  },
};
</script>