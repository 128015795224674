<template>
  <div>
    <div class="row mb-2">
      <div class="col col-lg-7">

      </div>
      <div class="col col-lg-2">
        <input type="date" class="form-control" v-model="fecha_desde"/>
      </div>
      <div class="col col-lg-2">
        <input type="date" class="form-control" v-model="fecha_hasta"/>
      </div>
      <div class="col col-lg-1">
        <button class="btn btn-small btn-primary" @click="filtrar()">Filtrar</button>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th>Fecha Creación</th>
          <th>Nro. Orden</th>
          <th>Nro. Operacion</th>
          <th>Fecha</th>
          <th>Monto</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="val in ordenes" :key="val.id">
          <td class="text-center">{{ val.created_at }}</td>
          <td class="text-center">{{ val.order_code }}</td>
          <td class="text-center">{{ val.numero_operacion }}</td>
          <td class="text-center">{{ val.fecha }}</td>
          <td class="text-center">{{ val.monto }}</td>
        </tr>
      </tbody>
    </table>
    <div v-if="cargando" class="text-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Cargando datos...</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["cuenta_id", "mid", "tipo"],

  data() {
    return {
      cargando:false,
      fecha_desde:'',
      fecha_hasta:'',
      ordenes: [],
    };
  },

  created() {
    this.getOrdenes();
  },

  methods: {

    filtrar(){
      this.ordenes = [];
      this.cargando = true;
      axios
        .get(`orders?opt=mov-ftr&cid=${this.cuenta_id}&mid=${this.mid}&fd=${this.fecha_desde}&fh=${this.fecha_hasta}`)
        .then((resp) => {
          this.cargando = false;
          this.ordenes = resp.data.map((o) => {
            let orden = o.orden;
            let pago = orden["payment_in"];
            let cuenta_acreditar = pago.account_receive;

            return {
              created_at: orden.created_at,
              order_code: orden.order_code,
              numero_operacion: pago.operation_number,
              fecha: pago.date,
              monto: pago.total,
            };
          });
        })
        .catch(err => {
          this.cargando = false;
        })
    },


    getOrdenes() {
      axios
        .get(`orders?opt=cuenta&cid=${this.cuenta_id}&mid=${this.mid}`)
        .then((resp) => {
          this.ordenes = resp.data.map((o) => {
            let orden = o.orden;
            let pago = orden["payment_in"];
            let cuenta_acreditar = pago.account_receive;

            return {
              created_at: orden.created_at,
              order_code: orden.order_code,
              numero_operacion: pago.operation_number,
              fecha: pago.date,
              monto: pago.total,
            };
          });
        });
    },
  },
};
</script>

<style scoped>
</style>