<template>
    <table>
      <thead>
        <tr>
          <th class="text-center">ID</th>
          <th class="text-center">Fecha</th>
          <th class="text-center">Metodo</th>
          <th class="text-center">Nro. Confirmación</th>
          <th class="text-center">Codigo Orden</th>
          <th class="text-center">Descripción</th>
          <th class="text-center">Moneda</th>
          <th class="text-center">Monto</th>
          <th class="text-center"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="pago in pagos" :key="pago.id">
          <td class="text-center">
            <button
              class="btn btn-outline-primary m-1"
              @click="detallePago(pago.id)"
            >
              ID - {{ pago.id }}
            </button>
          </td>
          <td class="text-center">{{ pago.fecha }}</td>
          <td class="text-center">{{pago.voucher['card_type']}}</td>
          <td class="text-center">{{pago.voucher['operation_number']}}</td>
          <td class="text-center">{{ pago.order_code }}</td>
          <td>{{ pago.descripcion }}</td>
          <td>{{ pago.moneda_pago['name'] }}</td>
          <td class="text-right">{{ pago.monto | numFormat("0,0.00") }}</td>
          <td class="text-right"><button
                      class="btn btn-sm btn-outline-primary m-2"
                      type="button"
                      @click="downloadImage(pago.id)"
                    >
                      <i class="material-icons">image</i>
                    </button></td>
        </tr>
      </tbody>
      <tfoot class="bg-secondary">
        <tr>
          <td colspan="8" class="text-right text-white h5">
            {{ calcularTotal | numFormat("0,0.00") }}
          </td>
          <td></td>
        </tr>
      </tfoot>
    </table>
</template>

<script>
import axios from "axios";

export default {
    props:["cuenta_id", "mid", "tipo"],
  data() {
    return {
      pagos: [],
    };
  },

  created() {
      this.getPagos();
  },

  computed: {
      calcularTotal(){

          if(this.pagos.length <= 0) return 0;

          let total = 0;

          this.pagos.forEach(p => {
              total += parseFloat(p.total);
          });

          return total;
      }
  },

  methods: {

    getPagos(){
         axios.get("pagos?opt=cuenta&cid="+this.cuenta_id+'&mid='+this.mid+'&tipo=pago-orden').then((resp) => {
            this.pagos = resp.data;
        });
    },

    detallePago(pagoId) {
      let pago = this.pagos.filter((p) => p.id == pagoId)[0];

      this.$router.push({
        name: 'detalle-pago',
        params: { pagoId},
      });
      //   console.log(compra);
    },

    downloadImage(id){
        axios({
        method: "get",
        url: "pagos?opt=descargar-voucher&id="+id,
        responseType: "blob" //stream // arraybuffer
        })
        .then(response => {

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "file.png"); //or any other extension
          link.setAttribute("target", "_blank"); //or any other extension
          document.body.appendChild(link);
          link.click(); 

        })
      }
  },
};
</script>

<style scoped>
</style>