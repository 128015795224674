 <template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Ordenes de Pago
        </li>
      </ol>
    </nav>
    <!-- <OperationNumbersForm class="display-hide"/> -->
    <!-- <div class="table-responsive-xl">
      
    </div> -->

    <div class="row">
      <OrdenPagoLista v-for="(val, index) in ordersFilter"
              @remover="removerItem(index)"
              :orden="val"
              :key="val.id"
              :cuentas="cuentas"
              :index="index"
            />
    </div>
  </div>
</template>
<script>
import EventBus from "@/event-bus.js";
import ConfirmationService from "@/service/ConfirmationService";
import SearchOption from "@/components/confirm/SearchOptionsView";
import OperationNumbersForm from "@/components/confirm/OperationNumbersFormView";
import PaymentAgentsList from "@/components/confirm/PaymentAgentsList";
import OrdenPagoLista from "@/components/confirm/OrdenPagoLista";

export default {
  components: {
    SearchOption,
    OperationNumbersForm,
    PaymentAgentsList,
    OrdenPagoLista,
  },

  data() {
    return {
      path:process.env.VUE_APP_AXIOS_PUBLIC_URL,
      display_table: true,
      display_search: false,
      account_id: "",
      orders: [],
      payments: [],
      cuentas: [],
      image: 'https://unpkg.com/vue-image-magnifier@0.1.1/example/img/DA2D9393-4081-4384-B493-95DA1620C26D.png'
    };
  },

  async created() {
    EventBus.$on("searchParam", this.searchParam);
    EventBus.$on("searchRange", this.searchRange);
    EventBus.$on("refreshOperationNumbersList", this.refreshList);
    EventBus.$on("hideSearch", this.hideSearch);
    await ConfirmationService.Account.get('?country=2').then(
      (resp) => (this.cuentas = resp.data.data)
    );
    await ConfirmationService.Order.get().then(
      (resp) => (this.orders = resp.data)
    );
    await ConfirmationService.PaymentAgent.get().then(
      (resp) => (this.payments = resp.data)
    );
  },

  computed: {
    accountsFilter() {
      const data = this.orders;
      const result = [];
      const map = new Map();

      for (const val of data) {
        if (val.payment_in) {
          let account = val.payment_in.account_receive;
          if (!map.has(account.account_id)) {
            map.set(account.account_id, true); // set any value to Map
            result.push({
              id: account.account_id,
              name: account.name,
            });
          }
        }
      }

      return result;
    },

    ordersFilter() {
      let filter_data = this.orders;

      if (this.account_id != "") {
        filter_data = filter_data.filter((order) => {
          // console.log("Received "+JSON.parse(order.payment_in.account_receive).account_id+" Selected "+this.account_id)
          return (order.payment_in.account_receive.account_id == this.account_id);
        });
      }

      return filter_data;
    },
  },

  methods: {

    removerItem(i){
      this.orders.splice(i, 1);
    },
    showSearchOption() {
      this.display_search = true;
      this.display_table = false;
      /*  this.$modal.show('search') */
    },

    async refreshList() {
      this.orders = [];
      await ConfirmationService.Order.get().then(
        (resp) => (this.orders = resp.data)
      );
    },

    searchParam(data) {
      this.display_search = false;
      this.display_table = true;
      let query = "?opt=" + data.search_option + "&val=" + data.param;

      ConfirmationService.Order.get(query)
        .then((resp) => {
          this.orders = [];
          if (resp.data.length <= 0 && resp.data.order_type_id == 3) {
            window.swal(
              "Información",
              "No se encontraron resultados.",
              "warning"
            );
          }
          this.orders = resp.data;
        })
        .catch((error) => {
          let obj = error.response.data.error.message;
          let message = "";

          for (const prop in obj) {
            if (prop == "no-confirmed") {
              return window.swal("Información", obj[prop], "error");
            }
            message += obj[prop] + "\n";
            //console.log(`obj.${prop} = ${obj[prop]}`);
          }
          window.swal("Información", message, "error");
        });
    },

    searchRange(data) {
      this.display_search = false;
      this.display_table = true;
      let query =
        "?opt=range" +
        "&fd=" +
        data.from_date +
        "&td=" +
        data.to_date +
        "&s=" +
        data.status_id +
        "&c=" +
        data.currency_id;
      this.orders = [];
      ConfirmationService.Order.get(query)
        .then((resp) => (this.orders = resp.data))
        .catch((error) => {
          let obj = error.response.data.error.message;
          let message = "";

          for (const prop in obj) {
            if (prop == "no-confirmed") {
              return window.swal("Información", obj[prop], "error");
            }
            message += obj[prop] + "\n";
            //console.log(`obj.${prop} = ${obj[prop]}`);
          }
          window.swal("Información", message, "error");
        });
    },
    hideSearch() {
      this.display_search = false;
      this.display_table = true;
    },
  },
};
</script>