<template>
<div>

  <div class="card mt-2">
    <div class="card-body">
      <h5>{{bank.name}}</h5>
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 ">
          <input class="form-control" v-model="availability_amount" type="text" />
        </div>
        <div class="col-12 col-sm-12 col-md-12 col-lg-4">
          <button class="btn btn-small btn-danger" @click.prevent="removeAvailability">
            <i class="material-icons">clear</i>
          </button>
          <button class="btn btn-small btn-success ml-1" @click.prevent="updateAvailability">
            <i class="material-icons">save</i>
          </button>
        </div>
      </div>
     <!--  <p>
        <progress-bar :options="options" :value="80" />
      </p> -->
    </div>
  </div>
</div>
</template>
<script>
import axios from "axios";

export default {
  props: ["bank"],
  data() {
    return {
      availability_amount: this.bank.availability_amount,
      options: {
        text: {
          color: "#FFFFFF",
          shadowEnable: true,
          shadowColor: "#000000",
          fontSize: 14,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false
        },
        progress: {
          color: "#2dbd2d",
          backgroundColor: "#333333"
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: "line"
        }
      }
    };
  },

  methods: {
    detail() {},

    removeAvailability() {
      axios
        .delete("banks/availability/" + this.bank.id)
        .then(response => {
          swal('Información', response.data.message, 'success')
          this.$emit("refresh");
        })
    },

    updateAvailability() {
      axios
        .put("banks/availability/" + this.bank.id, {
          availability_amount: this.availability_amount
        })
        .then(response => {
          swal('Información', response.data.message, 'success')
          this.$emit("refresh");
        })
    }
  }
};
</script>