import Service from './Service';

const resource = '_rates';

export default {

    pot(query,data){
        let qry = (query) ? query :'';

        return Service.post(resource+qry, data);
    },

    postImage(data){
        return Service.post(`${resource}/image`, data, {
            headers:{
              "Content-Type": "multipart/form-data"
            }
          });
    },
    
    get(query){
        let qry = (query) ? query :'';

        return Service.get(resource+qry);
    },
    put(query, id){

        return Service.put(resource+'/'+id, query);
    },

    getById(id){
        return Service.get(resource+"/"+id);
    },

}