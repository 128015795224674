<template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/pagos">Pagos</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">Pagos Compra</li>
      </ol>
    </nav>
    <div>
      <router-link :to="{
        path:'/registrar-pago'
      }" class="btn btn-primary">Nuevo Pago</router-link>
    </div>
    <table>
      <thead>
        <tr>
          <th class="text-center">ID</th>
          <th class="text-center">Tasa Mayor</th>
          <th class="text-center">Total Pagado</th>
          <th class="text-center">Cuenta a Acreditar</th>
          <th class="text-center">Monto Bolivares</th>
          <th class="text-center">Fecha</th>
          <th class="text-center"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="compra in compras" :key="compra.id">
          <td class="text-center">
            <button
              class="btn btn-outline-primary m-1"
              @click="detalleCompra(compra.id)"
            >
              OC-{{ compra.id }}
            </button>
          </td>
          <td class="text-right">
            {{ compra.tasa_compra_mayor | numFormat("0,0.00") }}
          </td>
          <td class="text-right">
            {{ compra.monto_pago | numFormat("0,0.00") }}
          </td>
          <td class="text-center">{{ compra.cuenta.name }}</td>
          <td class="text-right">
            {{ compra.monto_moneda_compra | numFormat("0,0.00") }}
          </td>
          <td class="text-center">{{ compra.fecha }}</td>
          <td class="text-center">
            <div class="btn-group" role="group">
              <button class="btn btn-sm btn-outline-primary" @click="registrarPago(compra)">
                <i v-if="!compra.pagada" class="material-icons">monetization_on</i>
                <i v-if="compra.pagada" class="material-icons">payment</i>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot class="bg-secondary">
        <tr>
          <td colspan="3" class="text-right text-white h5">
            {{ calcularTotalPagado | numFormat("0,0.00") }}
          </td>
          <td colspan="2" class="text-right text-white h5">
            {{ calcularTotalComprado | numFormat("0,0.00") }}
          </td>
          <td colspan="2"></td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      compras: [],
    };
  },

  created() {
    axios.get("compras").then((resp) => {
      this.compras = resp.data;
    });
  },

  computed: {
    calcularTotalPagado() {
      let total = 0;

      if (!this.compras) {
        return total;
      }

      this.compras.forEach((compra) => {
        total += parseFloat(compra.monto_pago);
      });

      return total;
    },

    calcularTotalComprado() {
      let total = 0;

      if (!this.compras) {
        return total;
      }

      this.compras.forEach((compra) => {
        total += parseFloat(compra.monto_moneda_compra);
      });

      return total;
    },
  },

  methods: {
    detalleCompra(compraId) {
      let compra = this.compras.filter((c) => c.id == compraId)[0];

      this.$router.push({
        name: 'detalle-compra',
        params: { compraId, detalle: compra },
      });
      //   console.log(compra);
    },

    registrarPago(){

      this.$router.push({
        name: 'registrar-pago',
        params: { compraId, detalle: compra },
      });
    }
  },
};
</script>

<style scoped>
</style>