 <template>
  <div>
    <br />
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/">Tablero</router-link>
        </li>
        <li class="breadcrumb-item text-primary">
          <router-link tag="a" to="/compras">Compras</router-link>
        </li>
        <li class="breadcrumb-item active" aria-current="page">
          Registro de Compra
        </li>
      </ol>
    </nav>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-6 col-lg-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div v-if="detalle" class="form-group">
                    <label for="description">Orden de Compra</label>
                    <input class="form-control text-right"
                      :value="'OC-'+id"
                      disabled="disabled"
                      id="ocid"/>
                  </div>
                  <div class="form-group">
                    <label for="moneda">Moneda</label
                    ><span class="text-danger"> *</span>
                    <select class="form-control" v-model="moneda_pago_id">
                     <option v-for="moneda in monedas" :key="moneda.id" :value="moneda['id']">[{{moneda['iso']}}] {{moneda['name']}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="description">Tasa al Mayor</label
                    ><span class="text-danger"> *</span>
                    <input
                      class="form-control text-right"
                      v-model="tasaMayor"
                      id="tasaMayor"
                    />
                  </div>
                  <div class="form-group">
                    <label for="description">Bolivares a Comprar</label
                    ><span class="text-danger"> *</span>
                    <input
                      class="form-control text-right"
                      v-model="montoMonedaCompra"
                      id="montoMonedaCompra"
                    />
                  </div>
                  <div class="form-group">
                    <label for="owner_account">Cuenta a Depositar </label
                    ><span class="text-danger"> *</span>
                    <select
                      v-model="cuenta_acreditar_id"
                      id="owner_account"
                      class="form-control"
                    >
                      <option value>SELECCIONE</option>
                      <option
                        v-for="(val, index) in cuentas"
                        :key="index"
                        :value="val.id"
                      >
                        {{ val.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="description">Total a Pagar</label
                    ><span class="text-danger"> *</span>
                    <input
                      class="form-control text-right"
                      v-model="totalPago"
                      id="totalPago"
                      :disabled="true"
                    />
                  </div>

                  <div class="form-group">
                    <label for="description">Descripción</label
                    ><span class="text-danger"> *</span>
                    <input
                      class="form-control"
                      v-model="descripcion"
                      id="description"
                    />
                  </div>
                  <div v-if="detalle" class="form-group">
                    <label for="description">Fecha</label>
                    <input
                      class="form-control text-right"
                      v-model="fecha"
                      id="fecha"
                      disabled="disabled"
                    />
                  </div>
                  <div class="form-group text-right">
                    <div class="btn-group">
                      <button
                        v-if="!id"
                        class="btn btn-small btn-primary"
                        @click="registrarCompra()"
                      >
                        Registrar
                      </button>
                       <button
                        v-if="id && !pagada"
                        class="btn btn-small btn-info"
                        @click.prevent="confirmarCompra()"
                      >
                        Confirmar
                      </button>
                      <button
                        v-if="id && !pagada"
                        class="btn btn-small btn-success"
                        @click="actualizarCompra()"
                      >
                        Actualizar
                      </button>

                      <button
                        v-if="id"
                        class="btn btn-small btn-danger"
                        @click.prevent="eliminarCompra()"
                      >
                        Anular
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
 
 <script>
import axios from "axios";
import SelectList from "@/service/SelectListService";
import AccountService from "@/service/AccountService";

export default {
  props: ["compraId", "detalle"],

  data() {
    return {
      pagada: false,
      id: null,
      moneda_pago_id:2,
      tasaMayor: "",
      montoMonedaCompra: "",
      cuenta_acreditar_id: "",
      totalPago: "",
      descripcion: "",
      fecha: "",
      cuentas: [],
      monedas:[],
    };
  },

  async created() {
    if (this.compraId && !this.detalle) {
      this.$router.push({
        path: "/compras",
      });
    }

     await SelectList.Currency.get().then(resp => (this.monedas = resp.data));

    await AccountService.get("?country=1").then(
      (resp) => (this.cuentas = resp.data.data)
    );

    if (this.detalle) {
      let detalle = this.detalle;

      this.tasaMayor = detalle.tasa_compra_mayor;
      this.montoMonedaCompra = detalle.monto_moneda_compra;
      this.cuenta_acreditar_id = detalle.cuenta_acreditar_id;
      this.totalPago = detalle.monto_pago;
      this.fecha = detalle.fecha;
      this.id = detalle.id;
      this.descripcion = detalle.descripcion;
      this.pagada = detalle.pagada;
      this.moneda_pago_id = detalle.moneda_pago_id;
    }
  },


  watch:{
    montoMonedaCompra(actual, viejo){
      let pago = 0;
      if(this.tasaMayor > 0 && actual > 0){
        pago = actual / this.tasaMayor;
        
        if(typeof(pago) == 'number'){
          this.totalPago = pago.toFixed(2);
        }
      }
    }
  },

  methods: {
    registrarCompra() {
      let data = {
        tasa_compra_mayor: this.tasaMayor,
        // moneda_compra_id : this.moneda_compra_id,
        monto_moneda_compra: this.montoMonedaCompra,
        moneda_pago_id : this.moneda_pago_id,
        monto_pago: this.totalPago,
        cuenta_acreditar_id: this.cuenta_acreditar_id,
        // fecha : this.fecha,
        descripcion: this.descripcion,
      };

      axios
        .post("compras", data)
        .then((resp) => {
          this.$router.push({
            path:'/compras'

          }
          );
          swal(resp.data.message, "", "success");
        })
        .catch((err) => {
          let mensaje = err.response.data.error.message;

          swal("Detalle del Error:", mensaje.toString(), "error");
        });
    },

    actualizarCompra() {
      let data = {
        id: this.compraId,
        tasa_compra_mayor: this.tasaMayor,
        // moneda_compra_id : this.moneda_compra_id,
        monto_moneda_compra: this.montoMonedaCompra,
        moneda_pago_id : this.moneda_pago_id,
        monto_pago: this.totalPago,
        cuenta_acreditar_id: this.cuenta_acreditar_id,
        descripcion: this.descripcion,
        // fecha : this.fecha,
      };

      axios
        .post("compras?opt=update", data)
        .then((resp) => {
          swal("Registro actualizado exitosamente.", "", "success");
        })
        .catch((err) => {
          let mensaje = err.response.data.error.message;

          swal("Detalle del Error:", mensaje.toString(), "error");
        });
    },

    confirmarCompra(){
      if(this.detalle.pagos_compra.length <= 0){
        return swal("La compra no tiene asociado pagos.", "", "info");
      }

       swal({
        icon: "warning",
        title: "",
        text: "¿Segur@ de confirmar la Orden de Compra?",
        buttons: {
          cancel: true,
          confirm: "Continuar",
        },
      }).then((conf) => {
        if (conf) {
            axios.post("compras?opt=confirmar", {id:this.id})
            .then(resp => {
              swal("Orden de Compra confirmada exitosamente.", "", "success");
              this.$router.push({
                path:'/compras'
              });
            })
            .catch(err => {
              let mensaje = err.response.data.error.message;
              swal("Detalle del Error:", mensaje.toString(), "error");
            })
        }
      });
    },

    eliminarCompra() {
      swal({
        icon: "warning",
        title: "",
        text: "¿Segur@ de eliminar la Orden de Compra?",
        buttons: {
          cancel: true,
          confirm: "Continuar",
        },
      }).then((conf) => {
        if (conf) {
            axios.delete("compras/"+this.compraId)
            .then(resp => {
              swal("Registro eliminado exitosamente.", "", "success");
              this.$router.push({
                path:'/compras'
              });
            })
            .catch(err => {
              let mensaje = err.response.data.error.message;
              swal("Detalle del Error:", mensaje.toString(), "error");
            })
        }
      });
    },
  },
};
</script>
 
 <style scoped>
</style>