<template>
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div class="card border-success">
                <div class="card-body">
                    <div class="row">
                        <div class="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <label for="pad_payment_method">Metodo de Pago</label>
                            <select id="pad_payment_method" class="form-control" v-model="payment_method" disabled>
                                <option value>SELECCIONE</option>
                                <option v-for="val in payment_methods" :key="val.id" :value="val.id">{{val.name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <label for="pad_account">Cuentas de Recepción</label>
                            <select id="pad_account" class="form-control" v-model="account" disabled>
                                <option value>SELECCIONE</option>
                                <option v-for="val in accounts" :key="val.id" :value="val.id">{{val.name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <label for="pad_currency">Moneda</label>
                            <select id="pad_currency" class="form-control" v-model="currency" disabled>
                                 <option value>SELECCIONE</option>
                                <option v-for="val in currencies" :key="val.id" :value="val.id">{{val.name}}</option>
                            </select>
                        </div>
                        <div class="form-group col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <label for="pad_date">Fecha de la Operación</label>
                            <input id="pad_date" type="date" class="form-control" v-model="date" disabled/>
                        </div>
                        <div class="form-group col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <label for="pad_time">Hora</label>
                            <input id="pad_time" type="time" class="form-control" v-model="time" disabled/>
                        </div>
                        <div class="form-group col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <label for="pad_noperation">Nro. de Operación</label>
                            <input id="pad_noperation" type="text" class="form-control" v-model="operation_number" disabled/>
                        </div>
                        <div class="form-group col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <label for="pad_amount">Monto</label>
                            <input id="pad_amount" type="text" class="form-control" v-model="amount" disabled/>
                        </div>
                        <div class="form-group col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <label for="pad_comission">C. Provincia</label>
                            <input id="pad_comission" type="text" class="form-control" v-model="comission_amount" disabled/>
                        </div>
                        <div class="form-group col-sm-12 col-md-12 col-lg-6 col-xl-6">
                            <label for="pad_total">Total</label>
                            <input id="pad_total" type="text" class="form-control" v-model="total" disabled/>
                        </div>
                        <div v-if="!confirmed && annulled != 1" class="form-group col-sm-12 text-right">
                            <div class="btn-group">
                                <button class="btn btn-danger" @click="deletePaymentAgent">Anular</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import EventBus from "@/event-bus.js";
import Confirmation from "@/modules/Confirmation";
import ConfirmationService from "@/service/ConfirmationService";
import SelectListService from "@/service/SelectListService";
import PaymentAgentDetail from '@/components/confirm/PaymentAgentDetail'
export default {
    props:["id"],
    data(){
        return {
            payment_method:'',
            account:'',
            currency:'',
            date:'',
            time:'',
            operation_number:'',
            comission_amount:'',
            total:'',
            amount:'',
            confirmed:true,
            annulled:false,
            payment_methods:[],
            accounts:[],
            currencies:[],
        }
    },

    async created() {
        await SelectListService.PaymentMethod.get().then(
            resp => (this.payment_methods = resp.data)
        );
        await ConfirmationService.Account.get().then(
            resp => (this.accounts = resp.data.data)
        );
        await SelectListService.Currency.get().then(
            resp => (this.currencies = resp.data)
        );
        await ConfirmationService.OperationNumbers.get('?opt=nc').then(
            resp => (this.operation_numbers = resp.data)
        );
        await ConfirmationService.PaymentAgent.getById(this.id).then( resp => this.setDataForm(resp.data));

        this.payment_agent_id = this.id;
    },
    watch: {
        $route(to, from) {
            ConfirmationService.PaymentAgent.getById(this.id).then( resp => this.setDataForm(resp.data));
        }
    },
     methods: {
        setDataForm(data){
                this.account = JSON.parse(data.account_receive).account_id;
                this.payment_method = data.payment_method_id;
                this.operation_number = data.operation_number;
                this.currency = data.currency_id;
                this.date = data.date;
                this.time = data.time;
                this.amount = data.amount;
                this.total = data.total;
                this.annulled = data.annulled;
                this.confirmed = (data.confirmed == 0) ? false : true;
                this.comission_amount = data.comission_province_amount;
        },
       deletePaymentAgent(){
        
           ConfirmationService.PaymentAgent.delete(this.id)
           .then(resp => {
               this.annulled = resp.data.annulled
               swal('Información', resp.data.message, 'success')
                this.$router.push({path:'/confirm/payment-agents'});
           })
           .catch(error => {

           })
       }

    }
}
</script>