<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th class="text-center th-min">
            <div class="form-group">
              <input type="date" v-model="date" class="form-control" />
            </div>
          </th>
          <th class="text-center th-min">
            <div class="form-group">
              <select v-model="status" class="form-control">
                <option value="">Estatus</option>
                <option
                  v-for="val in statusFilter"
                  :key="val.id"
                  :value="val.id"
                >
                  {{ val.name }}
                </option>
              </select>
            </div>
          </th>
          <th class="text-center th-min">
            <div class="form-group">
              <select v-model="requested_by" class="form-control">
                <option value="">Solicitado por</option>
                <option
                  v-for="val in requestedByFilter"
                  :value="val.id"
                  :key="val.id"
                >
                  {{ val.name }}
                </option>
              </select>
            </div>
          </th>
          <th class="text-center th-min">
            <div class="form-group">
              <select v-model="register_by" class="form-control">
                <option value="">Registrado por</option>
                <option
                  v-for="val in registerByFilter"
                  :value="val.id"
                  :key="val.id"
                >
                  {{ val.name }}
                </option>
              </select>
            </div>
          </th>
          <th class="text-center th-min">
            <div class="form-group">
              <select v-model="currency" class="form-control">
                <option value="">TODOS</option>
                <option
                  v-for="val in currencyFilter"
                  :key="val.id"
                  :value="val.id"
                >
                  {{ val.name }}
                </option>
              </select>
            </div>
          </th>
          <th class="text-center th-min">Monto</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="val in expensesFilter" :key="val.id">
          <td class="text-center">
            <router-link
              tag="a"
              :to="{ path: '/expenses/detail/' + val.id }"
              class="text-primary"
            >
              {{ val.created_at }}
            </router-link>
          </td>
          <td class="text-center">
            {{
              val.status_id == 18 && val.confirmed == 0
                ? "POR CONFIRMAR"
                : val.estatus.name
            }}
          </td>
          <td class="text-center">{{ val.solicitado.name }}</td>
          <td class="text-center">{{ val.registrado.name }}</td>
          <td class="text-center">{{ val.moneda.name }}</td>
          <td class="text-right">{{ val.amount | numFormat("0,0.00") }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="5" class="text-right">TOTAL</td>
          <td class="text-right">{{ total_amount | numFormat("0,0.00") }}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import axios from "axios";
import ExpenseService from "@/service/ExpenseService";

export default {
    props:["cuenta_id", "mid", "tipo"],
 

  data() {
    return {
      total_amount: 0,
      date: "",
      status: "",
      register_by: "",
      requested_by: "",
      confirmed_by: "",
      currency: "",
      expenses: [],
    };
  },


  created() {
    this.getExpenses();
  },

  computed: {
    statusFilter() {
      const data = this.expenses;
      const result = [];
      const map = new Map();

      for (const status of data) {
        if (!map.has(status.status_id)) {
          map.set(status.status_id, true); // set any value to Map
          result.push({
            id: status.status_id,
            name: status.estatus.name,
          });
        }
      }

      return result;
    },

    registerByFilter() {
      const data = this.expenses;
      const result = [];
      const map = new Map();

      for (const registerBy of data) {
        if (!map.has(registerBy.user_id)) {
          map.set(registerBy.user_id, true); // set any value to Map
          result.push({
            id: registerBy.user_id,
            name: registerBy.registrado.name,
          });
        }
      }

      return result;
    },

    requestedByFilter() {
      const data = this.expenses;
      const result = [];
      const map = new Map();

      for (const val of data) {
        if (!map.has(val.user_request_id)) {
          map.set(val.user_request_id, true); // set any value to Map
          result.push({
            id: val.user_request_id,
            name: val.solicitado.name,
          });
        }
      }

      return result;
    },

    currencyFilter() {
      const data = this.expenses;
      const result = [];
      const map = new Map();

      for (const currency of data) {
        if (!map.has(currency.currency_id)) {
          map.set(currency.currency_id, true); // set any value to Map
          result.push({
            id: currency.currency_id,
            name: currency.moneda.name,
          });
        }
      }

      return result;
    },

    expensesFilter() {
      var data_expenses = this.expenses;

      if (this.date != "") {
        let replace_date = this.date.split("-");
        replace_date =
          replace_date[2] + "-" + replace_date[1] + "-" + replace_date[0];


        data_expenses = data_expenses.filter((d) => {
            let s_date = d.created_at.split(' ')[0].split('-');
            let fecha = s_date[2] + "-" + s_date[1] + "-" + s_date[0];
            return (fecha == replace_date)
        });
      }

      if (this.status != "") {
        data_expenses = data_expenses.filter((d) => d.status_id == this.status);
      }

      if (this.register_by != "") {
        data_expenses = data_expenses.filter(
          (d) => d.user_id == this.register_by
        );
      }

      if (this.confirmed_by != "") {
        data_expenses = data_expenses.filter(
          (d) => d.confirmed_by_id == this.confirmed_by
        );
      }

      if (this.currency != "") {
        data_expenses = data_expenses.filter(
          (d) => d.currency_id == this.currency
        );
      }

      this.showTotal(data_expenses);

      return data_expenses;
    },
  },

  methods: {

    showTotal(data) {
      if (data && data.length > 0) {
        var total = 0;
        for (let i = 0; i < data.length; i++) {
          total += parseFloat(data[i].amount);
        }
      }

      this.total_amount = total;
    },

    getExpenses() {
      axios
        .get(`vales?opt=mov-vale&cid=${this.cuenta_id}&mid=${this.mid}`)
        .then((response) => {
          this.expenses = response.data;
        })
        .catch((error) => {
          window.M.toast({ html: error.response.data.msg });
        });
    },
  },
};
</script>